import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import useBasicDropdownSearch from 'hooks/useBasicDropdownSearch';

import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import TextInputDash from 'components/TextInputDash';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import {
  createCheck,
  createMerchant,
  editCheck,
  uploadMerchantLogo,
} from '../../API/backend_helper';
import MerchantsDropdown from '../../components/MerchantsDropdown';
import DatePickerDash from '../../components/DatePickerDash';
import VendorForm from '../vendors/VendorForm';

import {
  formatDateLocally,
  formatNumberGlobally,
  getCurrencySymbol,
  getFormattedDate,
  hasUpToTwoDecimalPlaces,
  roundToTwo,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';

const AddEditCheck = ({
  vendors,
  allAccounts = [],
  setVendors,
  setIsActive,
  editCheckData,
  loadChecks,
  setEditCheckData,
  allBills = [],
  BILL_CHECK,
  VENDOR_CHECK,
  checkType,
  BillIdParam = '',
  setSearchParams,
}) => {
  const formikRef = useRef();

  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [initialLoader] = useState(false);

  const [vendorModalOpen, setVendorModalOpen] = useState(false);

  const options = useMemo(
    () => ({
      mode: 'single',
      enableTime: false,
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  const handleSubmit = async (values, formikHandler) => {
    if (values.status === 'draft') {
      setDraftLoading(true);
    } else {
      setLoading(true);
    }
    try {
      const {
        vendorId,
        date,
        amount,
        accountId,
        checkNumber,
        memo,
        billId,
        status,
      } = values;

      const data = {
        accountId,
        checkNumber,
        amount,
        status,
        memo,

        // currency: selectedCurrency,
      };
      data.date = getFormattedDate(date, teamDateFormat);
      if (!editCheckData?.id) {
        data.payeeType = checkType === BILL_CHECK ? 'bill' : 'vendor';
      } else {
        data.payeeType = editCheckData?.payeeType;
      }
      if (checkType === BILL_CHECK) {
        data.billId = billId;
      } else if (checkType === VENDOR_CHECK) {
        data.vendorId = vendorId;
      }

      if (editCheckData?.id) {
        await editCheck(editCheckData?.id, data);
      } else {
        await createCheck(data);
      }

      await loadChecks({ loading: false });
      setIsActive(false);

      if (editCheckData?.id) {
        setEditCheckData(null);
      }

      if (editCheckData?.id) {
        toast.success('Check Updated Successfully');
      } else {
        toast.success('Check Created Successfully');
      }
      if (BillIdParam) {
        setSearchParams({});
      }

      formikHandler.resetForm();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
      setDraftLoading(false);
    }
  };

  const handleUploadVendorLogo = async (data, id) => {
    try {
      await uploadMerchantLogo(data, id);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddVendor = async (data) => {
    try {
      const response = await createMerchant(data);
      setVendors((prev) => [response, ...prev]);
      formikRef?.current?.setFieldValue('vendorId', response?.id);
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };
  const billOptions = useMemo(
    () =>
      (allBills || [])?.map((bill) => ({
        ...bill,
        name: `${bill?.vendor?.name} | ${getCurrencySymbol(bill?.currency)}${parseFloat(bill?.total).toFixed(2)}`,
        id: bill?.id,
      })),
    [allBills],
  );

  const {
    dropdownOptions: billsOption,
    handleDropdownSearch: handleBillSearch,
    resetDropdownSearch: resetBillSearch,
  } = useBasicDropdownSearch({
    options: billOptions,
  });

  useEffect(() => {
    if (
      formikRef.current?.setFieldValue &&
      billOptions?.length > 0 &&
      BillIdParam
    ) {
      formikRef.current?.setFieldValue('billId', parseInt(BillIdParam));
      const bill = (billOptions || [])?.find(
        (bill) => bill?.id === parseInt(BillIdParam),
      );
      if (bill?.id) {
        formikRef.current?.setFieldValue('amount', bill?.total);
      }
    }
  }, [billOptions, formikRef?.current?.setFieldValue, BillIdParam, checkType]);

  return (
    <>
      <ModalBasic
        modalOpen={vendorModalOpen}
        setModalOpen={setVendorModalOpen}
        title="Add Vendor"
        width="!w-[1088px]"
      >
        <VendorForm
          handleUploadVendorLogo={handleUploadVendorLogo}
          handleAddVendor={handleAddVendor}
          setFormOpen={setVendorModalOpen}
          isModalView
          activeVendor={null}
        />
      </ModalBasic>

      <div className="   ">
        <div className="flex justify-between items-center mb-10">
          <h3 className="text-[36px] font-medium text-[#00000099]">
            {editCheckData?.id ? 'Edit Check' : 'Add a Check'}
          </h3>
          <button
            onClick={() => {
              if (Object.keys(editCheckData || {}).length > 0) {
                setEditCheckData(null);
              }
              setIsActive(false);
            }}
            type="button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6.17676"
                y="7.82422"
                width="2.3292"
                height="19.7982"
                rx="1.1646"
                transform="rotate(-45 6.17676 7.82422)"
                fill="#667085"
              />
              <rect
                x="20.177"
                y="6.17676"
                width="2.3292"
                height="19.7982"
                rx="1.1646"
                transform="rotate(45 20.177 6.17676)"
                fill="#667085"
              />
            </svg>
          </button>
        </div>
        {initialLoader ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 mb-5">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className="">
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                vendorId: editCheckData?.vendorId || null,
                date: formatDateLocally(
                  editCheckData?.date?.split('T')?.[0] || new Date(),
                  teamDateFormat,
                ),
                amount: formatNumberGlobally(editCheckData?.amount, true) || '',
                accountId: editCheckData?.accountId || null,
                checkNumber: editCheckData?.checkNumber || '',
                billId: editCheckData?.billId || '',

                memo: editCheckData?.memo || '',
              }}
              validationSchema={Yup.object({
                amount: Yup.string().required('Please Enter Amount'),
                accountId: Yup.string().required('Please Select Account'),
                checkNumber: Yup.string()
                  .trim()
                  .max(255, 'Check Number must be at most 255 characters')
                  .required('Please Enter Check Number'),
                memo: Yup.string()
                  .trim()
                  .max(300, 'Notes must be 300 characters or less'),
              })}
              validate={(values) => {
                const errors = {};
                if (checkType === BILL_CHECK && !values?.billId) {
                  errors.billId = 'Please Select a Bill';
                }
                if (checkType === VENDOR_CHECK && !values?.vendorId) {
                  errors.vendorId = 'Please Select a Vendor';
                }
                if (!values?.date) {
                  errors.date = 'Please Select a date';
                }
                if (parseFloat(values?.amount || 0) < 0) {
                  errors.amount = 'Amount must be a positive number';
                }

                return errors;
              }}
              onSubmit={handleSubmit}
            >
              {(validation) => (
                <Form className=" flex-1 overflow-hidden">
                  <div className=" pb-1 grid md:grid-cols-2 xl:grid-cols-3 gap-3 sm:gap-6 sm:gap-y-1 gap-y-1 mb-8">
                    <div className="">
                      {checkType === VENDOR_CHECK && (
                        <div
                          className={`flex flex-col items-start w-full ${
                            validation.errors.vendorId ? '' : 'pb-5'
                          }`}
                        >
                          <label
                            className={`text-sm font-medium text-slate-600 block mb-2 ${
                              validation.touched.vendorId &&
                              validation.errors.vendorId
                                ? '!text-rose-400'
                                : ''
                            }`}
                            htmlFor="vendorId"
                          >
                            Vendor <span className="text-rose-500">*</span>
                          </label>
                          <MerchantsDropdown
                            vendors={vendors}
                            setVendors={setVendors}
                            inActiveVendor={
                              editCheckData?.vendor?.status === 'inactive'
                                ? editCheckData?.vendor
                                : {}
                            }
                            selectedMerchant={validation.values.vendorId}
                            setSelectedMerchant={(value) => {
                              validation.setFieldValue('vendorId', value);
                            }}
                            isSetCategoryStyle
                            height={`h-12 border-[#D0D5DD] ${
                              validation.touched.vendorId &&
                              validation.errors.vendorId
                                ? '!border-rose-400'
                                : ''
                            }`}
                            id="vendorId"
                            name="vendorId"
                            placeholder="Select Vendor"
                            addNewOptionButton
                            handleNewOptionButton={() => {
                              setVendorModalOpen(true);
                            }}
                          />
                          {validation.touched.vendorId &&
                          validation.errors.vendorId ? (
                            <div className="text-xs text-rose-400 mt-1">
                              {validation.errors.vendorId}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {checkType === BILL_CHECK && (
                        <div
                          className={` flex flex-col items-start w-full ${
                            validation.errors.billId ? '' : 'pb-5'
                          }`}
                        >
                          <label className="text-slate-600 text-[14px] font-medium leading-5 mb-2.5">
                            Bill <span className="text-red-500">*</span>
                          </label>
                          <DropdownFull
                            options={billsOption}
                            placeholder="Select Bill"
                            name="name"
                            selected={validation.values.billId}
                            setSelected={(bill) => {
                              validation.setFieldValue('billId', bill?.id);
                              validation.setFieldValue(
                                'amount',
                                parseFloat(bill?.total).toFixed(2),
                              );
                            }}
                            search
                            handleSearch={handleBillSearch}
                            resetSearch={resetBillSearch}
                            scrollIntoView={false}
                            selectFullObj
                            className="!h-fit !w-full"
                            error={
                              validation.touched.billId &&
                              validation.errors.billId
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className=" pb-5">
                      <DatePickerDash
                        label="Date"
                        required
                        validation={validation}
                        placeholder="Select Date"
                        value={
                          validation.values.date ? validation.values.date : null
                        }
                        options={options}
                        onChange={(selectedDates) => {
                          validation.setFieldValue('date', selectedDates[0]);
                        }}
                        name="date"
                        id="date"
                        error={
                          validation.touched.date && validation.errors.date
                            ? validation.errors.date
                            : ''
                        }
                        onBlur={(e) => {
                          if (!e.target.classList?.value?.includes('active')) {
                            validation.setFieldValue('date', e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="pb-5 flex flex-col ">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="accountId"
                      >
                        Account <span className="text-rose-500">*</span>
                      </label>
                      <CategoriesDropdown
                        allCategories={allAccounts}
                        selectedCategoryId={validation.values.accountId}
                        setSelectedCategoryId={(value) => {
                          validation.setFieldValue('accountId', value);
                        }}
                        isSetCategoryStyle
                        id="accountId"
                        name="accountId"
                        // type="account"
                        placeholderText="Select Account"
                        isError={
                          validation.touched.accountId &&
                          validation.errors.accountId
                        }
                        height={`h-12 ${
                          validation.touched.accountId &&
                          validation.errors.accountId
                            ? '!border-rose-500'
                            : '!border-[#D0D5DD] hover:!border-slate-400'
                        }   `}
                      />
                      {validation.touched.accountId &&
                      validation.errors.accountId ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.accountId}
                        </div>
                      ) : null}
                    </div>
                    <div className="">
                      <TextInputDash
                        label="Check Number"
                        id="checkNumber"
                        name="checkNumber"
                        placeholder="Check Number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.checkNumber || ''}
                        error={
                          validation.touched.checkNumber &&
                          validation.errors.checkNumber
                        }
                        required
                      />
                    </div>
                    <div className="relative flex flex-col ">
                      <label
                        className={`block text-sm text-slate-600 font-medium leading-5 mb-2.5 ${
                          validation.touched.amount &&
                          validation.errors.amount &&
                          '!text-rose-500'
                        }`}
                        htmlFor="amount"
                      >
                        Amount
                        <span className="text-rose-500"> *</span>
                      </label>
                      <input
                        id="amount"
                        type="number"
                        placeholder="0.00"
                        name="amount"
                        className={`btn pl-[3.35rem] pr-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 ${
                          validation.touched.amount &&
                          validation.errors.amount &&
                          '!border-rose-500'
                        }`}
                        onChange={validation.handleChange}
                        onBlur={(e) => {
                          validation.handleBlur(e);
                          if (
                            !hasUpToTwoDecimalPlaces(
                              parseFloat(validation.values.amount || 0),
                            )
                          ) {
                            validation.setFieldValue(
                              'amount',
                              roundToTwo(validation.values.amount),
                            );
                          }
                        }}
                        value={validation.values.amount || ''}
                        onWheel={(e) => e.target.blur()}
                      />
                      <span className="absolute top-[2.6rem] left-4 flex items-center gap-2">
                        <span className="text-slate-600">$</span>

                        <div className="w-[0.038rem] h-[1.188rem] bg-slate-600" />
                      </span>
                      {validation.touched.amount && validation.errors.amount ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.amount}
                        </div>
                      ) : null}
                    </div>

                    <div className="flex flex-col gap-2.5 ">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="memo"
                      >
                        Memo
                      </label>
                      <textarea
                        name="memo"
                        id="memo"
                        placeholder="Write A Memo"
                        className={`form-textarea px-4 py-2.5 rounded-[0.313rem] text-slate-600 justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal placeholder:text-base
                      ${
                        validation.touched.memo &&
                        validation.errors.memo &&
                        'border-rose-300'
                      }`}
                        type="memo"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.memo || ''}
                      />
                      {validation.touched.memo && validation.errors.memo ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.memo}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Modal footer */}
                  <div className="px-5 pt-4 pb-20">
                    <div className="flex flex-wrap justify-end space-x-4">
                      <button
                        type="button"
                        className="btn-sm h-11 px-8 text-base border-[#667085]  hover:border-slate-800  text-[#667085]"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsActive(false);
                          if (Object.keys(editCheckData || {}).length > 0) {
                            setEditCheckData(null);
                          }
                        }}
                      >
                        Close
                      </button>
                      {checkPermission(
                        PermissionKeys.APPROVE_BILLS,
                        accessType,
                        permissions,
                      ) &&
                        (!editCheckData?.id ||
                          editCheckData?.status === 'draft') && (
                          <button
                            type="button"
                            className="btn-sm h-11 px-4 text-base bg-white text-indigo-500 hover:text-indigo-600 disabled:opacity-70 disabled:cursor-not-allowed border-indigo-500 hover:border-indigo-600"
                            disabled={loading || draftLoading}
                            onClick={() => {
                              validation.setFieldValue('status', 'draft');
                              validation.handleSubmit();
                            }}
                          >
                            {draftLoading && (
                              <svg
                                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                              </svg>
                            )}
                            Save as Draft
                          </button>
                        )}
                      {checkPermission(
                        PermissionKeys.APPROVE_BILLS,
                        accessType,
                        permissions,
                      ) && (
                        <button
                          type="button"
                          className="btn-sm h-11 px-4 text-base bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
                          disabled={loading || draftLoading}
                          onClick={() => {
                            validation.setFieldValue('status', 'approved');
                            validation.handleSubmit();
                          }}
                        >
                          {loading && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          {editCheckData?.id
                            ? 'Update & Approve'
                            : 'Save & Approve'}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default AddEditCheck;
