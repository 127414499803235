/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import TaxModal from 'pages/settings/taxes/TaxModal';
import DropdownFull from '../../components/DropdownFull';
import ModalBasic from '../../components/ModalBasic';
import {
  createAccount,
  createTax,
  updateAccountById,
} from '../../API/backend_helper';
import {
  AccountColorOptions,
  areArraysEqual,
  getContrast,
  TEAM_CURRENCY,
} from '../../utils/Utils';
import ColorPicker from '../component/ColorPicker';

const AccountsModal = ({
  isOpen,
  setIsOpen,
  accountTypes,
  accountSubTypes,
  loadData,
  account,
  edit,
  currencies,
  subType,
  mainType,
  taxes = [],
  loadTaxes = () => {},
  setNewAddedCategory = () => {},
  accounts = [],
  parentAccount,
  setParentAccount = () => {},
}) => {
  const formikRef = useRef();

  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [localAccount, setLocalAccount] = useState(account);
  const [mainTypeId, setMainTypeId] = useState('');
  const [subTypeId, setSubTypeId] = useState('');
  const [selectedColor, setSelectedColor] = useState(
    mainType ? mainType?.color || '#D2D6DB' : '#D2D6DB',
  );
  const [renderCurrency, setRenderCurrency] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [taxModalOpen, setTaxModalOpen] = useState(false);
  const [taxesDropdownNoClick, setTaxesDropdownNoClick] = useState(false);
  const [localAccounts, setLocalAccounts] = useState([]);

  useEffect(() => {
    if (accounts?.length > 0 && isOpen) {
      const filteredData = accounts?.map((item) => ({
        ...item, // Spread the existing object
        categories: item?.categories?.filter(
          (category) =>
            category?.status === 'active' &&
            category?.subType?.name !== 'Sales Taxes to Pay',
        ), // Filter categories
      }));
      setLocalAccounts(filteredData);
    }
  }, [accounts, isOpen]);

  useEffect(() => {
    if (subType) {
      formikRef.current.setFieldValue('subTypeId', subType);
      setSubTypeId(subType);
    }
  }, [subType, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      formikRef.current.resetForm();
      setMainTypeId('');
      setSubTypeId('');
      setLocalAccount(null);
      setParentAccount(null);
      setSelectedColor('#D2D6DB');
      setRenderCurrency(false);
      setSelectedTaxes([]);
      setLocalAccounts([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (account) {
      // setSelectedTaxes(account?.taxes?.map((t) => t?.id));
      setLocalAccount(account);
    }
  }, [edit, parentAccount, account]);

  useEffect(() => {
    if (localAccount) {
      setSubTypeId(localAccount?.subType?.name);
      setMainTypeId(
        parentAccount?.id ? parentAccount?.type : localAccount?.type,
      );
      if (parentAccount?.id && parentAccount?.color) {
        setSelectedColor(parentAccount?.color);
      }
    }
  }, [localAccount, isOpen, parentAccount]);

  useEffect(() => {
    if (isOpen && !account) {
      if (mainType) {
        setMainTypeId(mainType?.name);
      } else {
        setMainTypeId('');
      }
      if (subType) {
        setSubTypeId(subType);
      } else {
        setSubTypeId('');
      }
    }
  }, [isOpen]);

  const handleSubmit = async (values, formikHelper) => {
    setLoading(true);
    const subTypeInt = accountSubTypes?.find(
      (subType) => subType.name === values.subTypeId,
    )?.id;
    const data = {
      ...values,
      subTypeId: subTypeInt,
    };
    if (!values?.isChildAccount && values?.parentAccountId) {
      data.parentAccountId = null;
    }

    if (!edit && values?.taxes) {
      data.taxes = values?.taxes ? [values?.taxes] : [];
    } else if (
      // !areArraysEqual(
      //   localAccount?.taxes?.map((t) => t?.id) || [],
      //   selectedTaxes,
      // )
      localAccount?.taxes?.[0]?.id !== values?.taxes
    ) {
      data.taxes = values?.taxes ? [values?.taxes] : [];
    } else {
      data.taxes = [];
    }

    try {
      const res = !edit
        ? await createAccount(data)
        : await updateAccountById(localAccount.id, data);

      if (res?.id) {
        setNewAddedCategory?.(res);
        await loadData(true, res);
        formikHelper.resetForm();
        setIsOpen(false);
        setSubTypeId('');
        setMainTypeId('');
        if (!edit) {
          toast.success('Account created successfully.');
        } else {
          toast.success('Account updated successfully.');
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTax = async (tax) => {
    try {
      const response = await createTax(tax);
      if (response?.data?.tax?.id) {
        formikRef?.current?.setFieldValue('taxes', response?.data?.tax?.id);
        loadTaxes();
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const disabledTaxes = useMemo(() => {
    if (localAccount?.taxes?.length > 0) {
      const disabled = localAccount?.taxes?.filter((tax) => !tax?.enabled);
      return disabled || [];
    }
    return [];
  }, [localAccount]);

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={edit ? 'Edit Account' : 'Create Account'}
      width="max-w-5xl"
      noClick={taxesDropdownNoClick}
      contentId="accountsModal"
    >
      <TaxModal
        isOpen={taxModalOpen}
        setIsOpen={setTaxModalOpen}
        handleAddTax={handleAddTax}
      />
      {/* Modal content */}
      <div className="bg-white w-full overflow-x-auto">
        <Formik
          enableReinitialize
          initialValues={{
            isChildAccount: Boolean(parentAccount?.id),
            parentAccountId: parentAccount?.id || null,
            type: mainTypeId || '',
            subTypeId: subTypeId || '',
            name: localAccount?.name || '',
            taxes: localAccount?.taxes?.[0]?.id || '',
            color:
              parentAccount?.color ||
              localAccount?.color ||
              mainType?.color ||
              '#D2D6DB',
            currency:
              parentAccount?.currency ||
              localAccount?.currency ||
              team?.currency ||
              localStorage.getItem(TEAM_CURRENCY) ||
              '',
            accountNumber: localAccount?.accountNumber || '',
            description: localAccount?.description || '',
          }}
          validationSchema={Yup.object({
            isChildAccount: Yup.boolean().required('This field is required'),
            parentAccountId: Yup.string().when('isChildAccount', {
              is: true, // Apply validation when isChildAccount is true
              then: () => Yup.string().required('Parent Account is required'),
              otherwise: () => Yup.string().notRequired(),
            }),
            type: Yup.string().required('Please Select a Type'),
            subTypeId: Yup.string().required('Please Select a Sub Type'),
            name: Yup.string()
              .required('Please Enter Name')
              .max(100, 'Name must be 100 characters or less'),
            color: Yup.string().required('Please Select Color'),
            currency: Yup.string().when('isChildAccount', {
              is: false, // Apply validation when isChildAccount is true
              then: () =>
                renderCurrency
                  ? Yup.string().required('Currency is required')
                  : Yup.string().nullable(),
              otherwise: () => Yup.string().notRequired(),
            }),
            accountNumber: Yup.string().max(
              100,
              'Account Number must be 100 characters or less',
            ),
            description: Yup.string().max(
              500,
              'Description must be 500 characters or less',
            ),
            taxes: Yup.string().optional(),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => {
            useEffect(() => {
              if (subTypeId) {
                validation.setFieldValue('subTypeId', subTypeId);
              }
            }, [subTypeId]);

            useEffect(() => {
              if (validation?.values && validation?.values?.subTypeId) {
                const isCurrencyRendered = accountSubTypes?.find(
                  (subType) => subType.name === validation?.values.subTypeId,
                )?.anchorEligible;
                setRenderCurrency(isCurrencyRendered);
              }
              if (!validation?.values?.subTypeId) {
                setRenderCurrency(false);
              }
            }, [validation?.values?.subTypeId, validation?.values?.type]);

            useEffect(() => {
              if (!localAccount && validation?.values?.type && accountTypes) {
                const selectedType = accountTypes?.find(
                  (type) => type?.name === validation.values.type,
                );
                setSelectedColor(selectedType?.color);
                validation.setFieldValue('color', selectedType?.color);
              }
            }, [validation?.values?.type]);
            return (
              <Form>
                <div className="flex flex-col items-center justify-center gap-3 sm:gap-9 self-stretch py-[2.25rem] px-12">
                  <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[1.2rem] self-stretch">
                    <div className="col-span-full grid sm:grid-cols-2 lg:grid-cols-3 gap-2.5 w-full ">
                      <label className=" cursor-pointer text-base font-medium text-nowrap flex items-center">
                        <input
                          type="checkbox"
                          checked={validation.values.isChildAccount}
                          className="form-checkbox mr-2 h-5 w-5"
                          onChange={() =>
                            validation.setFieldValue(
                              'isChildAccount',
                              !validation.values.isChildAccount,
                            )
                          }
                          // disabled={parentAccount?.id}
                        />
                        This is a sub-account
                      </label>

                      {validation?.values?.isChildAccount && (
                        <div className="flex flex-col">
                          <label
                            className="block mb-2 text-sm text-slate-600 font-medium leading-5"
                            htmlFor="type"
                          >
                            Parent Account
                            <span className="text-rose-500">*</span>
                          </label>
                          <CategoriesDropdown
                            allCategories={localAccounts}
                            selectedCategoryId={
                              validation?.values?.parentAccountId
                            }
                            setSelectedCategoryId={(value, account) => {
                              validation.setFieldValue(
                                'parentAccountId',
                                value,
                              );
                              validation.setFieldValue(
                                'currency',
                                account?.currency,
                              );
                              validation.setFieldValue('type', account?.type);
                              validation.setFieldValue(
                                'subTypeId',
                                account?.subType?.name,
                              );
                              validation.setFieldValue(
                                'taxes',
                                account?.taxes?.[0]?.id || '',
                              );
                            }}
                            isSetCategoryStyle
                            showSubAccount={false}
                            disabled={parentAccount?.id}
                            id="parentAccountId"
                            name="parentAccountId"
                            height="h-12"
                            type="account"
                          />
                          {validation.touched.parentAccountId &&
                          validation.errors.parentAccountId ? (
                            <div className="text-xs mt-1 text-rose-500">
                              {validation.errors.parentAccountId}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col items-start w-full">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="type"
                      >
                        Account Type<span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={accountTypes?.map((d) => ({
                          ...d,
                          name: d.name,
                          id: d.name,
                        }))}
                        name="name"
                        setSelected={(name) =>
                          validation.setFieldValue('type', name)
                        }
                        selected={validation.values.type}
                        error={
                          validation.touched.type && validation.errors.type
                        }
                        disabled={
                          validation?.values?.isChildAccount || localAccount?.id
                        }
                        accounts
                        accountStyle
                        placeholder="Select"
                        className="!h-fit"
                      />
                    </div>
                    <div className="flex flex-col items-start w-full">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="subTypeId"
                      >
                        Account Sub-Type<span className="text-rose-500">*</span>
                      </label>
                      <DropdownFull
                        options={accountSubTypes
                          ?.filter((d) => {
                            const selectedType = accountTypes?.find(
                              (type) => type?.name === validation.values.type,
                            );
                            return d.type === selectedType?.name;
                          })
                          .map((d) => ({
                            ...d,
                            subTypeId: d.name,
                            id: d.name,
                          }))}
                        name="subTypeId"
                        setSelected={(option) => {
                          validation.setFieldValue('subTypeId', option);
                        }}
                        selected={validation.values.subTypeId}
                        error={
                          validation.touched.subTypeId &&
                          validation.errors.subTypeId
                        }
                        accounts
                        accountStyle
                        subType
                        placeholder="Select"
                        disabled={
                          !validation.values.type ||
                          validation?.values?.isChildAccount
                        }
                        className="!h-fit"
                      />
                    </div>
                    <div className="flex flex-col items-start w-full">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="name"
                      >
                        Account Name<span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.name &&
                                      validation.errors.name &&
                                      'border-rose-500'
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {!validation.values.isChildAccount && (
                    <div className="flex flex-row w-full items-center justify-start">
                      <div className="flex flex-col gap-2.5 items-start">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="color"
                        >
                          Select Display Color
                          <span className="text-rose-500">*</span>
                        </label>
                        <div className="relative h-[7.375rem] w-full px-4 sm:px-0 sm:w-[28.337rem] py-3 flex flex-col justify-center items-center gap-2.5 self-stretch rounded-[10px] border border-dashed border-indigo-500 shadow-md">
                          <div className="flex flex-col justify-center items-center gap-2.5">
                            <div
                              className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center text-black"
                              style={{
                                backgroundColor: selectedColor,
                                border: !selectedColor
                                  ? '1px solid black'
                                  : 'none',
                              }}
                            >
                              <div
                                className={`${getContrast(selectedColor, 'text-black', 'text-white')} text-xs uppercase font-normal`}
                              >
                                {validation.values.name
                                  ? validation.values.name
                                      .split(' ')
                                      .slice(0, 2)
                                      .map((word) => word.charAt(0))
                                      .join('')
                                  : ''}
                              </div>
                            </div>
                            <p className="text-slate-600 text-sm">
                              Click to select the color
                            </p>
                            <ColorPicker
                              ColorOptions={AccountColorOptions}
                              selectedColor={selectedColor}
                              setSelectedColor={setSelectedColor}
                            />
                            {validation.touched.color &&
                            validation.errors.color ? (
                              <span className="text-xs absolute -bottom-9 text-rose-500">
                                {validation.errors.color}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex items-start self-stretch gap-[1.2rem]">
                    <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="accountNumber"
                      >
                        Account Number / Code
                      </label>
                      <input
                        id="accountNumber"
                        type="text"
                        placeholder="Optional"
                        name="accountNumber"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.accountNumber &&
                                      validation.errors.accountNumber &&
                                      'border-rose-500'
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.accountNumber || ''}
                      />
                      {validation.touched.accountNumber &&
                      validation.errors.accountNumber ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.accountNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <input
                        id="description"
                        type="text"
                        placeholder="Optional"
                        name="description"
                        className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white
                                    placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                      validation.touched.description &&
                                      validation.errors.description &&
                                      'border-rose-300'
                                    }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex items-start self-stretch gap-[1.2rem]">
                    {!validation?.values?.isChildAccount && renderCurrency && (
                      <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="currency"
                        >
                          Currency<span className="text-rose-500">*</span>
                        </label>
                        <DropdownFull
                          options={currencies?.map((c) => ({
                            ...c,
                            id: c?.isoCode,
                            name: `${c?.symbol} ${c?.name}`,
                          }))}
                          name="name"
                          setSelected={(id) =>
                            validation.setFieldValue('currency', id)
                          }
                          selected={validation.values.currency}
                          error={
                            validation.touched.currency &&
                            validation.errors.currency
                          }
                          accountStyle
                          bottomOfTable
                          ids={['accountsModal']}
                          scrollIntoView={false}
                          setNoClick={setTaxesDropdownNoClick}
                        />
                        {validation.touched.currency &&
                        validation.errors.currency ? (
                          <div className="text-xs mt-1 text-rose-500">
                            {validation.errors.currency}
                          </div>
                        ) : null}
                      </div>
                    )}

                    <div className="flex flex-col items-start gap-2.5 w-[28.337rem]">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="taxes"
                      >
                        The default tax setting for this account
                      </label>
                      <div className="relative w-full">
                        <DropdownFull
                          selected={validation.values.taxes}
                          setSelected={(v) => {
                            validation.setFieldValue('taxes', v);
                          }}
                          options={
                            [...disabledTaxes, ...taxes]?.map((t) => ({
                              name: `${t?.name}`,
                              id: t?.id,
                              percentage: t?.percentage,
                            })) || []
                          }
                          id="taxes"
                          placeholder="No Taxes"
                          flyout
                          width="!w-full"
                          height={`h-12 ${validation.values.taxes ? 'pr-8 ' : ''}`}
                          // disabled={validation.values.isChildAccount}
                          className="w-full"
                          addNewOptionLabel="Add A New Tax"
                          setNoClick={setTaxesDropdownNoClick}
                          name="name"
                          scrollIntoView={false}
                          handleNewOptionButton={() => setTaxModalOpen(true)}
                          addNewOptionButton
                          taxes
                          bottomOfTable
                          ids={['accountsModal']}
                        />
                        {validation.errors.taxes ? (
                          <div className="text-xs mt-1 text-rose-500">
                            {validation.errors.taxes}
                          </div>
                        ) : null}
                        {validation.values.taxes && (
                          <button
                            className="absolute top-1/2 right-2 -translate-y-1/2"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              validation.setFieldValue('taxes', '');
                            }}
                          >
                            <svg
                              className="w-[14px] h-[14px] fill-[#667085cc] group-hover:fill-slate-800 pointer-events-none"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="border-t border-[#D0D5DD] bg-white flex py-[2rem] px-12 justify-end items-center gap-4 self-stretch w-full ">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      type="button"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                      }}
                    >
                      {edit ? 'Discard' : 'Close'}
                    </button>
                    <button
                      type="submit"
                      className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                      disabled:bg-indigo-400    disabled:cursor-not-allowed"
                      disabled={loading}
                    >
                      {loading && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      {edit ? 'Save' : 'Create'}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalBasic>
  );
};

export default AccountsModal;
