import {
  assignInvoicesToMemo,
  assignMemoToInvoice,
  getInvoices,
} from 'API/backend_helper';
import ModalBasic from 'components/ModalBasic';
import ReusableTable from 'components/ReusableTable';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import DateFilter from 'pages/transactions/DateFilter';
import SearchForm from 'partials/actions/SearchForm';
import React, { useEffect, useMemo, useState } from 'react';
import {
  formatNumberGlobally,
  getCurrencySymbol,
  getCurrencySymbolFromIso,
  getFormattedDate,
} from 'utils/Utils';
import { DateTime } from 'luxon';
import Tooltip from 'components/Tooltip';

let searchTimeout = null;

const IconHeader = ({ icon, text }) => (
  <div className="flex gap-2 items-center mr-2">
    {/* {icon} */}
    <span className="font-medium text-sm">{text}</span>
  </div>
);

const tableInitialState = {
  columnSizing: {
    select: 40,
    number: 30,
    customer: 250,
    date: 100,
    dueDate: 100,
    total: 130,
    amount: 130,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  pagination: { pageIndex: 0, pageSize: 100000 },
  columnVisibility: {},
  columnOrder: [],
  showGlobalFilter: false,
};

const RecordInvoiceMemo = ({
  isOpen,
  setIsOpen,
  currencies,
  teamDateFormat,
  reloadInvoices,
}) => {
  const [expanded, setExpanded] = useState({});
  const [allInvoices, setAllInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // Table
  const [tableState, setTableState] = useState({ ...tableInitialState });
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  // Filters

  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');

  const { invoices, filters } = allInvoices || {};

  const AmountLeft = () => {
    let amount = 0;
    if (selectedInvoices?.length > 0) {
      selectedInvoices?.forEach((inv) => {
        amount += parseFloat(inv?.amountToPay);
      });
      return formatNumberGlobally(
        Math.abs(isOpen?.amountDue) - Math.abs(amount),
        true,
      );
    }
    return Math.abs(isOpen?.amountDue);
  };

  const onSelectInvoice = (invoice) => {
    let temp = JSON.parse(JSON.stringify(selectedInvoices));
    const find = selectedInvoices?.find((inv) => inv?.id === invoice?.id);
    if (find) {
      temp = temp?.filter((inv) => inv?.id !== invoice?.id);
      setSelectedInvoices(temp);
    } else {
      const amount = AmountLeft();
      temp.push({
        id: invoice?.id,
        amountDue: invoice?.amountDue,
        amountToPay:
          Math.abs(amount) >= Math.abs(invoice?.amountDue)
            ? parseFloat(Math.abs(invoice?.amountDue))?.toFixed(2)
            : parseFloat(Math.abs(amount))?.toFixed(2),
      });

      setSelectedInvoices(temp);
    }
  };

  const onAmountChange = (value, invoice) => {
    if (!value?.toString()?.match(/^[-]?\d*\.?\d{0,2}$/)) {
      return;
    }
    const findIndex = selectedInvoices?.findIndex(
      (inv) => inv?.id === invoice?.id,
    );
    if (
      (findIndex >= 0 &&
        Math.abs(parseFloat(value)) <= selectedInvoices[findIndex]?.amountDue &&
        Math.abs(parseFloat(value)) <= Math.abs(isOpen?.amountDue)) ||
      !value
    ) {
      const temp = JSON.parse(JSON.stringify(selectedInvoices));
      const tempObj = { ...temp[findIndex] };
      tempObj.amountToPay = value;
      temp[findIndex] = tempObj;
      setSelectedInvoices(temp);
    }
  };

  const getData = async ({
    type = isOpen?.invoiceType === 'invoice' ? 'memo' : 'invoice',
    search = searchValue || '',
    limit = 1000000,
    page = 1,
    loading = false,
    customers = isOpen?.customerId,
    status = selectedStatuses?.length > 0
      ? selectedStatuses
      : ['unpaid', 'overdue', 'partial'],
    startDate = startDateFilter
      ? getFormattedDate(startDateFilter, teamDateFormat)
      : '',
    endDate = endDateFilter
      ? getFormattedDate(endDateFilter, teamDateFormat)
      : '',
    sortOrder = '',
    sortDirection = '',
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
        status,
        startDate,
        endDate,
        search,
        isRecurring: false,
        orderBy: sortOrder,
        orderDirection: sortDirection,
        approvalStatus: 'approved',
      });
      setAllInvoices(res?.data);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      console.log('error', e);
    }
  };

  const onDateChange = async (startDate, endDate) => {
    await getData({
      startDate: startDate ? getFormattedDate(startDate, teamDateFormat) : '',
      endDate: endDate ? getFormattedDate(endDate, teamDateFormat) : '',
      page: 1,
      loading: true,
    });
  };

  const handleResetFilters = () => {
    setIsLoading(true);
    setSelectedStatuses([]);
    setStartDateFilter('');
    setEndDateFilter('');
    getData({
      page: 1,
      customers: [],
      status: [],
      startDate: '',
      endDate: '',
      search: '',
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getData({ search: formattedValue, page: 1 });
    }, 900);
  };

  const onAssignInvoice = async () => {
    try {
      setLoading(true);
      let res = null;
      if (isOpen?.invoiceType === 'invoice') {
        res = await assignMemoToInvoice(isOpen?.id, {
          creditMemos: selectedInvoices.map((inv) => ({
            id: inv?.id,
            amount: inv?.amountToPay,
          })),
        });
      } else {
        res = await assignInvoicesToMemo(isOpen?.id, {
          invoices: selectedInvoices.map((inv) => ({
            id: inv?.id,
            amount: inv?.amountToPay,
          })),
        });
      }
      setLoading(false);
      reloadInvoices({ loading: true });
      setIsOpen(null);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  // Table  Logic

  const renderDetailPanel = ({ row }) => {
    const isInvoiceSelected = selectedInvoices?.find(
      (inv) => inv?.id === row?.original?.id,
    );

    return (
      <div className="flex items-end gap-4">
        <div className="relative flex flex-col ">
          <label
            className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
            htmlFor="amount"
          >
            {`${isOpen?.invoiceType === 'invoice' ? 'Credit Memo' : 'Invoice'} ${
              /^\d+$/.test(row?.original?.invoiceNumber)
                ? row?.original?.invoiceNumber?.toString()?.padStart(4, '0')
                : row?.original?.invoiceNumber
            } : Amount to Apply`}
          </label>
          <input
            id="amount"
            type="number"
            placeholder="0.00"
            name="amount"
            max={row?.original?.amountDue}
            className="btn pl-[3.35rem] pr-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80"
            onChange={(e) => onAmountChange(e.target.value, row?.original)}
            value={isInvoiceSelected?.amountToPay}
            onWheel={(e) => e.target.blur()}
          />
          <span className="absolute top-[2.6rem] left-4 flex items-center gap-2">
            <span className="text-slate-600">
              {getCurrencySymbol(isOpen?.currency)}
            </span>

            <div className="w-[0.038rem] h-[1.188rem] bg-slate-600" />
          </span>
        </div>

        {isInvoiceSelected?.amountToPay > 0 && (
          <div
            className={`capitalize mb-[2px]  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${
                                  parseFloat(row?.original?.amountDue)?.toFixed(
                                    2,
                                  ) ===
                                  parseFloat(
                                    isInvoiceSelected?.amountToPay,
                                  )?.toFixed(2)
                                    ? 'text-[#40AA00] bg-[#40AA001A]'
                                    : 'text-[#EDB900] bg-[#EDB9001A]'
                                }
                           `}
          >
            {parseFloat(row?.original?.amountDue)?.toFixed(2) ===
            parseFloat(isInvoiceSelected?.amountToPay)?.toFixed(2)
              ? isOpen?.invoiceType === 'invoice'
                ? 'Apply Full Credit'
                : 'Full Payment'
              : isOpen?.invoiceType === 'invoice'
                ? 'Apply Partial Credit'
                : 'Partial Payment'}
          </div>
        )}
      </div>
    );
  };

  const initialExpandedState = useMemo(
    () =>
      tableData?.reduce?.((acc, row, index) => {
        const isInvoiceSelected = selectedInvoices?.find(
          (inv) => inv.id === row.id,
        );
        if (isInvoiceSelected) {
          acc[`${index}`] = true;
        }
        return acc;
      }, {}),
    [tableData],
  );

  useEffect(() => {
    if (tableData?.length > 0 && Object.keys(initialExpandedState).length > 0) {
      setTableState((prev) => ({ ...prev, expanded: initialExpandedState }));
    }
  }, [initialExpandedState]);

  useEffect(() => {
    if (isOpen?.id) {
      getData({ loading: true });
      setTableState({
        ...tableInitialState,
        columnSizing:
          isOpen?.invoiceType === 'memo'
            ? { ...tableInitialState?.columnSizing }
            : {
                select: 40,
                number: 30,
                customer: 300,
                date: 200,
                dueDate: 100,
                total: 200,
                amount: 130,
              },
      });
    } else {
      setSelectedInvoices([]);
      setTableState({ ...tableInitialState });
      setExpanded({});
    }
    return () => {
      setSelectedInvoices([]);
      setTableState({ ...tableInitialState });
      setExpanded({});
    };
  }, [isOpen]);

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        header: '',
        enableResizing: false,
        enableSorting: false,
        Cell: (row) => (
          <div className="flex items-center">
            <label className="inline-flex ">
              <span className="sr-only">Select</span>
              <input
                id={row?.row?.original?.id}
                className="form-checkbox cursor-pointer h-5 w-5"
                type="checkbox"
                onClick={(e) => e.stopPropagation()}
                onChange={() => {
                  const findIndex = selectedInvoices?.findIndex(
                    (inv) => inv?.id === row?.row?.original?.id,
                  );
                  if (findIndex >= 0) {
                    row?.row?.toggleExpanded(false);
                  } else {
                    row?.row?.toggleExpanded(true);
                  }
                  onSelectInvoice(row?.row?.original);
                }}
                checked={
                  selectedInvoices?.find?.(
                    (inv) => inv?.id === row?.row?.original?.id,
                  )?.id
                }
              />
            </label>
          </div>
        ),
        minSize: 40,
      },
      {
        id: 'invoiceNumber',
        accessorKey: 'invoiceNumber',
        header: '#',
        Header: <IconHeader icon={null} text="#" />,
        enableResizing: true,
        enableSorting: true,
        Cell: (row) => (
          <div className="text-sm text-[#667085]">
            {/^\d+$/.test(row?.row?.original?.invoiceNumber)
              ? row?.row?.original?.invoiceNumber?.toString()?.padStart(4, '0')
              : row?.row?.original?.invoiceNumber}
          </div>
        ),
        sortingFn: (rowA, rowB) =>
          rowA?.original?.invoiceNumber?.localeCompare(
            rowB?.original?.invoiceNumber,
          ),
      },
      {
        id: 'customer',
        accessorKey: 'customer',
        header: 'Customer',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
              </svg>
            }
            text="Customer"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]">
            {row?.row?.original?.customer?.customer || '--'}
          </div>
        ),
        enableColumnResizing: true,
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          rowA?.original?.customer?.customer?.localeCompare(
            rowB?.original?.customer?.customer,
          ),
      },
      {
        id: 'date',
        accessorKey: 'date',
        header: 'Date',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Date"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {DateTime.fromISO(row?.row?.original?.date, {
              setZone: true,
            }).toFormat('MMM dd, yyyy')}
          </div>
        ),
        // enableResizing: false,
        enableSorting: true,
        minSize: 120,
        sortingFn: (rowA, rowB) => {
          const dateA = DateTime.fromISO(rowA?.original?.date);
          const dateB = DateTime.fromISO(rowB?.original?.date);
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        },
      },
      {
        id: 'dueDate',
        accessorKey: 'dueDate',
        header: 'Due',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Due"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {DateTime.fromISO(row?.row?.original?.dueDate, {
              setZone: true,
            }).toFormat('MMM dd, yyyy')}
          </div>
        ),
        minSize: 120,
        // enableResizing: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const dateA = DateTime.fromISO(rowA?.original?.dueDate);
          const dateB = DateTime.fromISO(rowB?.original?.dueDate);
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        },
      },
      {
        id: 'total',
        accessorKey: 'total',
        header: 'Total',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text={
              isOpen?.invoiceType === 'invoice'
                ? 'Total Credit Amount'
                : 'Total'
            }
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {getCurrencySymbol(row?.row?.original?.currency)}
            {formatNumberGlobally(row?.row?.original?.total ?? 0)}
          </div>
        ),
        // enableResizing: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const salaryA = rowA?.original?.total ?? 0;
          const salaryB = rowB?.original?.total ?? 0;
          if (salaryA < salaryB) return -1;
          if (salaryA > salaryB) return 1;
          return 0;
        },
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: 'Amount Due',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Amount Due"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {getCurrencySymbol(row?.row?.original?.currency)}
            {formatNumberGlobally(row?.row?.original?.amountDue ?? 0)}
          </div>
        ),
        enableResizing: false,
        enableSorting: true,
      },
    ],
    [selectedInvoices, isOpen],
  );

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`Select ${
        isOpen?.invoiceType === 'invoice' ? 'Credit Memo' : 'an Invoice'
      }`}
      width="max-w-[1052px] flex flex-col"
      contentId="invoicesMemosAssignModal"
      dialogClassName="relative"
      subHeader={
        <p className="font-medium text-sm">
          Remaining balance :{' '}
          {getCurrencySymbolFromIso(isOpen?.currency, currencies)}
          {/* {isOpen?.paymentStatus === 'partial'
              ? parseFloat(isOpen?.total - isOpen?.paidAmount)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )
              : */}
          {formatNumberGlobally(isOpen?.amountDue)}
        </p>
      }
      headerClassName="!py-3"
    >
      <div className="px-8 mt-4">
        {((false &&
          AmountLeft() > 0 &&
          AmountLeft()?.toFixed(2) !==
            Math.abs(isOpen?.amountDue)?.toFixed(2)) ||
          AmountLeft() < 0) && (
          <div className="pb-4">
            {/* Show this as Info */}
            {/* {AmountLeft() > 0 &&
              AmountLeft()?.toFixed(2) !==
                Math.abs(isOpen?.amountDue)?.toFixed(2) && (
                <div className="text-sm text-[#78BD4F] border border-[#78BD4F] bg-[#78BD4F] bg-opacity-5 py-1 px-4 rounded-[5px]">
                  <div>
                    <span className="font-medium">
                      {' '}
                      {isOpen?.invoiceType === 'invoice'
                        ? 'Invoice'
                        : 'Memo'}{' '}
                      Assigned Amount:{' '}
                    </span>
                    {getCurrencySymbol(isOpen?.currency)}
                    {formatNumberGlobally(
                      parseFloat(Math.abs(isOpen?.amountDue) - AmountLeft()),
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <div>
                      <span className="font-medium">Unassigned Amount: </span>
                      {getCurrencySymbol(isOpen?.currency)}
                      {formatNumberGlobally(AmountLeft())}{' '}
                    </div>
                  </div>
                </div>
              )} */}

            {/* Show this as error/warning */}
            {/* {AmountLeft() < 0 && (
              <div className="text-sm text-rose-500 border border-rose-500 bg-rose-100 pt-1 px-4 rounded-[5px]">
                <div>You paid more than the actual amount</div>
                <div>
                  <span className="font-medium text-sm"> Invoice Amount:</span>{' '}
                  {formatNumberGlobally(Math.abs(isOpen?.amountDue))}
                </div>
                <div>
                  <span className="font-medium text-sm">
                    {' '}
                    Amount{' '}
                    {isOpen?.invoiceType === 'invoice'
                      ? 'Credit'
                      : 'Refund'}:{' '}
                  </span>{' '}
                  {formatNumberGlobally(
                    parseFloat(
                      Math.abs(AmountLeft()) + Math.abs(isOpen?.amountDue),
                    ),
                  )}
                </div>
              </div>
            )} */}
          </div>
        )}
        <div>
          <div className="w-full  flex justify-between items-center flex-wrap gap-2 mb-4">
            <ul className="flex flex-wrap -m-1 py-2">
              <li className="m-1 w-[300px] max-w-full inline-flex">
                <BasicCheckboxDropdown
                  selectedOptions={selectedStatuses}
                  setSelectedOptions={setSelectedStatuses}
                  apiFunc={(obj) => getData({ ...obj, loading: true })}
                  options={[
                    {
                      value: 'partial',
                      label: 'Partial',
                    },
                    {
                      value: 'unpaid',
                      label: 'Approved',
                    },
                    {
                      value: 'overdue',
                      label: 'Overdue',
                    },
                    {
                      value: 'sent',
                      label: 'Sent',
                    },
                  ]}
                  onApply={(values) => {
                    setIsLoading(true);
                    // getData({
                    //   page: 1,
                    //   status: values,
                    //   loading: true,
                    // });
                  }}
                  onClear={() => {
                    setIsLoading(true);
                    getData({ status: [], page: 1, loading: false });
                    setSelectedStatuses([]);
                  }}
                  label="All Statuses"
                  type="status"
                  apiFilter={filters?.status}
                />
              </li>
              <li className="m-1 w-[300px] max-w-full inline-flex">
                <DateFilter
                  startDateFilter={startDateFilter}
                  endDateFilter={endDateFilter}
                  getTransactionsApi={getData}
                  setStartDateFilter={setStartDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  onDateChange={onDateChange}
                  apiFilter={filters || {}}
                  align="right"
                  maxDate={null}
                />
              </li>
              <li className="m-1 w-[100px] ml-5 max-w-full inline-flex">
                <button
                  onClick={handleResetFilters}
                  className="btn h-10 py-0 px-4 font-normal text-sm leading-6 border border-red-400 text-red-400 bg-[#FF4B4B05]"
                  type="button"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-1 f"
                  >
                    <path
                      d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                      fill="#FF4B4B"
                    />
                  </svg>
                  Reset
                </button>
              </li>
            </ul>
          </div>
          <ReusableTable
            mantineTableHeadProps={{
              className: 'shadow-sm z-[6] border-none',
              sx: {
                '& > tr > th': {
                  borderBottom: 'none !important',
                  color: '#101828 !important',
                  fontWeight: '500 !important',
                },
                '& th .mantine-Divider-root': {
                  borderWidth: '3px !important',
                  borderLeftColor: '#e2e8f0',
                },
                '&  th:first-child': {
                  display: 'none',
                },
                '&  th:nth-child(2)': {
                  display: 'none',
                },
              },
            }}
            mantineTableBodyRowProps={(row) => ({
              sx: {
                transition: 'background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: 'rgb(160, 205, 133, 0.05) !important',
                },
                '&:hover td': {
                  backgroundColor: 'transparent',
                },
                '&:last-of-type td': {
                  borderBottom: 'none !important',
                },
                '&  td:first-child': {
                  display: row?.isDetailPanel ? 'table-cell' : 'none',
                },
                '&  td:nth-child(2)': {
                  display: 'none',
                },
              },
            })}
            columns={
              isOpen?.invoiceType === 'invoice'
                ? columns?.filter(
                    (col) => col?.id !== 'dueDate' && col?.id !== 'amount',
                  )
                : columns
            }
            data={invoices || []}
            enablePagination={false}
            renderDetailPanel={renderDetailPanel}
            onSortingChange={(sorting) => {
              const sort = sorting();
              let finalSort = [];
              if (sort?.length > 0) {
                const currentSortId = sort[0]?.id;
                // Check if the same column is being sorted again
                if (tableState?.sorting?.[0]?.id === currentSortId) {
                  if (tableState?.sorting?.[0]?.desc === false) {
                    // If currently sorted as 'asc', toggle to 'desc'
                    finalSort = [{ id: currentSortId, desc: true }];
                  } else {
                    // If currently sorted as 'desc', remove sorting (no sorting)
                    finalSort = [];
                  }
                } else {
                  // If a different column is being sorted, apply 'asc' sorting
                  finalSort = [{ id: currentSortId, desc: false }];
                }
              }
              // Avoid unnecessary fetch if no change in sorting state
              if (
                tableState?.sorting?.length === finalSort?.length &&
                tableState?.sorting?.[0]?.id === finalSort?.[0]?.id &&
                tableState?.sorting?.[0]?.desc === finalSort?.[0]?.desc
              ) {
                return;
              }
              // Update the table state with the new sorting
              setTableState((prev) => ({
                ...prev,
                sorting: finalSort,
              }));
              getData({
                sortOrder: finalSort?.[0]?.id || '',
                sortDirection: finalSort?.[0]?.id
                  ? finalSort?.[0]?.desc
                    ? 'desc'
                    : 'asc'
                  : '',
              });
            }}
            state={{
              isLoading,

              sorting: tableState.sorting, // Ensure this is an array
              globalFilter: tableState.globalFilter,
              columnSizing: tableState?.columnSizing,
              columnVisibility: tableState?.columnVisibility,
              expanded,
            }}
            onExpandedChange={setExpanded}
            onStateChange={setTableState}
            mantineTopToolbarProps={{ className: 'bg-[#A0CD850F] min-h-16' }}
            renderToolbarInternalActions={({ table }) => (
              <div className="flex gap-5 items-center flex-wrap gap-y-2 ">
                <SearchForm
                  searchVal={searchValue}
                  setSearchVal={handleSearch}
                  placeholder={
                    isOpen?.invoiceType === 'invoice'
                      ? 'Search Credit Memos'
                      : 'Search Invoices'
                  }
                  height="h-10 !border-slate-300 focus:!border-slate-400 hover:!border-slate-400"
                  searchIconClassName="!stroke-[#868e96] !text-[#868e96] "
                />
                <div className="bg-[#D0D5DD] min-w-px h-10  sm:block" />
                <div className="flex gap-4 ml-auto">
                  {/* <MRT_ShowHideColumnsButton
                    table={table}
                    className="border border-slate-300 h-10 w-10 bg-white"
                    sx={{
                      height: '10rem',
                    }}
                  /> */}
                  <Tooltip
                    content="Reset filters"
                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                    position="bottom"
                    isFixed
                    ids={['accountantMainPage', 'clientsTableFirmView']}
                  >
                    <button
                      onClick={() => {
                        handleSearch('');
                        if (searchValue) {
                          getData({ search: '', page: 1 });
                        }
                        setTableState((prevState) => {
                          const newState = {
                            ...tableInitialState,
                            pagination: prevState?.pagination,
                          };

                          return newState;
                        });
                      }}
                      className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                      type="button"
                    >
                      <svg
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                      >
                        <path
                          d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                          className="fill-current"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
            columnAc
            enableColumnResizing
            enableSorting
            enableRowActions
            enableGlobalFilter={false} // disables search bar
            enableFilters={false} // disables column filters
            enableDensityToggle={false} // Hides density toggle
            enableFullScreenToggle={false} // Hides full-screen toggle
            enableColumnActions={false} // Hides action dropdown from each column
            mantineTableProps={{
              withBorder: false, // Removes the outer border
              withColumnBorders: false, // Removes borders between columns
            }}
            mantineTableContainerProps={{
              className: 'border-none scrollbar mb-6 pb-1',
              id: 'invitesTableFirmView',
            }}
            mantinePaperProps={{
              className: '!border-none  shadow-none min-h-fit',
            }}
            renderBottomToolbar={() => null}
          />
        </div>
      </div>
      <footer className="sticky w-full  bottom-0 bg-white z-20 px-10 py-6 border-t border-[#D0D5DD]">
        <div className="flex gap-4 justify-between items-center flex-wrap">
          {/* <p className="text-[#667085]">Remaining Amount: $100</p> */}
          <div className="flex gap-4 ml-auto">
            <button
              type="button"
              onClick={() => setIsOpen(null)}
              className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085]"
            >
              Close
            </button>
            {(selectedInvoices?.length > 0 || invoices?.length > 0) && (
              <Tooltip
                content={`${
                  !selectedInvoices?.length
                    ? 'Select an Invoice to proceed'
                    : parseFloat(AmountLeft() ?? 0)?.toFixed(2) ===
                        Math.abs(isOpen?.amountDue)?.toFixed(2)
                      ? 'Please assign an amount greater than zero to the invoice(s)'
                      : parseFloat(AmountLeft() ?? 0) < 0
                        ? 'Assigned amount to invoice(s) is greater than Transaction amount'
                        : ''
                }`}
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown min-w-[200px] text-wrap"
                position="top"
                size="sm"
                tooltipShow={
                  !selectedInvoices?.length ||
                  parseFloat(AmountLeft() ?? 0) < 0 ||
                  parseFloat(AmountLeft() ?? 0)?.toFixed(2) ===
                    Math.abs(isOpen?.amountDue)?.toFixed(2)
                }
              >
                <button
                  type="button"
                  disabled={
                    !selectedInvoices?.length ||
                    parseFloat(AmountLeft() ?? 0) < 0 ||
                    loading ||
                    parseFloat(AmountLeft() ?? 0)?.toFixed(2) ===
                      Math.abs(isOpen?.amountDue)?.toFixed(2)
                  }
                  onClick={onAssignInvoice}
                  className="h-11 px-7 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:cursor-not-allowed "
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}{' '}
                  {isOpen?.invoiceType === 'invoice'
                    ? 'Apply Credit'
                    : 'Apply Credit to Invoice(s)'}
                  {/* Apply Payment to Invoice(s) */}
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </footer>
    </ModalBasic>
  );
};

export default RecordInvoiceMemo;
