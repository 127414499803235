import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useNavigate } from 'react-router-dom';
import {
  amountColor,
  formatNumberGlobally,
  getBillStatusColor,
  getContrast,
  getCurrencySymbolFromIso,
} from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';

const InstanceBody = ({
  instance,
  transactionId,
  setActiveInstance,
  handleTransactionUnassign,
  setIsOpen,
  setTransactionsModalOpen = null,
  activeBill,
  currencies,
  unassignLoader,
}) => {
  const navigate = useNavigate();
  const { team } = useSelector((state) => state.Team);

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
      {/* Row */}
      <tr className="">
        {/* {transactionId && (
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="flex items-center">
              <label className="inline-flex ">
                <span className="sr-only">Select</span>
                <input
                  className="form-checkbox cursor-pointer"
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => {
                      setActiveInstance(instance);
                  }}
                  checked={activeInstance?.id === instance?.id}
                />
              </label>
            </div>
          </td>
        )} */}
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{moment(instance?.dueDate).format('MMM DD, YYYY')}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`text-left font-medium ${amountColor(
              instance?.total?.toString(),
            )}`}
          >
            {getCurrencySymbolFromIso(activeBill?.currency, currencies)}
            {formatNumberGlobally(
              parseFloat(instance?.unpaidAmount)?.toFixed(2),
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`${getBillStatusColor(
              instance?.status,
            )} px-3  capitalize py-1 w-fit rounded-md  text-sm font-semibold `}
          >
            {activeBill?.approvalStatus === 'draft'
              ? 'Draft'
              : instance?.status === 'unpaid'
                ? 'Approved'
                : instance?.status || '--'}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            {instance?.status === 'paid' ||
            activeBill?.approvalStatus === 'draft' ? (
              <div className="w-full text-center">--</div>
            ) : (
              checkPermission(
                PermissionKeys.APPROVE_BILLS,
                accessType,
                permissions,
              ) && (
                <div className="flex gap-4">
                  <button
                    className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] "
                    onClick={() => {
                      if (instance?.status === 'paid') {
                        return;
                      }
                      if (setActiveInstance) {
                        setActiveInstance(instance);
                        setIsOpen(false);
                        setTransactionsModalOpen(true);
                      }
                    }}
                    type="button"
                  >
                    Record A Payment
                  </button>
                  {team?.country === 'US' &&
                    (instance?.status === 'unpaid' ||
                      instance?.status === 'overdue') && (
                      <button
                        className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] "
                        onClick={() => {
                          if (instance?.status === 'paid') {
                            return;
                          }
                          navigate(
                            `/print-checks?mode=create&billId=${activeBill?.id}`,
                          );
                        }}
                        type="button"
                      >
                        Print Check
                      </button>
                    )}
                </div>
              )
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              className={`text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 ${
                descriptionOpen && 'rotate-180'
              }`}
              aria-expanded={descriptionOpen}
              onClick={(e) => {
                e.stopPropagation();
                setDescriptionOpen(!descriptionOpen);
              }}
              aria-controls={`description-${instance?.id}`}
              type="button"
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>

      <tr
        id={`description-${instance?.id}`}
        // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
        role="region"
        className={`${!descriptionOpen && 'hidden'}`}
      >
        <td colSpan={transactionId ? '5' : '5'} className=" py-3">
          <div className=" bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
            <h3 className="text-base font-semibold mb-3">Transactions:</h3>
            {instance?.transactions?.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="table-auto w-full bg-white">
                  {/* Table header */}
                  <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                    <tr>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium text-left">Description</div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium text-left">
                          Authorized On
                        </div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium text-left">Amount</div>
                      </th>
                      <th />
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <span className="sr-only">Menu</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                    {instance?.transactions?.map((trans, index) => (
                      <tr className="" key={index}>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                              {trans?.vendor?.logoUrl ||
                              trans?.plaidCategoryIconUrl ? (
                                <img
                                  className="rounded-full"
                                  src={
                                    trans?.vendor?.logoUrl ||
                                    trans?.plaidCategoryIconUrl
                                  }
                                  alt={trans?.name}
                                />
                              ) : (
                                <div
                                  className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                  style={{
                                    backgroundColor: trans?.categoryAccount
                                      ?.group?.color
                                      ? trans?.categoryAccount?.group?.color
                                      : '',
                                  }}
                                >
                                  <div
                                    className={`${
                                      trans?.categoryAccount?.group?.color
                                        ? getContrast(
                                            trans?.categoryAccount?.group
                                              ?.color,
                                            'text-black',
                                            'text-white',
                                          )
                                        : 'text-black'
                                    } text-xs font-normal uppercase`}
                                  >
                                    {trans?.description
                                      ? trans.description
                                          .replace(/[^\w\s]/gi, '')
                                          .split(' ')
                                          .filter(Boolean)
                                          .slice(0, 2)
                                          .map((word) => word.charAt(0))
                                          .join('')
                                      : ''}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="font-normal text-slate-600 leading-6 mr-2">
                              {trans?.description?.length > 19 ? (
                                <Tooltip
                                  content={trans?.description}
                                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                >
                                  <span>
                                    {`${trans?.description?.slice(0, 19)}...`}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span>{trans?.description}</span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-left ">
                            {moment(trans?.authorizedDate).format(
                              'DD MMM, yyyy',
                            )}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div
                            className={`font-semibold leading-6 text-sm  ${amountColor(
                              trans?.amount?.toString(),
                            )}`}
                          >
                            {trans?.amount?.toString().charAt(0) === '-'
                              ? ''
                              : '-'}
                            {getCurrencySymbolFromIso(
                              trans?.currency,
                              currencies,
                            )}
                            {trans?.amount?.toString().charAt(0) === '-'
                              ? formatNumberGlobally(
                                  parseFloat(
                                    trans?.amount?.toString().replace('-', ''),
                                  )?.toFixed(2),
                                )
                              : formatNumberGlobally(
                                  parseFloat(trans?.amount)?.toFixed(2),
                                )}
                          </div>
                        </td>
                        <td />
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                          {checkPermission(
                            PermissionKeys.APPROVE_BILLS,
                            accessType,
                            permissions,
                          ) && (
                            <div className="flex items-center">
                              <button
                                onClick={() =>
                                  handleTransactionUnassign(
                                    instance?.id,
                                    trans?.id,
                                  )
                                }
                                disabled={unassignLoader}
                                className="btn-sm whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 text-white"
                                type="button"
                              >
                                {unassignLoader === trans?.id && (
                                  <svg
                                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                  </svg>
                                )}
                                Unassign
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center">No Transaction Found.</div>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default InstanceBody;
