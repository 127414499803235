import React, { memo, useEffect, useState } from 'react';
import InvoicesTable from './InvoicesTable';
import EstimatesTable from './EstimatesTable';
import MemosTable from './MemosTable';

const INVOICES_TABLE = 'invoicesTable';
const ESTIMATES_TABLE = 'estimatesTable';
const MEMOS_TABLE = 'memosTable';

const Invoices = ({
  invoices,
  estimates,
  invoicesLocalLimit,
  setInvoicesLocalLimit,
  estimatesLocalLimit,
  setEstimatesLocalLimit,
  getInvoicesData,
  getEstimatesData,
  isInvoicesTabLoaded,
  setIsInvoicesTabLoaded,
  overviewData,
  findCurrencySymbol,
  customer,
  memos,
  memosLocalLimit,
  setMemosLocalLimit,
  getMemosData,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeTable, setActiveTable] = useState(INVOICES_TABLE);

  const loadData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        getInvoicesData({}),
        getEstimatesData({}),
        getMemosData({}),
      ]);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isInvoicesTabLoaded) {
      loadData();
      setIsInvoicesTabLoaded(true);
    }
  }, []);

  return loading ? (
    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin w-10 h-10 fill-current shrink-0"
      >
        <path
          d="M14.5 2.41699V7.25033"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 21.749V26.5824"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 5.95703L9.37662 9.37662"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 19.6211L23.0426 23.0407"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.41699 14.5H7.25033"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.749 14.5H26.5824"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 23.0407L9.37662 19.6211"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 9.37662L23.0426 5.95703"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
        Fetching data, please wait...
      </p>
    </div>
  ) : (
    <div className="space-y-10">
      {/* Summary  */}
      <div className="border border-[#D0D5DD] rounded-[12px] p-6">
        <h3 className="text-[24px] font-medium text-[#666666] mb-2">
          Invoices Summary
        </h3>

        <div className="grid md:grid-cols-3 xl:grid-cols-5 divide-y md:divide-y-0 md:divide-x divide-[#D0D5DD] md:gap-y-4">
          <div className="py-5">
            <p className="text-[#667085] font-medium mb-5">Total Paid</p>
            <h3 className="text-[28px] font-semibold text-[#101828]">
              {' '}
              {overviewData?.totalPaid?.toString().charAt(0) === '-' ? '-' : ''}
              {findCurrencySymbol(overviewData?.currency)}
              {overviewData?.totalPaid?.toString().charAt(0) === '-'
                ? parseFloat(
                    overviewData?.totalPaid?.toString().replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(overviewData?.totalPaid)?.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )}
            </h3>
          </div>
          <div className="py-5 md:pl-[20%]">
            <p className="text-[#667085] font-medium mb-5">Total Unpaid</p>
            <h3 className="text-[28px] font-semibold text-[#101828]">
              {overviewData?.totalUnpaidAmount?.toString().charAt(0) === '-'
                ? '-'
                : ''}
              {findCurrencySymbol(overviewData?.currency)}
              {overviewData?.totalUnpaidAmount?.toString().charAt(0) === '-'
                ? parseFloat(
                    overviewData?.totalUnpaidAmount
                      ?.toString()
                      .replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(overviewData?.totalUnpaidAmount)?.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )}
            </h3>
          </div>
          <div className="py-5 md:pl-[20%]">
            <p className="text-[#667085] font-medium mb-5">Total Overdue</p>
            <h3 className="text-[28px] font-semibold text-[#101828]">
              {' '}
              {overviewData?.totalOverdueAmount?.toString().charAt(0) === '-'
                ? '-'
                : ''}
              {findCurrencySymbol(overviewData?.currency)}
              {overviewData?.totalOverdueAmount?.toString().charAt(0) === '-'
                ? parseFloat(
                    overviewData?.totalOverdueAmount
                      ?.toString()
                      .replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(overviewData?.totalOverdueAmount)?.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )}
            </h3>
          </div>
          <div className="py-5 md:pl-[20%]">
            <p className="text-[#667085] font-medium mb-5">Credit Balance</p>
            <h3 className="text-[28px] font-semibold text-[#101828]">
              {customer?.balance?.toString().charAt(0) === '-' ? '-' : ''}
              {findCurrencySymbol(overviewData?.currency)}
              {customer?.balance?.toString().charAt(0) === '-'
                ? parseFloat(
                    customer?.balance?.toString().replace('-', '') || 0,
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(customer?.balance || 0)?.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )}
            </h3>
          </div>
          <div className="py-5 md:pl-[20%]">
            <p className="text-[#667085] font-medium mb-5">
              Average Time to Pay{' '}
            </p>
            <h3 className="text-[28px] font-semibold text-[#101828]">
              {parseFloat(overviewData?.averageDaysToPay)?.toFixed(2)} days
            </h3>
          </div>
        </div>
      </div>
      {/* Tables  */}
      <div className="border border-[#D0D5DD] rounded-[12px] p-6">
        {/* Tabs  */}
        <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 flex-wrap">
          <div
            onClick={() => setActiveTable(INVOICES_TABLE)}
            className={`text-sm px-2  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTable === INVOICES_TABLE &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
            role="button"
            tabIndex="0"
          >
            Invoices
          </div>
          <div
            onClick={() => setActiveTable(ESTIMATES_TABLE)}
            className={`text-sm px-2  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTable === ESTIMATES_TABLE &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
            role="button"
            tabIndex="0"
          >
            Estimates
          </div>
          <div
            onClick={() => setActiveTable(MEMOS_TABLE)}
            className={`text-sm px-2  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTable === MEMOS_TABLE &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
            role="button"
            tabIndex="0"
          >
            Credit Memos
          </div>
        </div>
        {activeTable === INVOICES_TABLE ? (
          <InvoicesTable
            invoices={invoices}
            invoicesLocalLimit={invoicesLocalLimit}
            getInvoicesData={getInvoicesData}
            setInvoicesLocalLimit={setInvoicesLocalLimit}
          />
        ) : activeTable === ESTIMATES_TABLE ? (
          <EstimatesTable
            estimates={estimates}
            estimatesLocalLimit={estimatesLocalLimit}
            getEstimatesData={getEstimatesData}
            setEstimatesLocalLimit={setEstimatesLocalLimit}
          />
        ) : (
          <MemosTable
            memos={memos}
            memosLocalLimit={memosLocalLimit}
            getMemosData={getMemosData}
            setMemosLocalLimit={setMemosLocalLimit}
          />
        )}
      </div>
    </div>
  );
};

export default Invoices;
