import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Transition from 'utils/Transition';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import {
  addPendingReceipt,
  createMerchant,
  editPendingReceipt,
  getExpenseTypesByWebPerson,
  updateMerchant,
  uploadMerchantLogo,
} from '../../API/backend_helper';
import MerchantsDropdown from '../../components/MerchantsDropdown';
import DatePickerDash from '../../components/DatePickerDash';
import WarningBox from '../../components/WarningBox';
import VendorForm from '../vendors/VendorForm';

import {
  formatDateLocally,
  getCurrencySymbol,
  getFormattedDate,
  hasUpToTwoDecimalPlaces,
  roundToTwo,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
  transformAccounts,
} from '../../utils/Utils';

const AddEditReceipt = ({
  vendors,
  allAccounts = [],
  currencies,
  expenseTypes = [],
  setVendors,
  setIsActive,
  editReceiptData,
  uploadedBillData,
  setUploadedBillData,
  billModalPdfView,
  setBillModalPdfView,
  loadUnmatchedReceipts,
  setEditReceiptData,
  allCategories = [],
  projects = [],
  tags = [],
  getMerchantsApi = () => {},
}) => {
  const formikRef = useRef();
  const currencyRef = useRef(null);

  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [peopleAccounts, setPeopleAccounts] = useState([]);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [editVendor, setEditVendor] = useState(null);

  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [initialLoader] = useState(false);

  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [pdfBlockLeftDistance, setPdfBlockLeftDistance] = useState(32);
  const [pdfBlockHeight, setPdfBlockHeight] = useState(400);

  const [selectedCurrency, setSelectedCurrency] = useState(
    uploadedBillData?.currency ||
      team?.currency ||
      localStorage.getItem(TEAM_CURRENCY),
  );

  const loadExpenseTypes = async () => {
    try {
      const res = await getExpenseTypesByWebPerson(editReceiptData?.peopleId);
      setPeopleAccounts(
        transformAccounts(res?.data?.results || [], 'all', 'expense'),
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (editReceiptData?.id && editReceiptData?.peopleId) {
      loadExpenseTypes();
    }
  }, [editReceiptData]);

  const options = useMemo(
    () => ({
      mode: 'single',
      enableTime: false,
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  useEffect(() => {
    if (
      editReceiptData?.receiptUrl ||
      Object.keys(uploadedBillData || {}).length > 0
    ) {
      if (uploadedBillData?.currency) {
        setSelectedCurrency(uploadedBillData?.currency);
      }
      if (editReceiptData?.currency) {
        setSelectedCurrency(editReceiptData?.currency);
      }
      if (!billModalPdfView) {
        setBillModalPdfView(true);
      }
    }
  }, [editReceiptData?.receiptUrl, uploadedBillData]);

  const handleSubmit = async (values, formikHandler) => {
    if (values.approvalStatus === 'draft') {
      setDraftLoading(true);
    } else {
      setLoading(true);
    }
    try {
      const {
        vendorId,
        date,
        amount,
        accountId,
        expenseReportTypeId,
        description,
        categoryAccountId,
        tags,
        projectId,
      } = values;

      const data = {
        vendorId,
        date,
        amount,
        accountId,
        description,
        currency: selectedCurrency,
        tags,
        projectId,
      };
      if (editReceiptData?.id && editReceiptData?.peopleId) {
        data.expenseReportTypeId = expenseReportTypeId;
      } else {
        data.categoryAccountId = categoryAccountId;
      }

      data.date = getFormattedDate(date, teamDateFormat);

      if (uploadedBillData?.receiptUrl || editReceiptData?.receiptUrl) {
        data.receiptUrl =
          uploadedBillData?.receiptUrl || editReceiptData?.receiptUrl;
      }

      if (editReceiptData?.id) {
        await editPendingReceipt(editReceiptData?.id, data);
      } else {
        await addPendingReceipt(data);
      }

      await loadUnmatchedReceipts({ loading: false });
      setIsActive(false);
      setBillModalPdfView(false);
      if (editReceiptData?.id) {
        setEditReceiptData(null);
      }
      if (Object.keys(uploadedBillData || {}).length > 0) {
        setUploadedBillData({});
      }
      if (editReceiptData?.id) {
        toast.success('Receipt Updated Successfully');
      } else {
        toast.success('Receipt Created Successfully');
      }

      formikHandler.resetForm();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
      setDraftLoading(false);
    }
  };

  const handleUploadVendorLogo = async (data, id) => {
    try {
      await uploadMerchantLogo(data, id);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddVendor = async (data) => {
    try {
      const response = await createMerchant(data);
      setVendors((prev) => [response, ...prev]);
      formikRef?.current?.setFieldValue('vendorId', response?.id);
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateVendor = async (data, id) => {
    try {
      await updateMerchant(data, id);
      setVendorModalOpen(false);
      setEditVendor(null);
      getMerchantsApi();
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePdfViewFixedBlock = () => {
    setPdfBlockLeftDistance((window.innerWidth - 2000) / 2 + 32);
    let blockHeight =
      window.innerWidth > 1400
        ? window.innerHeight - 150
        : window.innerHeight - 350;
    if (
      document.getElementById('billsModalViewImageId') &&
      blockHeight >
        document.getElementById('billsModalViewImageId')?.clientHeight
    ) {
      blockHeight =
        document.getElementById('billsModalViewImageId')?.clientHeight + 10;
    }
    setPdfBlockHeight(blockHeight);
  };

  const onEditVendor = (vendorId) => {
    const vendor = vendors?.find((v) => v?.id === vendorId);
    if (vendor) {
      setEditVendor(vendor);
      setVendorModalOpen(true);
    }
  };

  useEffect(() => {
    handlePdfViewFixedBlock();
    window.addEventListener('resize', handlePdfViewFixedBlock);

    return () => window.removeEventListener('resize', handlePdfViewFixedBlock);
  }, [billModalPdfView]);

  return (
    <>
      <ModalBasic
        modalOpen={vendorModalOpen}
        setModalOpen={(v) => {
          setVendorModalOpen(v);
          if (editVendor) setEditVendor(null);
        }}
        title={editVendor ? 'Edit Vendor' : 'Add Vendor'}
        width="!w-[1088px]"
      >
        {vendorModalOpen && (
          <VendorForm
            handleUploadVendorLogo={handleUploadVendorLogo}
            handleAddVendor={handleAddVendor}
            setFormOpen={setVendorModalOpen}
            isModalView
            activeVendor={editVendor || null}
            handleUpdateVendor={handleUpdateVendor}
          />
        )}
      </ModalBasic>

      <div className="   ">
        <div className="flex justify-between items-center mb-10">
          <h3
            className={`text-[36px] font-medium text-[#00000099]
              ${billModalPdfView ? 'w-fit 1xl:w-[500px] xxl:w-[600px] overflow-hidden 3xl:w-[700px] 4xl:w-[800px] max-w-full 1xl:translate-x-full 1xl:ml-5' : ''}
              `}
          >
            {editReceiptData?.id ? 'Edit Receipt' : 'Add a Receipt'}
          </h3>
          <button
            onClick={
              () => {
                if (Object.keys(uploadedBillData || {}).length > 0) {
                  setUploadedBillData({});
                }
                if (Object.keys(editReceiptData || {}).length > 0) {
                  setEditReceiptData(null);
                }
                setIsActive(false);
                if (billModalPdfView) {
                  setBillModalPdfView(false);
                }
              }
              // billModalPdfView
              //   ? setBillsWarningModalOpen(true)
              //   :
            }
            type="button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6.17676"
                y="7.82422"
                width="2.3292"
                height="19.7982"
                rx="1.1646"
                transform="rotate(-45 6.17676 7.82422)"
                fill="#667085"
              />
              <rect
                x="20.177"
                y="6.17676"
                width="2.3292"
                height="19.7982"
                rx="1.1646"
                transform="rotate(45 20.177 6.17676)"
                fill="#667085"
              />
            </svg>
          </button>
        </div>
        {initialLoader ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 mb-5">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div
            className={
              billModalPdfView ? 'flex 1xl:flex-row flex-col gap-6' : ''
            }
          >
            {billModalPdfView && (
              <div
                className={`relative 1xl:w-[500px] xxl:w-[600px] overflow-hidden 3xl:w-[700px] 4xl:w-[800px] max-w-full  1xl:max-h-none

                  ${
                    (
                      uploadedBillData?.receiptUrl ||
                      editReceiptData?.receiptUrl
                    )
                      ?.toLowerCase()
                      ?.endsWith?.('.pdf')
                      ? '  w-full'
                      : 'h-fit w-fit mx-auto'
                  }`}
              >
                <div
                  className={`1xl:fixed overflow-hidden top-24 left-8 1xl:w-[500px] xxl:w-[600px] 3xl:w-[700px] 4xl:w-[800px] max-w-full max-h-[30rem] 1xl:max-h-none border  border-gray-300 rounded-xl
                      ${
                        (
                          uploadedBillData?.receiptUrl ||
                          editReceiptData?.receiptUrl
                        )
                          ?.toLowerCase()
                          ?.endsWith?.('.pdf')
                          ? '1xl:max-h-[80dvh]  w-full'
                          : 'max-h-fit 1xl:max-h-fit w-fit mx-auto !overflow-auto scrollbar'
                      }`}
                  style={{
                    left: `${pdfBlockLeftDistance > 32 ? pdfBlockLeftDistance : 32}px`,
                    height: `${pdfBlockHeight}px`,
                  }}
                >
                  {(
                    uploadedBillData?.receiptUrl || editReceiptData?.receiptUrl
                  )?.endsWith?.('.pdf') ||
                  (
                    uploadedBillData?.receiptUrl || editReceiptData?.receiptUrl
                  )?.endsWith?.('.PDF') ? (
                    <iframe
                      src={
                        uploadedBillData?.receiptUrl ||
                        editReceiptData?.receiptUrl
                      }
                      title="Receipt PDF"
                      className="w-full h-full rounded-xl min-h-[35rem] 1xl:min-h-[40rem]"
                    />
                  ) : (
                    <img
                      src={
                        uploadedBillData?.receiptUrl ||
                        editReceiptData?.receiptUrl
                      }
                      alt="Uploaded receipt"
                      className=" max-w-full min-h-[35rem] 1xl:min-h-[40rem] max-h-[inherit] mx-auto h-fit object-contain  rounded-xl"
                      id="billsModalViewImageId"
                    />
                  )}
                </div>
              </div>
            )}
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                vendorId: editReceiptData?.id
                  ? editReceiptData?.vendorId
                  : uploadedBillData?.matchingVendor?.vendorId === 'null' ||
                      uploadedBillData?.matchingVendor?.vendorId ===
                        'undefined' ||
                      !uploadedBillData?.matchingVendor?.vendorId
                    ? null
                    : uploadedBillData?.matchingVendor?.vendorId,
                date: formatDateLocally(
                  editReceiptData?.date?.split('T')?.[0] ||
                    uploadedBillData?.date ||
                    new Date(),
                  teamDateFormat,
                ),
                amount:
                  editReceiptData?.amount || uploadedBillData?.amount || '',
                accountId:
                  editReceiptData?.accountId ||
                  uploadedBillData?.matchingPhysicalAccount
                    ?.physicalAccountId ||
                  null,
                categoryAccountId:
                  editReceiptData?.categoryAccountId ||
                  uploadedBillData?.matchingCategoryAccount
                    ?.categoryAccountId ||
                  null,
                expenseReportTypeId:
                  editReceiptData?.expenseReportType?.id ||
                  uploadedBillData?.matchingExpenseTypes?.[0]?.expenseTypeId ||
                  null,
                description:
                  editReceiptData?.description ||
                  uploadedBillData?.description ||
                  '',
                projectId: editReceiptData?.projectId || '',
                currency:
                  editReceiptData?.currency ||
                  uploadedBillData?.currency ||
                  uploadedBillData?.currency ||
                  team?.currency ||
                  localStorage.getItem(TEAM_CURRENCY) ||
                  '',
                tags:
                  editReceiptData?.tags?.length > 0
                    ? editReceiptData?.tags?.map((tag) => tag?.id)
                    : [],
              }}
              validationSchema={Yup.object({
                vendorId: Yup.string().required('Please Select Vendor Name'),
                date: Yup.string().required('Please Select Due Date'),
                amount: Yup.string().required('Please Enter Amount'),
                accountId: Yup.string().required('Please Select Account'),
                currency: Yup.string().required('Please Select Currency'),
                projectId: Yup.string().optional().nullable(),
                description: Yup.string().max(
                  300,
                  'Notes must be 300 characters or less',
                ),
              })}
              validate={(values) => {
                const errors = {};
                if (
                  editReceiptData?.id &&
                  editReceiptData?.peopleId &&
                  !values?.expenseReportTypeId
                ) {
                  errors.expenseReportTypeId = 'Please Select Expense Category';
                } else if (
                  !editReceiptData?.peopleId &&
                  !values?.categoryAccountId
                ) {
                  errors.categoryAccountId = 'Please Select Account Category';
                }

                return errors;
              }}
              onSubmit={handleSubmit}
            >
              {(validation) => (
                <Form className=" flex-1 overflow-hidden">
                  <div className=" pb-1 grid grid-cols-12 gap-3 sm:gap-6 sm:gap-y-1 gap-y-1 mb-8">
                    {billModalPdfView && !editReceiptData?.id && (
                      <div className="col-span-full mb-4">
                        <WarningBox
                          isInfo
                          content={`COUNT AI has successfully extracted the key details from your uploaded receipt and pre-filled the form below. Please review the information, make any necessary adjustments, and hit 'Save' to proceed!`}
                        />
                      </div>
                    )}
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <div
                        className={`flex flex-col items-start w-full ${
                          validation.errors.vendorId ? '' : 'pb-5'
                        }`}
                      >
                        <label
                          className={`text-sm font-medium text-slate-600 block mb-2 ${
                            validation.touched.vendorId &&
                            validation.errors.vendorId
                              ? '!text-rose-400'
                              : ''
                          }`}
                          htmlFor="vendorId"
                        >
                          Vendor <span className="text-rose-500">*</span>
                          {validation.values.vendorId && (
                            <span
                              role="button"
                              tabIndex={0}
                              className="text-indigo-500 text-sm ml-1 cursor-pointer leading-none"
                              onClick={() =>
                                onEditVendor(validation.values.vendorId)
                              }
                            >
                              Edit Vendor
                            </span>
                          )}
                        </label>
                        <MerchantsDropdown
                          vendors={vendors}
                          setVendors={setVendors}
                          inActiveVendor={
                            editReceiptData?.vendor?.status === 'inactive'
                              ? editReceiptData?.vendor
                              : {}
                          }
                          selectedMerchant={validation.values.vendorId}
                          setSelectedMerchant={(value) => {
                            validation.setFieldValue('vendorId', value);
                          }}
                          isSetCategoryStyle
                          height={`h-12 border-[#D0D5DD] ${
                            validation.touched.vendorId &&
                            validation.errors.vendorId
                              ? '!border-rose-400'
                              : ''
                          }`}
                          id="vendorId"
                          name="vendorId"
                          addNewOptionButton
                          handleNewOptionButton={() => {
                            setVendorModalOpen(true);
                          }}
                        />
                        {validation.touched.vendorId &&
                        validation.errors.vendorId ? (
                          <div className="text-xs text-rose-400 mt-[2px]">
                            {validation.errors.vendorId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 pb-5  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <DatePickerDash
                        label="Date"
                        required
                        validation={validation}
                        placeholder="Select Date"
                        value={
                          validation.values.date ? validation.values.date : null
                        }
                        options={options}
                        onChange={(selectedDates) => {
                          validation.setFieldValue('date', selectedDates[0]);
                        }}
                        name="date"
                        id="date"
                        error={
                          validation.touched.date && validation.errors.date
                            ? validation.errors.date
                            : ''
                        }
                        onBlur={(e) => {
                          if (!e.target.classList?.value?.includes('active')) {
                            validation.setFieldValue('date', e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div
                      className={`pb-5 flex flex-col col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      {editReceiptData?.id && editReceiptData?.peopleId ? (
                        <>
                          <p className="text-slate-600 text-[14px] font-medium leading-5 mb-2.5">
                            Expense <span className="text-red-500">*</span>
                          </p>
                          <DropdownFull
                            options={expenseTypes}
                            placeholder="Select Expense"
                            name="name"
                            selected={validation.values.expenseReportTypeId}
                            setSelected={(value) =>
                              validation.setFieldValue(
                                'expenseReportTypeId',
                                value,
                              )
                            }
                            scrollIntoView={false}
                            className="!h-fit"
                          />
                          {validation.touched.expenseReportTypeId &&
                          validation.errors.expenseReportTypeId ? (
                            <span className="text-xs text-rose-500 h-[14px] -mt-1">
                              {validation.errors.expenseReportTypeId}
                            </span>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <label
                            className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                            htmlFor="categoryAccountId"
                          >
                            Category <span className="text-rose-500">*</span>
                          </label>
                          <CategoriesDropdown
                            allCategories={allCategories}
                            selectedCategoryId={
                              validation.values.categoryAccountId
                            }
                            setSelectedCategoryId={(value) => {
                              validation.setFieldValue(
                                'categoryAccountId',
                                value,
                              );
                            }}
                            isSetCategoryStyle
                            id="categoryAccountId"
                            name="categoryAccountId"
                            height="h-12"
                            type="account"
                            placeholderText="Select Category"
                          />
                          {validation.touched.categoryAccountId &&
                          validation.errors.categoryAccountId ? (
                            <div className="text-xs mt-1 text-rose-500">
                              {validation.errors.categoryAccountId}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                    <div
                      className={`pb-5 flex flex-col col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="accountId"
                      >
                        Account <span className="text-rose-500">*</span>
                      </label>
                      <CategoriesDropdown
                        allCategories={
                          editReceiptData?.id && editReceiptData?.peopleId
                            ? peopleAccounts
                            : allAccounts
                        }
                        selectedCategoryId={validation.values.accountId}
                        setSelectedCategoryId={(value) => {
                          validation.setFieldValue('accountId', value);
                        }}
                        isSetCategoryStyle
                        id="accountId"
                        name="accountId"
                        height="h-12"
                        type="account"
                        placeholderText="Select Account"
                      />
                      {validation.touched.accountId &&
                      validation.errors.accountId ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.accountId}
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`relative flex flex-col col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="amount"
                      >
                        Amount ({selectedCurrency}){' '}
                        <span className="text-rose-500">*</span>
                        <span
                          role="button"
                          tabIndex={0}
                          className="text-indigo-500 text-sm ml-1 cursor-pointer leading-none"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setShowCurrencyDropdown(!showCurrencyDropdown);
                          }}
                        >
                          {showCurrencyDropdown ? 'Cancel' : 'Change Currency'}
                        </span>
                      </label>
                      <input
                        id="amount"
                        type="number"
                        placeholder="0.00"
                        name="amount"
                        className={`btn pl-[3.75rem] pr-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80 ${
                          validation.touched.amount &&
                          validation.errors.amount &&
                          'border-rose-300'
                        }`}
                        onChange={validation.handleChange}
                        onBlur={(e) => {
                          validation.handleBlur(e);
                          if (
                            !hasUpToTwoDecimalPlaces(
                              parseFloat(validation.values.amount || 0),
                            )
                          ) {
                            validation.setFieldValue(
                              'amount',
                              roundToTwo(validation.values.amount),
                            );
                          }
                        }}
                        value={validation.values.amount || ''}
                        onWheel={(e) => e.target.blur()}
                      />
                      <span className="absolute top-[2.6rem] left-4 flex items-center gap-2">
                        <span className="text-slate-600">
                          {getCurrencySymbol(selectedCurrency || {})}
                        </span>

                        <div className="w-[0.038rem] h-[1.188rem] bg-slate-600" />
                      </span>
                      {validation.touched.amount && validation.errors.amount ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.amount}
                        </div>
                      ) : null}
                      <Transition
                        show={showCurrencyDropdown}
                        tag="div"
                        className="z-60 absolute top-20 left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                        enter="transition ease-out duration-100 transform"
                        enterStart="opacity-0 -translate-y-2"
                        enterEnd="opacity-100 translate-y-0"
                        leave="transition ease-out duration-100"
                        leaveStart="opacity-100"
                        leaveEnd="opacity-0"
                      >
                        {showCurrencyDropdown && (
                          <div
                            ref={currencyRef}
                            className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
                            onFocus={() => setShowCurrencyDropdown(true)}
                            onBlur={() => setShowCurrencyDropdown(false)}
                          >
                            <div className="max-h-48 overflow-auto scrollbar">
                              {currencies?.map((currency, index) => (
                                <button
                                  type="button"
                                  key={index}
                                  className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                                    currency.id === selectedCurrency?.id &&
                                    'text-indigo-500'
                                  }`}
                                  onClick={() => {
                                    setSelectedCurrency(currency?.isoCode);
                                    setShowCurrencyDropdown(false);
                                  }}
                                >
                                  {`${currency?.symbol} ${currency?.name}`}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </Transition>
                    </div>
                    <div
                      className={` col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 pb-5  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label className="mb-2.5 text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center">
                        Project{' '}
                        {validation.values.projectId && (
                          <span
                            onClick={() => {
                              validation.setFieldValue('projectId', '');
                            }}
                            role="button"
                            tabIndex={0}
                            className="ml-1 text-indigo-500 cursor-pointer"
                          >
                            Clear
                          </span>
                        )}
                      </label>
                      <DropdownFull
                        options={projects}
                        selected={validation.values.projectId}
                        setSelected={(value) =>
                          validation.setFieldValue('projectId', value)
                        }
                        name="name"
                        height="h-12"
                        scrollIntoView={false}
                        className="!h-fit"
                      />

                      {validation.touched.projectId &&
                      validation.errors.projectId ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-1">
                          {validation.errors.projectId}
                        </span>
                      ) : null}
                    </div>

                    <div
                      className={` col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 pb-5  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label className="mb-2.5 text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center">
                        Tags
                      </label>
                      <BasicCheckboxDropdown
                        selectedOptions={validation.values.tags}
                        setSelectedOptions={(tags) => {
                          validation.setFieldValue('tags', tags);
                        }}
                        options={
                          tags?.map((t) => ({
                            label: t?.name,
                            value: t?.id,
                          })) || []
                        }
                        label="Tags"
                        type="tag"
                        onApply={(tag) => {
                          const isTagSelected = (
                            validation.values.tags || []
                          )?.some((_tag) => _tag === tag);

                          let newTags;
                          if (isTagSelected) {
                            newTags = (validation.values.tags || [])?.filter(
                              (_tag) => _tag !== tag,
                            );
                          } else {
                            newTags = [...validation.values.tags, tag];
                          }
                          validation.setFieldValue('tags', newTags);
                        }}
                        flyout
                        width="w-full min-w-full"
                        search
                        height="h-12"
                        className="w-full"
                        bottomOfTable
                        ids={['receiptsPageContainer']}
                        // isAddOption
                        // addOptionText="Add New Tag"
                        // addOption={() => setTagModalOpen(true)}
                      />
                    </div>
                    <div
                      className={`flex flex-col gap-2.5 col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        placeholder="Write A Note"
                        className={`form-textarea px-4 py-2.5 rounded-[0.313rem] text-slate-600 justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal placeholder:text-base
                      ${
                        validation.touched.description &&
                        validation.errors.description &&
                        'border-rose-300'
                      }`}
                        type="description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Modal footer */}
                  <div className="px-5 pt-4 pb-20">
                    <div className="flex flex-wrap justify-end space-x-4">
                      <button
                        type="button"
                        className="btn-sm h-11 px-8 text-base border-[#667085]  hover:border-slate-800  text-[#667085]"
                        onClick={(e) => {
                          e.stopPropagation();
                          // if (billModalPdfView) {
                          //   setBillsWarningModalOpen(true);
                          // } else {
                          setIsActive(false);
                          if (billModalPdfView) {
                            setBillModalPdfView(false);
                          }

                          if (Object.keys(uploadedBillData || {}).length > 0) {
                            setUploadedBillData({});
                          }
                          if (Object.keys(editReceiptData || {}).length > 0) {
                            setEditReceiptData(null);
                          }
                          // }
                        }}
                      >
                        Close
                      </button>
                      {checkPermission(
                        PermissionKeys.APPROVE_BILLS,
                        accessType,
                        permissions,
                      ) && (
                        <button
                          type="button"
                          className="btn-sm h-11 px-8 text-base bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
                          disabled={loading || draftLoading}
                          onClick={() => {
                            validation.setFieldValue(
                              'approvalStatus',
                              'approved',
                            );
                            validation.handleSubmit();
                          }}
                        >
                          {loading && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          {editReceiptData?.id ? 'Update' : 'Save'}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default AddEditReceipt;
