import moment from 'moment';
import React, { useState } from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import { getStringSegments, trimText } from 'utils/Utils';
import RecurrenceTemplateModal from './RecurrenceTemplateModal';
import { deleteRecurringTemplates } from '../../API/backend_helper';
import { Loader } from '../../components/Svg';

const RecurringTemplatesTable = ({
  recurringTemplates,
  loadRecurringTemplates,
  setAddType,
  setEditInvoice,
  setIsPreview,
  setIsAssignedTransTab,
  setSearchParams,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [modalOpen, setModalOpen] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [loadingTemplates, setLoadingTemplates] = useState({});

  const handleDeleteTemplate = async (id) => {
    setLoadingTemplates({ ...loadingTemplates, [id]: true });
    try {
      await deleteRecurringTemplates(id);
      await loadRecurringTemplates({});
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingTemplates((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  return (
    <>
      <RecurrenceTemplateModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        activeTemplate={activeTemplate}
        loadRecurringTemplates={loadRecurringTemplates}
      />
      {/* Table */}

      <div className=" overflow-auto scrollbar" id="invoicesRecurringTemplates">
        <table className="table-fixed w-full dark:text-slate-300 divide-y divide-slate-200">
          {/* Table header */}
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[10rem] max-w-[10rem] whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">Recurrence Pattern</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[10rem] max-w-[10rem] whitespace-nowrap">
                <div className="font-medium text-left">Next Invoice Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[10rem] max-w-[10rem] whitespace-nowrap">
                <div className="font-medium text-left">Recurrence End Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[12rem] max-w-[12rem] whitespace-nowrap">
                <div className="font-medium text-left">Customer</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[10rem] max-w-[10rem] whitespace-nowrap">
                <div className="font-medium text-left">Auto Email Customer</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[10rem] max-w-[10rem] whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">
                  Advance Creation Days
                </div>
              </th>
              {checkPermission(
                PermissionKeys.APPROVE_INVOICES,
                accessType,
                permissions,
              ) && (
                <th className="px-2 first:pl-5 last:pr-5 text-left py-4 w-[10rem] max-w-[10rem] whitespace-nowrap sm:table-cell hidden ">
                  <div className="font-medium text-left">Actions</div>
                </th>
              )}
            </tr>
          </thead>
          {/* Table body */}
          {recurringTemplates?.length ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {recurringTemplates.map((b) => (
                <tr
                  key={b?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddType('Template');
                    setEditInvoice(b);
                    setIsPreview(false);
                    setIsAssignedTransTab(false);
                    setSearchParams({
                      tab: 'recurring',
                      id: b?.id,
                      type: 'template',
                    });
                  }}
                  className={`w-full px-[1.25rem] cursor-pointer h-16 hover:bg-[#a0cd85] hover:bg-opacity-5  `}
                >
                  <td className="px-2 text-sm capitalize first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    {b?.recurrencePattern}
                  </td>
                  <td className="px-2 pl-4 first:pl-5 last:pr-5 py-2.5 text-left">
                    {b?.nextInvoiceDate
                      ? moment(
                          b?.nextInvoiceDate?.split?.('T')?.[0],
                          'yyyy-MM-DD',
                        )?.format('MMM DD, YYYY')
                      : '--'}
                  </td>
                  <td className="px-2  first:pl-5 last:pr-5 py-2.5 text-left   ">
                    {b?.recurrenceEndDate
                      ? moment(
                          b?.recurrenceEndDate?.split?.('T')?.[0],
                          'yyyy-MM-DD',
                        )?.format('MMM DD, YYYY')
                      : '--'}
                  </td>
                  <td className="px-2 pl-4 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                    <Tooltip
                      tooltipShow={b?.customer?.customer?.length > 20}
                      content={
                        b?.customer?.customer?.length > 20
                          ? (() => {
                              const segments = getStringSegments(
                                b?.customer?.customer,
                                40,
                              );
                              return segments.map((segment, index) => (
                                <p key={index}>{segment}</p>
                              ));
                            })()
                          : b?.customer?.customer
                      }
                      contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                      isFixed
                      ids={[
                        'invoicesRecurringTemplates',
                        'invoiceEstimateContainer',
                      ]}
                    >
                      <span className="">
                        {trimText(b?.customer?.customer || '', 20)}
                      </span>
                    </Tooltip>
                  </td>
                  <td className="px-2 pl-4 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                    {b?.emailCustomer ? 'Yes' : 'No'}
                  </td>
                  <td className="px-2 pl-4 first:pl-5 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                    {b?.inAdvanceCreationDays}
                  </td>
                  {checkPermission(
                    PermissionKeys.APPROVE_INVOICES,
                    accessType,
                    permissions,
                  ) && (
                    <td className="px-2 last:pr-5 py-2.5 text-left  sm:table-cell hidden ">
                      <div className="flex gap-2 items-center">
                        <button
                          className="btn-sm  h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642]"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAddType('Template');
                            setEditInvoice(b);
                            setIsPreview(false);
                            setIsAssignedTransTab(false);
                            setSearchParams({
                              tab: 'recurring',
                              id: b?.id,
                              type: 'template',
                            });

                            // setModalOpen(true);
                            // setActiveTemplate(b);
                          }}
                          type="button"
                        >
                          Edit
                        </button>
                        {loadingTemplates[b?.id] ? (
                          <Loader height="h-5 ml-4" width="w-5" />
                        ) : (
                          <button
                            className="btn-sm  h-[30px] text-sm text-rose-500 whitespace-nowrap bg-rose-50 px-2.5 border border-rose-500"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteTemplate(b?.id);
                            }}
                            type="button"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!(recurringTemplates?.length > 0) && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Templates Found
          </h6>
        )}
      </div>
    </>
  );
};

export default RecurringTemplatesTable;
