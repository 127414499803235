import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { toast } from 'react-toastify';
import AlertBox from 'components/AlertBox';
import AddTaskModal from 'pages/inbox/AddTaskModal';
import {
  getTeamById,
  getCurrenciesData,
  getJournalEntries,
  createJournalEntries,
  getMerchants,
  getCustomers,
  getAccounts,
  getAccountTypes,
  updateJournalEntry,
  deleteJournalEntry,
  getAllProjects,
  getTags,
  getProducts,
  getTaxes,
  getJournalEntry,
} from '../../API/backend_helper';
import SearchForm from '../../partials/actions/SearchForm';
import PaginationClassic from '../../components/PaginationClassic';
import Tooltip from '../../components/Tooltip';
import emptySearchImg from '../../images/custom/empty-search.svg';
import {
  TEAM_ID,
  transformAccounts,
  getContrast,
  ACCOUNT_TYPES,
  DevEnv,
  getIncDecAccountTypeStatus,
  getCurrencySymbolFromIso,
  trimText,
  getFormattedDate,
  TEAM_CURRENCY,
  formatDateLocally,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';
import DeleteJournalEntryModal from './DeleteJournalEntryModal';
import JournalEntryForm from './JournalEntryForm';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import DateFilter from '../transactions/DateFilter';
import CategoriesFilter from '../transactions/Filters/CategoriesFilter';
import TransactionDetails from '../transactions/TransactionPanel';
import ReceiptModal from '../transactions/ReceiptModal';
import CreateRulePopup from '../../components/CreateRulePopup';
import CreateRuleModal from '../../components/CreateRuleModal';
import AppLayout from '../../components/AppLayout';
import JournalEntriesTable from './JournalEntriesTable';

const DescriptionLength = 25;
let searchTimeout = null;

const JournalEntries = () => {
  const location = useLocation();
  const pageRef = useRef();

  const Env = process.env.REACT_APP_ENV;
  const descriptionTDRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId') || null;
  const accountType = searchParams.get('accountType') || null;
  const startId = searchParams.get('startId') || null;
  const endId = searchParams.get('endId') || null;
  const mode = searchParams.get('mode') || null;
  const _journalUuid = searchParams.get('journalUuid');

  // console.log("description Ref", descriptionTDRef.current?.clientWidth);

  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [isLoading, setIsLoading] = useState(false);
  const [flyoutIsLoading, setFlyoutIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [journalentries, setJournalEntries] = useState([]);
  const [activeEntry, setActiveEntry] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Journal Entries');
  const [errMessage, setErrMessage] = useState('');
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [teamCurrency, setTeamCurrency] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsRes, setAllAccountsRes] = useState([]);
  const [accountCategories, setAccountCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [accountLength, setAccountLength] = useState(20);
  const [addEditLoader, setAddEditLoader] = useState(false);
  const [deleteEntryLoader, setDeleteEntryLoader] = useState(false);
  const [projects, setProjects] = useState([]);
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [onlyManualJE, setOnlyManualJE] = useState(false);

  // Flyout
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState('details');
  const [splitTransactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [physicalAccounts, setPhysicalAccounts] = useState([]);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [popupCategory, setPopupCategory] = useState(null);
  const [popupTransaction, setPopupTransaction] = useState(null);
  const [jEDuplicated, setJEDuplicated] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  // Filters
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);

  const getEntriesById = async () => {
    try {
      const res = await getJournalEntry(_journalUuid);
      setTitle('Edit Journal Entry');
      setActiveEntry(res?.data?.journalEntries);
      if (jEDuplicated) {
        setJEDuplicated(false);
      }
      setFormOpen(true);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (mode === 'edit' && _journalUuid) {
      getEntriesById();
    }
  }, [mode, _journalUuid]);

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(
        PermissionKeys.VIEW_JOURNALS_ENTRIES,
        accessType,
        permissions,
      )
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const getData = async ({
    limit = localLimit,
    page = journalentries?.page,
    search = searchValue || '',
    loading = false,
    _onlyManualJE = onlyManualJE,
    accounts = selectedAccount || [],
    accountTypes = selectedAccountTypes || [],
    startDate = startDateFilter
      ? getFormattedDate(startDateFilter, teamDateFormat)
      : '',
    endDate = endDateFilter
      ? getFormattedDate(endDateFilter, teamDateFormat)
      : '',
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getJournalEntries({
        page,
        search,
        limit,
        accounts,
        startDate,
        endDate,
        accountType: accountTypes,
        entryType: _onlyManualJE ? ['manual'] : [],
      });
      const temp = [];
      if (res?.data?.results?.length > 0) {
        const groupedObjects = res?.data?.results.reduce((acc, obj) => {
          const key = obj.journalLinkUuid;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        // Convert the object of arrays to an array of arrays
        const groupedArrays = Object.values(groupedObjects).map((group) => {
          const debits = group.filter((obj) => obj.amountDebit !== null);
          const credits = group.filter((obj) => obj.amountDebit === null);
          const groupObj = { data: debits.concat(credits) };
          groupObj.debitTotal =
            debits?.reduce?.(
              (acc, obj) => acc + parseFloat(obj?.amountDebit),
              0,
            ) || 0;
          groupObj.creditTotal =
            credits?.reduce?.(
              (acc, obj) => acc + parseFloat(obj?.amountCredit),
              0,
            ) || 0;
          return groupObj;
        });
        res.data.results = groupedArrays;
      }
      setJournalEntries(res?.data);
      setIsReset(false);
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const getTagsApi = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getProductsApi = async () => {
    try {
      const res = await getProducts({ limit: 10000, status: 'active' });
      setProducts(res?.message?.products);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadProjects = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePopupLogic = (category, transaction) => {
    setPopupCategory(category);
    setPopupTransaction(transaction);
    setPopupOpen(true);
  };

  useEffect(() => {
    if (formOpen || flyoutOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [formOpen, flyoutOpen]);

  const handleDeleteEntry = async (id) => {
    setDeleteEntryLoader(true);
    try {
      await deleteJournalEntry(id);
      getData({});
      setActiveEntry(null);
      setModalOpen(false);
      setFormOpen(false);
      setDeleteEntryLoader(false);
      toast.success('Journal Entry deleted successfully.');
    } catch (err) {
      console.log('error', err);
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
        setModalOpen(true);
      } else {
        setModalOpen(false);
      }
      setDeleteEntryLoader(false);
    }
  };

  const onSearch = (val) => {
    setSearchValue(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getData({
        page: 1,
        search: encodeURIComponent(val || ''),
        loading: true,
      });
    }, 900);
  };

  const loadAllVendors = async () => {
    try {
      const res = await getMerchants({ limit: 10000, status: 'active' });
      setVendors(res?.vendors);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (location?.state?.journalEntry?.length > 0) {
      setFlyoutOpen(false);
      setTitle('Edit Journal Entry');
      setActiveEntry(location?.state?.journalEntry);
      setFormOpen(true);
    }
  }, [location?.state?.journalEntry]);

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({ status: 'active' });
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      setTypes(typeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountsApi = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllAccountsRes(accountsRes);
      setAllAccounts(transformAccounts(accountsRes, 'allAccounts'));
      setAccountCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      setPhysicalAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getAccountsApi();
    getAccountTypesApi();
    loadAllVendors();
    loadProjects();
    getTagsApi();
    loadTaxes();
    getProductsApi();
    getCustomersApi();
  }, []);

  useEffect(() => {
    if (currencies?.length > 0) {
      // Find matching currency based on teamDefaultCurrency
      const matchingCurrency = currencies?.find(
        (currency) => currency?.isoCode === teamDefaultCurrency,
      );
      setTeamCurrency(matchingCurrency);
    }
  }, [currencies]);

  useEffect(() => {
    if (searchParams?.size === 0) {
      getData({
        loading: true,
      });
    } else {
      getData({
        loading: true,
        accounts: accountId ? [accountId] : [], // Use accountId if available, otherwise fallback to selectedAccount
        accountTypes: accountType ? [accountType] : [], // Same for accountTypes
        startDate: startId
          ? getFormattedDate(
              formatDateLocally(startId, teamDateFormat),
              teamDateFormat,
            )
          : '', // Handle startDate based on startId
        endDate: endId
          ? getFormattedDate(
              formatDateLocally(endId, teamDateFormat),
              teamDateFormat,
            )
          : '', // Handle endDate based on endId
      });
      // Update selected account if accountId is not null
      if (accountId !== null) {
        setSelectedAccount([+accountId]);
      }

      // Update selected account types if accountType is not null
      if (accountType !== null) {
        setSelectedAccountTypes([accountType]);
      }

      // Update start date filter if startId is not null
      if (startId !== null) {
        setStartDateFilter(formatDateLocally(startId, teamDateFormat));
      }

      // Update end date filter if endId is not null
      if (endId !== null) {
        setEndDateFilter(formatDateLocally(endId, teamDateFormat));
      }

      // Update data using the latest parameters
    }
  }, [searchParams]);

  document.title = 'COUNT | Journal Entries';

  const { filters, results } = journalentries || {};
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setAccountLength(40);
      } else if (window.innerWidth > 1700) {
        setAccountLength(35);
      } else if (window.innerWidth > 992) {
        setAccountLength(20);
      } else {
        setAccountLength(20);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDateChange = async (startDate, endDate) => {
    await getData({
      startDate: startDate ? getFormattedDate(startDate, teamDateFormat) : '',
      endDate: endDate ? getFormattedDate(endDate, teamDateFormat) : '',
      page: 1,
      loading: true,
    });
  };

  const handleResetFilters = async () => {
    setSelectedAccount([]);
    setStartDateFilter('');
    setEndDateFilter('');
    setSelectedAccountTypes([]);
    setSearchValue('');
    setIsReset(true);
    setOnlyManualJE(false);
    await getData({
      page: 1,
      accounts: [],
      accountTypes: [],
      startDate: '',
      endDate: '',
      search: '',
      loading: true,
      _onlyManualJE: false,
    });
  };

  const onDelete = () => {
    setErrMessage('');
    setFlyoutOpen(false);
    setModalOpen(true);
  };

  const scrollPageToTop = () => {
    pageRef?.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const data = useMemo(() => {
    if (results?.length > 0) {
      return results?.flatMap?.((item, index) => [
        ...item?.data?.map?.((d) => ({ ...d, itemIndex: index })),
        {
          creditTotal: item?.creditTotal,
          debitTotal: item?.debitTotal,
          isTotalRow: true,
          currency: item?.data?.[0]?.currency,
        },
        {
          isEmptyRow: true,
        },
      ]);
    }
    return [];
  }, [results]);

  return (
    <>
      {/* <JournalEntryModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleAddEntry={handleAddEntry}
        activeEntry={activeEntry}
        handleUpdateEntry={handleUpdateEntry}
        setActiveEntry={setActiveEntry}
        setFormOpen={setFormOpen}
        setTitle={setTitle}
      /> */}
      <DeleteJournalEntryModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleDeleteEntry={handleDeleteEntry}
        activeEntry={activeEntry}
        errMessage={errMessage}
        loading={deleteEntryLoader}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <CreateRuleModal
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        category={popupCategory}
        transaction={popupTransaction}
        reloadTransactions={getData}
      />
      {popupOpen && (
        <CreateRulePopup
          open={popupOpen}
          setOpen={setPopupOpen}
          category={popupCategory}
          transaction={popupTransaction}
          openModal={setRuleModalOpen}
        />
      )}
      <AddTaskModal
        modalOpen={taskModalOpen}
        setModalOpen={setTaskModalOpen}
        transactions
        selectedTransaction={selectedRow?.transaction}
        projects={projects}
      />

      <AppLayout pageId="journalEntriesContainer" ref={pageRef}>
        <main className="relative grow ">
          {/* <JournalEntryFlyout
              flyoutOpen={flyoutOpen}
              setFlyoutOpen={setFlyoutOpen}
              activeEntry={activeEntry}
              setActiveEntry={setActiveEntry}
              activeEntryTransactions={activeEntryTransactions}
              activeEntryAnalytics={activeEntryAnalytics}
              goToTransactions={goToTransactions}
              handleUpdateEntry={handleUpdateEntry}
              flyoutIsLoading={flyoutIsLoading}
              setTitle={setTitle}
              setFormOpen={setFormOpen}
              currency={currencies?.find?.(c => c.isoCode === teamDefaultCurrency)}
            /> */}
          {selectedRow && (
            <TransactionDetails
              transaction={selectedRow?.transaction}
              setTransaction={setSelectedRow}
              transactionPanelOpen={flyoutOpen}
              setTransactionPanelOpen={setFlyoutOpen}
              accounts={physicalAccounts}
              allCategories={accountCategories}
              vendors={vendors}
              setVendors={setVendors}
              customers={customers}
              allExpenseCategories={expenseCategories}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              reloadTransaction={getData}
              setSplitTransactionModalOpen={setSplitTransactionModalOpen}
              setFromDropdown={setFromDropdown}
              fromDropdown={fromDropdown}
              receiptModalOpen={receiptModalOpen}
              setReceiptModalOpen={setReceiptModalOpen}
              activeReceipt={activeReceipt}
              setActiveReceipt={setActiveReceipt}
              journalEntry
              ruleModalOpen={ruleModalOpen}
              setRuleModalOpen={setRuleModalOpen}
              setPopupOpen={setPopupOpen}
              setPopupCategory={setPopupCategory}
              setPopupTransaction={setPopupTransaction}
              handlePopupLogic={handlePopupLogic}
              projects={projects}
              team={team}
              currencies={currencies}
              tags={tags}
              setTags={setTags}
              taxes={taxes}
              loadTaxes={loadTaxes}
              taskModalOpen={taskModalOpen}
              setTaskModalOpen={setTaskModalOpen}
              loadTransactionFromApi
            />
          )}
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex justify-between mb-6 gap-4">
              <div className="flex xl:items-center gap-x-10 flex-1 xl:flex-row flex-col gap-y-2 ">
                <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                  {title}
                </h1>
                {formOpen && jEDuplicated && (
                  <AlertBox
                    type="success"
                    heading="Journal entry successfully duplicated. Note: date has been reset to today"
                    className="!py-2  !w-fit items-center"
                    isCross
                    onActionButton={() => setJEDuplicated(false)}
                  />
                )}
              </div>
              {!formOpen ? (
                <div className="sm:flex gap-4 items-center">
                  {checkPermission(
                    PermissionKeys.MANAGE_JOURNAL_ENTRIES,
                    accessType,
                    permissions,
                  ) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveEntry(null);
                        setTitle('Add Journal Entry');
                        if (jEDuplicated) {
                          setJEDuplicated(false);
                        }
                        setFormOpen(true);
                      }}
                      type="button"
                      className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setFormOpen(true)}
                      >
                        <path
                          d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Add Journal Entry
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActiveEntry(null);
                    setTitle('Journal Entries');
                    setFormOpen(false);
                    if (jEDuplicated) {
                      setJEDuplicated(false);
                    }
                  }}
                  type="button"
                  className="h-[38px]"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17688"
                      y="7.82422"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17688 7.82422)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1771"
                      y="6.17676"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1771 6.17676)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
            {!formOpen && (
              <div className="w-full mb-4 mt-6 flex sm:flex-row flex-col justify-between items-center gap-4 sm:gap-7">
                <div className="flex-1 max-w-[1000px] flex gap-4 flex-wrap">
                  <div className="flex-1">
                    <CategoriesFilter
                      allCategories={allAccounts}
                      selectedCategoryId={selectedAccount}
                      setSelectedCategoryId={setSelectedAccount}
                      getTransactionsApi={getData}
                      apiFilter={filters?.accounts}
                      isReset={isReset}
                      type="account"
                      journalEntries
                    />
                  </div>
                  <BasicCheckboxDropdown
                    selectedOptions={selectedAccountTypes}
                    setSelectedOptions={setSelectedAccountTypes}
                    options={ACCOUNT_TYPES}
                    // apiFunc={getData}
                    onApply={(values) => {
                      getData({
                        page: 1,
                        accountTypes: values,
                        loading: true,
                      });
                    }}
                    onClear={() => {
                      setSelectedAccountTypes([]);
                      getData({
                        page: 1,
                        accountTypes: [],
                        loading: true,
                      });
                    }}
                    label="Account Type"
                    type="accountTypes"
                    apiFilter={filters?.accountType}
                  />

                  <div className="flex-1">
                    <DateFilter
                      startDateFilter={startDateFilter}
                      endDateFilter={endDateFilter}
                      getTransactionsApi={getData}
                      setStartDateFilter={setStartDateFilter}
                      setEndDateFilter={setEndDateFilter}
                      onDateChange={onDateChange}
                      apiFilter={filters || {}}
                      maxDate={null}
                      align="right"
                    />
                  </div>
                </div>
              </div>
            )}
            {formOpen && (
              <JournalEntryForm
                setActiveEntry={setActiveEntry}
                activeEntry={activeEntry}
                onDeleteJournalEntry={onDelete}
                setFormOpen={setFormOpen}
                setTitle={setTitle}
                vendors={vendors}
                setVendors={setVendors}
                customers={customers}
                currencies={currencies}
                teamCurrency={teamCurrency}
                allAccounts={allAccounts}
                accountCategories={accountCategories}
                types={types}
                loading={addEditLoader}
                projects={projects}
                tags={tags}
                getData={getData}
                setAddEditLoader={setAddEditLoader}
                setCustomers={setCustomers}
                products={products}
                allAccountsRes={allAccountsRes}
                scrollPageToTop={scrollPageToTop}
                jEDuplicated={jEDuplicated}
                setJEDuplicated={setJEDuplicated}
              />
            )}
            {!formOpen && (
              <>
                <JournalEntriesTable
                  data={data}
                  results={results}
                  setActiveEntry={setActiveEntry}
                  setSelectedRow={setSelectedRow}
                  setFlyoutOpen={setFlyoutOpen}
                  setTitle={setTitle}
                  setFormOpen={setFormOpen}
                  jEDuplicated={jEDuplicated}
                  setJEDuplicated={setJEDuplicated}
                  journalEntries={journalentries}
                  isLoading={isLoading}
                  getData={getData}
                  searchValue={searchValue}
                  handleSearch={onSearch}
                  handleResetFilters={handleResetFilters}
                  onlyManualJE={onlyManualJE}
                  setOnlyManualJE={setOnlyManualJE}
                  setLocalLimit={setLocalLimit}
                />

                {/* <div className="flex flex-col gap-5 items-center justify-center w-full">
                        <h6 className="text-center mt-6">
                          No Journal Entries Found
                        </h6>
                        <img
                          src={emptySearchImg}
                          alt="No journalentries found"
                          className="h-[12.5rem]"
                        />
                      </div> */}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default JournalEntries;
