/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MRT_ShowHideColumnsButton } from 'mantine-react-table';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import SearchForm from 'partials/actions/SearchForm';
import ReusableTable from 'components/ReusableTable';
import {
  getCurrencySymbolFromIso,
  getIncDecAccountTypeStatus,
} from 'utils/Utils';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useMediaQuery } from '@mantine/hooks';

const tableInitialState = {
  columnSizing: {
    date: 120,
    description: 200,
    account: 150,
    type: 80,
    tags: 100,
    amountDebit: 130,
    amountCredit: 130,
    amount: 130,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  columnOrder: [],
  showGlobalFilter: false,
  expanded: true,
  columnVisibility: {
    date: true,
    description: true,
    account: true,
    type: true,
    tags: false,
    amountDebit: true,
    amountCredit: true,
    amount: true,
  },
};

const JournalEntriesTable = ({
  data,
  journalEntries,
  searchValue,
  handleSearch,

  setActiveEntry,
  setSelectedRow,
  setFlyoutOpen,
  results,
  setTitle,
  setFormOpen,
  jEDuplicated,
  setJEDuplicated,
  getData,

  isLoading,
  handleResetFilters,
  onlyManualJE,
  setOnlyManualJE,
  setLocalLimit,
}) => {
  const XML = useMediaQuery('(max-width: 1480px)');
  const navigate = useNavigate();
  const { currencies } = useSelector((state) => state.Currencies);
  const { accessType, permissions } = useSelector((state) => state.User);

  const [tableState, setTableState] = useState({
    ...tableInitialState,
    isLoading: true,
    sorting: [], // Ensure sorting is initialized as an array
  });

  const fetchJE = ({
    page = journalEntries?.page,
    limit = journalEntries?.pageSize,
    search = searchValue,
    sortBy = tableState?.sorting?.[0]?.id || '',
    order = tableState?.sorting?.[0]?.id
      ? tableState?.sorting?.[0]?.desc
        ? 'desc'
        : 'asc'
      : '',
  }) => {
    getData({
      page: parseInt(page),
      limit,
      loading: true,
      search,
      order,
      sortBy,
    });
  };

  const IconHeader = ({ icon, text }) => (
    <div className="flex gap-2 items-center mr-2">
      {/* {icon} */}
      <span className="font-medium text-sm">{text}</span>
    </div>
  );

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        id: 'date',
        accessorKey: 'date',
        header: 'Date',
        Header: <IconHeader text="Date" />,
        enableColumnResizing: true,
        minSize: 100,
        Cell: ({ row }) =>
          !row?.original?.isEmptyRow && (
            <div
              className={`flex items-center text-[#667085] whitespace-nowrap ${row?.original?.isTotalRow ? 'font-bold' : ''}`}
            >
              {row?.original?.isTotalRow
                ? 'Total'
                : moment?.utc(row?.original?.date)?.format('MMM DD, YYYY')}
            </div>
          ),
        enableSorting: false,
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: 'Description',
        Header: <IconHeader text="Description" />,
        Cell: ({ row }) =>
          !row?.original?.isTotalRow &&
          !row?.original?.isEmptyRow && (
            <div className="text-[#667085]">
              {row?.original?.descriptionLine
                ? row?.original?.descriptionLine
                : row?.original?.descriptionEntry}
            </div>
          ),
        enableColumnResizing: true,
        enableSorting: false,
      },
      {
        id: 'account',
        accessorKey: 'account',
        header: 'Account',
        Header: <IconHeader text="Account" />,
        Cell: ({ row }) => {
          const name = row?.original?.account?.name || '';
          const mask = row?.original?.account?.mask || '';
          const combinedAccount = mask ? `${name} (...${mask})` : name;
          return (
            !row?.original?.isTotalRow &&
            !row?.original?.isEmptyRow && (
              <div className=" text-[#667085]">{combinedAccount}</div>
            )
          );
        },
        enableColumnResizing: true,
        enableSorting: false,
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: 'Type',

        Header: <IconHeader text="Type" />,
        Cell: ({ row }) =>
          !row?.original?.isTotalRow &&
          !row?.original?.isEmptyRow && (
            <div className="text-[#667085]">{row?.original?.accountType}</div>
          ),
        enableColumnResizing: false,
        enableSorting: false,
      },
      {
        id: 'tags',
        accessorKey: 'tags',
        header: 'Tags',

        Header: <IconHeader text="Tags" />,
        Cell: ({ row }) =>
          !row?.original?.isTotalRow &&
          !row?.original?.isEmptyRow && (
            <div className="text-[#667085]">
              {row?.original?.tags?.map((t, i) => (
                <p key={t?.id}>
                  {t?.name}
                  {row?.original?.tags?.length - 1 !== i && ','}
                </p>
              ))}
            </div>
          ),
        enableColumnResizing: false,
        enableSorting: false,
      },
      {
        id: 'amountDebit',
        accessorKey: 'amountDebit',
        header: 'Amount Debit',

        Header: <IconHeader text="Amount Debit" />,
        Cell: ({ row }) =>
          !row?.original?.isEmptyRow &&
          (row?.original?.isTotalRow ? (
            <div className="text-[#667085] font-bold">
              {getCurrencySymbolFromIso(row?.original?.currency, currencies)}
              {parseFloat(row?.original?.debitTotal)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              )}
            </div>
          ) : (
            <div className="text-[#667085] flex items-center">
              {row?.original?.amountDebit ? (
                getIncDecAccountTypeStatus(
                  row?.original?.accountType,
                  'debit',
                ) === 'upArrow' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className="w-4 h-3  shrink-0 fill-current text-[#78BD4F]"
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className={`w-4 h-3 ${'rotate-180'} shrink-0 fill-current text-[#FF4B4B]`}
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                )
              ) : (
                ''
              )}
              <span className="ml-2">
                {row?.original?.amountDebit
                  ? `${getCurrencySymbolFromIso(
                      row?.original?.currency,
                      currencies,
                    )}${parseFloat(row?.original?.amountDebit)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`
                  : ''}
              </span>
            </div>
          )),
        enableColumnResizing: false,
        enableSorting: false,
      },
      {
        id: 'amountCredit',
        accessorKey: 'amountCredit',
        header: 'Amount Credit',

        Header: <IconHeader text="Amount Credit" />,
        Cell: ({ row }) =>
          row?.original?.isTotalRow ? (
            <div className="text-[#667085] font-bold">
              {getCurrencySymbolFromIso(row?.original?.currency, currencies)}
              {parseFloat(row?.original?.creditTotal)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              )}
            </div>
          ) : (
            <div className="text-[#667085] flex items-center">
              {row?.original?.amountCredit ? (
                getIncDecAccountTypeStatus(
                  row?.original?.accountType,
                  'credit',
                ) === 'upArrow' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className="w-4 h-3 ml-2  shrink-0 fill-current text-[#78BD4F]"
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className={`w-4 h-3 ml-2 ${'rotate-180'} shrink-0 fill-current text-[#FF4B4B]`}
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                )
              ) : (
                ''
              )}
              <span className="ml-2">
                {row?.original?.amountCredit
                  ? `${getCurrencySymbolFromIso(
                      row?.original?.currency,
                      currencies,
                    )}${parseFloat(row?.original?.amountCredit)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}`
                  : ''}
              </span>
            </div>
          ),
        enableColumnResizing: false,
        enableSorting: false,
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: 'Amount (+/-)',

        Header: <IconHeader text="Amount (+/-)" />,
        Cell: ({ row }) =>
          !row?.original?.isTotalRow &&
          !row?.original?.isEmptyRow && (
            <div className="text-[#667085] flex items-center">
              {row?.original?.amount?.toString().charAt(0) === '-' ? '-' : ''}
              {getCurrencySymbolFromIso(row?.original?.currency, currencies)}
              {parseFloat(
                row?.original?.amount?.toString().replace('-', ''),
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          ),
        enableColumnResizing: false,
        enableSorting: false,
      },
    ],
    [currencies],
  );

  return (
    <ReusableTable
      columns={columns}
      data={data}
      positionPagination="both"
      enableColumnResizing
      enableSorting={false}
      rowCount={journalEntries?.totalRecords || 0}
      pageCount={-1}
      onPaginationChange={(updater) => {
        const pagination =
          typeof updater === 'function' ? updater({}) : updater;

        if (!pagination || typeof pagination !== 'object') {
          return;
        }
        const { pageIndex, pageSize } = pagination;
        setLocalLimit(pageSize ?? parseInt(journalEntries?.limit));
        fetchJE({
          page: pageIndex >= 0 ? pageIndex + 1 : parseInt(journalEntries?.page),
          limit: pageSize ?? parseInt(journalEntries?.limit),
        });
      }}
      initialState={{
        columnSizing: {
          name: 200,
          owner: 200,
          email: 200,
          status: 100,
          manage: 100,
        },
      }}
      state={{
        isLoading,
        pagination: {
          pageIndex: parseInt(journalEntries?.page) - 1 || 0,
          pageSize: parseInt(journalEntries?.limit) ?? 0,
        },
        sorting: tableState.sorting, // Ensure this is an array
        globalFilter: tableState.globalFilter,
        columnSizing: tableState?.columnSizing,
        columnVisibility: tableState?.columnVisibility,
      }}
      onStateChange={setTableState}
      enableGlobalFilter={false} // disables search bar
      enableFilters={false} // disables column filters
      enableDensityToggle={false} // Hides density toggle
      enableFullScreenToggle={false} // Hides full-screen toggle
      enableColumnActions={false} // Hides action dropdown from each column
      mantineTableProps={{
        withBorder: false, // Removes the outer border
        withColumnBorders: false, // Removes borders between columns
      }}
      mantineTableBodyCellProps={({ row }) =>
        !row?.original?.isTotalRow
          ? {
              style: { borderTop: 'none' },
            }
          : {
              style: {
                borderBottom: '0.0625rem solid #dee2e6',
                paddingTop: '6px',
                paddingBottom: '6px',
              },
            }
      }
      mantineTableContainerProps={{
        className: 'border-none scrollbar mb-2 pb-1',
        id: 'journalEntriesTable',
      }}
      mantineTableBodyRowProps={({ row }) => ({
        onClick: (e) => {
          e.stopPropagation();
          const journalEntry = row?.original;
          if (row?.original?.isEmptyRow || row?.original?.isTotalRow) {
            return;
          }
          if (
            journalEntry?.transactionId ||
            journalEntry?.billInstanceId ||
            journalEntry?.invoiceId ||
            journalEntry?.transaction?.billInstanceId ||
            journalEntry?.transaction?.invoiceId
          ) {
            setActiveEntry(journalEntry);
            setSelectedRow(journalEntry);
            if (journalEntry?.transaction?.billInstanceId) {
              navigate('/bills', {
                state: {
                  activeBillId:
                    journalEntry?.transaction?.assignedToBillInstance?.billId,
                },
              });
            }
            if (journalEntry?.billInstanceId) {
              navigate('/bills', {
                state: {
                  activeBillId: journalEntry?.billInstance?.billId,
                },
              });
            } else if (journalEntry?.transaction?.invoiceId) {
              navigate(
                `/invoices?tab=invoices&id=${journalEntry?.transaction?.invoiceId}&from=journalEntry`,
              );
            } else if (journalEntry?.invoiceId) {
              navigate(
                `/invoices?tab=invoices&id=${journalEntry?.invoiceId}&from=journalEntry`,
              );
            } else if (journalEntry?.transactionId) {
              setFlyoutOpen(true);
            }
          } else if (!journalEntry?.systemCreated) {
            setFlyoutOpen(false);
            if (
              checkPermission(
                PermissionKeys.MANAGE_JOURNAL_ENTRIES,
                accessType,
                permissions,
              )
            ) {
              setTitle('Edit Journal Entry');
              setActiveEntry(results?.[journalEntry?.itemIndex]?.data);
              setFormOpen(true);
              if (jEDuplicated) {
                setJEDuplicated(false);
              }
            }
          }
        },

        style: {
          cursor:
            !row?.original?.isEmptyRow && !row?.original?.isTotalRow
              ? 'pointer'
              : 'default',
        }, // Change cursor to pointer on hover
        sx: {
          transition: 'background-color 0.2s ease',
          '&:hover': {
            backgroundColor: !row?.original?.isEmptyRow
              ? 'rgb(160, 205, 133, 0.05) !important'
              : 'transparent !important',
          },
          '&:hover td': {
            backgroundColor: 'transparent',
          },
          '&:last-of-type td': {
            borderBottom: 'none !important',
          },
        },
      })}
      columnAc
      mantinePaperProps={{
        className: '!border-none  shadow-none min-h-fit',
      }}
      mantineTopToolbarProps={{
        className: 'bg-[#A0CD850F] min-h-16',
        sx: {
          '& > div:nth-child(2)': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '0.5rem',
          },
          '& > div:nth-child(2) > div': {
            marginTop: XML ? '' : '0px !important',
            padding: XML ? '' : '0 16px !important',
            height: XML ? '' : '44px',
          },
          '& > div:nth-child(3) > div': {
            marginTop: '0px !important',
            // padding: '0 !important',
            // height: '44px',
          },
          '& .mantine-Select-wrapper input': {
            border: '1px solid #D0D5DD',
            outline: 'none',
            borderRadius: '0.375rem',
          },
          '& .mantine-Select-wrapper input:hover': {
            border: '1px solid #94a3b8',
          },
          '& .mantine-Select-wrapper input:focus': {
            outline: 'none',
            boxShadow: 'none',
          },
        },
      }}
      mantineTableBodyProps={{ className: '' }}
      mantineTool
      mantineColumnActionsButtonProps={{ className: 'bg-rose-500' }}
      renderToolbarInternalActions={({ table }) => (
        <div className="flex gap-5 items-center flex-wrap gap-y-2">
          <button
            className="h-8 px-3 border border-indigo-500 text-indigo-500 rounded-[5px] inline-flex gap-2 items-center  whitespace-nowrap text-[14px]"
            onClick={() => {
              getData({
                page: 1,
                loading: true,
                _onlyManualJE: !onlyManualJE,
              });
              setOnlyManualJE(!onlyManualJE);
            }}
            type="button"
          >
            <input
              type="checkbox"
              checked={onlyManualJE}
              className="form-checkbox h-5 w-5"
            />
            Show manual entries only
          </button>
          <SearchForm
            searchVal={searchValue}
            setSearchVal={handleSearch}
            placeholder="Search Journal Entries"
            height="h-10"
          />
          <div className="bg-[#D0D5DD] min-w-px h-10 hidden sm:block" />
          <div className="flex gap-4 ml-auto">
            <MRT_ShowHideColumnsButton
              table={table}
              className="border border-slate-300 h-10 w-10 bg-white"
              sx={{
                height: '10rem',
              }}
            />
            <Tooltip
              content="Reset filters"
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage', 'clientsTableFirmView']}
            >
              <button
                onClick={() => {
                  handleResetFilters();
                  setTableState((prevState) => {
                    const newState = {
                      ...tableInitialState,
                      pagination: prevState?.pagination,
                    };

                    return newState;
                  });
                }}
                className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                type="button"
              >
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                >
                  <path
                    d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                    className="fill-current"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
      enablePagination={!isLoading}
      manualPagination
      mantineBottomToolbarProps={{
        className: 'mb-6 pt-4',
        sx: {
          '& > div:last-child > div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '0.5rem',
          },
          '& .mantine-Select-wrapper input': {
            border: '1px solid #D0D5DD',
            outline: 'none',
            borderRadius: '0.375rem',
          },
          '& .mantine-Select-wrapper input:hover': {
            border: '1px solid #94a3b8',
          },
          '& .mantine-Select-wrapper input:focus': {
            outline: 'none',
            boxShadow: 'none',
          },
        },
      }}
      // renderBottomToolbar={() => null} // Completely hide the footer
    />
  );
};

export default JournalEntriesTable;
