/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ModalBasic from './ModalBasic';
import TextInputDash from './TextInputDash';
import { createAutomation } from '../API/backend_helper';
import { Loader } from './Svg';

const CreateRuleModal = ({
  open,
  setOpen,
  category,
  transaction,
  reloadTransactions = null,
}) => {
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const { description } = values;
    const data = {
      name: `${description} Rule`,
      applyRetroactively: values.applyRetroactively,
      trigger: {
        entity: 'transaction',
        is: 'created',
        position: 0,
      },
      filters: [],
      conditions: [
        {
          key: 'description',
          value: description,
          caseSensitive: false,
          position: 1,
          operator: 'ct',
        },
      ],
      actions: [
        {
          key: 'categoryAccountId',
          value: category?.id,
          position: 2,
        },
      ],
    };
    try {
      await createAutomation(data);
      if (reloadTransactions && values.applyRetroactively) {
        await reloadTransactions({ scrollToTop: false });
      }
      toast.success('Transaction rule created successfully.');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <ModalBasic
      title="Create Automation"
      smallModal
      modalOpen={open}
      setModalOpen={setOpen}
    >
      <Formik
        enableReinitialize
        initialValues={{
          description: transaction || '',
          applyRetroactively: true,
        }}
        validationSchema={Yup.object({
          description: Yup.string().required('Please Enter Name'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="flex justify-between flex-col">
              <div className="px-5 pt-4 pb-1">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium mb-1"
                >
                  If transaction description contains:{' '}
                </label>
                <div className="relative flex">
                  <TextInputDash
                    id="description"
                    name="description"
                    type="text"
                    paddingBottom="pb-4"
                    required
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ''}
                    error={
                      validation.touched.description &&
                      validation.errors.description
                    }
                  />
                </div>
                <div className=" text-slate-600">
                  Automatically categorize as{' '}
                  <span className="text-indigo-500">{category?.name}</span>
                </div>
                <div className="flex items-center gap-3 mt-4">
                  <div className="flex items-center">
                    <input
                      id="applyRetroactively"
                      name="applyRetroactively"
                      type="checkbox"
                      className="h-4 w-4 form-checkbox cursor-pointer"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.applyRetroactively}
                    />
                    <label
                      htmlFor="applyRetroactively"
                      className="ml-2 block text-sm text-gray-900 cursor-pointer"
                    >
                      Apply to all past transactions
                    </label>
                  </div>
                </div>
              </div>
              {/* Modal footer */}
              <div className="flex flex-wrap justify-end space-x-2 px-5 py-4 mt-3 border-t border-[#D0D5DD]">
                <button
                  type="button"
                  className="py-2.5 px-[1.875rem] h-11 w-[7rem] text-center flex justify-center items-center text-base leading-6 font-base rounded-[0.313rem] bg-white text-indigo-500 border border-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="py-2.5 px-[1.875rem] h-11 w-[7rem] bg-indigo-500 text-center text-white text-base leading-6 font-base rounded-[0.313rem] disabled:cursor-not-allowed"
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Loader width="w-6" height="h-6" color="#FFFFFF" />
                    </div>
                  ) : (
                    <span>Create</span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default CreateRuleModal;
