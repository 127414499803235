import { useEffect, useState } from 'react';
import { Loader } from 'components/Svg';
import ModalBasic from '../../components/ModalBasic';

const ReceiptModal = ({
  isOpen,
  setIsOpen,
  receipt,
  isExpenseReporting = false,
  type = '',
  title = '',
  setActiveAttachment = null,
}) => {
  const [pdfIframeLoading, setPdfIframeLoading] = useState(true);

  useEffect(() => {
    if (!isOpen && setActiveAttachment) {
      setActiveAttachment(null);
    }
  }, [isOpen]);
  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={title || ''}
      width="max-w-5xl scrollbar"
    >
      <div className="w-full bg-white py-[1.875rem]">
        <div className="w-full px-10">
          <div className="">
            <div className="w-full border border-gray-300 rounded-xl ">
              {receipt?.endsWith?.('.mp4') ||
              receipt?.endsWith?.('.MP4') ||
              type?.endsWith?.('mp4') ||
              type?.endsWith?.('MP4') ? (
                <video
                  className="w-full object-cover border border-gray-300 rounded-xl"
                  controls
                >
                  <source
                    src={
                      isExpenseReporting
                        ? receipt
                        : receipt?.images?.[0]?.receiptUrl
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                  isExpenseReporting
                    ? !(
                        receipt?.endsWith?.('.pdf') ||
                        receipt?.endsWith?.('.PDF')
                      ) &&
                      !(type
                        ? type?.endsWith?.('pdf') || type?.endsWith?.('PDF')
                        : false)
                    : !(
                        receipt?.images?.[0]?.receiptUrl?.endsWith?.('.pdf') ||
                        receipt?.images?.[0]?.receiptUrl?.endsWith?.('.PDF')
                      )
                ) ? (
                <img
                  src={
                    isExpenseReporting
                      ? receipt
                      : receipt?.images?.[0]?.receiptUrl
                  }
                  alt="Uploaded receipt"
                  className="w-full object-cover border border-gray-300 rounded-xl"
                />
              ) : (
                <>
                  {pdfIframeLoading && (
                    <div className="w-full h-[40rem] flex justify-center items-center">
                      <Loader />
                    </div>
                  )}
                  <iframe
                    src={
                      isExpenseReporting
                        ? receipt
                        : receipt?.images?.[0]?.receiptUrl
                    }
                    title="Receipt PDF"
                    className="w-full h-[40rem] max-h-[80dvh] border border-gray-300 rounded-xl"
                    onLoad={() => setPdfIframeLoading(false)}
                    style={{
                      display: pdfIframeLoading ? 'none' : 'block',
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {!isExpenseReporting && (
            <div className="mt-[1.875rem]">
              <p className="leading-5 text-slate-600 text-sm font-medium mb-2.5">
                Notes
              </p>
              <div className="px-4 py-3.5 rounded-[0.313rem] drop-shadow-sm bg-black bg-opacity-5 border border-gray-300">
                <p className="text-slate-600 leading-6">{receipt?.note}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalBasic>
  );
};

export default ReceiptModal;
