import React from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import Tooltip from '../../components/Tooltip';
import PaginationClassic from '../../components/PaginationClassic';

const ReportersTable = ({
  reporters = {},
  setActiveReporter,
  setExpenseReporterModalOpen,
  setDeleteReporterModalOpen,
  setDeleteTitle,
  setDeleteType,
  setLocalReportersLimit,
  loadExpenseReporters,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const { limit, totalRecords, page } = reporters?.paginateReports || {};

  return (
    <>
      <div className="bg-white flex-1">
        <div className="overflow-x-auto h-full">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="w-full shadow-md rounded-[0.313rem] h-16 text-sm leading-[1.125rem] text-slate-600">
              <tr className="w-full py-[1.438rem] px-[1.375rem]  ">
                <th className="pl-5">
                  <div className="text-left font-medium">Name</div>
                </th>
                <th className="">
                  <div className="text-left font-medium">Email</div>
                </th>
                <th className="w-[400px]">
                  <div className="text-left font-medium">Account(s)</div>
                </th>

                {checkPermission(
                  PermissionKeys.MANAGE_EXPENSE_CLAIMS,
                  accessType,
                  permissions,
                ) && (
                  <th className="text-center pr-5">
                    <div className="mx-auto w-[9rem] font-medium">Actions</div>
                  </th>
                )}
              </tr>
            </thead>
            {/* Table body */}
            {reporters?.expenseReporters?.length > 0 && (
              <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                {reporters.expenseReporters.map((reporter) => (
                  <tr
                    key={reporter?.id}
                    className={`w-full pl-[1.375rem] pr-[1.125rem] min-h-20 hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer `}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <td className="whitespace-nowrap py-5 pl-5">
                      <div className="flex items-center">
                        <div className="text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                          {reporter?.firstName} {reporter?.lastName}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-5">
                      <div className=" text-left">{reporter?.email}</div>
                    </td>
                    <td className="max-w-[400px] w-[400px] py-5">
                      <div className=" text-left">
                        {reporter?.accounts?.length > 0
                          ? reporter?.accounts?.map?.((acc) => (
                              <div key={acc?.id}>{acc?.name}</div>
                            ))
                          : '--'}
                      </div>
                    </td>

                    {checkPermission(
                      PermissionKeys.MANAGE_EXPENSE_CLAIMS,
                      accessType,
                      permissions,
                    ) && (
                      <td className="whitespace-nowrap py-5 text-center pr-5">
                        <div className="mx-auto w-[9rem] inline-flex justify-center items-center gap-3.5">
                          <Tooltip
                            content="Edit Reporter"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          >
                            <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                              {/* Edit SVG  */}
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveReporter(reporter);
                                  setExpenseReporterModalOpen(true);
                                }}
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                  stroke="#858D9D"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </Tooltip>
                          <Tooltip
                            content="Delete Reporter"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          >
                            <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                              {/* Delete SVG  */}
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveReporter(reporter);
                                  setDeleteTitle('Delete Expense Reporter');
                                  setDeleteType(' expense reporter ');
                                  setDeleteReporterModalOpen(true);
                                }}
                              >
                                <path
                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                  fill="white"
                                  stroke="#D0D5DD"
                                  strokeOpacity="0.6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                  stroke="#858D9D"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.693 16.3574H14.8505"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.1552 13.7656H15.3948"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {!(reporters?.expenseReporters?.length > 0) && (
            <div className="flex flex-col gap-5 items-center justify-center w-full">
              <h6 className="text-center mt-6">No Reporters Found</h6>
              {/* <img
                src={emptySearchImg}
                alt="No reporters found"
                className="h-[12.5rem]"
              /> */}
            </div>
          )}
        </div>
      </div>
      <div className="mt-10">
        <PaginationClassic
          setLimit={setLocalReportersLimit}
          onPageChange={loadExpenseReporters}
          pagination={{ limit, page, totalRecords }}
        />
      </div>
    </>
  );
};

export default ReportersTable;
