import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { updatePeople } from 'API/backend_helper';
import ModalBasic from '../../../components/ModalBasic';
import TextInputDash from '../../../components/TextInputDash';

const PersonEmailUpdateForm = ({
  isOpen,
  setIsOpen,
  activePeople,
  loadData,
  handleCb,
}) => {
  const formikRef = useRef(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      formikRef?.current?.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await updatePeople(values, activePeople?.id);
      await loadData();
      handleCb(values?.email);
      setIsOpen(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Person Details"
      dialogClassName="!overflow-visible"
    >
      <Formik
        enableReinitialize
        initialValues={{
          email: activePeople?.email || '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              'Please enter valid email',
            )
            .required('Please Enter Email')
            .max(100, 'Email must be at most 100 characters'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className="flex flex-col  justify-between overflow-visible ">
            <div className="px-7 py-5">
              <TextInputDash
                label="Enter Person's Email Address"
                id="email"
                name="email"
                required
                placeholder="Email Address"
                value={validation.values.email || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                paddingBottom="pb-5"
                error={validation.touched.email && validation.errors.email}
                disabled={activePeople?.provider}
              />
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap gap-4  justify-end px-5 py-4 border-t">
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6 disabled:cursor-not-allowed"
                onClick={() => setIsOpen(false)}
                type="button"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="h-11 w-20 btn !bg-indigo-500 text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:!bg-indigo-300    disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading ? (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default PersonEmailUpdateForm;
