import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSubscriptions,
  getManageStripeCustomerPortal,
  getTeamStats,
  getTeamSubscriptionHistory,
  getTeamSubscriptions,
} from '../../../API/backend_helper';
import AlertBox from '../../../components/AlertBox';
import SubscriptionPlan from '../../createworkspace/SubscriptionPlan/SubscriptionPlan';
import {
  getCurrencySymbol,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
  TEAM_ID,
} from '../../../utils/Utils';
import { setSubscription } from '../../../redux/Subscription/reducer';

const Billing = () => {
  const dispatch = useDispatch();

  const { subscription } = useSelector((state) => state.Subscription);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);
  const teamId = localStorage.getItem(TEAM_ID);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [billingHistory, setBillingHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState();
  const [stat, setStat] = useState(null);
  const [managePaymentMethod, setManagePaymentMethod] = useState('');

  const loadStats = async () => {
    try {
      const res = await getTeamStats(teamId);
      setStat(res?.data?.stat);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadSubscriptions = async (loading = false) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const resBillingHistory = await getTeamSubscriptionHistory();
      const res = await fetchSubscriptions();
      setSubscriptions(res?.data?.plans);
      if (loading) {
        setIsLoading(false);
      }
      setBillingHistory(resBillingHistory?.data?.billingHistories);
    } catch (err) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('err', err);
    }
  };

  const loadActiveSubscriptions = async () => {
    try {
      const res = await getTeamSubscriptions(teamId);
      dispatch(setSubscription(res?.data?.subscription));
    } catch (err) {
      console.log('err', err);
    }
  };

  const getManagePaymentLink = async () => {
    try {
      const res = await getManageStripeCustomerPortal(teamId);
      setManagePaymentMethod(res?.data?.session?.url);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadActiveSubscriptions();
    loadStats();
    loadSubscriptions(true);
    getManagePaymentLink();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'due':
        return 'text-[#E48642] bg-[#E486421A]';
      case 'unpaid':
        return 'text-[#FF4B4B] bg-[#FF4B4B1A]';
      case 'paid':
        return 'text-[#40AA00] bg-[#40AA001A]';
      default:
        return 'text-slate-500 bg-slate-100';
    }
  };

  function daysDifferenceFromMilliseconds(milliseconds) {
    // Convert milliseconds to a Moment.js date
    const dateFromMilliseconds = moment(milliseconds * 1000);
    // Get today's date
    const today = moment();

    // Calculate the difference in days
    const daysDifference = dateFromMilliseconds.diff(today, 'days');

    return daysDifference + 1;
  }

  if (isLoading) {
    return (
      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animate-spin w-10 h-10 fill-current shrink-0"
        >
          <path
            d="M14.5 2.41699V7.25033"
            stroke="#E48642"
            stroke-width="2.55882"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.5 21.749V26.5824"
            stroke="#E48642"
            stroke-width="2.55882"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.95703 5.95703L9.37662 9.37662"
            stroke="#E48642"
            stroke-width="1.58955"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.623 19.6211L23.0426 23.0407"
            stroke="#E48642"
            stroke-width="1.58955"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.41699 14.5H7.25033"
            stroke="#E48642"
            stroke-width="1.58955"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.749 14.5H26.5824"
            stroke="#E48642"
            stroke-width="1.58955"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.95703 23.0407L9.37662 19.6211"
            stroke="#E48642"
            stroke-width="1.58955"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.623 9.37662L23.0426 5.95703"
            stroke="#E48642"
            stroke-width="1.58955"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
          Fetching data, please wait...
        </p>
      </div>
    );
  }

  return (
    <div>
      {stat && (
        <>
          <h3 className="text-[22px] font-medium text-[#00000099] mb-8">
            Monthly Usage
          </h3>
          <div className="grid md:grid-cols-4 space-y-4 md:space-y-0 divide-y md:divide-y-0 md:divide-x divide-[#D0D5DD] border border-[#D0D5DD] rounded-[12px] p-4 mb-3">
            <div className="md:pr-[10%]">
              <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
                Revenue (3 month avg.)
              </h3>
              <h2 className="text-[22px] font-medium text-[#101828] text-right w-full">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {parseFloat(stat?.income)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h2>
            </div>
            <div className=" pt-4 md:pt-0 md:px-[10%]">
              <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
                Expenses (3 month avg.)
              </h3>
              <h2 className="text-[22px] font-medium text-[#101828] w-full text-right">
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {parseFloat(stat?.expense)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h2>
            </div>
            <div className="pt-4 md:pt-0 md:px-[10%]">
              <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
                Connected Institutions
              </h3>
              <h2 className="text-[22px] font-medium text-[#101828] w-full text-right">
                {stat?.connectionCount}
              </h2>
            </div>
            <div className="pt-4 md:pt-0 md:pl-[10%]">
              <h3 className="text-[18px] font-medium text-[#666666] leading-tight mb-6">
                Employee Seats
              </h3>
              <h2 className="text-[22px] font-medium text-[#101828] w-full text-right">
                {subscription?.employeeSeat || 0}
              </h2>
            </div>
          </div>
        </>
      )}
      <div className="flex items-end gap-4">
        <h3 className="text-2xl  text-[#101828B2] font-medium mb-0">Billing</h3>
        {(team?.hasPaymentMethod || subscription?.default_payment_method) &&
          managePaymentMethod && (
            <a
              href={managePaymentMethod || '#'}
              target="_blank"
              rel="noreferrer"
              className="text-base cursor-pointer leading-[24px] inline-flex text-indigo-500 underline font-medium"
            >
              Manage Subscription
            </a>
          )}
      </div>
      {team?.subscriptionAlerts?.[team?.subscriptionAlerts?.length - 1]
        ?.message && (
        <div className="my-5">
          <AlertBox
            type="error"
            heading="Payment Failed"
            text={
              team?.subscriptionAlerts?.[team?.subscriptionAlerts?.length - 1]
                ?.message
            }
            hideActionButton
          />
        </div>
      )}
      {team.isOnComplimentary ? (
        <AlertBox
          type="info"
          heading="Complimentary Growing Plan"
          text="You are currently subscribed to the Complimentary Growing Plan."
          hideActionButton
        />
      ) : (
        subscription?.plan?.amount !== 0 &&
        subscription?.status === 'trialing' && (
          <AlertBox
            type="info"
            heading="Trial"
            text={`${daysDifferenceFromMilliseconds(subscription?.trial_end)} days until your trial expires. Following that, you will transition to
              the monthly plan and payment will be charged.`}
            hideActionButton
          />
        )
      )}

      {!team.isOnComplimentary && (
        <SubscriptionPlan
          subscriptions={subscriptions}
          selectedSubscription={subscription?.plan?.id}
          subscription={subscription}
          isBillingPage
          hasUsedTrial={team?.hasUsedTrial}
          // setSelectedSubscription={setSelectedSubscription}
        />
      )}

      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[20px] font-medium text-[#667085]">
          Billing History
        </h3>
      </div>
      {!isLoading ? (
        <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm   overflow-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Invoice</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Amount</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Date</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className=" text-left font-medium">Status</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                  <div className="text-left font-medium" />
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
              {/* Row */}
              {billingHistory?.length > 0 &&
                billingHistory.map((subscription) => (
                  <tr
                    className="border-b border-slate-300"
                    key={subscription?.id}
                  >
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] leading-tight mr-1">
                        <span className="capitalize">
                          {
                            subscription?.lines?.data?.[
                              subscription?.lines?.data?.length - 1
                            ]?.description
                          }
                        </span>
                        <div className="pt-1 text-xs text-slate-700">
                          {moment(
                            subscription?.lines?.data?.[0]?.period?.start *
                              1000,
                          )?.format('MMM DD, YYYY')}{' '}
                          -{' '}
                          {moment(
                            subscription?.lines?.data?.[0]?.period?.end * 1000,
                          )?.format('MMM DD, YYYY')}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-sm text-[#667085] leading-tight mr-1">
                        {subscription?.invoice_pdf ? (
                          <a
                            href={subscription?.invoice_pdf}
                            download
                            className="underline text-indigo-500 hover:text-indigo-600"
                          >
                            {`${getCurrencySymbol(subscription?.currency)}${
                              parseFloat(
                                subscription?.amount_paid / 100,
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || '0'
                            }`}
                          </a>
                        ) : (
                          <span className="cursor-default">
                            {`${getCurrencySymbol(subscription?.currency)}${
                              parseFloat(
                                subscription?.amount_paid / 100,
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || '0'
                            }`}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div className="text-sm text-[#667085] capitalize">
                        {moment(subscription?.created * 1000)?.format(
                          'MMM DD, YYYY',
                        )}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3">
                      <div
                        className={` w-fit p-2.5 py-2 rounded-[5px] text-sm leading-tight  capitalize font-medium ${getStatusColor(
                          subscription?.status,
                        )}`}
                      >
                        {subscription?.status?.replace('_', ' ')}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 text-right">
                      {/*
                      <button
                        className="disabled:cursor-not-allowed disabled:opacity-50"
                        disabled={+subscription?.amountPaid === 0}
                      >
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66602 13.1667L9.99935 16.5M9.99935 16.5L13.3327 13.1667M9.99935 16.5V9M16.666 12.9524C17.6839 12.1117 18.3327 10.8399 18.3327 9.41667C18.3327 6.88536 16.2807 4.83333 13.7493 4.83333C13.5673 4.83333 13.3969 4.73833 13.3044 4.58145C12.2177 2.73736 10.2114 1.5 7.91602 1.5C4.46424 1.5 1.66602 4.29822 1.66602 7.75C1.66602 9.47175 2.36222 11.0309 3.48847 12.1613"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>*/}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {billingHistory?.length === 0 && (
            <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
              No Data Found
            </h6>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              stroke-width="2.55882"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              stroke-width="2.55882"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              stroke-width="1.58955"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      )}
    </div>
  );
};

export default Billing;
