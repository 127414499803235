import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import ModalBasic from '../../components/ModalBasic';
import { getBillById } from '../../API/backend_helper';

import {
  formatNumberGlobally,
  getCurrencySymbolFromIso,
  getStringSegments,
  trimText,
} from '../../utils/Utils';

import Tooltip from '../../components/Tooltip';
import TagsBoard from '../invoices/TagsBoard';

const BillDetails = ({
  currencies,
  isOpen,
  setIsOpen,
  projects,
  setActiveReceipt,
  setReceiptModalOpen,
  onEditBillDetailsModal,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeBill, setActiveBill] = useState({});

  const loadActiveBill = async () => {
    setLoading(true);
    try {
      const res = await getBillById(isOpen);
      setActiveBill(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadActiveBill();
    }
  }, [isOpen]);

  const calcSubTotal = () => {
    let sum = 0;
    activeBill?.lineItems?.forEach((d) => {
      sum +=
        parseFloat(d.price || 0)?.toFixed(2) *
        parseFloat(d.quantity || 0)?.toFixed(2);
    });
    return parseFloat(sum || 0)?.toFixed(2);
  };

  const currencySymbol = useMemo(() => {
    if (currencies?.length > 0 && activeBill?.currency)
      return getCurrencySymbolFromIso(activeBill?.currency, currencies);
  }, [currencies, activeBill]);

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="View Bill"
      width="max-w-[949px] flex flex-col relative"
    >
      {checkPermission(PermissionKeys.MANAGE_BILLS, accessType, permissions) &&
        !loading &&
        activeBill?.instances?.[0]?.status !== 'paid' && (
          <button
            type="button"
            className="bg-indigo-500 text-white h-[38px] px-4 rounded-[5px] w-fit absolute top-[22px] left-40 "
            onClick={() => {
              onEditBillDetailsModal(activeBill?.id);
              setIsOpen(false);
            }}
          >
            Edit Bill
          </button>
        )}
      {loading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 mb-5">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="px-7 grid grid-cols-3 gap-y-5  my-7 justify-center">
            <div>
              <div className="text-[#667085] font-medium text-sm">
                Vendor Name <span className="text-[#FF4B4B]">*</span>
              </div>
              <div className="flex text-[#667085] text-base mt-1">
                <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                  {activeBill?.vendor?.logoUrl ? (
                    <img
                      className="rounded-full"
                      src={activeBill?.vendor?.logoUrl}
                      alt={activeBill?.vendor?.name}
                    />
                  ) : (
                    <div className="w-[1.687rem] h-[1.687rem] rounded-[5.125rem] flex items-center justify-center bg-slate-400 dark:bg-slate-700">
                      <div className="text-xs font-normal uppercase text-white">
                        {activeBill?.vendor?.name
                          ? activeBill?.vendor.name
                              .replace(/[^\w\s]/gi, '')
                              .split(' ')
                              .filter(Boolean)
                              .slice(0, 2)
                              .map((word) => word.charAt(0))
                              .join('')
                          : ''}
                      </div>
                    </div>
                  )}
                </div>
                <div className="font-normal text-slate-600 leading-6 mr-2">
                  {activeBill?.vendor?.name?.length > 19 ? (
                    <Tooltip
                      content={activeBill?.vendor?.name}
                      contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                    >
                      <span>
                        {`${activeBill?.vendor?.name?.slice(0, 19)}...`}
                      </span>
                    </Tooltip>
                  ) : (
                    <span>{activeBill?.vendor?.name}</span>
                  )}
                </div>
              </div>
            </div>
            {/* <div>
            <div className="text-[#667085] font-medium text-sm">Recurring</div>
            <div className="text-[#667085] text-base mt-1">
              {activeBill?.recurring ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-5 w-5 mx-auto fill-[#78bd4f]"
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>
              ) : (
                "-"
              )}
            </div>
          </div> */}
            <div>
              <div className="text-[#667085] font-medium text-sm">
                Bill Number
              </div>
              <div className="text-[#667085] text-base mt-1">
                {activeBill?.billNumber || '--'}
              </div>
            </div>
            <div>
              <div className="text-[#667085] font-medium text-sm">Currency</div>
              <div className="text-[#667085] text-base mt-1">
                {activeBill?.currency}
              </div>
            </div>
            <div>
              <div className="text-[#667085] font-medium text-sm">
                P.O/S.O. Number
              </div>
              <div className="text-[#667085] text-base mt-1">
                {activeBill?.purchaseOrderNumber || '--'}
              </div>
            </div>
            <div>
              <div className="text-[#667085] font-medium text-sm">
                Bill Date
              </div>
              <div className="text-[#667085] text-base mt-1">
                {moment(activeBill?.firstBillDate).format('MMM DD, YYYY')}
              </div>
            </div>
            <div>
              <div className="text-[#667085] font-medium text-sm">
                Bill Due Date
              </div>
              <div className="text-[#667085] text-base mt-1">
                {moment(
                  activeBill?.dueDate
                    ? activeBill?.dueDate
                    : activeBill?.instances?.[0]?.dueDate,
                ).format('MMM DD, YYYY')}
              </div>
            </div>
            {activeBill?.receiptUrl && (
              <div>
                <div className="text-[#667085] font-medium text-sm">
                  Uploaded Bill
                </div>
                <div className=" w-fit mt-1">
                  <div
                    className="cursor-pointer text-[#667085] text-base mt-1 hover:underline"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveReceipt(activeBill?.receiptUrl);
                      setReceiptModalOpen(true);
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    View Uploaded Bill
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bg-transparent mx-7 rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] flex-1 ">
            {/* Table */}
            <div className="overflow-x-auto h-full w-full">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-sm font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">Description</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-left">
                        Expense Category
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-center">Qty</div>
                    </th>

                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell ">
                      <div className="font-medium text-left">
                        Price (per unit)
                      </div>
                    </th>
                    {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell ">
                      <div className="font-medium text-left">
                        Tax (per unit)
                      </div>
                    </th> */}
                    <th className="pr-[34px]  pl-2 first:pl-5 py-3 whitespace-nowrap table-cell ">
                      <div className="font-medium text-right">Amount</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm divide-y divide-[#D0D5DD] ">
                  {activeBill?.lineItems?.map((b, i) => (
                    <>
                      <tr key={i} className="">
                        <td className="w-full px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                          {b?.description}
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                          <div className=" w-[220px]">
                            {b?.categoryAccount?.name}
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                          {b?.quantity}
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left relative">
                          {currencySymbol}
                          {formatNumberGlobally(parseFloat(b?.price))}
                        </td>
                        {/* <td className="px-2 first:pl-5 relative last:pr-5 py-2.5 text-left">
                        {currencySymbol}{" "}
                        {parseFloat(b?.tax || 0)?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td> */}
                        <td className="pr-5 pl-2 text-[#667085] first:pl-5 last:pr-5 py-2.5 text-right">
                          <div className="text-right">
                            <div className="min-w-[4rem] w-fit ml-auto text-left">
                              {/* {currency?.symbol} */}
                              {currencySymbol}
                              {formatNumberGlobally(
                                parseFloat(
                                  (parseFloat(
                                    b.tax === 'NaN' ? 0 : b.tax || 0,
                                  ) +
                                    parseFloat(b.price || 0)) *
                                    +b.quantity || 0,
                                )?.toFixed(2),
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {(b?.customer?.customer ||
                        b?.project?.name ||
                        b?.tags?.length > 0) && (
                        <tr className="!border-t-0">
                          <td
                            colSpan={6}
                            className="px-5 pb-3 whitespace-nowrap"
                          >
                            <div className="flex items-center gap-2">
                              {b?.customer?.customer && (
                                <Tooltip
                                  className="cursor-default"
                                  tooltipShow={
                                    b?.customer?.customer?.length > 30
                                  }
                                  content={(() => {
                                    const segments = getStringSegments(
                                      b?.customer?.customer,
                                      50,
                                    );
                                    return segments.map((segment, index) => (
                                      <p key={index}>{segment}</p>
                                    ));
                                  })()}
                                  contentClassName=" rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown text-center"
                                  position="top"
                                >
                                  <p>
                                    <span className="font-medium">
                                      Customer:
                                    </span>{' '}
                                    <span
                                      className="cursor-pointer hover:underline hover:text-indigo-500"
                                      role="button"
                                      tabIndex="0"
                                      onClick={() =>
                                        navigate(`/customer/${b?.customer?.id}`)
                                      }
                                    >
                                      {trimText(b?.customer?.customer, 30)}
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                              {b?.customer?.customer && b?.project?.name && (
                                <span className="text-black text-sm">|</span>
                              )}
                              {b?.project?.name && (
                                <Tooltip
                                  className="cursor-default"
                                  tooltipShow={b?.project?.name?.length > 30}
                                  content={(() => {
                                    const segments = getStringSegments(
                                      b?.project?.name,
                                      50,
                                    );
                                    return segments.map((segment, index) => (
                                      <p key={index}>{segment}</p>
                                    ));
                                  })()}
                                  contentClassName=" rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown text-center"
                                  position="top"
                                >
                                  <p>
                                    {' '}
                                    <span className="font-medium">
                                      Project:
                                    </span>{' '}
                                    <span
                                      className="cursor-pointer hover:underline hover:text-indigo-500"
                                      role="button"
                                      tabIndex="0"
                                      onClick={() =>
                                        navigate(
                                          `/projects/${b?.project?.id}?tab=overview`,
                                        )
                                      }
                                    >
                                      {trimText(b?.project?.name, 30)}
                                    </span>
                                  </p>
                                </Tooltip>
                              )}
                              {(b?.customer?.customer || b?.project?.name) &&
                                b?.tags?.length > 0 && (
                                  <span className="text-black text-sm">|</span>
                                )}

                              {b?.tags?.length > 0 && (
                                <Tooltip
                                  className="cursor-default"
                                  tooltipShow={
                                    b?.tags?.[0]?.name?.length > 30 ||
                                    b?.tags?.length > 1
                                  }
                                  content={b?.tags?.map((t, i) => {
                                    if (t?.name?.length > 13) {
                                      const segments = getStringSegments(
                                        t?.name,
                                        13,
                                      );
                                      return segments.map((segment, index) => (
                                        <p
                                          key={index}
                                          className="text-sm text-center leading-tight"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {segment}{' '}
                                          {index === segments?.length - 1 &&
                                            b?.tags?.length - 1 !== i &&
                                            ','}
                                        </p>
                                      ));
                                    }
                                    return (
                                      <p
                                        key={t?.id}
                                        className="text-sm text-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {t?.name}
                                        {b?.tags?.length - 1 !== i && ','}
                                      </p>
                                    );
                                  })}
                                  contentClassName=" rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                                  position="bottom"
                                >
                                  <p>
                                    <span className="font-medium">Tags:</span>{' '}
                                    {trimText(b?.tags?.[0]?.name, 30)}{' '}
                                    {b?.tags?.length > 1 &&
                                      `+${b?.tags?.length - 1}`}
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  <tr className="">
                    <td
                      colSpan={6}
                      className="px-2 first:pl-5 last:pr-5 py-4 text-left mt-5"
                    >
                      <div className="flex justify-between gap-6">
                        <div className="w-2/3">
                          <div className="text-[#667085] font-medium text-sm">
                            Memo
                          </div>
                          <div className="text-[#667085] text-base mt-1">
                            {activeBill?.notes || '--'}
                          </div>
                        </div>
                        <div className="flex gap-6 justify-end">
                          <div className="flex flex-col gap-2 text-[#667085]">
                            <span>Subtotal:</span>
                            <span>Tax:</span>
                            <span>Discount:</span>
                            <span className="text-nowrap text-[#101828]">
                              Total ({activeBill?.currency}):
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 text-[#667085] font-medium">
                            <span className="min-w-[4rem]">
                              {currencySymbol}
                              {formatNumberGlobally(calcSubTotal())}
                            </span>
                            <span className="min-w-[4rem]">
                              {currencySymbol}
                              {formatNumberGlobally(
                                parseFloat(activeBill?.tax)?.toFixed(2),
                              )}
                            </span>
                            <span className="min-w-[4rem]">
                              {currencySymbol}
                              {formatNumberGlobally(
                                parseFloat(activeBill?.discount)?.toFixed(2),
                              )}
                            </span>
                            <span className="min-w-[4rem] text-[#101828] font-medium">
                              {currencySymbol}

                              {formatNumberGlobally(
                                parseFloat(activeBill?.total)?.toFixed(2),
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* {billRows?.length > 0 && ( */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </ModalBasic>
  );
};

export default BillDetails;
