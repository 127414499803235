import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToast = ({ category, openModal }) => (
  <div className="flex items-center gap-1">
    <p className="text-white leading-5 text-[16px] font-medium w-[105%]">
      Category changed to {category?.name}
    </p>
    <button
      type="button"
      className="bg-white text-[#78BD4F] flex items-center justify-center h-[32px] w-[100px] rounded-[5px] gap-2 py-2.5 px-[1.875rem] whitespace-nowrap"
      onClick={openModal}
    >
      <span className="leading-5 text-[16px]">+ Add Rule</span>
    </button>
  </div>
);

const CreateRulePopup = ({ open, setOpen, category, openModal }) => {
  const [currentToastId, setCurrentToastId] = useState(null);

  useEffect(() => {
    if (open && category) {
      // Dismiss the previous toast immediately when a new one comes
      if (currentToastId) {
        toast.dismiss(currentToastId);
      }

      // Create a new toast and store its ID
      const id = toast(
        <CustomToast
          category={category}
          openModal={() => {
            openModal(true);
            toast.dismiss(id);
            setOpen(false);
          }}
        />,
        {
          position: 'top-right',
          autoClose: 15000,
          onClose: () => setOpen(false),
          type: 'success',
          className: 'categoryToast',
        },
      );

      // Store the new toast ID to control its dismissal later
      setCurrentToastId(id);

      // Dismiss the toast after the autoClose duration
      setTimeout(() => {
        toast.dismiss(id);
      }, 15000); // Adjust time as needed for the toast duration

      // return () => {
      //   // Cleanup: Dismiss the toast if the component unmounts or `open` changes
      //   toast.dismiss(id);
      // };
    }
  }, [open, category]);

  return null;
};

export default CreateRulePopup;
