/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MRT_ShowHideColumnsButton } from 'mantine-react-table';
import { deletePendingReceiptById } from 'API/backend_helper';
import ReceiptModal from 'pages/transactions/ReceiptModal';
import moment from 'moment';
import { formatNumber, getCurrencySymbolFromIso } from 'utils/Utils';
import ReusableTable from 'components/ReusableTable';

import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import SearchForm from 'partials/actions/SearchForm';
import { toast } from 'react-toastify';

let searchTimeout = null;
const tableInitialState = {
  columnSizing: {
    name: 300,
    username: 150,
    password: 150,
    link: 100,
    actions: 100,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  columnOrder: [],
  showGlobalFilter: false,
};
const EncryptedTable = ({
  encryptions,
  deleteEncryptions,
  deleteEncryptionLoader,
  isLoading,
  getEncryptions,

  //   setSelectedRow,
  //   setRowDetailsOpen,
  setAddEditEncryptionModal,
  setActiveEncrypt,
  tableSortValues,
  setTableSortValues,
}) => {
  const [tableState, setTableState] = useState({
    ...tableInitialState,
    isLoading: true,
    sorting: [
      { id: tableSortValues?.sortBy, desc: tableSortValues?.order === 'desc' },
    ],
  });
  const [searchValue, setSearchValue] = useState('');

  const [recordsDeleteLoading, setRecordsDeleteLoading] = useState({});
  const [showPasswords, setShowPasswords] = useState([]);

  const fetchEncryptions = ({
    page = encryptions?.page || 1,
    limit = encryptions?.pageSize,
    search = searchValue,
    sortBy = tableState?.sorting?.[0]?.id || '',
    order = tableState?.sorting?.[0]?.id
      ? tableState?.sorting?.[0]?.desc
        ? 'desc'
        : 'asc'
      : '',
  }) => {
    getEncryptions({
      page: parseInt(page),
      limit,
      loading: true,
      search,
      order,
      sortBy,
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getEncryptions({ search: formattedValue, page: 1 });
    }, 900);
  };

  const copyToClipboard = (password) => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        toast.success('Copied');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <div className="flex items-center gap-x-2">
            {row?.original?.linkThumbnail ? (
              <img
                src={row?.original?.linkThumbnail}
                alt="Receipt"
                className="w-8 h-8 object-contain"
                onClick={() => {
                  // setAddEditEncryptionModal(row?.original?.receiptUrl);
                }}
              />
            ) : (
              <div className="text-[#9A9A9A]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-8 w-8"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  <path d="M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
                  <path d="M10 11v-2a2 2 0 1 1 4 0v2" />
                </svg>
              </div>
            )}
            {row?.original?.name}
          </div>
        ),
      },
      {
        accessorKey: 'username',
        id: 'username',
        header: 'Username',
        Cell: ({ row }) => (
          <div className="flex items-center gap-2">
            <div>{row?.original?.username}</div>
            {row?.original?.username?.length > 0 && (
              <div
                className={`w-[14px] h-[14px] inline-flex justify-center items-center `}
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(row?.original?.username);
                }}
                role="button"
                tabIndex="0"
              >
                <svg
                  viewBox="0 0 48 48"
                  height="18px"
                  width="18px"
                  className={`max-h-4  max-w-4 text-[#667085] fill-[#667085] `}
                >
                  <path fill="none" d="M0 0h48v48H0z" />
                  <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" />
                </svg>
              </div>
            )}
          </div>
        ),
      },

      {
        id: 'password',
        // accessorKey: 'password',
        header: 'Password',
        enableSorting: false,
        Cell: ({ row }) => (
          <div className="flex items-center gap-2">
            <div>
              {' '}
              {row?.original?.password?.length > 0
                ? showPasswords?.includes(row?.original?.id)
                  ? row?.original?.password
                  : '*'.repeat(row?.original?.password?.length)
                : ''}
            </div>
            <div className="relative">
              {row?.original?.password?.length > 0 ? (
                !showPasswords?.includes(row?.original?.id) ? (
                  <svg
                    className="fill-[#9A9A9A] h-[14px]  cursor-pointer mt-2.5"
                    style={{ transform: 'translate(0, -50%)' }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    onClick={() => {
                      setShowPasswords((prev) => [...prev, row?.original?.id]);
                    }}
                  >
                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" />
                  </svg>
                ) : (
                  <svg
                    className="fill-[#9A9A9A] h-[14px] cursor-pointer mt-2.5"
                    style={{ transform: 'translate(0, -50%)' }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    onClick={() => {
                      setShowPasswords(
                        showPasswords?.filter((id) => id !== row?.original?.id),
                      );
                    }}
                  >
                    <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                  </svg>
                )
              ) : null}
            </div>
            {row?.original?.password?.length > 0 && (
              <div
                className={`w-[14px] h-[14px] -mt-1 inline-flex justify-center items-center `}
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(row?.original?.password);
                }}
                role="button"
                tabIndex="0"
              >
                <svg
                  viewBox="0 0 48 48"
                  height="18px"
                  width="18px"
                  className={`max-h-4  max-w-4 text-[#667085] fill-[#667085] `}
                >
                  <path fill="none" d="M0 0h48v48H0z" />
                  <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" />
                </svg>
              </div>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'link',
        id: 'link',
        Cell: ({ row }) =>
          row.original?.link ? (
            <a
              href={
                /^https?:\/\//.test(row.original?.link)
                  ? row.original?.link
                  : `https://${row.original?.link}`
              }
              target="_blank"
              rel="noreferrer"
              className="text-indigo-500 underline"
            >
              View Link
            </a>
          ) : (
            '--'
          ),
        header: 'Link',
        enableSorting: false,
      },
      {
        id: 'actions',
        accessorKey: 'actions',
        header: 'Manage',
        enableSorting: false,
        meta: { hidden: false },

        Cell: ({ row }) => (
          <div className="flex gap-3 items-center">
            {/* Edit Button  */}
            <button
              onClick={() => {
                setAddEditEncryptionModal(row?.original);
                setActiveEncrypt(row?.original);
              }}
              className=" disabled:cursor-not-allowed h-7 w-7 border border-[#D0D5DD] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
              type="button"
            >
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.98682 1.16699H5.82015C2.90348 1.16699 1.73682 2.33366 1.73682 5.25033V8.75033C1.73682 11.667 2.90348 12.8337 5.82015 12.8337H9.32015C12.2368 12.8337 13.4035 11.667 13.4035 8.75033V7.58366"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.92691 1.76206L5.33024 6.35872C5.15524 6.53372 4.98024 6.87789 4.94524 7.12872L4.69441 8.88456C4.60108 9.52039 5.05024 9.96372 5.68608 9.87622L7.44191 9.62539C7.68691 9.59039 8.03107 9.41539 8.21191 9.24039L12.8086 4.64372C13.6019 3.85039 13.9752 2.92872 12.8086 1.76206C11.6419 0.595392 10.7202 0.968725 9.92691 1.76206Z"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.26807 2.4209C9.6589 3.81507 10.7497 4.9059 12.1497 5.30257"
                  stroke="#858D9D"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            {/* Delete Button  */}
            <button
              className="w-7 h-7 cursor-pointer disabled:border-[1.2px] disabled:bg-white inline-flex items-center justify-center disabled:border-rose-500 disabled:rounded-[8px] disabled:cursor-not-allowed"
              onClick={async (e) => {
                e.stopPropagation();
                setRecordsDeleteLoading((prev) => ({
                  ...prev,
                  [row?.original?.id]: true,
                }));
                await deleteEncryptions(row?.original?.id);
                setRecordsDeleteLoading((prev) => ({
                  ...prev,
                  [row?.original?.id]: true,
                }));
              }}
              type="button"
              disabled={recordsDeleteLoading?.[row?.original?.id]}
            >
              {recordsDeleteLoading?.[row?.original?.id] ? (
                <Loader height="h-4 w-4" color="#f43f5e" />
              ) : (
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-full w-full"
                >
                  <path
                    d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                    fill="white"
                    stroke="#f43f5e"
                    strokeOpacity="0.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                    stroke="#f43f5e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.693 16.3574H14.8505"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.1552 13.7656H15.3948"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </div>
        ),
      },
    ],
    [encryptions, deleteEncryptionLoader, showPasswords],
  );

  return (
    <ReusableTable
      columns={columns}
      data={encryptions?.records || []}
      rowCount={encryptions?.totalRecords || 0}
      pageCount={-1}
      onPaginationChange={(updater) => {
        const pagination =
          typeof updater === 'function' ? updater({}) : updater;

        if (!pagination || typeof pagination !== 'object') {
          return;
        }
        const { pageIndex, pageSize } = pagination;
        fetchEncryptions({
          page: pageIndex >= 0 ? pageIndex + 1 : parseInt(encryptions?.page),
          limit: pageSize ?? parseInt(encryptions?.limit),
        });
      }}
      onSortingChange={(sorting) => {
        const sort = sorting();
        if (
          sort?.[0]?.id === tableSortValues?.sortBy &&
          tableSortValues?.order === (sort?.[0]?.desc ? 'desc' : 'asc')
        ) {
          return;
        }
        let finalSort = [];
        if (sort?.length > 0) {
          const currentSortId = sort[0]?.id;
          // Check if the same column is being sorted again
          if (tableState?.sorting?.[0]?.id === currentSortId) {
            if (tableState?.sorting?.[0]?.desc === false) {
              // If currently sorted as 'asc', toggle to 'desc'
              finalSort = [{ id: currentSortId, desc: true }];
            } else {
              // If currently sorted as 'desc', remove sorting (no sorting)
              finalSort = [];
            }
          } else {
            // If a different column is being sorted, apply 'asc' sorting
            finalSort = [{ id: currentSortId, desc: false }];
          }
        }
        // Avoid unnecessary fetch if no change in sorting state
        if (
          tableState?.sorting?.length === finalSort?.length &&
          tableState?.sorting?.[0]?.id === finalSort?.[0]?.id &&
          tableState?.sorting?.[0]?.desc === finalSort?.[0]?.desc
        ) {
          return;
        }
        // Update the table state with the new sorting
        setTableState((prev) => ({
          ...prev,
          sorting: finalSort,
        }));
        setTableSortValues({
          sortBy: finalSort?.[0]?.id || '',
          order: finalSort?.[0]?.id
            ? finalSort?.[0]?.desc
              ? 'desc'
              : 'asc'
            : '',
        });
        fetchEncryptions({
          sortBy: finalSort?.[0]?.id || '',
          order: finalSort?.[0]?.id
            ? finalSort?.[0]?.desc
              ? 'desc'
              : 'asc'
            : '',
        });
      }}
      state={{
        isLoading,
        pagination: {
          pageIndex: parseInt(encryptions?.page) - 1 || 0,
          pageSize: parseInt(encryptions?.limit) ?? 0,
        },
        sorting: tableState.sorting, // Ensure this is an array
        globalFilter: tableState.globalFilter,
        columnSizing: tableState?.columnSizing,
        columnVisibility: tableState?.columnVisibility,
      }}
      onStateChange={setTableState}
      mantineTopToolbarProps={{ className: 'bg-[#A0CD850F] min-h-16' }}
      renderDetailPanel={({ row }) => (
        <div>
          {row?.original?.customFields?.length > 0 ? (
            <>
              <h3 className="font-semibold mb-2 text-base">
                Encrypted Custom Fields
              </h3>
              <div className="overflow-x-auto mb-1">
                <table className="w-full text-sm text-left border border-[#D0D5DD]">
                  <thead className=" bg-white  ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-[#D0D5DD] w-10"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-[#D0D5DD]"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-[#D0D5DD]"
                      >
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {row?.original?.customFields?.map((field, index) => (
                      <tr className="bg-white" key={field?.id}>
                        <td className="px-6 py-4 border border-[#D0D5DD]">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 border border-[#D0D5DD]">
                          {field?.name}
                        </td>
                        <td className="px-6 py-4 border border-[#D0D5DD]">
                          {field?.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="text-base text-center my-4">
              No custom fields found
            </div>
          )}
        </div>
      )}
      renderToolbarInternalActions={({ table }) => (
        <div className="flex gap-5 items-center flex-wrap gap-y-2">
          <SearchForm
            searchVal={searchValue}
            setSearchVal={handleSearch}
            placeholder="Search Record"
            height="h-10"
          />
          <div className="bg-[#D0D5DD] min-w-px h-10  sm:block" />
          <div className="flex gap-4 ml-auto">
            <MRT_ShowHideColumnsButton
              table={table}
              className="border border-slate-300 h-10 w-10 bg-white"
              sx={{
                height: '10rem',
              }}
            />
            <Tooltip
              content="Reset filters"
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage', 'clientsTableFirmView']}
            >
              <button
                onClick={() => {
                  handleSearch('');
                  if (searchValue) {
                    fetchEncryptions({ search: '', page: 1 });
                  }
                  setTableState((prevState) => {
                    const newState = {
                      ...tableInitialState,
                      pagination: prevState?.pagination,
                    };

                    return newState;
                  });
                }}
                className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                type="button"
              >
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                >
                  <path
                    d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                    className="fill-current"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
      columnAc
      enableColumnResizing
      enableSorting
      //   mantineTableBodyRowProps={
      //     isMatching
      //       ? ({ row }) => ({
      //           onClick: (event) => {
      //             event.stopPropagation(); // Prevent event bubbling
      //             // Add your custom row click logic here
      //             setSelectedRow(
      //               row.original?.expenseReportTransaction?.transaction,
      //             );
      //             setRowDetailsOpen(true);
      //           },
      //           style: { cursor: 'pointer' }, // Change cursor to pointer on hover
      //         })
      //       : null
      //   }
      enableColumnActions={false}
      mantineTableProps={{
        withBorder: false, // Removes the outer border
        withColumnBorders: false, // Removes borders between columns
      }}
      mantineTableContainerProps={{
        className: 'border-none scrollbar mb-6 pb-1',
        id: 'invitesTableFirmView',
      }}
      mantinePaperProps={{
        className: '!border-none  shadow-none min-h-fit',
      }}
      enablePagination={!isLoading}
      manualPagination
    />
  );
};

export default EncryptedTable;
