import React, { memo } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

const pageThumbnailPlugin = (props) => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps) => {
      const { slot } = renderProps;

      slot.children = PageThumbnail;

      // Reset the sub slot
      slot.subSlot.attrs = {};
      slot.subSlot.children = null;

      return slot;
    },
  };
};

const PdfThumbnail = memo(({ fileUrl, width = 80, pageIndex = 0 }) => {
  const thumbnailPluginInstance = thumbnailPlugin({
    renderSpinner: () => null,
  });
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => pageIndex} width={width} />,
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
        renderLoader={() => null}
      />
    </Worker>
  );
});

PdfThumbnail.displayName = 'PdfThumbnail';

export default PdfThumbnail;
