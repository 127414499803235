import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { IS_ACCOUNTANT, IS_PERSON, TEAM_ID } from '../utils/Utils';
// While login you can still access these Public routes
const AllowedWhileLogin = [
  '/privacy-policy',
  '/privacy',
  '/terms',
  '/guest-signup',
  '/oauth',
  '/invoice-payment',
  '/public/invoice-estimate',
  '/public/customer-statement',
  '/public/invoice-estimate/print-ready',
  '/public/reports/sales-tax-liability/print-ready',
  '/public/reports/expense-claim/print-ready',
  '/public/reports/balanceSheet/print-ready',
  '/public/reports/profit-loss/print-ready',
];

const checkAllowedWLogin = (allowedArray, currentUrl) => {
  let allowed = false;
  for (let i = 0; i < allowedArray?.length; i++) {
    if (currentUrl?.includes(allowedArray?.[i])) {
      allowed = true;
      break;
    }
  }
  return allowed;
};

const PublicRoute = (props) => {
  // const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken');
  const teamId = localStorage.getItem(TEAM_ID);
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  if (
    !IsPerson &&
    token &&
    props?.path !== '/workspace/:type' &&
    props?.path !== '/practice/:id' &&
    props?.path !== '/verify-email' &&
    !checkAllowedWLogin(AllowedWhileLogin, props?.path)
  ) {
    return (
      <Navigate
        to={{
          pathname: teamId
            ? '/'
            : IsAccountant
              ? '/accountant'
              : '/manage-workspaces',
          state: { from: props.location },
        }}
      />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        {' '}
        <Component {...props} />{' '}
      </>
    )}
  />
);

export { PublicRoute, AccessRoute };
