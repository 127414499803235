/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useMemo, useState } from 'react';
import ReusableTable from 'components/ReusableTable';
import Tooltip from 'components/Tooltip';
import { MRT_ShowHideColumnsButton } from 'mantine-react-table';
import SearchForm from 'partials/actions/SearchForm';
import { DateTime } from 'luxon';
import { formatNumberGlobally, getCurrencySymbol } from 'utils/Utils';
import TextInputDash from 'components/TextInputDash';

const IconHeader = ({ icon, text }) => (
  <div className="flex gap-2 items-center mr-2">
    {/* {icon} */}
    <span className="font-medium text-sm">{text}</span>
  </div>
);

const tableInitialState = {
  columnSizing: {
    select: 40,
    number: 70,
    customer: 350,
    date: 100,
    dueDate: 100,
    total: 100,
    amount: 130,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  pagination: { pageIndex: 0, pageSize: 10 },
  columnVisibility: {},
  columnOrder: [],
  showGlobalFilter: false,
};

const MatchInvoiceTable = ({
  invoices,
  onSelectInvoice,
  selectedInvoices,
  onAmountChange,
  isSplit = false,
  onReferenceChange,
}) => {
  const [tableState, setTableState] = useState({ ...tableInitialState });
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (invoices?.length > 0) {
      setTableData(invoices);
    }
  }, [invoices]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (!value) {
      setTableData(invoices);
      return;
    }
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    const filtered = invoices?.filter((invoice) =>
      `${
        /^\d+$/.test(invoice?.invoiceNumber)
          ? invoice?.invoiceNumber?.toString()?.padStart(4, '0')
          : invoice?.invoiceNumber
      }${invoice?.customer?.customer || '--'}${getCurrencySymbol(
        invoice?.currency,
      )}${formatNumberGlobally(invoice?.total ?? 0, true)}`
        ?.replace(/\s/g, '')
        ?.toLowerCase()
        ?.includes(formattedValue),
    );
    setTableData(filtered);
  };

  const renderDetailPanel = ({ row }) => {
    const isInvoiceSelected = selectedInvoices?.find(
      (inv) => inv?.id === row?.original?.id,
    );

    return (
      <div className="flex items-end gap-4">
        <div className="relative flex flex-col max-w-[300px] w-[300px]">
          <label
            className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
            htmlFor="amount"
          >
            {`Invoice ${
              /^\d+$/.test(row?.original?.invoiceNumber)
                ? row?.original?.invoiceNumber?.toString()?.padStart(4, '0')
                : row?.original?.invoiceNumber
            } : Amount to Pay`}
          </label>
          <input
            id="amount"
            type="number"
            placeholder="0.00"
            name="amount"
            max={row?.original?.amountDue}
            className="btn pl-[3.35rem] pr-4 py-2.5 h-12 rounded-[0.313rem] justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] text-slate-600 placeholder:text-slate-600 placeholder:text-opacity-80"
            onChange={(e) => onAmountChange(e.target.value, row?.original)}
            value={isInvoiceSelected?.amountToPay}
            onWheel={(e) => e.target.blur()}
            disabled={isSplit}
          />
          <span className="absolute bottom-[13px] left-4 flex items-center gap-2">
            <span className="text-slate-600">
              {getCurrencySymbol(row?.original?.currency)}
            </span>

            <div className="w-[0.038rem] h-[1.188rem] bg-slate-600" />
          </span>
        </div>

        {isInvoiceSelected?.amountToPay > 0 && (
          <div
            className={`capitalize mb-[2px]  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                              ${
                                parseFloat(row?.original?.amountDue)?.toFixed(
                                  2,
                                ) ===
                                parseFloat(
                                  isInvoiceSelected?.amountToPay,
                                )?.toFixed(2)
                                  ? 'text-[#40AA00] bg-[#40AA001A]'
                                  : 'text-[#EDB900] bg-[#EDB9001A]'
                              }
                         `}
          >
            {parseFloat(row?.original?.amountDue)?.toFixed(2) ===
            parseFloat(isInvoiceSelected?.amountToPay)?.toFixed(2)
              ? 'Full Payment'
              : 'Partial Payment'}
          </div>
        )}
        <TextInputDash
          label="Reference"
          placeholder="Reference"
          value={isInvoiceSelected?.notes}
          onChange={(e) => onReferenceChange(e.target.value, row?.original)}
          width="w-[300px]"
          onWheel={(e) => e.target.blur()}
          paddingBottom="pb-0"
        />
      </div>
    );
  };

  const initialExpandedState = useMemo(
    () =>
      tableData?.reduce?.((acc, row, index) => {
        const isInvoiceSelected = selectedInvoices?.find(
          (inv) => inv.id === row.id,
        );
        if (isInvoiceSelected) {
          acc[`${index}`] = true;
        }
        return acc;
      }, {}),
    [tableData],
  );

  useEffect(() => {
    if (tableData?.length > 0 && Object.keys(initialExpandedState).length > 0) {
      setTableState((prev) => ({ ...prev, expanded: initialExpandedState }));
    }
  }, [initialExpandedState]);

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        header: '',
        enableResizing: false,
        enableSorting: false,
        Cell: (row) => (
          <div className="flex items-center">
            <label className="inline-flex ">
              <span className="sr-only">Select</span>
              <input
                id={row?.row?.original?.id}
                className="form-checkbox cursor-pointer h-5 w-5"
                type="checkbox"
                onClick={(e) => e.stopPropagation()}
                onChange={() => {
                  const findIndex = selectedInvoices?.findIndex(
                    (inv) => inv?.id === row?.row?.original?.id,
                  );
                  if (findIndex >= 0) {
                    row?.row?.toggleExpanded(false);
                  } else {
                    row?.row?.toggleExpanded(true);
                  }
                  onSelectInvoice(row?.row?.original);
                }}
                checked={
                  selectedInvoices?.find?.(
                    (inv) => inv?.id === row?.row?.original?.id,
                  )?.id
                }
              />
            </label>
          </div>
        ),
        minSize: 40,
      },
      {
        id: 'number',
        accessorKey: 'number',
        header: '#',
        Header: <IconHeader icon={null} text="#" />,
        enableResizing: true,
        enableSorting: true,
        Cell: (row) => (
          <div className="text-sm text-[#667085]">
            {/^\d+$/.test(row?.row?.original?.invoiceNumber)
              ? row?.row?.original?.invoiceNumber?.toString()?.padStart(4, '0')
              : row?.row?.original?.invoiceNumber}
          </div>
        ),
        sortingFn: (rowA, rowB) =>
          rowA?.original?.invoiceNumber?.localeCompare(
            rowB?.original?.invoiceNumber,
          ),
      },
      {
        id: 'customer',
        accessorKey: 'customer',
        header: 'Customer',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
              </svg>
            }
            text="Customer"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]">
            {row?.row?.original?.customer?.customer || '--'}
          </div>
        ),
        enableColumnResizing: true,
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          rowA?.original?.customer?.customer?.localeCompare(
            rowB?.original?.customer?.customer,
          ),
      },
      {
        id: 'date',
        accessorKey: 'date',
        header: 'Date',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Date"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {DateTime.fromISO(row?.row?.original?.date, {
              setZone: true,
            }).toFormat('MMM dd, yyyy')}
          </div>
        ),
        enableResizing: false,
        enableSorting: true,
        minSize: 120,
        sortingFn: (rowA, rowB) => {
          const dateA = DateTime.fromISO(rowA?.original?.date);
          const dateB = DateTime.fromISO(rowB?.original?.date);
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        },
      },
      {
        id: 'dueDate',
        accessorKey: 'dueDate',
        header: 'Due',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Due"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {DateTime.fromISO(row?.row?.original?.dueDate, {
              setZone: true,
            }).toFormat('MMM dd, yyyy')}
          </div>
        ),
        minSize: 120,
        enableResizing: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const dateA = DateTime.fromISO(rowA?.original?.dueDate);
          const dateB = DateTime.fromISO(rowB?.original?.dueDate);
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;
          return 0;
        },
      },
      {
        id: 'total',
        accessorKey: 'total',
        header: 'Total',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Total"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {getCurrencySymbol(row?.row?.original?.currency)}
            {formatNumberGlobally(row?.row?.original?.total ?? 0)}
          </div>
        ),
        enableResizing: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const salaryA = rowA?.original?.total ?? 0;
          const salaryB = rowB?.original?.total ?? 0;
          if (salaryA < salaryB) return -1;
          if (salaryA > salaryB) return 1;
          return 0;
        },
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: 'Amount Due',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-[#667085] stroke-[#667085] fill-none"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
                <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
              </svg>
            }
            text="Amount Due"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {getCurrencySymbol(row?.row?.original?.currency)}
            {formatNumberGlobally(row?.row?.original?.amountDue ?? 0)}
          </div>
        ),
        enableResizing: false,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const salaryA = rowA?.original?.amountDue ?? 0;
          const salaryB = rowB?.original?.amountDue ?? 0;
          if (salaryA < salaryB) return -1;
          if (salaryA > salaryB) return 1;
          return 0;
        },
      },
    ],
    [isSplit, selectedInvoices],
  );

  return (
    <ReusableTable
      columns={columns}
      data={tableData}
      enableColumnResizing
      enableSorting
      state={tableState}
      onStateChange={setTableState}
      enableRowActions
      enableGlobalFilter={false} // disables search bar
      enableFilters={false} // disables column filters
      enableDensityToggle={false} // Hides density toggle
      enableFullScreenToggle={false} // Hides full-screen toggle
      enableColumnActions={false} // Hides action dropdown from each column
      mantineTableProps={{
        withBorder: false, // Removes the outer border
        withColumnBorders: false, // Removes borders between columns
      }}
      mantineTableContainerProps={{
        className: 'border-none scrollbar mb-6 pb-1',
        id: 'invitesTableFirmView',
      }}
      mantinePaperProps={{
        className: '!border-none  shadow-none min-h-fit',
      }}
      // mantineTool
      enablePagination={false}
      mantineTopToolbarProps={{ className: 'bg-[#A0CD850F] min-h-16' }}
      renderDetailPanel={renderDetailPanel}
      renderToolbarInternalActions={({ table }) => (
        <div className="flex gap-5 items-center flex-wrap gap-y-2">
          <SearchForm
            searchVal={searchValue}
            setSearchVal={handleSearch}
            placeholder="Search Invoice"
            height="h-10"
          />
          <div className="bg-[#D0D5DD] min-w-px h-10 hidden sm:block" />
          <div className="flex gap-4 ml-auto">
            <MRT_ShowHideColumnsButton
              table={table}
              className="border border-slate-300 h-10 w-10 bg-white hidden"
              sx={{
                height: '10rem',
              }}
            />
            <Tooltip
              content="Reset filters"
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage', 'clientsTableFirmView']}
            >
              <button
                onClick={() => {
                  setTableState((prevState) => {
                    const newState = {
                      ...tableInitialState,
                    };
                    if (prevState?.expanded) {
                      newState.expanded = prevState?.expanded;
                    }
                    return newState;
                  });
                  handleSearch('');
                }}
                className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                type="button"
              >
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                >
                  <path
                    d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                    className="fill-current"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
      renderBottomToolbar={() => null} // Completely hide the footer
    />
  );
};

export default MatchInvoiceTable;
