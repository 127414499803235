import React, { useEffect, useState } from 'react';
import {
  ignoreMatchingSingleTransaction,
  onConfirmMatchingTrans,
} from 'API/backend_helper';
import { toast } from 'react-toastify';
import ReuseableTable from './ReuseableTable';
import ManualTransfer from './ManualTransfer';

const TransferTab = ({
  setNoClick,
  allCategories,
  reloadTransaction,
  reloadTransactions,
  transaction,
  setClose,
}) => {
  const [isManual, setIsManual] = useState(false);
  const [matchedTransaction, setMatchedTransaction] = useState(null);
  const [matchingLoader, setMatchingLoader] = useState(false);
  const [skipLoader, setSkipLoader] = useState(false);

  const onMatchingTransaction = async () => {
    setMatchingLoader(true);
    try {
      await onConfirmMatchingTrans(matchedTransaction);
      // await reloadTransaction({});
      toast.success('Transaction matched successfully');
      setMatchingLoader(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setMatchingLoader(false);
      console.log('error', e);
    }
  };

  const onSkip = async () => {
    setSkipLoader(true);
    try {
      await ignoreMatchingSingleTransaction(transaction?.id, {
        matchingType: 'transfer',
      });
      // await reloadTransaction({});
      toast.success('Matching skipped successfully');
      setSkipLoader(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setSkipLoader(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (!transaction?.transferMatchings?.length > 0) {
      setIsManual(true);
    }
  }, [transaction]);

  return (
    <div>
      <div
        className={`${!isManual ? 'mx-10  mb-4' : ''} flex flex-col overflow-auto scrollbar`}
        style={{
          maxHeight: `calc(100dvh - ${isManual ? '215px' : '324px'})`,
          height: `calc(100dvh - ${isManual ? '215px' : '324px'})`,
        }}
        id="transactionsCategoryModalTransferTab"
      >
        <p className={`${isManual ? 'px-10 ' : ''}  mb-4`}>
          Mark this transaction as a transfer by confirming the suggested match
          or selecting the other account manually. This won't affect your income
          or expenses, but it keeps your account balances accurate.
        </p>
        <div className="flex-1">
          {isManual ? (
            <ManualTransfer
              setNoClick={setNoClick}
              allCategories={allCategories}
              reloadTransaction={reloadTransaction}
              reloadTransactions={reloadTransactions}
              transaction={transaction}
              setIsManual={setIsManual}
              isManual={isManual}
              setClose={setClose}
            />
          ) : !transaction?.transferMatchings?.length > 0 ? (
            <div className="text-[18px] text-slate-500 text-center h-full flex items-center justify-center">
              No matching transfer found.{' '}
              <span
                className="italic cursor-pointer text-indigo-400 pl-1"
                role="button"
                tabIndex={0}
                onClick={() => setIsManual(true)}
              >
                Configure manually.
              </span>
            </div>
          ) : (
            <>
              <p className="text-[20px] text-[#101828]">Possible Matches</p>
              <ReuseableTable
                transactions={transaction?.transferMatchings}
                setMatchedTransaction={setMatchedTransaction}
                matchedTransaction={matchedTransaction}
              />
            </>
          )}
        </div>
      </div>

      {!isManual && (
        <footer className="px-10 py-6 border-t border-[#D0D5DD]">
          <div className="flex gap-4 justify-between flex-wrap">
            <button
              type="button"
              onClick={() => setIsManual(!isManual)}
              className="h-11 w-fit inline-flex items-center underline justify-center gap-2  text-indigo-500"
            >
              Switch to Manual Transfer
            </button>
            {transaction?.transferMatchings?.length > 0 && (
              <div className="flex gap-4 ml-auto">
                <button
                  type="button"
                  onClick={onSkip}
                  disabled={matchingLoader || skipLoader}
                  className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085] disabled:cursor-not-allowed disabled:opacity-60"
                >
                  {skipLoader && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Skip
                </button>
                <button
                  type="button"
                  disabled={!matchedTransaction || matchingLoader || skipLoader}
                  onClick={onMatchingTransaction}
                  className="h-11 w-[180px] inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:bg-indigo-300 disabled:cursor-not-allowed"
                >
                  {matchingLoader && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Confirm Match
                </button>
              </div>
            )}
          </div>
        </footer>
      )}
    </div>
  );
};

export default TransferTab;
