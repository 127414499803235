/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-unstable-nested-components */
import ReusableTable from 'components/ReusableTable';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import { MRT_ShowHideColumnsButton } from 'mantine-react-table';
import SearchForm from 'partials/actions/SearchForm';
import React, { useEffect, useMemo, useState } from 'react';

const IconHeader = ({ icon, text }) => (
  <div className="flex gap-2 items-center mr-2">
    {icon}
    <span className="font-medium text-sm">{text}</span>
  </div>
);

const tableInitialState = {
  columnSizing: {
    name: 200,
    email: 200,
    type: 200,
    actions: 300,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  pagination: { pageIndex: 0, pageSize: 10 },
  columnVisibility: {},
  columnOrder: [],
  showGlobalFilter: false,
};

const FirmClientsInvite = ({
  onChangeInvite,
  accountantFirm,
  loadAccountantFirm,
}) => {
  const [tableState, setTableState] = useState({ ...tableInitialState });
  const [filteredInvites, setFilteredInvites] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState([]);

  const [inviteLoading, setInviteLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (!value) {
      setTableData(filteredInvites || []);
      return;
    }
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    const filtered = filteredInvites?.filter((invite) =>
      `${invite?.team?.name}${invite?.team?.email}${invite?.team?.type || '--'}`
        ?.replace(/\s/g, '')
        ?.toLowerCase()
        ?.includes(formattedValue),
    );
    setTableData(filtered);
  };

  useEffect(() => {
    if (accountantFirm?.firmInvites?.length > 0) {
      const tempData =
        accountantFirm?.firmInvites?.filter(
          (invite) => invite.status === 'active',
        ) || [];
      setFilteredInvites(tempData);
      setTableData(tempData);
    } else if (accountantFirm?.firmInvites?.length === 0) {
      setFilteredInvites([]);
      setTableData([]);
    }
  }, [accountantFirm?.firmInvites]);

  useEffect(() => {
    loadAccountantFirm({});
  }, []);

  const handleOnChangeInvite = async (value, invite) => {
    if (value === 'accept') {
      setInviteLoading(invite?.id);
    }
    if (value === 'decline') {
      setDeclineLoading(invite?.id);
    }
    await onChangeInvite(value, invite);
    setInviteLoading(false);
    setDeclineLoading(false);
  };

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Workspace Name',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l80 0 0-64c0-26.5 21.5-48 48-48s48 21.5 48 48l0 64 80 0c8.8 0 16-7.2 16-16l0-384c0-8.8-7.2-16-16-16L64 48zM0 64C0 28.7 28.7 0 64 0L320 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm88 40c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48zM232 88l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48zm144-16l48 0c8.8 0 16 7.2 16 16l0 48c0 8.8-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16z" />
              </svg>
            }
            text="Workspace Name"
          />
        ),
        enableColumnResizing: true,
        enableSorting: true,
        Cell: (row) => (
          <div className="flex items-center">
            <div className="w-7 h-7 shrink-0 mr-2 sm:mr-3 inline-flex justify-center items-center">
              {row?.row?.original?.team?.logoUrl ? (
                <img
                  src={row?.row?.original?.team?.logoUrl}
                  alt={`${row?.row?.original?.team.name} logo`}
                  className="max-h-full max-w-full"
                />
              ) : (
                <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A] dark:bg-slate-700">
                  <span className="uppercase text-[#7425F4] text-xs">
                    {row?.row?.original?.team?.name?.split(' ')?.[0]?.charAt(0)}
                    {row?.row?.original?.team?.name?.split(' ')?.[1]?.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <div className="text-sm text-[#667085] dark:text-slate-100">
              {row?.row?.original?.team?.name}
            </div>
          </div>
        ),
        sortingFn: (rowA, rowB) =>
          rowA?.original?.team?.name?.localeCompare(rowB?.original?.team?.name),
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: 'Email',
        Header: (
          <IconHeader
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-4 w-4 text-[#667085] fill-[#667085]"
              >
                <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
              </svg>
            }
            text="Email"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {row?.row?.original?.team?.email || '--'}
          </div>
        ),
        enableColumnResizing: true,
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          rowA?.original?.team?.email?.localeCompare(
            rowB?.original?.team?.email,
          ),
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: 'Workspace Type',
        Header: (
          <IconHeader
            icon={
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-[#667085] stroke-[#667085]"
              >
                <path
                  d="M14 20.4V14.6C14 14.2686 14.2686 14 14.6 14H20.4C20.7314 14 21 14.2686 21 14.6V20.4C21 20.7314 20.7314 21 20.4 21H14.6C14.2686 21 14 20.7314 14 20.4Z"
                  className="stroke-current"
                  strokeWidth="2"
                />
                <path
                  d="M3 20.4V14.6C3 14.2686 3.26863 14 3.6 14H9.4C9.73137 14 10 14.2686 10 14.6V20.4C10 20.7314 9.73137 21 9.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z"
                  className="stroke-current"
                  strokeWidth="2"
                />
                <path
                  d="M14 9.4V3.6C14 3.26863 14.2686 3 14.6 3H20.4C20.7314 3 21 3.26863 21 3.6V9.4C21 9.73137 20.7314 10 20.4 10H14.6C14.2686 10 14 9.73137 14 9.4Z"
                  className="stroke-current"
                  strokeWidth="2"
                />
                <path
                  d="M3 9.4V3.6C3 3.26863 3.26863 3 3.6 3H9.4C9.73137 3 10 3.26863 10 3.6V9.4C10 9.73137 9.73137 10 9.4 10H3.6C3.26863 10 3 9.73137 3 9.4Z"
                  className="stroke-current"
                  strokeWidth="2"
                />
              </svg>
            }
            text="Workspace Type"
          />
        ),
        Cell: (row) => (
          <div className="text-sm text-[#667085]  capitalize">
            {row?.row?.original?.team?.type || '--'}
          </div>
        ),
        enableColumnResizing: true,
        enableSorting: true,
        sortingFn: (rowA, rowB) =>
          (rowA?.original?.team?.type || '--')?.localeCompare(
            rowB?.original?.team?.type || '--',
          ),
      },
      {
        id: 'actions',
        accessorKey: 'actions',
        header: 'Actions',
        Header: (
          <IconHeader
            icon={
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-[#667085] stroke-[#667085]"
              >
                <path
                  d="M21 9.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9.5M17.3862 17.7113L15.6879 20.8653C15.4103 21.3808 15.2715 21.6386 15.1023 21.7059C14.9555 21.7643 14.7896 21.7498 14.6551 21.6668C14.5001 21.5712 14.4081 21.2933 14.2241 20.7375L11.5004 12.5113C11.3392 12.0245 11.2586 11.7812 11.3166 11.6191C11.367 11.478 11.478 11.367 11.6191 11.3166C11.7812 11.2586 12.0245 11.3392 12.5113 11.5004L20.7374 14.2241C21.2933 14.4082 21.5712 14.5002 21.6668 14.6551C21.7498 14.7897 21.7642 14.9555 21.7058 15.1024C21.6386 15.2715 21.3808 15.4103 20.8652 15.6879L17.7113 17.3862C17.6328 17.4285 17.5935 17.4497 17.5591 17.4768C17.5286 17.501 17.501 17.5286 17.4768 17.5591C17.4497 17.5935 17.4285 17.6328 17.3862 17.7113Z"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="stroke-current"
                />
              </svg>
            }
            text="Actions"
          />
        ),
        Cell: (row) => (
          <div className="flex gap-4">
            <button
              onClick={() => handleOnChangeInvite('accept', row?.row?.original)}
              className="btn h-10 border-[#78BD4F] whitespace-nowrap bg-[#78BD4F0D] text-[#78BD4F] text-sm font-normal w-[100px] disabled:cursor-not-allowed"
              type="button"
              disabled={
                inviteLoading === row?.row?.original?.id ||
                declineLoading === row?.row?.original?.id
              }
            >
              {inviteLoading === row?.row?.original?.id ? (
                <Loader height="h-5 w-5" color="#78BD4F" />
              ) : (
                'Accept Invite'
              )}
            </button>
            <button
              onClick={() =>
                handleOnChangeInvite('decline', row?.row?.original)
              }
              className="btn h-10 border-[#FF4B4B] bg-[#FF4B4B0D] text-[#FF4B4B] text-sm font-normal px-2.5 w-[68px] disabled:cursor-not-allowed"
              type="button"
              disabled={
                inviteLoading === row?.row?.original?.id ||
                declineLoading === row?.row?.original?.id
              }
            >
              {declineLoading === row?.row?.original?.id ? (
                <Loader height="h-5 w-5" color="#FF4B4B" />
              ) : (
                'Decline'
              )}
            </button>
          </div>
        ),
        enableColumnResizing: false,
        enableSorting: false,
      },
    ],
    [inviteLoading, declineLoading],
  );

  return (
    <ReusableTable
      columns={columns}
      data={tableData}
      enableColumnResizing
      enableSorting
      initialState={{
        columnSizing: {
          name: 200,
          email: 200,
          type: 200,
          actions: 300,
        },
      }}
      state={tableState}
      onStateChange={setTableState}
      enableGlobalFilter={false} // disables search bar
      enableFilters={false} // disables column filters
      enableDensityToggle={false} // Hides density toggle
      enableFullScreenToggle={false} // Hides full-screen toggle
      enableColumnActions={false} // Hides action dropdown from each column
      mantineTableProps={{
        withBorder: false, // Removes the outer border
        withColumnBorders: false, // Removes borders between columns
      }}
      mantineTableContainerProps={{
        className: 'border-none scrollbar mb-12 pb-1',
        id: 'invitesTableFirmView',
      }}
      mantinePaperProps={{
        className: '!border-none  shadow-none',
      }}
      // mantineTool
      enablePagination={false}
      mantineTopToolbarProps={{ className: 'bg-[#A0CD850F] min-h-16' }}
      renderToolbarInternalActions={({ table }) => (
        <div className="flex gap-5 items-center flex-wrap gap-y-2">
          <SearchForm
            searchVal={searchValue}
            setSearchVal={handleSearch}
            placeholder="Search Invite"
            height="h-10"
          />
          <div className="bg-[#D0D5DD] min-w-px h-10 hidden sm:block" />
          <div className="flex gap-4 ml-auto">
            <MRT_ShowHideColumnsButton
              table={table}
              className="border border-slate-300 h-10 w-10 bg-white"
              sx={{
                height: '10rem',
              }}
            />
            <Tooltip
              content="Reset filters"
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage', 'clientsTableFirmView']}
            >
              <button
                onClick={() => {
                  setTableState(tableInitialState);
                  handleSearch('');
                }}
                className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                type="button"
              >
                <svg
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                >
                  <path
                    d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                    className="fill-current"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
      renderBottomToolbar={() => null} // Completely hide the footer
    />
  );
};

export default FirmClientsInvite;
