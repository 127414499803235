import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  firmInviteBusinessOwner,
  withdrawFirmInviteBusinessOwner,
} from 'API/backend_helper';
import DropdownFull from 'components/DropdownFull';
import InviteClientModal from './InviteClient';
import NewClientsTable from './NewClientsTable';
import ClientInvitationsTable from './ClientInvitationsTable';

const ClientsTable = ({
  clients,
  setIsOpenModal,
  setLeaveTeam,
  onTeamChange,
  loading,
  accountantFirmId,
  firm,
  loadAccountantFirm,
  InviteClientEmailParam,
}) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredOwnerData, setFilteredOwnerData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [inviteClient, setInviteClient] = useState(false);
  const [withdrawFirmMemberLoader, setWithdrawFirmMemberLoader] = useState({});
  const [clientsType, setClientsType] = useState('');

  useEffect(() => {
    if (clients?.length > 0) {
      const tempTeams = [...clients];
      const sortedTeams = tempTeams?.sort?.(
        (a, b) =>
          moment(b?.createdAt, 'YYYY-MM-DD').toDate() -
          moment(a?.createdAt, 'YYYY-MM-DD').toDate(),
      );
      setFilteredData(sortedTeams);
    } else {
      setFilteredData([]);
    }
  }, [clients]);

  useEffect(() => {
    setFilteredOwnerData(
      firm?.ownerInvitations?.filter((m) => m?.status !== 'accepted'),
    );
  }, [firm?.ownerInvitations]);

  const getOwnerClientValue = (team) =>
    team?.createdByFirmId
      ? 'Practice Owned'
      : team?.users?.length > 0
        ? `${team?.users?.[0]?.firstName || ''} ${team?.users?.[0]?.lastName || ''}`
        : '--';

  const handleSearch = (value) => {
    setSearchValue(value);
    if (!value) {
      setFilteredData(clients || []);
      return;
    }
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    const filtered = clients?.filter((team) =>
      `${team?.name}${getOwnerClientValue(team)}${team?.email}${team?.phone}active`
        ?.replace(/\s/g, '')
        ?.toLowerCase()
        ?.includes(formattedValue),
    );

    setFilteredData(filtered);
  };

  const withdrawMemberInvitation = async (invitationId) => {
    setWithdrawFirmMemberLoader((prev) => ({ ...prev, [invitationId]: true }));
    try {
      await withdrawFirmInviteBusinessOwner(accountantFirmId, invitationId);
      await loadAccountantFirm({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  const resendClientInvite = async (email, invitationId) => {
    try {
      setWithdrawFirmMemberLoader((prev) => ({
        ...prev,
        [invitationId]: true,
      }));
      const res = await firmInviteBusinessOwner(accountantFirmId, {
        email,
      });
      await loadAccountantFirm({ loading: false });
    } catch (e) {
      console.log('error', e);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  const FilteredClients = useMemo(() => {
    const tempFiltered = filteredData?.filter(
      (client) =>
        (clientsType === 'advisor' && client?.createdByFirmId) ||
        (clientsType === 'client' && !client?.createdByFirmId) ||
        !clientsType,
    );
    return tempFiltered;
  }, [clientsType, filteredData]);

  useEffect(() => {
    if (InviteClientEmailParam) {
      setInviteClient(true);
    }
  }, [InviteClientEmailParam]);

  return (
    <div>
      <InviteClientModal
        isOpen={inviteClient}
        setIsOpen={setInviteClient}
        accountantFirmId={accountantFirmId}
        loadAccountantFirm={loadAccountantFirm}
        InviteClientEmailParam={InviteClientEmailParam}
      />
      <div className="flex justify-between flex-wrap gap-4">
        <DropdownFull
          options={[
            { name: 'All Workspaces', id: '' },
            { name: 'Practice Owned', id: 'advisor' },
            { name: 'Client Owned', id: 'client' },
          ]}
          height="h-11 !border-indigo-500 !bg-[#E4864205] "
          selected={clientsType}
          setSelected={(value) => {
            setClientsType(value);
          }}
          name="name"
          placeholder="Priority By"
          scrollIntoView={false}
          buttonTextClassSub="!text-indigo-500"
          chevronClass="!text-indigo-500"
          className="!w-[300px]"
        />
        <div className="flex flex-wrap  mb-5 justify-end gap-5 ml-auto">
          <div className="flex gap-y-2 flex-wrap justify-end">
            <button
              className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white mr-2"
              aria-controls="basic-modal"
              onClick={(e) => {
                e.preventDefault();
                navigate('/workspace/new');
              }}
              type="button"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Create Client's Workspace
            </button>
            <button
              className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white"
              aria-controls="basic-modal"
              onClick={(e) => {
                e.preventDefault();
                setInviteClient(true);
              }}
              type="button"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Invite a Client
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm overflow-auto">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-4">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <>
            {filteredOwnerData?.length > 0 && (
              <>
                <h3 className="mb-4 text-[24px] text-[#666666] font-medium ">
                  Invitations
                </h3>
                <ClientInvitationsTable
                  withdrawMemberInvitation={withdrawMemberInvitation}
                  withdrawFirmMemberLoader={withdrawFirmMemberLoader}
                  clientInvitations={filteredOwnerData}
                  resendClientInvite={resendClientInvite}
                />
                <h3 className="pb-4 text-[24px] text-[#666666] font-medium">
                  Clients
                </h3>
              </>
            )}
            <NewClientsTable
              data={FilteredClients}
              onTeamChange={onTeamChange}
              searchValue={searchValue}
              handleSearch={handleSearch}
              setClientsType={setClientsType}
              getOwnerClientValue={getOwnerClientValue}
              firm={firm}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ClientsTable;
