import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCurrencySymbol } from 'utils/Utils';
import SaleTaxLiabilityTable from './SaleTaxLiabilityTable';

const SaleTaxLiabilityPdf = () => {
  const [searchParams] = useSearchParams();

  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const currency = searchParams.get('currency');
  const accounts = searchParams.get('accounts');
  const reportType = searchParams.get('reportType');
  const accessToken = searchParams.get('accessToken');
  const teamId = searchParams.get('teamId');
  const teamName = searchParams.get('teamName');

  const [generatedTransaction, setGeneratedTransaction] = useState([]);

  const handleSubmit = async (paramValues = {}) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/sales-tax?${paramValues.firstStartDate && `startDate=${paramValues.firstStartDate}&`}endDate=${paramValues.firstEndDate}&accounts=${paramValues?.accounts?.length > 0 ? paramValues?.accounts : ''}&currency=${currency}&reportType=${reportType || 'accrual'}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'count-workspace-id': teamId,
          },
        },
      );

      setGeneratedTransaction(response?.data?.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const formatDate = (date) => moment(date).format('MMM DD, YYYY');

  useEffect(() => {
    if (searchParams?.size !== 0) {
      handleSubmit({
        firstStartDate: startDate,
        firstEndDate: endDate,
        currency,
        reportType,
        accounts: accounts?.length > 0 ? accounts?.split?.(',') : '',
      });
    }
  }, [searchParams]);

  return (
    <div className="p-4">
      <h1 className="text-2xl text-center text-black  font-medium mb-4">
        Sales Tax Liability Report
      </h1>
      <h2 className="text-lg text-center text-black  font-medium mb-1">
        {teamName}
      </h2>
      <h2 className="text-base text-center text-black mb-4">
        {formatDate(generatedTransaction?.filters?.startDate)} to{' '}
        {formatDate(generatedTransaction?.filters?.endDate)}
      </h2>
      <div>
        <SaleTaxLiabilityTable
          currencySymbol={getCurrencySymbol(currency || 'USD')}
          generatedTransaction={generatedTransaction}
          selectedType={reportType}
          isPdf
        />
      </div>
    </div>
  );
};
export default SaleTaxLiabilityPdf;
