import { Loader } from 'components/Svg';
import React from 'react';
import {
  checkImageByLink,
  checkImageByType,
  checkVideoByLink,
  checkVideoByType,
} from 'utils/Utils';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import AttachmentThumbnail from './AttachmentThumbnail';

const AttachmentBox = ({
  attachment,
  onClickDelete,
  onClickView,
  showAccountantAssignee,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <div
      className="relative border min-h-[170px] w-full cursor-pointer flex flex-col justify-between border-[#667085] rounded-[10px]  " // bg-gray-300 bg-opacity-10
      onClick={() => onClickView(attachment)}
      role="button"
      tabIndex="0"
    >
      <div className="w-full">
        {(
          attachment?.fileUrl
            ? attachment?.fileUrl?.endsWith('.pdf')
            : attachment?.type?.includes('pdf')
        ) ? (
          <div className="w-full h-24 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              width="30"
              viewBox="0 0 512 512"
            >
              <path
                fill="#e48642"
                d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
              />
            </svg>
          </div>
        ) : (
            attachment?.fileUrl
              ? checkVideoByLink(attachment?.fileUrl)
              : checkVideoByType(attachment?.type)
          ) ? (
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  w-4 fill-[#667085]"
            >
              <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
            </svg>
            <video
              src={attachment?.fileUrl || attachment?.preview}
              className=" w-full h-24 object-cover rounded-[10px] rounded-b-none"
            />
          </div>
        ) : (
            attachment?.fileUrl
              ? checkImageByLink(attachment?.fileUrl)
              : checkImageByType(attachment?.type)
          ) ? (
          <img
            src={attachment?.fileUrl || attachment?.preview}
            className="w-full h-24 rounded-[10px] rounded-b-none object-contain"
            alt="attachment"
          />
        ) : (
          <div className="h-24 w-full flex items-center justify-center">
            <div className="h-20 rounded-full w-20 inline-flex justify-center items-center bg-indigo-100">
              <AttachmentThumbnail />
            </div>
          </div>
        )}
      </div>
      <p className="rounded-[inherit] rounded-t-none text-sm bg-[#00000099] text-white leading-tight break-all py-2 px-1">
        {attachment?.fileName || attachment?.name}
      </p>

      {(showAccountantAssignee ||
        checkPermission(
          PermissionKeys.MANAGE_TASKS,
          accessType,
          permissions,
        )) && (
        <div className="absolute h-5 w-5 top-2 right-3">
          {attachment?.id ? (
            <button
              className="relative p-0 bg-slate-200 hover:bg-slate-300 z-10 btn h-6 w-6 rounded"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClickDelete(attachment);
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer h-4 w-4"
              >
                <path
                  d="M2.25 4.5H3.75H15.75"
                  stroke="#FF4B4B"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                  stroke="#FF4B4B"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 8.25V12.75"
                  stroke="#FF4B4B"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 8.25V12.75"
                  stroke="#FF4B4B"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ) : (
            <div className="relative p-0 bg-slate-200 z-10 btn h-6 w-6 rounded">
              <Loader height="h-4" width="w-4" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AttachmentBox;
