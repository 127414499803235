import React from 'react';

const TobTabs = ({
  activeTab,
  setActiveTab,
  ESTIMATES_TAB,
  INVOICES_TAB,
  RECURRING_TAB,
  MEMOS_TAB,
  isLoading,
}) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-4 mt-8 flex-wrap">
    <div
      onClick={() => setActiveTab(INVOICES_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent text-[#667085] pb-3
                  ${
                    activeTab === INVOICES_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      role="button"
      tabIndex="0"
    >
      Invoices
    </div>
    <div
      onClick={() => setActiveTab(MEMOS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent text-[#667085] pb-3
                  ${
                    activeTab === MEMOS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      role="button"
      tabIndex="0"
    >
      Credit Memos
    </div>
    <div
      onClick={() => setActiveTab(ESTIMATES_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent text-[#667085] pb-3
                  ${
                    activeTab === ESTIMATES_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      role="button"
      tabIndex="0"
    >
      Estimates
    </div>
    <div
      onClick={() => setActiveTab(RECURRING_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent text-[#667085] pb-3
                  ${
                    activeTab === RECURRING_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      role="button"
      tabIndex="0"
    >
      Recurring Templates
    </div>
  </div>
);

export default TobTabs;
