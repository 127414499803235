import React, { useEffect, useState } from 'react';
import { getCurrencySymbol } from 'utils/Utils';
import { useSearchParams } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { transformData } from '.';
import BalanceSheetTable from './BalanceSheetTable';

const BalanceSheetPdf = () => {
  const [searchParams] = useSearchParams();

  const date = searchParams.get('date');
  const currency = searchParams.get('currency');
  const reportType = searchParams.get('reportType');

  const accessToken = searchParams.get('accessToken');
  const teamId = searchParams.get('teamId');
  const teamName = searchParams.get('teamName');

  const [generatedReport, setGeneratedReport] = useState([]);

  const handleSubmit = async ({
    currency = '',
    reportType = '',
    endDate = '',
  }) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/balance-sheet?endDate=${endDate}&reportType=${reportType || 'accural'}&currency=${currency}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'count-workspace-id': teamId,
          },
        },
      );

      const data = response?.data?.data?.categorized;
      const formattedData = Object.keys(data).length && transformData(data);
      setGeneratedReport(formattedData);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const formatDate = (date) => moment(date).format('MMM DD, YYYY');

  useEffect(() => {
    if (searchParams?.size !== 0) {
      handleSubmit({
        currency,
        reportType,
        endDate: date,
      });
    }
  }, [searchParams]);

  return (
    <div className="p-4">
      <h1 className="text-2xl text-center text-black  font-medium mb-4">
        Balance Sheet ({reportType})
      </h1>
      <h2 className="text-lg text-center text-black  font-medium mb-1">
        {teamName}
      </h2>
      <div>
        <BalanceSheetTable
          currencySymbol={getCurrencySymbol(currency || 'USD')}
          generatedReport={generatedReport}
          values={{
            firstEndDate: generatedReport?.filters?.endDate,
            reportType,
          }}
          isPdf
        />
      </div>
    </div>
  );
};

export default BalanceSheetPdf;
