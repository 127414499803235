import {
  bulkExcludeTransactions,
  deleteTransactionsByIDs,
  ignoreMatchingSingleTransaction,
  onIgnoreMatchingTransaction,
} from 'API/backend_helper';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getCurrencySymbol } from 'utils/Utils';

const DuplicatesTab = ({
  transaction,
  reloadTransaction,
  reloadTransactions,
  setClose,
}) => {
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [keepAllLoader, setKeepAllLoader] = useState(false);
  const [singleLoader, setSingleLoader] = useState(false);
  const [deleteDuplicates, setDeleteDuplicates] = useState(false);

  const onTransSelection = (id) => {
    if (selectedTransactions?.includes(id)) {
      setSelectedTransactions(selectedTransactions?.filter((i) => i !== id));
    } else {
      setSelectedTransactions([...selectedTransactions, id]);
    }
  };

  const onKeepAll = async () => {
    setKeepAllLoader(true);
    try {
      await ignoreMatchingSingleTransaction(transaction?.id, {
        matchingType: 'duplicate',
      });
      // await reloadTransaction({});
      toast.success('Action performed successfully');
      setKeepAllLoader(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setKeepAllLoader(false);
      console.log('error', e);
    }
  };

  const onKeepSelected = async () => {
    setSingleLoader(true);
    try {
      const transactionIds = transaction?.duplicateMatchings?.map(
        (transaction) => transaction.matchedTransactionId,
      );
      transactionIds?.push(transaction?.id);
      const transactionsToDelete = transactionIds?.filter(
        (transactionId) => !selectedTransactions?.includes(transactionId),
      );
      if (transactionsToDelete?.length > 0) {
        await ignoreMatchingSingleTransaction(transaction?.id, {
          matchingType: 'duplicate',
        });
        await onIgnoreMatchingTransaction(
          transaction?.duplicateMatchings?.[0]?.matchingTransactionId,
          {
            deletedTransactionIds: transactionsToDelete,
          },
        );
        if (deleteDuplicates) {
          await deleteTransactionsByIDs({
            ids: transactionsToDelete,
          });
        } else {
          await bulkExcludeTransactions({
            transactionIds: transactionsToDelete,
            excluded: true,
          });
        }
        // await reloadTransaction({});
        toast.success('Action performed successfully');
        setSingleLoader(false);
        reloadTransactions({ scrollToTop: false });
        setClose(false);
      } else {
        await ignoreMatchingSingleTransaction(transaction?.id, {
          matchingType: 'duplicate',
        });
        toast.success('Action performed successfully');
        setSingleLoader(false);
        reloadTransactions({ scrollToTop: false });
        setClose(false);
      }
    } catch (e) {
      setSingleLoader(false);
      console.log('error', e);
    }
  };

  return (
    <div>
      <div className="px-10">
        {!transaction?.duplicateMatchings?.length > 0 ? (
          <div
            className="text-[18px] text-slate-500 text-center  flex items-center justify-center"
            style={{
              maxHeight: 'calc(100dvh - 215px)',
              height: 'calc(100dvh - 215px)',
            }}
          >
            No Duplicate Transactions found
          </div>
        ) : (
          <div
            className="flex flex-col mb-6 overflow-auto scrollbar"
            style={{
              maxHeight: 'calc(100dvh - 332px)',
              height: 'calc(100dvh - 332px)',
            }}
          >
            <p className="mb-2">
              Identify and mark duplicate transactions to avoid errors and keep
              your books clean.
            </p>

            <div className=" grid md:grid-cols-2 gap-4 pb-4 pr-2">
              {transaction?.duplicateMatchings?.length > 0 && (
                <div
                  key={transaction?.id}
                  className="bg-indigo-500 bg-opacity-5 border border-indigo-500 p-4 rounded-[5px]"
                >
                  <label
                    className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
                    htmlFor={transaction?.id}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5"
                      id={transaction?.id}
                      onChange={() => onTransSelection(transaction?.id)}
                    />

                    <p className="text-[20px] text-[#101828]">
                      {transaction?.description || '--'}
                    </p>
                  </label>

                  <div className="pl-9 flex flex-col gap-y-2">
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Date: </p>
                      <p>
                        {moment(transaction?.authorizedDate).format('MMM DD')}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Amount: </p>
                      <p>
                        {transaction?.amount?.toString().charAt(0) === '-'
                          ? ''
                          : '-'}
                        {getCurrencySymbol(transaction?.currency)}
                        {transaction?.amount?.toString().charAt(0) === '-'
                          ? parseFloat(
                              transaction?.amount?.toString().replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : parseFloat(transaction?.amount)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24">Vendor: </p>
                      <p>{transaction?.vendor?.name || '--'}</p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24">Customer: </p>
                      <p className="break-words overflow-hidden">
                        {transaction?.customer?.customer || '--'}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {transaction?.duplicateMatchings?.map((t) => (
                <div
                  key={t?.matchedTransaction?.id}
                  className="border border-[#D0D5DD] p-4 rounded-[5px] "
                >
                  <label
                    className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
                    htmlFor={t?.matchedTransaction?.id}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5"
                      id={t?.matchedTransaction?.id}
                      onChange={() =>
                        onTransSelection(t?.matchedTransaction?.id)
                      }
                    />

                    <p className="text-[20px] text-[#101828]">
                      {t?.matchedTransaction?.description || '--'}
                    </p>
                  </label>

                  <div className="pl-9 flex flex-col gap-y-2">
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Date: </p>
                      <p>
                        {moment(t?.matchedTransaction?.authorizedDate).format(
                          'MMM DD',
                        )}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Amount: </p>
                      <p>
                        {t?.matchedTransaction?.amount?.toString().charAt(0) ===
                        '-'
                          ? ''
                          : '-'}
                        {getCurrencySymbol(t?.matchedTransaction?.currency)}
                        {t?.matchedTransaction?.amount?.toString().charAt(0) ===
                        '-'
                          ? parseFloat(
                              t?.matchedTransaction?.amount
                                ?.toString()
                                .replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : parseFloat(
                              t?.matchedTransaction?.amount,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24">Vendor: </p>
                      <p>{transaction?.vendor?.name || '--'}</p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24">Customer: </p>
                      <p className="break-words overflow-hidden">
                        {transaction?.customer?.customer || '--'}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {transaction?.duplicateMatchings?.length > 0 && (
        <footer className="px-10 py-6 border-t border-[#D0D5DD] flex justify-between">
          <div className="flex justify-end mb-2 pr-3">
            <label className=" cursor-pointer text-base font-medium text-nowrap flex items-center">
              <input
                type="checkbox"
                checked={deleteDuplicates}
                className="form-checkbox mr-2 h-5 w-5"
                onChange={() => setDeleteDuplicates((prev) => !prev)}
                // disabled={parentAccount?.id}
              />
              Delete Duplicate(s)
            </label>
          </div>
          <div className="flex gap-4 justify-end">
            <button
              type="button"
              onClick={onKeepAll}
              disabled={keepAllLoader || singleLoader}
              className="h-11 min-w-[90px] px-4 inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085] disabled:cursor-not-allowed disabled:opacity-60"
            >
              {keepAllLoader && (
                <svg
                  className="animate-spin w-4 h-4 fill-[#667085] shrink-0 mr-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Keep All
            </button>
            <button
              type="button"
              onClick={onKeepSelected}
              disabled={
                selectedTransactions?.length === 0 ||
                keepAllLoader ||
                singleLoader
              }
              className="h-11 px-7 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:bg-indigo-300 disabled:cursor-not-allowed"
            >
              {singleLoader && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              {deleteDuplicates
                ? 'Keep Selected & Delete Others'
                : 'Keep Selected & Exclude Others'}
            </button>
          </div>
        </footer>
      )}
    </div>
  );
};

export default DuplicatesTab;
