import React from 'react';
import { STATUS_OPTIONS } from 'pages/customers/CustomersTable';
import { INVOICE_DUE_DATE_OPTIONS } from 'pages/invoices';

const CDCustomerDetails = ({ clientDetails, setCustomerModalOpen }) => {
  const data = clientDetails?.customerRecord?.[0];

  return (
    <div className="w-[300px] min-w-[300px] border rounded-md bg-white h-fit">
      {/* Header Section */}
      <div className="flex justify-between items-center px-3 py-3 border-b">
        <p className="text-[#667085] font-normal text-base">Client Details</p>
        <button
          className="cursor-pointer"
          type="button"
          onClick={() => setCustomerModalOpen(true)}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
              fill="white"
              stroke="#D0D5DD"
              strokeOpacity="0.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
              stroke="#667085"
              strokeOpacity="0.8"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
              stroke="#667085"
              strokeOpacity="0.8"
              strokeWidth="1.3"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
              stroke="#858D9D"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {/* Mapped Data Section */}
      <div className="space-y-3 py-3">
        <div className="space-y-2">
          <div className="flex justify-between items-center px-3">
            <p className="text-sm text-slate-600 font-medium">Status:</p>
            <div
              className="h-7 rounded-[5px] capitalize bg-slate-100 inline-flex justify-center items-center px-2"
              style={{
                background: STATUS_OPTIONS?.find((s) => s?.id === data?.status)
                  ?.background,
                color: STATUS_OPTIONS?.find((s) => s?.id === data?.status)
                  ?.textColor,
              }}
            >
              {data?.status}
            </div>
          </div>
          <div className="px-3 ">
            <p className="text-sm text-slate-600 font-medium">
              Billing Address:
            </p>
            <p className="text-sm">
              {data?.billingAddress?.street}
              {data?.billingAddress?.street2
                ? `, ${data?.billingAddress?.street2}`
                : ''}{' '}
              {data?.billingAddress?.city}
              {data?.billingAddress?.state
                ? `, ${data?.billingAddress?.state}`
                : ''}
              {data?.billingAddress?.zipCode
                ? `, ${data?.billingAddress?.zipCode}`
                : ''}{' '}
              {data?.billingAddress?.country}
            </p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">
              Shipping Address:
            </p>
            <p className="text-sm">
              {data?.shippingAddress?.street}
              {data?.shippingAddress?.street2
                ? `, ${data?.shippingAddress?.street2}`
                : ''}{' '}
              {data?.shippingAddress?.city}
              {data?.shippingAddress?.state
                ? `, ${data?.shippingAddress?.state}`
                : ''}
              {data?.shippingAddress?.zipCode
                ? `, ${data?.shippingAddress?.zipCode}`
                : ''}{' '}
              {data?.shippingAddress?.country}
            </p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">Website:</p>
            <p className="text-sm">{data?.website || '--'}</p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">Tax ID:</p>
            <p className="text-sm">{data?.taxNumber || '--'}</p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">Payment Terms:</p>
            <p className="text-sm">
              {
                INVOICE_DUE_DATE_OPTIONS?.filter((opt) => opt?.id !== -1)
                  ?.map((opt) =>
                    opt?.id === 0 ? { ...opt, name: 'Due on receipt' } : opt,
                  )
                  ?.find((option) => option?.id === data?.paymentTerm)?.name
              }
            </p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">Sales Rep:</p>
            <p className="text-sm">
              {data?.salesRepId
                ? `${data?.salesRep?.firstName || ''} ${data?.salesRep?.lastName || ''}`
                : '--'}
            </p>
          </div>
          <div className="px-3  border-t pt-3">
            <p className="text-sm text-slate-600 font-medium">Full Name:</p>
            <p className="text-sm">
              {`${data?.contacts?.[0]?.firstName || ''} ${data?.contacts?.[0]?.lastName || ''}` ||
                '--'}
            </p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">Email:</p>
            <p className="text-sm">{data?.email || '--'}</p>
          </div>
          <div className="px-3">
            <p className="text-sm text-slate-600 font-medium">Mobile number:</p>
            <p className="text-sm">{data?.contacts?.[0]?.phone || '--'}</p>
          </div>

          <div className="px-3 border-t pt-3">
            <p className="text-sm text-slate-600 font-medium">Notes:</p>
            <p className="text-sm">{data?.notes || '--'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CDCustomerDetails;
