import React, { useEffect, useState } from 'react';
import {
  deleteAccountById,
  getAccounts,
  getAccountSubTypes,
  getAccountTypes,
  getTaxes,
} from 'API/backend_helper';
import { sortObjectsArray, transformAccounts } from 'utils/Utils';
import AccountsModal from 'pages/accounts/AccountsModal';
import { useSelector } from 'react-redux';
import SimpleAccountModal from 'pages/accounts/SimpleAccountModal';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import SearchForm from 'partials/actions/SearchForm';
import BasicConfirmationModal from 'components/BasicConfirmationModal';
import { toast } from 'react-toastify';
import AccountsTable from './AccountsTable';

const ChartOfAccounts = ({ updateOneStep }) => {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { currencies } = useSelector((state) => state.Currencies);

  const [isLoading, setIsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsDrop, setAllAccountsDrop] = useState([]);
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedParentAccount, setSelectedParentAccount] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [simpleModalOpen, setSimpleModalOpen] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [activeDeleteAccount, setActiveDeleteAccount] = useState({});
  const [accountDeleteLoading, setAccountDeleteLoading] = useState(false);

  const loadData = async (refreshData = false) => {
    if (!refreshData) {
      setIsLoading(true);
    }

    try {
      const res = await getAccounts({
        includeBalances: true,
      });

      setAllAccountsDrop(transformAccounts(res, 'allAccounts'));
      const tempAccounts = JSON.parse(JSON.stringify(res || []));
      const sorted = sortObjectsArray(tempAccounts, 'name', 'asc');
      setAllAccounts(sorted);
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      const subTypeResponse = await getAccountSubTypes();
      setTypes(typeResponse);
      setSubTypes(subTypeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteAccount = async (id) => {
    try {
      setAccountDeleteLoading(true);
      const res = await deleteAccountById(id);
      console.log('res', res);
      await loadData(true);
      setActiveDeleteAccount({});
      setDeleteAccountModalOpen(false);
      toast.success('Account deleted successfully.');
    } catch (err) {
      console.log('err', err);
    } finally {
      setAccountDeleteLoading(false);
    }
  };

  useEffect(() => {
    getAccountTypesApi();
    setSearchVal('');
    loadData();
    loadTaxes();
    setIsEditing(false);
    setSelectedAccount(null);
  }, []);

  return (
    <div>
      <AccountsModal
        isOpen={accountsModalOpen}
        setIsOpen={setAccountsModalOpen}
        accountTypes={types}
        accountSubTypes={subTypes}
        loadData={(arg1) => loadData(arg1, null)}
        account={selectedAccount}
        parentAccount={selectedParentAccount}
        setParentAccount={setSelectedParentAccount}
        edit={isEditing}
        currencies={currencies}
        mainType={null}
        subType={null}
        taxes={taxes}
        loadTaxes={loadTaxes}
        accounts={allAccountsDrop}
      />{' '}
      <SimpleAccountModal
        modalOpen={simpleModalOpen}
        setModalOpen={setSimpleModalOpen}
        account={selectedAccount}
        loadData={loadData}
        mainType={null}
      />
      <BasicConfirmationModal
        title="Delete Account"
        isOpen={deleteAccountModalOpen}
        setIsOpen={setDeleteAccountModalOpen}
        handleYes={() => handleDeleteAccount(activeDeleteAccount?.id)}
        loading={accountDeleteLoading}
      >
        <p className="text-base leading-6 text-slate-600 text-opacity-80">
          Are you sure want to delete{' '}
          <span className="font-medium">{activeDeleteAccount?.name}</span>{' '}
          account? This action cannot be undone.
        </p>
      </BasicConfirmationModal>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4 flex-wrap gap-4">
        <h1 className="text-black text-opacity-60 text-2xl font-medium leading-[2.375rem] mb-4 sm:mb-0">
          Chart of Accounts
        </h1>
        {checkPermission(
          PermissionKeys.MANAGE_ACCOUNTS,
          accessType,
          permissions,
        ) && (
          <button
            className="ml-auto inline-flex h-10 px-2 gap-1 items-center justify-center bg-indigo-500 text-white rounded-[5px]"
            onClick={(e) => {
              e.stopPropagation();
              setAccountsModalOpen(true);
            }}
            type="button"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className=""
            >
              <path
                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add Account
          </button>
        )}
      </div>
      <div className="flex justify-end mb-4">
        <SearchForm
          placeholder="Search"
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          transaction
          searchIconClassName="!text-[#66708580] !stroke-[#66708580]"
        />
      </div>
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <AccountsTable
          allAccounts={allAccounts}
          setIsEditing={setIsEditing}
          setSelectedAccount={setSelectedAccount}
          setAccountsModalOpen={setAccountsModalOpen}
          setSimpleModalOpen={setSimpleModalOpen}
          setDeleteAccountModalOpen={setDeleteAccountModalOpen}
          setActiveDeleteAccount={setActiveDeleteAccount}
          searchVal={searchVal}
          selectedTypes={[]}
          setSelectedParentAccount={setSelectedParentAccount}
        />
      )}
      <footer className="flex justify-between mt-6 gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
        <button
          type="button"
          className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={updateOneStep}
        >
          Next
        </button>
      </footer>
    </div>
  );
};

export default ChartOfAccounts;
