/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import WarningBox from 'components/WarningBox';
import ModalBasic from '../../components/ModalBasic';
import UploadFile from '../../components/UploadFile';
import { postParseReceipt } from '../../API/backend_helper';

const BillsChoiceModal = ({
  modalOpen,
  setModalOpen,
  setUploadedReceiptData,
  setReceiptForm,
  getMerchantsApi,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');

  useEffect(() => {
    if (!modalOpen) {
      setFiles([]);
      setImgPreview('');
    }
  }, [modalOpen]);

  const handleReceiptUpload = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('receipt', file);
    try {
      const res = await postParseReceipt(formData);
      if (res) {
        getMerchantsApi();
        setUploadedReceiptData(res);
        setReceiptForm(true);
        setModalOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  return (
    <ModalBasic
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title="Add Receipt"
    >
      <div className="w-full px-[30px] py-4 pb-6">
        <div className="flex flex-col gap-2.5">
          {isLoading && (
            <WarningBox
              isInfo
              content="Importing can take between 1-2 minutes. Please do not refresh the page"
            />
          )}
          <UploadFile
            onFilesAdded={(files) => {
              setFiles((prevFiles) => [...prevFiles, ...files]);
              if (files[0]) {
                setImgPreview(files[0].preview);
              }
              handleReceiptUpload(files[0]);
            }}
            name="receipt"
            id="receipt"
            imgPreview={imgPreview}
            setImgPreview={setImgPreview}
            files={files}
            setFiles={setFiles}
            pdf
            loading={isLoading}
            title="Import Receipt"
          />
          {/* {!isLoading && (
            <>
              <div className="w-full flex gap-2.5 items-center">
                <div className="h-px w-[90%] bg-[#D0D5DD]" />
                <p>Or</p>
                <div className="h-px w-[90%] bg-[#D0D5DD]" />
              </div>
              <div className="flex flex-col items-center">
                <div
                  className="text-indigo-500 text-center underline"
                  onClick={() => {
                    setReceiptForm(true);
                    // setUploadedReceiptData({});
                    setModalOpen(false);
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={null}
                >
                  Create a Receipt Manually
                </div>
              </div>
            </>
          )} */}
        </div>
      </div>
    </ModalBasic>
  );
};

export default BillsChoiceModal;
