import React from 'react';
import { Link } from 'react-router-dom';
import { formatValueWithSign } from 'utils/Utils';

const tableHeaders = {
  account: 'Account',
  totalSales: ' Total Sales',
  nonTaxSales: 'Non-Taxable Sales',
  taxableSales: 'Taxable Sales',
  taxRate: 'Tax Rate',
  taxCollected: 'Tax Collected',
  SalesTaxPayable: 'Sales Tax Payable',
};

const SaleTaxLiabilityTable = ({
  generatedTransaction,
  currencySymbol,
  selectedType,
  isPdf = false,
}) => (
  <div className="overflow-x-auto scrollbar overflow-y-hidden w-full">
    <table className="table-fixed min-w-full w-full dark:text-slate-300">
      <thead
        className={`w-full ${isPdf ? 'border-b-[2px]' : 'shadow-md'}   rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600`}
      >
        <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
          <th className="text-left w-[7rem] font-medium  px-4">
            <div className="">{tableHeaders.account}</div>
          </th>
          <th className="text-left w-[7rem] font-medium  px-4">
            <div className="">{tableHeaders.taxRate}</div>
          </th>
          <th className="w-[6rem] px-2">
            <div className="text-left text-wrap font-bold">
              {tableHeaders.taxableSales}
            </div>
          </th>

          <th className="w-[6rem] px-2">
            <div className="text-left font-bold">
              {tableHeaders.taxCollected}
            </div>
          </th>
          <th className="w-[6rem] px-2">
            <div className="text-left font-bold">
              {tableHeaders.SalesTaxPayable}
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
        {generatedTransaction?.categorized?.map((transaction) => (
          <tr
            className="w-full px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
            key={transaction?.id}
          >
            <td className=" px-4">
              <div className="text-left">
                {isPdf ? (
                  <div className="">{transaction?.tax?.name}</div>
                ) : (
                  <Link
                    to={`/reports/account-transaction?accountId=${transaction?.tax?.accountId}&startDate=${generatedTransaction?.filters?.startDate}&endDate=${generatedTransaction?.filters?.endDate}&currency=${generatedTransaction?.filters?.currency}${
                      generatedTransaction?.filters?.accounts?.length > 0
                        ? `&accounts=${generatedTransaction?.filters?.accounts?.join(
                            ',',
                          )}`
                        : ''
                    }&reportType=${selectedType}&from=sales-tax-liability`}
                    className="hover:underline text-indigo-500"
                  >
                    {transaction?.tax?.name}
                  </Link>
                )}
              </div>
            </td>
            <td className=" px-4">
              <div className="text-left">
                {parseFloat(transaction?.tax?.percentage || 0)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
                %
              </div>
            </td>
            <td className=" ">
              <div className="text-left px-2">
                {formatValueWithSign(
                  transaction?.taxableSales || 0,
                  false,
                  currencySymbol,
                )}
              </div>
            </td>
            <td className=" px-2">
              <div className="text-left">
                {formatValueWithSign(
                  transaction?.collectedAmount || 0,
                  false,
                  currencySymbol,
                )}
              </div>
            </td>
            <td className=" px-2">
              <div className="text-left">
                {formatValueWithSign(
                  transaction?.payableTax || 0,
                  false,
                  currencySymbol,
                )}
              </div>
            </td>
          </tr>
        ))}
        <tr className="w-full px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer font-semibold">
          <td className=" px-4">
            <div className="text-left">Total</div>
          </td>
          <td />
          <td className=" ">
            <div className="text-left px-2">
              {formatValueWithSign(
                generatedTransaction?.totalCollectedAndPayables?.taxableSales ||
                  0,
                false,
                currencySymbol,
              )}
            </div>
          </td>
          <td className=" px-2">
            <div className="text-left">
              {formatValueWithSign(
                generatedTransaction?.totalCollectedAndPayables
                  ?.collectedAmount || 0,
                false,
                currencySymbol,
              )}
            </div>
          </td>
          <td className=" px-2">
            <div className="text-left">
              {formatValueWithSign(
                generatedTransaction?.totalCollectedAndPayables?.payableTax ||
                  0,
                false,
                currencySymbol,
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default SaleTaxLiabilityTable;
