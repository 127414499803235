/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ModalBasic from '../../components/ModalBasic';

const ChecksChoiceModal = ({
  modalOpen,
  setModalOpen,
  BILL_CHECK,
  VENDOR_CHECK,
  setCheckForm,
  setCheckType,
}) => (
  <ModalBasic
    modalOpen={modalOpen}
    setModalOpen={setModalOpen}
    title="Create Check"
  >
    <div className="w-full px-[30px] py-4 pb-6">
      <div className="grid grid-cols-2 gap-4">
        <div
          className="text-indigo-500 text-center h-20 border border-indigo-500 inline-flex gap-2 justify-center rounded-[6px] hover:bg-indigo-500 hover:bg-opacity-5 font-medium items-center leading-tight transition-all"
          onClick={() => {
            setCheckType(BILL_CHECK);
            setCheckForm(true);
            setModalOpen(false);
          }}
          role="button"
          tabIndex="0"
          onKeyDown={null}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-current h-6 w-6"
          >
            <path
              d="M11 11.1667L14.5714 11.1667"
              stroke="#667085"
              strokeWidth="1.3"
              strokeLinecap="round"
              className="stroke-current"
            />
            <path
              d="M13.8571 9.7381L15.2857 11.1667L13.8571 12.5952"
              stroke="#667085"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-current"
            />
            <path
              d="M14.7003 8.19704V6.49979C14.7003 4.84293 13.3572 3.49979 11.7003 3.49979H4C2.34315 3.49979 1 4.84293 1 6.49978V8.71998C1 10.3768 2.34315 11.72 4 11.72H9.09564"
              stroke="#667085"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-current"
            />
            <circle
              cx="7.85016"
              cy="7.60988"
              r="1.37003"
              stroke="#667085"
              strokeWidth="1.3"
              className="stroke-current"
            />
          </svg>
          <span>Pay a Bill</span>
        </div>

        <div
          className="text-indigo-500 text-center h-20 border border-indigo-500 inline-flex gap-2 justify-center rounded-[6px] hover:bg-indigo-500 hover:bg-opacity-5 font-medium items-center leading-tight transition-all"
          onClick={() => {
            setCheckType(VENDOR_CHECK);
            setCheckForm(true);
            setModalOpen(false);
          }}
          role="button"
          tabIndex="0"
          onKeyDown={null}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-current h-6 w-6"
          >
            <path
              className="stroke-current"
              d="M17.0843 17.5V15.8333C17.0843 14.9493 16.7331 14.1014 16.108 13.4763C15.4829 12.8512 14.635 12.5 13.751 12.5H7.08431C6.20026 12.5 5.35241 12.8512 4.72729 13.4763C4.10217 14.1014 3.75098 14.9493 3.75098 15.8333V17.5"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              className="stroke-current"
              d="M10.4183 9.16667C12.2592 9.16667 13.7516 7.67428 13.7516 5.83333C13.7516 3.99238 12.2592 2.5 10.4183 2.5C8.57735 2.5 7.08496 3.99238 7.08496 5.83333C7.08496 7.67428 8.57735 9.16667 10.4183 9.16667Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>Pay a Vendor</span>
        </div>
      </div>
    </div>
  </ModalBasic>
);

export default ChecksChoiceModal;
