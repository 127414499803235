import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { statusOptions } from 'pages/projects';
import { useNavigate } from 'react-router-dom';
import AddProjectModal from 'pages/projects/AddProjectModal';

const ProjectsTab = ({
  projects,
  isProjectsTabLoaded,
  setIsProjectsTabLoaded,
  loadAllProjects,
  customer,
  WSId,
  handleCreateProject,
  accountantFirm,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);
  const [addProjectLoading, setAddProjectLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      await loadAllProjects({});
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isProjectsTabLoaded && customer?.id && WSId) {
      loadData();
      setIsProjectsTabLoaded(true);
    }
  }, [customer, isProjectsTabLoaded, WSId]);

  const onCreateProject = async (values) => {
    setAddProjectLoading(true);
    try {
      await handleCreateProject(values);
      setAddProjectModalOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setAddProjectLoading(false);
    }
  };

  return loading ? (
    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin w-10 h-10 fill-current shrink-0"
      >
        <path
          d="M14.5 2.41699V7.25033"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 21.749V26.5824"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 5.95703L9.37662 9.37662"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 19.6211L23.0426 23.0407"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.41699 14.5H7.25033"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.749 14.5H26.5824"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 23.0407L9.37662 19.6211"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 9.37662L23.0426 5.95703"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
        Fetching data, please wait...
      </p>
    </div>
  ) : (
    <div>
      {customer?.id && accountantFirm?.workspace?.id && (
        <AddProjectModal
          modalOpen={addProjectModalOpen}
          setModalOpen={setAddProjectModalOpen}
          customers={[customer]}
          setCustomers={() => {}}
          customerModalOpen={false}
          setCustomerModalOpen={() => {}}
          handleSubmit={onCreateProject}
          selectedCustomer={customer}
          modalLoading={addProjectLoading}
          selectedStatus={null}
          statusOptions={statusOptions}
          project={null}
          showCustomerDropdown={false}
          teamDefaultDateFormat={
            accountantFirm?.workspace?.defaults?.dateFormat
          }
        />
      )}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setAddProjectModalOpen(true)}
          type="button"
          className="btn-sm h-10 px-4 text-base bg-indigo-500 hover:bg-indigo-600 text-white
                             disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed  flex items-center justify-center  gap-2"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=""
          >
            <path
              d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add a Project
        </button>
      </div>
      <div className="w-full overflow-auto">
        <table className="table-fixed w-full dark:text-slate-300 divide-y divide-slate-200">
          {/* Table header */}
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[14rem] max-w-[14rem] whitespace-nowrap ">
                <div className="font-medium text-left">Name</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Status</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">Start Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 w-[7rem] max-w-[7rem] whitespace-nowrap">
                <div className="font-medium text-left">End Date</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          {projects?.length > 0 ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {projects.map((b) => (
                <tr
                  key={b?.id}
                  className={`w-full cursor-pointer px-[1.25rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5  `}
                  onClick={() => navigate(`/projects/${b?.id}?tab=overview`)}
                >
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    <span>{b?.name}</span>
                  </td>
                  <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    <div
                      className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
        
         `}
                      style={{
                        background: statusOptions?.find(
                          (status) => status?.id === b?.status,
                        )?.background,
                        color: statusOptions?.find(
                          (status) => status?.id === b?.status,
                        )?.textColor,
                      }}
                    >
                      {b?.status}
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {b?.startDate
                      ? moment(
                          b?.startDate?.split?.('T')?.[0],
                          'yyyy-MM-DD',
                        )?.format('MMM DD, YYYY')
                      : '--'}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left   ">
                    {b?.endDate
                      ? moment(
                          b?.endDate?.split?.('T')?.[0],
                          'yyyy-MM-DD',
                        )?.format('MMM DD, YYYY')
                      : '--'}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!projects?.length > 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Projects Found
          </h6>
        )}
      </div>
    </div>
  );
};

export default ProjectsTab;
