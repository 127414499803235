import React from 'react';
import ModalBasic from 'components/ModalBasic';
import { Loader } from 'components/Svg';

const TaskListDeleteModal = ({
  isOpen,
  setIsOpen,
  handleDeleteAction,
  deleteTaskListLoading = false,
}) => (
  <ModalBasic
    title="Delete Task List"
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    smallModal
  >
    <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
      <p className="text-base leading-6 text-slate-600 text-opacity-80">
        Warning, deleting a task list with also delete all tasks inside the
        list.
      </p>
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
        onClick={() => setIsOpen(false)}
        type="button"
        disabled={deleteTaskListLoading}
      >
        No
      </button>
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6 disabled:cursor-not-allowed disabled:bg-indigo-400"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteAction();
        }}
        type="button"
        disabled={deleteTaskListLoading}
      >
        {deleteTaskListLoading ? (
          <Loader color="#ffff" height="h-5 w-5" />
        ) : (
          'Yes'
        )}
      </button>
    </div>
  </ModalBasic>
);

export default TaskListDeleteModal;
