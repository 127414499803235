import React, { useEffect, useState } from 'react';
import AppLayout from 'components/AppLayout';
import {
  assignReceiptTransaction,
  getAccounts,
  getAllProjects,
  getCustomers,
  getExpenseTypes,
  getMatchedReceipts,
  getMerchants,
  getPendingUnmatchedReceipts,
  getTags,
  getTaxes,
} from 'API/backend_helper';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import { transformAccounts } from 'utils/Utils';
import TransactionDetails from 'pages/transactions/TransactionPanel';
import ReceiptModal from 'pages/transactions/ReceiptModal';
import ReceiptsTable from 'pages/receipts/ReceiptsTable';
import { toast } from 'react-toastify';
import TobTabs from './TobTabs';
import BillsChoiceModal from './ReceiptChoiceModal';
import AddEditReceipt from './AddEditReceipt';
import TransactionsModal from './AssignTransModal/TransactionsModal';

export const UNMATCHED_RECEIPTS_TAB = 'unmatchedReceipts';
export const MATCHED_RECEIPTS_TAB = 'matchedReceipts';

const Receipts = () => {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { currencies } = useSelector((state) => state.Currencies);
  const { team } = useSelector((state) => state.Team);

  const [activeTab, setActiveTab] = useState(UNMATCHED_RECEIPTS_TAB);
  const [isLoading, setIsLoading] = useState(false);
  const [isMatchLoading, setIsMatchLoading] = useState(false);

  // Unmatched Receipts
  const [unmatchedReceipts, setUnmatchedReceipts] = useState({});
  const [unmatchedReceiptsLimit, setUnmatchedReceiptsLimit] = useState(50);
  const [unmatchedReceiptsSortValues, setUnmatchedReceiptsSortValues] =
    useState({
      sortBy: '',
      order: '',
    });

  // matched Receipts
  const [matchedReceipts, setMatchedReceipts] = useState({});
  const [matchedReceiptsLimit, setMatchedReceiptsLimit] = useState(50);
  const [matchedReceiptsSortValues, setMatchedReceiptsSortValues] = useState({
    sortBy: '',
    order: '',
  });

  const [searchMatchedValue, setSearchMatchedValue] = useState('');
  const [searchUnmatchedValue, setSearchUnmatchedValue] = useState('');

  // Add Receipt Helping Data
  const [vendors, setVendors] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);

  // Add Receipt Logic
  const [addReceiptChoiceModalOpen, setAddReceiptChoiceModalOpen] =
    useState(false);
  const [receiptForm, setReceiptForm] = useState(false);
  const [billModalPdfView, setBillModalPdfView] = useState(false);
  const [uploadedReceiptData, setUploadedReceiptData] = useState(null);
  const [editReceiptData, setEditReceiptData] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState([]);

  // Transaction Panel Helpling Data
  const [currentTab, setCurrentTab] = useState('details');
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowDetailsOpen, setRowDetailsOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);

  // Assigning Transaction
  const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [transactionLocalLimit, setTransactionsLocalLimit] = useState(7);

  const [receiptModalOpen, setReceiptModalOpen] = useState(false);

  const getAllAccounts = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setAllExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      setAllAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
    } catch (err) {
      console.log('err', err);
    }
  };

  const getMerchantsApi = async () => {
    try {
      const res = await getMerchants({ status: 'active', limit: 100000 });
      setVendors(res?.vendors);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadExpenseTypes = async () => {
    try {
      const res = await getExpenseTypes();
      setExpenseTypes(res?.data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({ status: 'active' });
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTags = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAllProjects = async () => {
    try {
      const res = await getAllProjects({});
      setProjects(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    getAllAccounts();
    getMerchantsApi();
    loadExpenseTypes();
    getCustomersApi();
    loadTaxes();
    loadTags();
    loadAllProjects();
  }, []);

  const loadUnmatchedReceipts = async ({
    page = unmatchedReceipts?.page || 1,
    limit = unmatchedReceiptsLimit,
    loading = true,
    search = '',
    order = unmatchedReceiptsSortValues?.order || '',
    sortBy = unmatchedReceiptsSortValues?.sortBy || '',
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getPendingUnmatchedReceipts({
        page,
        limit,
        search,
        order,
        sortBy,
      });
      setUnmatchedReceipts(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMatchedReceipts = async ({
    page = unmatchedReceipts?.page || 1,
    limit = unmatchedReceiptsLimit,
    loading = true,
    search = '',
    order = matchedReceiptsSortValues?.order || '',
    sortBy = matchedReceiptsSortValues?.sortBy || '',
  }) => {
    if (loading) {
      setIsMatchLoading(true);
    }
    try {
      const res = await getMatchedReceipts({
        page,
        limit,
        matched: true,
        search,
        order,
        sortBy,
      });
      setMatchedReceipts(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsMatchLoading(false);
    }
  };

  useEffect(() => {
    loadUnmatchedReceipts({ loading: true });
    loadMatchedReceipts({ loading: true });
  }, []);

  document.title = 'COUNT | Receipts';

  const handleTransactionAssignment = async (
    transactionId = activeTransaction?.id,
  ) => {
    try {
      const res = await assignReceiptTransaction(transactionsModalOpen?.id, {
        transactionId,
      });
      if (res) {
        loadUnmatchedReceipts({ loading: false });
        loadMatchedReceipts({ loading: false });
        setTransactionsModalOpen(null);
      }
      toast.success('Transaction assigned successfully');
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <>
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <BillsChoiceModal
        modalOpen={addReceiptChoiceModalOpen}
        setModalOpen={setAddReceiptChoiceModalOpen}
        setUploadedReceiptData={setUploadedReceiptData}
        setReceiptForm={setReceiptForm}
        getMerchantsApi={getMerchantsApi}
      />
      <TransactionsModal
        isOpen={transactionsModalOpen}
        setIsOpen={setTransactionsModalOpen}
        transactions={[]}
        transactionsSearch=""
        activeTransaction={activeTransaction}
        // activeInstance={
        //   allBills?.bills?.[activeBillIndex]?.instances?.[activeInstanceIndex]
        // }
        setActiveTransaction={setActiveTransaction}
        localLimit={transactionLocalLimit}
        setLocalLimit={setTransactionsLocalLimit}
        handleTransactionAssignment={handleTransactionAssignment}
        updateInstanceTotal={() => {}}
        allCategories={allCategories}
        currencies={currencies}
      />
      {selectedRow?.id && (
        <TransactionDetails
          transactionPanelOpen={rowDetailsOpen}
          setTransactionPanelOpen={setRowDetailsOpen}
          transaction={selectedRow}
          setTransaction={setSelectedRow}
          tags={tags}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          allCategories={allCategories}
          vendors={vendors || []}
          setVendors={setVendors}
          accessType={accessType}
          setReceiptModalOpen={setReceiptModalOpen}
          setActiveReceipt={setActiveReceipt}
          accounts={allAccounts}
          setFromDropdown={setFromDropdown}
          fromDropdown={fromDropdown}
          allExpenseCategories={allExpenseCategories}
          customers={customers}
          team={team}
          currencies={currencies}
          taxes={taxes}
          loadTaxes={loadTaxes}
          journalEntry
          projects={projects}
        />
      )}
      <AppLayout
        mainClass="relative"
        showSidebar={!billModalPdfView}
        showNavLogo={billModalPdfView}
        pageId="receiptsPageContainer"
      >
        <main className="grow relative">
          <div
            className={`${billModalPdfView ? 'max-w-[2000px]' : 'max-w-9xl'} px-4 sm:px-6 lg:px-8 py-8 w-full  mx-auto h-full flex flex-col`}
          >
            {receiptForm ? (
              <AddEditReceipt
                setIsActive={setReceiptForm}
                uploadedBillData={uploadedReceiptData}
                billModalPdfView={billModalPdfView}
                setBillModalPdfView={setBillModalPdfView}
                vendors={vendors}
                setVendors={setVendors}
                allAccounts={allAccounts}
                currencies={currencies}
                loadUnmatchedReceipts={loadUnmatchedReceipts}
                expenseTypes={expenseTypes}
                editReceiptData={editReceiptData}
                setEditReceiptData={setEditReceiptData}
                setUploadedBillData={setUploadedReceiptData}
                allCategories={allCategories}
                projects={projects}
                tags={tags}
                getMerchantsApi={getMerchantsApi}
              />
            ) : (
              <>
                {/* Page header */}
                <div className="flex justify-between items-center mb-10">
                  <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                    Receipts
                  </h1>
                  <div className="sm:flex gap-4 items-center">
                    {checkPermission(
                      PermissionKeys.MANAGE_EXPENSE_CLAIMS,
                      accessType,
                      permissions,
                    ) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setAddReceiptChoiceModalOpen(true);
                        }}
                        type="button"
                        className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                            stroke="white"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Add Receipt
                      </button>
                    )}
                  </div>
                </div>
                <TobTabs
                  activeTab={activeTab}
                  setActiveTab={(t) => {
                    setActiveTab(t);
                    // getData({
                    //   type: t === INVOICES_TAB ? "invoice" : "estimate",
                    //   loading: true,
                    // });
                    // onSetActiveTab(t);
                  }}
                  MATCHED_RECEIPTS_TAB={MATCHED_RECEIPTS_TAB}
                  UNMATCHED_RECEIPTS_TAB={UNMATCHED_RECEIPTS_TAB}
                />
                {activeTab === UNMATCHED_RECEIPTS_TAB && (
                  <ReceiptsTable
                    unmatchedReceipts={unmatchedReceipts}
                    unmatchedReceiptsLimit={unmatchedReceiptsLimit}
                    setUnmatchedReceiptsLimit={setUnmatchedReceiptsLimit}
                    loadUnmatchedReceipts={loadUnmatchedReceipts}
                    setEditReceiptData={(data) => {
                      setEditReceiptData(data);
                      setReceiptForm(true);
                    }}
                    isLoading={isLoading}
                    searchValue={searchUnmatchedValue}
                    setSearchValue={setSearchUnmatchedValue}
                    tableSortValues={unmatchedReceiptsSortValues}
                    setTableSortValues={setUnmatchedReceiptsSortValues}
                    setTransactionsModalOpen={setTransactionsModalOpen}
                    loadOtherReceipt={loadMatchedReceipts}
                  />
                )}
                {activeTab === MATCHED_RECEIPTS_TAB && (
                  <ReceiptsTable
                    unmatchedReceipts={matchedReceipts}
                    unmatchedReceiptsLimit={matchedReceiptsLimit}
                    setUnmatchedReceiptsLimit={setMatchedReceiptsLimit}
                    loadUnmatchedReceipts={loadMatchedReceipts}
                    isMatching
                    setSelectedRow={setSelectedRow}
                    setRowDetailsOpen={setRowDetailsOpen}
                    isLoading={isMatchLoading}
                    searchValue={searchMatchedValue}
                    setSearchValue={setSearchMatchedValue}
                    tableSortValues={matchedReceiptsSortValues}
                    setTableSortValues={setMatchedReceiptsSortValues}
                    loadOtherReceipt={loadUnmatchedReceipts}
                  />
                )}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};
export default Receipts;
