import DropdownFull from 'components/DropdownFull';
import SearchForm from 'partials/actions/SearchForm';
import React, { useEffect, useState } from 'react';
import WarningBox from 'components/WarningBox';
import { updateTransactionCategory } from 'API/backend_helper';
import Tooltip from 'components/Tooltip';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { sortObjectsArray } from 'utils/Utils';
import { useSelector } from 'react-redux';
import CategoryBox from './CategoryBox';
import { TAB_NAMES } from '..';

const CategoryTab = ({
  transaction,
  reloadTransaction,
  reloadTransactions,
  setAccountModalOpen,
  setNoClick,
  setClose,
  isOpen,
  allCategoriesIncome,
  allCategoriesExpense,
  accountTypes,
  newAddedCategory,
  setNewAddedCategory,
  activeTab,
  handlePopupLogic,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [searchVal, setSearchVal] = useState('');
  const [sortBy, setSortBy] = useState('Name');
  const [assignLoading, setAssignLoading] = useState(false);
  const [localCategories, setLocalCategories] = useState([]);
  const [filteredCateogories, setFilteredCateogories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryObj, setSelectedCategoryObj] = useState(null);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [type, setType] = useState('');

  useEffect(() => {
    if (
      (allCategoriesIncome?.length > 0 || allCategoriesExpense?.length > 0) &&
      isOpen
    ) {
      let temp = [];
      (isOpen?.type === 'Income'
        ? allCategoriesIncome
        : allCategoriesExpense
      )?.forEach((cat) => {
        temp.push(...(cat?.categories || []));
      });
      if (isOpen?.categoryAccount?.status === 'inactive') {
        temp = [{ ...isOpen?.categoryAccount }, ...temp];
      }
      setSelectedCategoryObj(
        temp?.find((cat) => cat?.id === isOpen?.categoryAccountId),
      );
      temp = sortObjectsArray(temp, 'type', 'asc', 'accountNumber');
      setLocalCategories(temp);
      setFilteredCateogories(temp);
    }
  }, [allCategoriesIncome, allCategoriesExpense, isOpen, activeTab]);

  useEffect(() => {
    if (
      activeTab === TAB_NAMES?.categorize &&
      localCategories?.length > 0 &&
      isOpen?.categoryAccountId === selectedCategory
    ) {
      const scrollToElement = () => {
        const element = document.getElementById(isOpen?.categoryAccountId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      };

      // Wait for the DOM to render fully before scrolling
      const timeout = setTimeout(scrollToElement, 100);
      return () => clearTimeout(timeout);
    }
  }, [activeTab, localCategories, isOpen?.id, selectedCategory]);

  useEffect(() => {
    if (isOpen) {
      const inputElement = document.getElementById(
        'category-modal-action-form',
      );
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (transaction?.id) {
      // if (
      //   transaction?.categoryAccountId &&
      //   selectedCategory !== transaction?.categoryAccountId
      // ) {
      // const element = document.getElementById(transaction?.categoryAccountId);
      // if (element) {
      //   element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // }
      // }
      if (localCategories?.length > 0) {
        const cat = localCategories.reduce((acc, category) => {
          // Check if the category itself matches the selectedCategory
          if (category?.id === transaction?.categoryAccountId) {
            acc = category; // Return the whole category if it matches
          }

          // Check if any subAccount matches the selectedCategory
          const subAccountMatch = category?.subAccounts?.find(
            (subAccount) => subAccount?.id === transaction?.categoryAccountId,
          );

          if (subAccountMatch) {
            acc = subAccountMatch; // Return the subAccount if it matches
          }

          return acc;
        }, null);
        if (cat) {
          if (transaction?.taxes?.length > 0 && cat?.taxes?.length > 0) {
            const tempTaxes = [];
            cat?.taxes?.forEach((t) => {
              const tax = transaction?.taxes?.find((_t) => _t?.id === t?.id);
              if (tax) {
                tempTaxes?.push(tax?.id);
              }
            });
            if (tempTaxes?.length > 0) {
              setSelectedTaxes(tempTaxes);
            }
          }
        }
      }
    }
  }, [transaction, allCategoriesIncome, allCategoriesExpense]);

  useEffect(() => {
    if (!isOpen) {
      setSearchVal('');
      setSortBy('Name');
      setLocalCategories([]);
      setFilteredCateogories([]);
      setSelectedCategory(null);
      setSelectedCategoryObj(null);
      setType('');
    } else {
      setSelectedCategory(isOpen?.categoryAccountId);
    }
  }, [isOpen]);

  const onNewCategoryAdd = async () => {
    setAssignLoading(true);
    try {
      const data = {
        categoryAccountId: newAddedCategory?.id,
      };
      await updateTransactionCategory(transaction?.id, data);
      await reloadTransaction({});
      setNewAddedCategory(null);
      setAssignLoading(false);
      reloadTransactions({ scrollToTop: false });
    } catch (e) {
      setAssignLoading(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (newAddedCategory?.id) {
      onNewCategoryAdd();
    }
  }, [newAddedCategory]);

  const updateFilters = (
    newType = type,
    newSortBy = sortBy,
    newSearchVal = searchVal,
  ) => {
    let filteredCategories = [...localCategories];

    // Filter by Type
    if (newType !== 'All' && newType !== '') {
      filteredCategories = filteredCategories?.filter(
        (cat) => cat?.type === newType,
      );
    }

    // Sort Categories
    if (newSortBy === 'Name') {
      filteredCategories?.sort((a, b) => a?.name?.localeCompare(b?.name));
    } else if (newSortBy === 'Type') {
      filteredCategories?.sort((a, b) => a?.type?.localeCompare(b?.type));
    }

    // Search Filter
    if (newSearchVal?.trim()) {
      const formattedValue = newSearchVal.replace(/\s/g, '').toLowerCase();

      filteredCategories = filteredCategories
        ?.map((category) => {
          const categoryNumberString =
            category?.accountNumber?.toString() || '';

          // Check if category name or account number matches
          const categoryNameMatch =
            category?.name
              ?.replace(/\s/g, '')
              .toLowerCase()
              .includes(formattedValue) ||
            categoryNumberString.includes(formattedValue);

          // Filter sub-accounts to only include the ones that match
          const matchingSubAccounts = category?.subAccounts?.filter(
            (subAccount) => {
              const subAccountNumberString =
                subAccount?.accountNumber?.toString() || '';
              return (
                subAccount?.name
                  ?.replace(/\s/g, '')
                  .toLowerCase()
                  .includes(formattedValue) ||
                subAccountNumberString.includes(formattedValue)
              );
            },
          );

          // If the category itself matches OR at least one sub-account matches, return the modified category
          if (categoryNameMatch || matchingSubAccounts.length > 0) {
            return {
              ...category,
              subAccounts:
                matchingSubAccounts.length > 0
                  ? matchingSubAccounts
                  : category.subAccounts,
            };
          }

          return null; // Exclude categories that don’t match at all
        })
        .filter(Boolean); // Remove null entries
    }

    setType(newType);
    setSortBy(newSortBy);
    setSearchVal(newSearchVal);
    setFilteredCateogories(filteredCategories);
  };

  const onTypeChange = (id) => updateFilters(id, sortBy, searchVal);
  const onSortBy = (id) => updateFilters(type, id, searchVal);
  const onSearch = (val) => updateFilters(type, sortBy, val);

  const onCategorySelect = (id, category) => {
    if (id !== selectedCategory) {
      if (id === isOpen?.categoryAccountId) {
        setSelectedTaxes(isOpen?.taxes?.map((tax) => tax?.id));
      } else if (
        id !== isOpen?.categoryAccountId &&
        category?.taxes?.length > 0
      ) {
        setSelectedTaxes(category?.taxes?.map((tax) => tax?.id));
      } else {
        setSelectedTaxes([]);
      }
      setSelectedCategory(id);
      setSelectedCategoryObj(category);
    }
  };

  const onSelectTaxes = (taxId) => {
    const temp = [...selectedTaxes];
    if (temp?.includes(taxId)) {
      const filter = temp?.filter((id) => id !== taxId);
      setSelectedTaxes(filter);
    } else {
      temp?.push(taxId);
      setSelectedTaxes(temp);
    }
  };

  const onSubmit = async () => {
    setAssignLoading(true);
    try {
      const cat = localCategories.reduce((acc, category) => {
        // Check if the category itself matches the selectedCategory
        if (category?.id === selectedCategory) {
          acc = category; // Return the whole category if it matches
        }

        // Check if any subAccount matches the selectedCategory
        const subAccountMatch = category?.subAccounts?.find(
          (subAccount) => subAccount?.id === selectedCategory,
        );

        if (subAccountMatch) {
          acc = subAccountMatch; // Return the subAccount if it matches
        }

        return acc;
      }, null);

      const data = {
        categoryAccountId: selectedCategory,
      };
      if (selectedTaxes?.length > 0) {
        if (transaction?.taxes?.length > 0) {
          let transTax = transaction?.taxes?.map((t) => t?.id);
          if (cat?.taxes?.length > 0) {
            const filteredTransTax = transTax?.filter((tId) => {
              const findTax = cat?.taxes?.find((t) => t?.id === tId);
              if (findTax) {
                return false;
              }
              return true;
            });
            transTax = filteredTransTax;
          }
          const temp = [...selectedTaxes, ...transTax];
          // Removing duplicates by ID
          const uniqueTaxes = [...new Set(temp)];

          data.taxes = uniqueTaxes;
        } else {
          data.taxes = selectedTaxes;
        }
      }
      await updateTransactionCategory(transaction?.id, data);
      if (cat) {
        handlePopupLogic(cat, transaction?.description);
      }
      // await reloadTransaction({});
      setAssignLoading(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setAssignLoading(false);
      console.log('error', e);
    }
  };

  return (
    <div>
      <div className="px-10">
        <div className="flex items-center gap-4 mb-6 ">
          <DropdownFull
            options={[
              {
                id: 'name',
                name: 'Name',
              },
              {
                id: 'type',
                name: 'Type',
              },
            ]}
            name="name"
            setSelected={(name) => onSortBy(name)}
            selected={sortBy}
            accounts
            accountStyle
            placeholder="Sort By"
            height="!h-11 "
            noMinW
            className="!w-[120px]"
          />
          <DropdownFull
            options={[
              {
                id: '',
                name: 'All',
              },
              ...(Array.isArray(accountTypes)
                ? accountTypes.map((acc) => ({
                    id: acc?.name,
                    name: acc?.name,
                  }))
                : []),
            ]}
            name="name"
            setSelected={(name) => onTypeChange(name)}
            selected={type}
            accounts
            accountStyle
            placeholder="Filter"
            height="!h-11 "
            className="!w-[120px]"
            noMinW
          />
          <div className="w-[1px] h-8 bg-[#D0D5DD80]" />
          <SearchForm
            searchVal={searchVal}
            setSearchVal={onSearch}
            placeholder="Search"
            height="!h-11 !w-full"
            className="flex-1 !w-full"
            id="category-modal-action-form"
          />
        </div>
        <div
          className="relative flex flex-col gap-3 mb-6 overflow-auto scrollbar pr-2"
          style={{
            maxHeight: 'calc(100dvh - 400px)',
            height: 'calc(100dvh - 400px)',
          }}
        >
          {transaction?.linkedTransferTransaction?.id && (
            <WarningBox
              className="mb-5 mt-1"
              content={`Categorizing this Transfer Type transaction will break its link with the associated transaction, '${transaction?.linkedTransferTransaction?.description || '...'}'. Proceed only if you understand the impact`}
            />
          )}
          {filteredCateogories?.length > 0 ? (
            filteredCateogories?.map((cat) => (
              <>
                <CategoryBox
                  category={cat}
                  key={cat?.id}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedTaxes={selectedTaxes}
                  setSelectedTaxes={setSelectedTaxes}
                  onCategorySelect={onCategorySelect}
                  onSelectTaxes={onSelectTaxes}
                />
                {cat?.subAccounts?.length > 0 && (
                  <div className="ml-8 space-y-3">
                    {cat?.subAccounts?.map((subAcc) => (
                      <CategoryBox
                        category={subAcc}
                        key={subAcc?.id}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        selectedTaxes={selectedTaxes}
                        setSelectedTaxes={setSelectedTaxes}
                        onCategorySelect={onCategorySelect}
                        onSelectTaxes={onSelectTaxes}
                      />
                    ))}
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="flex items-center justify-center h-[200px] w-full">
              No Categories Found
            </div>
          )}
          {selectedCategoryObj?.taxes?.length > 0 && (
            <div
              className="mt-auto shadow-lg sticky bottom-0 left-0 w-full h-auto bg-white z-10 border border-[#D0D5DD] border-b-0 rounded-b-none p-4 pt-2 rounded-[6px]"
              style={{
                boxShadow: ' 0px -5px 10px 1px rgba(0,0,0,0.15)',
              }}
            >
              <h3 className="text-[16px] text-[#101828] mb-2 mt-1">
                Apply Tax?
              </h3>
              <div className="grid sm:grid-cols-2 gap-y-1 gap-x-2">
                {selectedCategoryObj?.taxes?.map((tax) => (
                  <label
                    key={tax?.id}
                    htmlFor={`${isOpen?.id}${tax?.id}`}
                    className="w-fit text-sm text-[#667085] flex items-center gap-4 cursor-pointer mb-1.5"
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5"
                      id={`${isOpen?.id}${tax?.id}`}
                      checked={selectedTaxes?.includes(tax?.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        onSelectTaxes(tax?.id);
                      }}
                    />

                    <p>
                      {tax?.name} ({+tax?.percentage}%)
                    </p>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <footer className="px-10 py-6 border-t border-[#D0D5DD]">
        <div className="flex gap-4 justify-between flex-wrap">
          <button
            type="button"
            className="h-11 w-[220px] inline-flex items-center justify-center gap-2 border border-indigo-500 rounded-[5px] text-indigo-500"
            onClick={() => {
              setNoClick(true);
              setAccountModalOpen(true);
            }}
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 8.83398C14.25 9.0329 14.171 9.22366 14.0303 9.36431C13.8897 9.50497 13.6989 9.58398 13.5 9.58398H8.75V14.334C8.75 14.5329 8.67098 14.7237 8.53033 14.8643C8.38968 15.005 8.19891 15.084 8 15.084C7.80109 15.084 7.61032 15.005 7.46967 14.8643C7.32902 14.7237 7.25 14.5329 7.25 14.334V9.58398H2.5C2.30109 9.58398 2.11032 9.50497 1.96967 9.36431C1.82902 9.22366 1.75 9.0329 1.75 8.83398C1.75 8.63507 1.82902 8.44431 1.96967 8.30365C2.11032 8.163 2.30109 8.08398 2.5 8.08398H7.25V3.33398C7.25 3.13507 7.32902 2.94431 7.46967 2.80365C7.61032 2.663 7.80109 2.58398 8 2.58398C8.19891 2.58398 8.38968 2.663 8.53033 2.80365C8.67098 2.94431 8.75 3.13507 8.75 3.33398V8.08398H13.5C13.6989 8.08398 13.8897 8.163 14.0303 8.30365C14.171 8.44431 14.25 8.63507 14.25 8.83398Z"
                fill="#E48642"
              />
            </svg>
            Add New Category
          </button>
          <div className="flex gap-4 ml-auto">
            <button
              type="button"
              onClick={() => setClose(null)}
              className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085]"
            >
              Close
            </button>
            <Tooltip
              content={` ${
                transaction?.reconciled
                  ? 'Cannot be edited while reconciled'
                  : transaction?.billTransaction?.billInstanceId
                    ? 'Cannot be edited while assigned to a bill'
                    : transaction?.invoiceTransaction?.invoiceId
                      ? 'Cannot be edited while assigned to an invoice'
                      : transaction?.pending
                        ? 'Cannot be edited while pending'
                        : transaction?.excluded
                          ? 'Cannot be edited once excluded'
                          : 'Cannot be edited once reviewed'
              }`}
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
              size="sm"
              tooltipShow={
                transaction?.reviewed ||
                transaction?.billTransaction?.billInstanceId ||
                transaction?.invoiceTransaction?.invoiceId ||
                transaction?.pending ||
                transaction?.excluded
              }
              ids={['transactionsCategoryModal']}
              isFixed
            >
              <button
                type="button"
                disabled={
                  (isOpen?.categoryAccountId === selectedCategory &&
                    JSON.stringify(selectedTaxes?.sort()) ===
                      JSON.stringify(
                        isOpen?.taxes?.map((t) => t?.id)?.sort() || [],
                      )) ||
                  assignLoading ||
                  transaction?.pending ||
                  transaction?.excluded ||
                  transaction?.reviewed ||
                  transaction?.billTransaction?.billInstanceId ||
                  transaction?.invoiceTransaction?.invoiceId ||
                  transaction?.reconciled ||
                  !checkPermission(
                    PermissionKeys.MANAGE_TRANSACTIONS,
                    accessType,
                    permissions,
                  )
                }
                onClick={onSubmit}
                className="h-11 w-[180px] inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:bg-indigo-300 disabled:cursor-not-allowed"
              >
                {assignLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Assign Category
              </button>
            </Tooltip>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CategoryTab;
