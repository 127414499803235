import React, { useState } from 'react';
import AddTaskListModal from 'pages/inbox/TaskLists/AddTaskListModal';
import TaskListTasksTable from 'pages/inbox/TaskLists/TaskListTasksTable';
import DropdownFull from 'components/DropdownFull';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import SearchForm from 'partials/actions/SearchForm';
import useBasicDropdownSearch from 'hooks/useBasicDropdownSearch';
import TaskListActionsDropdown from 'pages/inbox/TaskLists/TaskListActionsDropdown';
import { deleteTaskListById, deleteTaskListTemplate } from 'API/backend_helper';
import { toast } from 'react-toastify';
import TaskListDeleteModal from 'pages/inbox/TaskLists/TaskListDeleteModal';
import TaskListsKanban from 'pages/inbox/TaskLists/TaskListsKanban';
import TasksListView from 'pages/inbox/TasksListView';
import InlineTaskModal from 'pages/inbox/TaskLists/InlineTaskModal';
import { FIRM_ID } from 'utils/Utils';
import TaskListTemplate from 'pages/inbox/TaskLists/TaskListTemplate';
import TaskListTemplateTable from 'pages/inbox/TaskLists/TaskListTemplateTable';
import TaskListTemplateModal from 'pages/inbox/TaskLists/TaskListTemplateModal';
import BasicConfirmationModal from 'components/BasicConfirmationModal';
import Tooltip from 'components/Tooltip';

export const TaskList = ({
  taskList,
  setSelectedTaskList,
  setAddTaskModalOpen,
  children,
  handleActionDropdown = () => {},
  showActionsDropdown = true,
  accountantWS = {},
  clients = [],
  Options = [
    {
      id: 'edit',
      period: 'Edit Task List',
    },
    {
      id: 'delete',
      period: 'Delete Task List',
    },
    {
      id: 'template',
      period: 'Save as Template',
    },
  ],
}) => {
  const [showTable, setShowTable] = useState(true);

  return (
    <>
      <div className="p-4 bg-[#F9FBFC] rounded-[12px]">
        <div className="border border-[#D0D5DD] rounded-[8px] p-4 bg-white flex justify-between items-start flex-wrap gap-4 gap-y-3">
          <div>
            <div className="flex gap-2 items-start">
              <button
                type="button"
                className="h-8"
                onClick={() => setShowTable((prev) => !prev)}
              >
                <svg
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${!showTable ? 'rotate-180' : ''}`}
                >
                  <path
                    d="M13.9425 6.55746L7.69254 0.307465C7.63449 0.249354 7.56556 0.203255 7.48969 0.171802C7.41381 0.140349 7.33248 0.124161 7.25035 0.124161C7.16821 0.124161 7.08688 0.140349 7.01101 0.171802C6.93514 0.203255 6.86621 0.249354 6.80816 0.307465L0.55816 6.55746C0.440885 6.67474 0.375 6.8338 0.375 6.99965C0.375 7.1655 0.440885 7.32456 0.55816 7.44184C0.675435 7.55912 0.834495 7.625 1.00035 7.625C1.1662 7.625 1.32526 7.55912 1.44253 7.44184L7.25035 1.63325L13.0582 7.44184C13.1162 7.49991 13.1852 7.54597 13.261 7.5774C13.3369 7.60883 13.4182 7.625 13.5003 7.625C13.5825 7.625 13.6638 7.60883 13.7397 7.5774C13.8155 7.54597 13.8845 7.49991 13.9425 7.44184C14.0006 7.38377 14.0467 7.31483 14.0781 7.23896C14.1095 7.16309 14.1257 7.08177 14.1257 6.99965C14.1257 6.91753 14.1095 6.83621 14.0781 6.76034C14.0467 6.68447 14.0006 6.61553 13.9425 6.55746Z"
                    fill="#667085"
                  />
                </svg>
              </button>
              <div>
                <div className="flex gap-2 items-center">
                  <h3 className="text-[18px] font-medium text-[#667085]">
                    {taskList?.name === 'Uncategorized'
                      ? 'No List'
                      : taskList?.name}
                  </h3>
                  <div className="bg-[#F4F4F4] rounded-[4px] leading-tight py-1 px-2 w-fit text-sm text-[#667085] font-medium">
                    {taskList?.tasks?.length || 0}
                  </div>
                </div>
                <h3 className="text-[#101828] text-sm mt-1">
                  {taskList?.teamId === accountantWS?.id
                    ? accountantWS?.name
                    : clients?.find((c) => c?.id === taskList?.teamId)?.name ||
                      '--'}
                  {taskList?.projectId && (
                    <>
                      <div className="inline-block mx-2 h-4 w-px bg-[#10182880] translate-y-[3px]" />
                      <span className="">
                        {taskList?.project?.name || '--'}
                      </span>
                    </>
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="ml-auto flex items-center gap-4 mt-[2px]">
            <button
              type="button"
              className="text-base  h-[30px] rounded-[4px] border border-indigo-500 text-indigo-500 inline-flex gap-1.5 items-center w-[112px] justify-center"
              onClick={() => {
                setSelectedTaskList(taskList);
                setAddTaskModalOpen(true);
              }}
            >
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 1.125V9.875M0.875 5.5H9.625"
                  stroke="#E48642"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Add Task
            </button>
            {showActionsDropdown && taskList?.id && (
              <TaskListActionsDropdown
                handleOption={handleActionDropdown}
                buttonClassName="!h-[30px] !w-[30px]"
                Options={Options}
              />
            )}
          </div>
        </div>
      </div>
      {showTable ? children : null}
    </>
  );
};

let searchTimeout = null;

const TaskLists = ({
  clients = [],
  accountantWS,
  taskLists,
  loadTaskLists,
  setAddTaskModalOpen,
  setSelectedTaskList,
  ids = [],
  findTaskType,
  handleUpdateTask,
  defaultAccountingWS,
  accountingFirmId,
  firmMembers,
  statusOptions,
  statusDropdownOpen,
  setStatusDropdownOpen,
  toggleDropdown,
  setSelectedTask,
  setDeleteModalOpen,
  setFlyoutOpen,
  taskLoading,
  handleTaskListShift,
  allTasks = [],
  loadTasks = () => {},
  tableLoading = false,
  tasksView,
  setTasksView,
  TASKS_VIEWS,

  clientFilter,
  setClientFilter,
  priorityFilter,
  setPriorityFilter,
  statusFilter,
  setStatusFilter,
  taskSearchFilter,
  setTaskSearchFilter,

  handleCreateTaskListTemplate,
  taskListTemplates,
  loadTaskListTemplates,
  handleGenerateTaskList,

  tasksSortValues,
  setTasksSortValues,
}) => {
  const FirmId = localStorage.getItem(FIRM_ID);

  const [showAllFilters, setShowAllFilters] = useState(false);
  const [addTaskListModalOpen, setAddTaskListModalOpen] = useState(false);
  const [activeTaskList, setActiveTaskList] = useState(null);

  const [deleteTaskListModal, setDeleteTaskListModal] = useState(false);
  const [deleteTaskListLoading, setDeleteTaskListLoading] = useState(false);

  const [taskListTemplateModalOpen, setTaskListTemplateModalOpen] =
    useState(false);
  const [activeTaskListTemplate, setActiveTaskListTemplate] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalLoading, setConfirmationModalLoading] =
    useState(false);

  const handleTasksSearch = (v) => {
    setTaskSearchFilter(v);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      loadTasks({
        search: v,
        loading: true,
        page: 1,
      });
    }, 500);
  };

  const handleDelete = async () => {
    setDeleteTaskListLoading(true);
    try {
      await deleteTaskListById(activeTaskList?.id);
      await loadTaskLists({});
      setDeleteTaskListModal(false);
      if (setActiveTaskList) {
        setActiveTaskList(null);
      }
      toast.success('Task list deleted successfully');
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteTaskListLoading(false);
    }
  };

  const handleDeleteTemplate = async () => {
    setConfirmationModalLoading(true);
    try {
      await deleteTaskListTemplate(activeTaskListTemplate?.id);
      await loadTaskListTemplates({});
      setConfirmationModalOpen(false);
      setActiveTaskListTemplate(null);
      toast.success('Task list template deleted successfully');
    } catch (err) {
      console.log('err', err);
    } finally {
      setConfirmationModalLoading(false);
    }
  };

  const handleResetFilters = () => {
    setPriorityFilter([]);
    setClientFilter('');
    setStatusFilter([]);
    setTaskSearchFilter('');
    loadTasks({
      search: '',
      clients: '',
      priority: '',
      status: [],
      loading: true,
      page: 1,
    });
  };

  const {
    dropdownOptions: dropdownClients,
    handleDropdownSearch: handleClientSearch,
    resetDropdownSearch: resetClientSearch,
  } = useBasicDropdownSearch({
    options: clients,
  });

  return (
    <div>
      <AddTaskListModal
        modalOpen={addTaskListModalOpen}
        setModalOpen={setAddTaskListModalOpen}
        clients={clients}
        accountantWS={accountantWS}
        showAccountantProjects
        showAccountantCustomers
        loadData={loadTaskLists}
        activeTaskList={activeTaskList}
        setActiveTaskList={setActiveTaskList}
      />
      <TaskListDeleteModal
        isOpen={deleteTaskListModal}
        setIsOpen={setDeleteTaskListModal}
        handleDeleteAction={handleDelete}
        deleteTaskListLoading={deleteTaskListLoading}
      />
      <TaskListTemplateModal
        modalOpen={taskListTemplateModalOpen}
        setModalOpen={setTaskListTemplateModalOpen}
        activeTaskListTemplate={activeTaskListTemplate}
        loadData={loadTaskListTemplates}
      />
      <BasicConfirmationModal
        title="Delete Task List Template"
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        handleYes={() => handleDeleteTemplate()}
        loading={confirmationModalLoading}
      >
        <p className="text-base leading-6 text-slate-600 text-opacity-80">
          Are you sure want to delete{' '}
          <span className="font-medium">{activeTaskListTemplate?.name}</span>{' '}
          task list template? This action cannot be undone.
        </p>
      </BasicConfirmationModal>
      <div className="flex flex-wrap items-center justify-between gap-4 w-full mb-6">
        <h3 className="text-[36px] font-medium text-[#667085]">Task List</h3>
        <div className="flex flex-wrap gap-4 ml-auto">
          <button
            className="ml-auto bg-indigo-500 text-white h-11 w-[150px] font-normal flex items-center justify-center gap-1 rounded-[0.313rem] shadow-sm whitespace-nowrap"
            onClick={() => {
              setSelectedTaskList({});
              setAddTaskModalOpen(true);
            }}
            type="button"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5013 4.16699V15.8337M4.66797 10.0003H16.3346"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add Task
          </button>
          <button
            className="ml-auto bg-white border border-indigo-500 text-indigo-500 h-11 w-[150px] font-normal flex items-center justify-center gap-1 rounded-[0.313rem] shadow-sm whitespace-nowrap"
            onClick={() => setAddTaskListModalOpen(true)}
            type="button"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-current stroke-current"
            >
              <path
                d="M10.5013 4.16699V15.8337M4.66797 10.0003H16.3346"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="stroke-current"
              />
            </svg>
            Add Task List
          </button>
        </div>
      </div>
      {/* Filters  */}
      <div className="w-full flex flex-col items-end gap-2 mb-6">
        <div className="w-full flex flex-nowrap justify-end items-start gap-6 gap-y-2.5 self-stretch">
          <div className="flex flex-wrap items-start justify-between gap-4 w-full">
            <div className="flex gap-6 gap-y-4 flex-wrap w-full sm:w-fit">
              <div className="grid sm:grid-cols-3 gap-y-4 gap-x-6 sm:w-fit w-full">
                <BasicCheckboxDropdown
                  selectedOptions={statusFilter}
                  setSelectedOptions={setStatusFilter}
                  options={[
                    { label: 'Not Started', value: 'notStarted' },
                    { label: 'In Progress', value: 'inProgress' },
                    { label: 'Review', value: 'review' },
                    { label: 'Complete', value: 'completed' },
                  ]}
                  label="Status"
                  type="status"
                  apiFunc={(values) => {
                    loadTasks({
                      status: values?.status || [],
                      loading: true,
                      page: 1,
                    });
                  }}
                  apiFilter={statusFilter}
                  onClear={() => {
                    setStatusFilter([]);
                    loadTasks({
                      status: [],
                      loading: true,
                      page: 1,
                    });
                  }}
                  width="!min-w-[210px] !w-full"
                  height="h-10"
                  inbox
                />
                <BasicCheckboxDropdown
                  selectedOptions={priorityFilter}
                  setSelectedOptions={setPriorityFilter}
                  options={[
                    { label: 'Standard Task', value: 'low' },
                    { label: 'Priority Task', value: 'high' },
                  ]}
                  label="Priority"
                  type="priority"
                  apiFunc={(values) => {
                    loadTasks({
                      priority: values?.priority || [],
                      loading: true,
                      page: 1,
                    });
                  }}
                  apiFilter={priorityFilter}
                  onClear={() => {
                    setPriorityFilter([]);
                    loadTasks({
                      priority: [],
                      loading: true,
                      page: 1,
                    });
                  }}
                  width="!min-w-[210px] !w-full"
                  height="h-10"
                  inbox
                />
                <DropdownFull
                  options={[
                    { name: 'All Clients', id: '' },
                    { name: 'dotted-line-item', id: 'dotted-line' },
                    ...dropdownClients,
                  ]}
                  height="h-10 !border-indigo-500 !bg-[#E4864205]"
                  selected={clientFilter}
                  setSelected={(value) => {
                    setClientFilter(value);
                    loadTasks({ clients: value, loading: true, page: 1 });
                  }}
                  name="name"
                  placeholder="All Clients"
                  scrollIntoView={false}
                  buttonTextClassSub="!text-indigo-500"
                  chevronClass="!text-indigo-500"
                  handleSearch={(value) =>
                    handleClientSearch(value, clientFilter)
                  }
                  resetSearch={resetClientSearch}
                  search
                  className="!min-w-[210px] !w-full"
                />
              </div>
              {(tasksView === TASKS_VIEWS.kanban ||
                tasksView === TASKS_VIEWS.group) && (
                <div className="flex gap-4 ml-auto min-w-max ">
                  <div className="mr-2 h-10 w-px bg-[#D0D5DD80] sm:block hidden" />
                  {/* <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllFilters((prev) => !prev);
                  }}
                  className={`btn h-10 py-0 px-4 font-normal text-sm leading-6 text-indigo-500 border ${
                    showAllFilters ? 'bg-[#E486421A]' : ''
                  }  border-indigo-500 hover:border-indigo-600 hover:text-indigo-600 text-indigo-500`}
                  type="button"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-1"
                  >
                    <path
                      d="M2.5 6.55801H17.5C17.8969 6.55801 18.225 6.2299 18.225 5.83301C18.225 5.43611 17.8969 5.10801 17.5 5.10801H2.5C2.1031 5.10801 1.775 5.43611 1.775 5.83301C1.775 6.2299 2.1031 6.55801 2.5 6.55801Z"
                      fill="#E48642"
                      stroke="#E48642"
                      strokeWidth="0.2"
                    />
                    <path
                      d="M5 10.725H15C15.3969 10.725 15.725 10.3969 15.725 10C15.725 9.6031 15.3969 9.275 15 9.275H5C4.6031 9.275 4.275 9.6031 4.275 10C4.275 10.3969 4.6031 10.725 5 10.725Z"
                      fill="#E48642"
                      stroke="#E48642"
                      strokeWidth="0.2"
                    />
                    <path
                      d="M8.33301 14.892H11.6663C12.0632 14.892 12.3913 14.5639 12.3913 14.167C12.3913 13.7701 12.0632 13.442 11.6663 13.442H8.33301C7.93611 13.442 7.60801 13.7701 7.60801 14.167C7.60801 14.5639 7.93611 14.892 8.33301 14.892Z"
                      fill="#E48642"
                      stroke="#E48642"
                      strokeWidth="0.2"
                    />
                  </svg>
                  All Filter
                </button> */}
                  <Tooltip
                    content="Reset Filters"
                    contentClassName="border-none overflow-visible text-sm !py-1 !px-2 text-[#667085] translate-y-1"
                    isFixed
                    ids={ids}
                  >
                    <button
                      onClick={handleResetFilters}
                      className="btn h-10 py-0 w-10 font-normal text-sm leading-6 border border-[#667085] text-[#667085] bg-[#FF4B4B05]"
                      type="button"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=""
                      >
                        <path
                          d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                          fill="#667085"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-4 ml-auto">
              <SearchForm
                searchVal={taskSearchFilter}
                setSearchVal={handleTasksSearch}
                placeholder="Search"
                height="!h-10 pr-6"
                searchIconClassName="!text-[#66708580] !stroke-[#66708580]"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pb-8 border-b border-[#D0D5DD] mb-5">
        <div className="flex gap-2.5">
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.group ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.group);
            }}
          >
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${tasksView === TASKS_VIEWS.group ? 'text-indigo-500 fill-indigo-500' : 'text-[#667085] fill-[#667085]'}`}
            >
              <path
                d="M14.25 7.875H1.75C1.41848 7.875 1.10054 8.0067 0.866116 8.24112C0.631696 8.47554 0.5 8.79348 0.5 9.125V12.25C0.5 12.5815 0.631696 12.8995 0.866116 13.1339C1.10054 13.3683 1.41848 13.5 1.75 13.5H14.25C14.5815 13.5 14.8995 13.3683 15.1339 13.1339C15.3683 12.8995 15.5 12.5815 15.5 12.25V9.125C15.5 8.79348 15.3683 8.47554 15.1339 8.24112C14.8995 8.0067 14.5815 7.875 14.25 7.875ZM14.25 12.25H1.75V9.125H14.25V12.25ZM14.25 1H1.75C1.41848 1 1.10054 1.1317 0.866116 1.36612C0.631696 1.60054 0.5 1.91848 0.5 2.25V5.375C0.5 5.70652 0.631696 6.02446 0.866116 6.25888C1.10054 6.4933 1.41848 6.625 1.75 6.625H14.25C14.5815 6.625 14.8995 6.4933 15.1339 6.25888C15.3683 6.02446 15.5 5.70652 15.5 5.375V2.25C15.5 1.91848 15.3683 1.60054 15.1339 1.36612C14.8995 1.1317 14.5815 1 14.25 1ZM14.25 5.375H1.75V2.25H14.25V5.375Z"
                stroke="white"
                strokeWidth="0.5"
                className="fill-current text-current"
              />
            </svg>

            <span> List View </span>
          </button>
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.lists ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.lists);
            }}
          >
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${tasksView === TASKS_VIEWS.lists ? 'text-indigo-500 fill-indigo-500' : 'text-[#667085] fill-[#667085]'}`}
            >
              <path
                d="M16.125 1H1.125C0.95924 1 0.800268 1.06585 0.683058 1.18306C0.565848 1.30027 0.5 1.45924 0.5 1.625V12.25C0.5 12.5815 0.631696 12.8995 0.866116 13.1339C1.10054 13.3683 1.41848 13.5 1.75 13.5H15.5C15.8315 13.5 16.1495 13.3683 16.3839 13.1339C16.6183 12.8995 16.75 12.5815 16.75 12.25V1.625C16.75 1.45924 16.6842 1.30027 16.5669 1.18306C16.4497 1.06585 16.2908 1 16.125 1ZM1.75 6H4.875V8.5H1.75V6ZM6.125 6H15.5V8.5H6.125V6ZM15.5 2.25V4.75H1.75V2.25H15.5ZM1.75 9.75H4.875V12.25H1.75V9.75ZM15.5 12.25H6.125V9.75H15.5V12.25Z"
                fill="#667085"
                stroke="white"
                strokeWidth="0.5"
                className="fill-current text-current"
              />
            </svg>

            <span> Table View </span>
          </button>
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.kanban ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.kanban);
            }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${tasksView === TASKS_VIEWS.kanban ? 'text-indigo-500 fill-indigo-500' : 'text-[#667085] fill-[#667085]'}`}
            >
              <path
                d="M5.5 0.375H1.75C1.41848 0.375 1.10054 0.506696 0.866116 0.741116C0.631696 0.975537 0.5 1.29348 0.5 1.625V5.375C0.5 5.70652 0.631696 6.02446 0.866116 6.25888C1.10054 6.4933 1.41848 6.625 1.75 6.625H5.5C5.83152 6.625 6.14946 6.4933 6.38388 6.25888C6.6183 6.02446 6.75 5.70652 6.75 5.375V1.625C6.75 1.29348 6.6183 0.975537 6.38388 0.741116C6.14946 0.506696 5.83152 0.375 5.5 0.375ZM5.5 5.375H1.75V1.625H5.5V5.375ZM13 0.375H9.25C8.91848 0.375 8.60054 0.506696 8.36612 0.741116C8.1317 0.975537 8 1.29348 8 1.625V5.375C8 5.70652 8.1317 6.02446 8.36612 6.25888C8.60054 6.4933 8.91848 6.625 9.25 6.625H13C13.3315 6.625 13.6495 6.4933 13.8839 6.25888C14.1183 6.02446 14.25 5.70652 14.25 5.375V1.625C14.25 1.29348 14.1183 0.975537 13.8839 0.741116C13.6495 0.506696 13.3315 0.375 13 0.375ZM13 5.375H9.25V1.625H13V5.375ZM5.5 7.875H1.75C1.41848 7.875 1.10054 8.0067 0.866116 8.24112C0.631696 8.47554 0.5 8.79348 0.5 9.125V12.875C0.5 13.2065 0.631696 13.5245 0.866116 13.7589C1.10054 13.9933 1.41848 14.125 1.75 14.125H5.5C5.83152 14.125 6.14946 13.9933 6.38388 13.7589C6.6183 13.5245 6.75 13.2065 6.75 12.875V9.125C6.75 8.79348 6.6183 8.47554 6.38388 8.24112C6.14946 8.0067 5.83152 7.875 5.5 7.875ZM5.5 12.875H1.75V9.125H5.5V12.875ZM13 7.875H9.25C8.91848 7.875 8.60054 8.0067 8.36612 8.24112C8.1317 8.47554 8 8.79348 8 9.125V12.875C8 13.2065 8.1317 13.5245 8.36612 13.7589C8.60054 13.9933 8.91848 14.125 9.25 14.125H13C13.3315 14.125 13.6495 13.9933 13.8839 13.7589C14.1183 13.5245 14.25 13.2065 14.25 12.875V9.125C14.25 8.79348 14.1183 8.47554 13.8839 8.24112C13.6495 8.0067 13.3315 7.875 13 7.875ZM13 12.875H9.25V9.125H13V12.875Z"
                fill="#667085"
                stroke="#FCF1E9"
                strokeWidth="0.5"
                className="fill-current text-current"
              />
            </svg>

            <span> Kanban View </span>
          </button>
          <div className=" mx-2 h-10 w-px bg-[#D0D5DD80] sm:block hidden" />
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.template ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.template);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className={`h-[18px] w-[18px] ${tasksView === TASKS_VIEWS.template ? 'text-indigo-500 stroke-indigo-500' : 'text-[#667085] stroke-[#667085]'}`}
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
              <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
              <path d="M14 12l6 0" />
              <path d="M14 16l6 0" />
              <path d="M14 20l6 0" />
            </svg>
            <span> Templates </span>
          </button>
        </div>
      </div>

      {/* Table */}
      <div
        className="w-full items-start flex flex-col overflow-x-auto scrollbar overflow-y-visible pb-12"
        id="tasksListsContainer"
      >
        {(!taskLists?.length === 0 || !(allTasks?.tasks?.length > 0)) &&
        tableLoading ? (
          <div className="w-full flex flex-col gap-2 items-center pb-2 justify-center mt-2">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : !taskLists?.length === 0 || !(allTasks?.tasks?.length > 0) ? (
          <div className="flex w-full items-center justify-center flex-col mt-10">
            <p className="text-slate-600 text-[20px] text-center">
              {tasksView === TASKS_VIEWS.lists
                ? 'No tasks found '
                : 'No task lists found'}
            </p>
          </div>
        ) : tasksView === TASKS_VIEWS.lists && allTasks?.tasks?.length > 0 ? (
          <TasksListView
            tasks={allTasks}
            clients={clients}
            findTaskType={findTaskType}
            priorityLoading={false}
            // ids={[`taskListTasks-${taskList?.id}`, ...ids]}
            handleUpdateTask={handleUpdateTask}
            showAccountantAssignee
            assigneeLoading={false}
            defaultAccountingWS={defaultAccountingWS}
            accountingFirmId={accountingFirmId}
            team={{
              users: firmMembers,
            }}
            statusLoading={false}
            statusOptions={statusOptions}
            statusDropdownOpen={statusDropdownOpen}
            setStatusDropdownOpen={setStatusDropdownOpen}
            toggleDropdown={toggleDropdown}
            setSelectedTask={setSelectedTask}
            setDeleteModalOpen={setDeleteModalOpen}
            // tableId={`taskListTasks-${taskList?.id}`}
            setFlyoutOpen={setFlyoutOpen}
            // taskListId={taskList?.id}
            loading={tableLoading}
            loadTasks={loadTasks}
            ids={ids}
            handleResetFilters={handleResetFilters}
            tableSortValues={tasksSortValues}
            setTableSortValues={setTasksSortValues}
          />
        ) : tasksView === TASKS_VIEWS.group ? (
          <div className="space-y-6 w-full">
            {taskLists?.map((taskList) => (
              <TaskList
                taskList={taskList}
                key={taskList?.id}
                setAddTaskModalOpen={setAddTaskModalOpen}
                setSelectedTaskList={setSelectedTaskList}
                setActiveTaskList={setActiveTaskList}
                setAddTaskListModalOpen={setAddTaskListModalOpen}
                handleActionDropdown={(id) => {
                  if (id === 'edit') {
                    setActiveTaskList(taskList);
                    setAddTaskListModalOpen(true);
                  } else if (id === 'delete') {
                    setActiveTaskList(taskList);
                    setDeleteTaskListModal(true);
                  } else if (id === 'template') {
                    handleCreateTaskListTemplate(taskList?.id);
                  }
                }}
                accountantWS={accountantWS}
                clients={clients}
              >
                {taskList?.tasks?.length > 0 ? (
                  <div>
                    <TaskListTasksTable
                      tasks={taskList?.tasks}
                      taskList={taskList}
                      clients={clients}
                      findTaskType={findTaskType}
                      priorityLoading={false}
                      ids={[`taskListTasks-${taskList?.id}`, ...ids]}
                      handleUpdateTask={handleUpdateTask}
                      showAccountantAssignee
                      assigneeLoading={false}
                      defaultAccountingWS={defaultAccountingWS}
                      accountingFirmId={accountingFirmId}
                      team={{
                        users: firmMembers,
                      }}
                      statusLoading={false}
                      statusOptions={statusOptions}
                      statusDropdownOpen={statusDropdownOpen}
                      setStatusDropdownOpen={setStatusDropdownOpen}
                      toggleDropdown={toggleDropdown}
                      setSelectedTask={setSelectedTask}
                      setDeleteModalOpen={setDeleteModalOpen}
                      tableId={`taskListTasks-${taskList?.id}`}
                      setFlyoutOpen={setFlyoutOpen}
                      taskListId={taskList?.id}
                      loading={taskLoading}
                    />
                    <InlineTaskModal
                      buttonContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      formContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      fieldsContainerClass="grid sm:grid-cols-2 xl:grid-cols-6 gap-4"
                      buttonsContainerClass="col-span-2 md:col-span-1 xl:col-span-2"
                      taskList={taskList}
                      firmMembers={firmMembers}
                      ids={ids}
                      loadData={loadTasks}
                      accountingFirmId={FirmId}
                      defaultAccountingWS={defaultAccountingWS}
                      clients={[
                        ...(accountantWS?.id
                          ? [
                              {
                                ...accountantWS,
                                name: 'No Client, Internal Task',
                              },
                            ]
                          : []),
                        { id: 'dotted-line', name: 'dotted-line-item' },
                        ...(clients.length > 0 ? clients : []),
                      ]}
                      disabledClients={
                        taskList?.teamId &&
                        taskList?.teamId !== accountantWS?.id
                      }
                    />
                  </div>
                ) : (
                  <div className="!mb-8 flex flex-col items-center justify-center">
                    <h3 className="text-[#667085] text-base text-center mb-5">
                      {' '}
                      No task has been added yet.
                    </h3>
                    <InlineTaskModal
                      buttonContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      formContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      fieldsContainerClass="grid sm:grid-cols-2 xl:grid-cols-6 gap-4"
                      buttonsContainerClass="col-span-2 md:col-span-1 xl:col-span-2"
                      taskList={taskList}
                      firmMembers={firmMembers}
                      ids={ids}
                      loadData={loadTasks}
                      accountingFirmId={FirmId}
                      defaultAccountingWS={defaultAccountingWS}
                      clients={[
                        ...(accountantWS?.id
                          ? [
                              {
                                ...accountantWS,
                                name: 'No Client, Internal Task',
                              },
                            ]
                          : []),
                        { id: 'dotted-line', name: 'dotted-line-item' },
                        ...(clients.length > 0 ? clients : []),
                      ]}
                      disabledClients={
                        taskList?.teamId &&
                        taskList?.teamId !== accountantWS?.id
                      }
                    />
                  </div>
                )}
              </TaskList>
            ))}
          </div>
        ) : tasksView === TASKS_VIEWS.kanban ? (
          <TaskListsKanban
            taskLists={taskLists}
            clients={clients}
            ids={['tasksListsContainer', ...ids]}
            handleTaskListShift={handleTaskListShift}
            handleActionDropdown={(id, taskList) => {
              if (id === 'edit') {
                setActiveTaskList(taskList);
                setAddTaskListModalOpen(true);
              } else if (id === 'delete') {
                setActiveTaskList(taskList);
                setDeleteTaskListModal(true);
              } else if (id === 'template') {
                handleCreateTaskListTemplate(taskList?.id);
              }
            }}
            setSelectedTaskList={setSelectedTaskList}
            setAddTaskModalOpen={setAddTaskModalOpen}
            setSelectedTask={setSelectedTask}
            setFlyoutOpen={setFlyoutOpen}
            isLoading={taskLoading}
            accountantWS={accountantWS}
            onAddTaskList={(e) => {
              e.stopPropagation();
              setAddTaskListModalOpen(true);
            }}
            firmMembers={firmMembers}
            loadTasks={loadTasks}
            accountingFirmId={FirmId}
            defaultAccountingWS={defaultAccountingWS}
            addTasksClients={[
              ...(accountantWS?.id
                ? [
                    {
                      ...accountantWS,
                      name: 'No Client, Internal Task',
                    },
                  ]
                : []),
              { id: 'dotted-line', name: 'dotted-line-item' },
              ...(clients.length > 0 ? clients : []),
            ]}
          />
        ) : tasksView === TASKS_VIEWS.template ? (
          taskListTemplates?.length === 0 ? (
            <div className="flex w-full items-center justify-center flex-col mt-10">
              <p className="text-slate-600 text-[20px] text-center">
                No Task List Templates found.
              </p>
            </div>
          ) : (
            <div className="space-y-6 w-full">
              {taskListTemplates?.map((taskList) => (
                <TaskListTemplate
                  taskListTemplate={taskList}
                  key={taskList?.id}
                  setAddTaskModalOpen={setAddTaskModalOpen}
                  setSelectedTaskList={setSelectedTaskList}
                  setActiveTaskList={setActiveTaskList}
                  setAddTaskListModalOpen={setAddTaskListModalOpen}
                  handleActionDropdown={(id) => {
                    if (id === 'edit') {
                      setActiveTaskListTemplate(taskList);
                      setTaskListTemplateModalOpen(true);
                    } else if (id === 'delete') {
                      setActiveTaskListTemplate(taskList);
                      setConfirmationModalOpen('template');
                    } else if (id === 'create') {
                      handleGenerateTaskList(taskList?.id);
                    }
                  }}
                  accountantWS={accountantWS}
                  clients={clients}
                >
                  {taskList?.taskTemplates?.length > 0 ? (
                    <div>
                      <TaskListTemplateTable
                        tasks={taskList?.taskTemplates}
                        clients={clients}
                        findTaskType={findTaskType}
                        ids={[`taskListTemplateTasks-${taskList?.id}`, ...ids]}
                        defaultAccountingWS={defaultAccountingWS}
                        accountingFirmId={accountingFirmId}
                        team={{
                          users: firmMembers,
                        }}
                        tableId={`taskListTemplateTasks-${taskList?.id}`}
                        loading={taskLoading}
                      />
                    </div>
                  ) : (
                    <div className="!mb-8 flex flex-col items-center justify-center">
                      <h3 className="text-[#667085] text-base text-center mb-5">
                        {' '}
                        No tasks found.
                      </h3>
                    </div>
                  )}
                </TaskListTemplate>
              ))}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default TaskLists;
