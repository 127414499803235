import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ModalBasic from '../../../components/ModalBasic';
import TextInputDash from '../../../components/TextInputDash';
import { updateTaskListTemplate } from '../../../API/backend_helper';
import { Loader } from '../../../components/Svg';

const TaskListTemplateModal = ({
  modalOpen,
  setModalOpen,
  loadData = null,
  activeTaskListTemplate,
  setActiveTaskListTemplate = null,
}) => {
  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!modalOpen) {
      formikRef.current.resetForm();
      if (setActiveTaskListTemplate) {
        setActiveTaskListTemplate(null);
      }
    }
  }, [modalOpen]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (activeTaskListTemplate?.id) {
        await updateTaskListTemplate(activeTaskListTemplate?.id, {
          name: values?.name,
          description: values?.description,
        });
      }

      if (loadData) {
        await loadData({});
      }
      toast.success('Task list template updated successfully');
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Edit Template"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      width="!w-[500px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: activeTaskListTemplate?.name || '',
          description: activeTaskListTemplate?.description || '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required('Task list template name is required')
            .max(
              255,
              'Task list template name must be less than or equal to 255 characters',
            ),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="bg-white border-y border-[#D0D5DD]">
              <div className="w-full">
                <div className=" gap-6 grid grid-cols-1 py-6 px-10 w-full">
                  <div className="flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Name <span className="text-red-600">*</span>
                    </label>
                    <TextInputDash
                      placeholder="Enter name"
                      value={validation.values.name}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      paddingBottom="pb-0"
                      name="name"
                      inputClass="!h-10 !mb-0"
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-1">
                        {validation.errors.name}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-span-full flex flex-col gap-2.5 w-full">
                    <label className="text-slate-600 font-medium text-[14px] leading-5">
                      Description
                    </label>
                    <TextInputDash
                      placeholder="Details about the task list template"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="description"
                      paddingBottom="pb-0"
                      type="textarea"
                    />
                    {validation.touched.description &&
                    validation.errors.description ? (
                      <span className="text-xs text-rose-500 h-[14px] -mt-3">
                        {validation.errors.description}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-6 px-[30px] flex w-full justify-end items-center gap-4">
              <div className="ml-auto flex items-center gap-4">
                <button
                  type="button"
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
                <button
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color="#FFFFFF" width="w-4" height="h-4" />
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default TaskListTemplateModal;
