import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../images/custom/count-logo.png';
import UserMenu from '../../components/DropdownProfile';
import { IS_ACCOUNTANT } from '../../utils/Utils';

const Tabs = ({
  className,
  activeTab,
  setActiveTab,
  OVERVIEW_TAB,
  CLIENTS_TAB,
  TASKS_TAB,
  INVITES_TAB,
  isAccountingFirmFound,
  InvitesCount,
  FIRM_SUBSCRIPTIONS_TAB,
  MANAGE_PRACTICE_TAB,
}) => (
  <div
    className={`gap-2 sm:gap-6 flex-1 justify-center text-[#5E6677] text-base  items-center ${className}`}
  >
    <button
      type="button"
      className={`px-2 cursor-pointer relative h-full ${activeTab === OVERVIEW_TAB ? 'font-semibold' : ''}`}
      onClick={() => setActiveTab(OVERVIEW_TAB)}
    >
      {activeTab === OVERVIEW_TAB && (
        <span className="inline-flex h-[3px] w-full absolute bottom-0 left-0 bg-indigo-500 rounded-full " />
      )}{' '}
      Overview
    </button>
    <button
      type="button"
      className={`px-2 cursor-pointer relative h-full ${activeTab === CLIENTS_TAB ? 'font-semibold' : ''}`}
      onClick={() => setActiveTab(CLIENTS_TAB)}
    >
      {activeTab === CLIENTS_TAB && (
        <span className="inline-flex h-[3px] w-full absolute bottom-0 left-0 bg-indigo-500 rounded-full " />
      )}{' '}
      Clients
    </button>
    {/* <button
      type="button"
      className={`px-2 cursor-pointer relative h-full ${activeTab === FIRM_SUBSCRIPTIONS_TAB ? 'font-semibold' : ''}`}
      onClick={() => setActiveTab(FIRM_SUBSCRIPTIONS_TAB)}
    >
      {activeTab === FIRM_SUBSCRIPTIONS_TAB && (
        <span className="inline-flex h-[3px] w-full absolute bottom-0 left-0 bg-indigo-500 rounded-full " />
      )}{' '}
      <span className="xl:inline hidden">COUNT Subscriptions</span>
      <span className="xl:hidden inline">Subscriptions</span>
    </button> */}
    {/* <button
      type="button"
      className={`px-2 cursor-pointer relative h-full ${activeTab === INVITES_TAB ? 'font-semibold' : ''}`}
      onClick={() => setActiveTab(INVITES_TAB)}
    >
      {activeTab === INVITES_TAB && (
        <span className="inline-flex h-[3px] w-full absolute bottom-0 left-0 bg-indigo-500 rounded-full " />
      )}{' '}
      Client Invites
      {InvitesCount > 0 && (
        <span className="ml-2 rounded-[4px] bg-indigo-500 text-sm !text-white min-h-4 min-w-6 inline-flex justify-center items-center">
          {InvitesCount}
        </span>
      )}
    </button> */}
    {isAccountingFirmFound && (
      <button
        type="button"
        className={`px-2 cursor-pointer relative h-full ${activeTab === TASKS_TAB ? 'font-semibold' : ''}`}
        onClick={() => setActiveTab(TASKS_TAB)}
      >
        {activeTab === TASKS_TAB && (
          <span className="inline-flex h-[3px] w-full absolute bottom-0 left-0 bg-indigo-500 rounded-full " />
        )}{' '}
        Tasks
      </button>
    )}
    <button
      type="button"
      className={`px-2 cursor-pointer relative h-full ${activeTab === MANAGE_PRACTICE_TAB ? 'font-semibold' : ''}`}
      onClick={() => setActiveTab(MANAGE_PRACTICE_TAB)}
    >
      {activeTab === MANAGE_PRACTICE_TAB && (
        <span className="inline-flex h-[3px] w-full absolute bottom-0 left-0 bg-indigo-500 rounded-full " />
      )}{' '}
      Manage Practice
    </button>
  </div>
);

function HeaderAccountant({
  activeTab,
  setActiveTab,
  OVERVIEW_TAB,
  CLIENTS_TAB,
  TASKS_TAB,
  INVITES_TAB,
  isAccountingFirmFound,
  InvitesCount,
  FIRM_SUBSCRIPTIONS_TAB,
  MANAGE_PRACTICE_TAB,
}) {
  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  const navigate = useNavigate();

  const { localUser } = useSelector((state) => state.User);

  const pendingInvitesCount = useMemo(() => {
    if (localUser?.invites?.length > 0) {
      const filteredInvites = localUser?.invites?.filter(
        (invite) => !invite?.declined,
      );
      return filteredInvites?.length;
    }
    return 0;
  }, [localUser?.invites]);

  const pendingFirmInvitesCount = useMemo(() => {
    if (localUser?.firmInvites?.length > 0) {
      const filteredInvites = localUser?.firmInvites?.filter(
        (invite) => invite?.status === 'active',
      );
      return filteredInvites?.length;
    }
    return 0;
  }, [localUser?.firmInvites]);

  const pendingOwnerInvitaionssCount = useMemo(() => {
    if (localUser?.ownerInvitaions?.length > 0) {
      const filteredInvites = localUser?.ownerInvitaions?.filter(
        (invite) => invite?.status === 'active',
      );
      return filteredInvites?.length;
    }
    return 0;
  }, [localUser?.ownerInvitaions]);

  return (
    <header className="sticky top-0 bg-white dark:bg-[#182235] border-b border-[#D0D5DD80] dark:border-slate-700 z-50">
      <div className="relative px-4 sm:px-6 lg:px-8 ">
        <div className="flex items-center justify-between h-fit lg:h-16 -mb-px gap-6 lg:py-0 pt-4">
          {/* HeaderAccountant: Left side */}

          <div className="flex gap-1.5 xs:items-center xs:flex-row flex-col">
            <img src={Logo} alt="logo" className="h-6 md:h-8 w-fit" />
            <div className="min-w-[3px] h-6 md:h-8 rounded-full bg-[#667085] hidden xs:inline-flex" />
            <h3 className="text-[20px] md:text-[26px] font-medium text-[#666666] leading-tight">
              Practice Manager
            </h3>
          </div>

          {/* Tabs  */}

          <Tabs
            className="h-full flex-1 lg:flex hidden"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            OVERVIEW_TAB={OVERVIEW_TAB}
            CLIENTS_TAB={CLIENTS_TAB}
            TASKS_TAB={TASKS_TAB}
            INVITES_TAB={INVITES_TAB}
            isAccountingFirmFound={isAccountingFirmFound}
            InvitesCount={InvitesCount}
            FIRM_SUBSCRIPTIONS_TAB={FIRM_SUBSCRIPTIONS_TAB}
            MANAGE_PRACTICE_TAB={MANAGE_PRACTICE_TAB}
          />

          {/* HeaderAccountant: Right side */}
          <div className="flex items-center space-x-3 ">
            {(pendingInvitesCount > 0 ||
              pendingFirmInvitesCount > 0 ||
              pendingOwnerInvitaionssCount > 0) && (
              <button
                className="relative w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ml-3"
                onClick={(e) => {
                  e.stopPropagation();
                  if (IsAccountant) {
                    localStorage.setItem(IS_ACCOUNTANT, 'false');
                  }
                  if (pendingInvitesCount) {
                    navigate('/manage-workspaces/my-invites');
                  } else {
                    navigate('/manage-workspaces/firm-invites');
                  }
                }}
                aria-controls="search-modal"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  className="h-4 w-4 fill-[#667085]"
                >
                  <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                </svg>
                <div className="absolute -translate-y-[50%] translate-x-1/2 right-0 rounded-full w-[1rem] h-[1rem] bg-indigo-500 text-white p-2.5 flex items-center justify-center text-[11px]">
                  {pendingInvitesCount > 9 ||
                  pendingFirmInvitesCount > 9 ||
                  pendingOwnerInvitaionssCount > 9
                    ? '9+'
                    : pendingInvitesCount ||
                      pendingFirmInvitesCount ||
                      pendingOwnerInvitaionssCount}
                </div>
              </button>
            )}

            {pendingInvitesCount > 0 && (
              <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
            )}
            <UserMenu align="right" />
          </div>
        </div>
        <div className="flex-1 lg:hidden block h-12">
          <Tabs
            className="h-12 flex-1 lg:hidden flex !items-center"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            OVERVIEW_TAB={OVERVIEW_TAB}
            CLIENTS_TAB={CLIENTS_TAB}
            TASKS_TAB={TASKS_TAB}
            INVITES_TAB={INVITES_TAB}
            isAccountingFirmFound={isAccountingFirmFound}
            InvitesCount={InvitesCount}
            FIRM_SUBSCRIPTIONS_TAB={FIRM_SUBSCRIPTIONS_TAB}
            MANAGE_PRACTICE_TAB={MANAGE_PRACTICE_TAB}
          />
        </div>
      </div>
    </header>
  );
}

export default HeaderAccountant;
