import React, { useEffect, useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import PlaidLink from '../PlaidLink';
import {
  createPlaidToken,
  getConnections,
  updateConnectionLink,
  updateAccountById,
  revokeAkahuConnection,
} from '../../../API/backend_helper';
import Tooltip from '../../../components/Tooltip';
import { TEAM_CURRENCY } from '../../../utils/Utils';
import BankWarning from '../../../components/BankWarning';
import AlertBox from '../../../components/AlertBox';
import AkahuLink from '../AkhahuLink/AkahuLink';
import AkahuRevokeAccess from './AkahuRevokeAccess';

let connectionPoll = null;
const LoadingIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-spin w-5 h-5 fill-current shrink-0"
  >
    <path
      d="M14.5 2.41699V7.25033"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 21.749V26.5824"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 5.95703L9.37662 9.37662"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 19.6211L23.0426 23.0407"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.41699 14.5H7.25033"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.749 14.5H26.5824"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 23.0407L9.37662 19.6211"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 9.37662L23.0426 5.95703"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Institution = ({ institution }) => (
  <div className="flex items-center">
    <Tooltip
      content={institution?.name}
      contentClassName="  text-sm text-[#667085] relative tooltipArrowDown"
    >
      <div
        className={`w-10 h-10  shrink-0 rounded-full  mr-3
      ${
        institution?.color && !institution?.logoUrl
          ? 'bg-gradient-to-tr from-slate-500 to-slate-300'
          : ''
      }
      `}
        style={
          institution?.color && !institution?.logoUrl
            ? { background: institution?.color }
            : {}
        }
      >
        {institution?.logoUrl ? (
          <img
            src={`${institution?.logoUrl}`}
            alt="logo"
            className="w-10 h-10 fill-current text-white"
          />
        ) : (
          <div className="flex items-center justify-center w-10 h-10">
            <div
              style={{ textTransform: 'uppercase' }}
              className="text-white font-bold text-lg "
            >
              {institution?.name?.charAt?.(0)}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
    <h3 className="text-lg font-medium text-[#101828CC] ">
      {institution?.name}
    </h3>
  </div>
);

function ConnectedBanks() {
  const resolveConnecRef = useRef();
  const PlaidButtonRef = useRef();

  const { team } = useSelector((state) => state.Team);
  const { accessType, permissions } = useSelector((state) => state.User);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [plaidLoader, setPlaidLoader] = useState(false);
  const [connections, setConnections] = useState([]);
  const [updateToken, setUpdateToken] = useState();
  const [updateTokenLoader, setUpdateTokenLoader] = useState();
  const [isPolling, setIsPolling] = useState(false);
  const [revokeAkahuAccess, setRevokeAkahuAccess] = useState(null);
  const [revokeAkahuAccessLoader, setRevokeAkahuAccessLoader] = useState(null);

  const location = useLocation();

  const localConnections = useMemo(() => {
    if (connections?.length > 0) {
      return [...connections].reverse();
    }
    return [];
  }, [connections]);

  const onBankClick = async (bank) => {
    try {
      const plaidRes = await createPlaidToken(null, {}, '');
      const { linkToken } = plaidRes;
      setToken(linkToken);
      setTimeout(() => {
        PlaidButtonRef.current.click();
      }, 1100);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (
      location?.state?.choiceModalOpen &&
      !isLoading &&
      checkPermission(
        PermissionKeys.Manage_Bank_Account,
        accessType,
        permissions,
      )
    ) {
      onBankClick();
    }
  }, [location, isLoading]);

  const getConnectionsApi = async () => {
    const response = await getConnections({});
    setConnections(response?.reverse?.());
    return response;
  };

  const checkConnectionAccount = async (res, timeOut = 15000) => {
    if (res?.length > 0) {
      for (let i = 0; i < res.length; i++) {
        if (
          res?.[i]?.lastSync === null ||
          res?.[i]?.syncStatus === 'CREATED' ||
          res?.[i]?.syncStatus === 'SYNCING'
        ) {
          if (!isPolling) {
            setIsPolling(true);
          }
          clearTimeout(connectionPoll);
          connectionPoll = null;
          connectionPoll = setTimeout(async () => {
            const newRes = await getConnectionsApi();
            await checkConnectionAccount(newRes);
          }, timeOut);
          break;
        }
        if (i === res.length - 1) {
          setIsPolling(false);
        }
      }
    }
  };

  const loadData = async (loader = false) => {
    try {
      if (loader) {
        setIsLoading(true);
      }
      const res = await getConnectionsApi();
      await checkConnectionAccount(res, 1);
      if (loader) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log('error', e);
      if (loader) {
        setIsLoading(false);
      }
    }
  };

  useEffect(
    () => () => {
      clearTimeout(connectionPoll);
    },
    [],
  );

  const updateAccount = async (id, data) => {
    try {
      const res = await updateAccountById(id, data);
      loadData();
      toast.success('Account updated successfully');
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    // if (isAuthenticated && localUser?.firstName && authToken) {
    if (team?.id) {
      loadData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessType, team?.id]);

  useEffect(() => {
    const createLinkToken = async () => {
      try {
        const response = await createPlaidToken();

        const { linkToken } = response;
        setToken(linkToken);
      } catch (e) {
        console.log('error', e);
      }
    };

    if (
      checkPermission(
        PermissionKeys.Manage_Bank_Account,
        accessType,
        permissions,
      ) &&
      team?.id &&
      !location?.state?.choiceModalOpen
    ) {
      if (teamDefaultCurrency === 'USD' || teamDefaultCurrency === 'CAD') {
        createLinkToken();
      }
    }
  }, [team]);

  const updateLinkToken = async (id) => {
    setUpdateToken({ id });
    try {
      setUpdateTokenLoader(true);
      const response = await updateConnectionLink(id);
      const { linkToken } = response?.data;
      setUpdateToken({ id, linkToken });
      setUpdateTokenLoader(false);
      setTimeout(() => {
        resolveConnecRef.current.click();
      }, 1000);
    } catch (e) {
      setUpdateTokenLoader(false);
      console.log('error', e);
    }
  };

  const onAkahuRevokeToken = async (id) => {
    setRevokeAkahuAccessLoader(true);
    try {
      const res = await revokeAkahuConnection(id);
      loadData();
      toast.success('Account access has been successfully revoked');
      setRevokeAkahuAccessLoader(false);
      setRevokeAkahuAccess(null);
    } catch (e) {
      setRevokeAkahuAccessLoader(false);
      console.log('error', e);
    }
  };

  if (isLoading || !team) {
    return (
      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animate-spin w-10 h-10 fill-current shrink-0"
        >
          <path
            d="M14.5 2.41699V7.25033"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 21.749V26.5824"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 5.95703L9.37662 9.37662"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 19.6211L23.0426 23.0407"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.41699 14.5H7.25033"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.749 14.5H26.5824"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 23.0407L9.37662 19.6211"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 9.37662L23.0426 5.95703"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
          Fetching data, please wait...
        </p>
      </div>
    );
  }

  return (
    <div className="grow">
      {/* {updateToken?.id &&
                          updateToken?.linkToken && (
                            <PlaidLink
                              loadData={loadData}
                              setIsLoading={setUpdateToken}
                              token={updateToken?.linkToken}
                              buttonText="Resolve Now"
                              buttonRef={resolveConnecRef}
                              team={team}
                            />)} */}
      {/* Panel body */}
      <div className="">
        <AkahuRevokeAccess
          isOpen={revokeAkahuAccess}
          setIsOpen={setRevokeAkahuAccess}
          loader={revokeAkahuAccessLoader}
          handleSubmit={onAkahuRevokeToken}
        />
        {checkPermission(
          PermissionKeys.Manage_Bank_Account,
          accessType,
          permissions,
        ) && <BankWarning teamProp={team} parentCB={loadData} />}
        {isPolling && (
          <AlertBox
            type="warning"
            heading="Your transactions are syncing!"
            text="This may take 10-15 minutes. We will let you know once all your transactions have been imported"
            hideActionButton
            icon={LoadingIcon}
          />
        )}
        <div className="flex justify-between mb-12 items-center">
          <h2 className="text-2xl   text-[#101828B2] font-medium">
            Connected Banks
          </h2>
          {checkPermission(
            PermissionKeys.Manage_Bank_Account,
            accessType,
            permissions,
          ) &&
            team &&
            (team?.country === 'NZ' ? (
              <AkahuLink
                ButtonUI={() => (
                  <button
                    type="button"
                    className="h-11  py-2.5 px-[1rem] flex items-center justify-center rounded-[0.313rem] shadow-sm bg-indigo-500 text-white"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    New Connection
                  </button>
                )}
              />
            ) : token ? (
              <PlaidLink
                loadData={loadData}
                setIsLoading={setPlaidLoader}
                token={token}
                team={team}
                // buttonRef={PlaidButtonRef}
              />
            ) : (
              <button
                type="button"
                className="h-11  py-2.5 px-[1rem] flex items-center justify-center rounded-[0.313rem] shadow-sm bg-indigo-500 text-white"
              >
                Loading ...
              </button>
            ))}
        </div>
        {plaidLoader && (
          <div className="flex justify-center mb-5">{LoadingIcon}</div>
        )}
        {/* Connected Apps cards */}
        {localConnections?.length > 0 ? (
          <section className="pb-6 border-b border-slate-200 dark:border-slate-700">
            <div className="grid grid-cols-12 gap-4">
              {localConnections?.map((connection) => (
                <div
                  key={connection?.id}
                  className={`col-span-full  md:col-span-12 w-full sm:w-1/2 mx-auto bg-white dark:bg-slate-800 rounded-[10px]
                  ${
                    connection?.connectionAlerts?.[0]?.syncStatus ===
                      'active' &&
                    connection?.connectionAlerts?.[0]?.requiredAction ===
                      'UPDATE_PLAID_AUTH'
                      ? 'border-[#E48642] border'
                      : ''
                  }`}
                  style={{ boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.06)' }}
                >
                  {/* Card content */}
                  <header className="flex rounded-t-[10px] justify-between items-center p-5  ">
                    {connection?.institution?.id ? (
                      <Institution institution={connection?.institution} />
                    ) : connection?.institutions?.length > 0 ? (
                      <div className="flex-col">
                        {connection?.institutions?.map((inst) => (
                          <Institution institution={inst} key={inst?.id} />
                        ))}
                      </div>
                    ) : null}
                    {connection?.status && team?.country && (
                      <div className="flex items-center  gap-4">
                        {/* Connected Button  */}
                        <div className="h-[34px] text-sm text-[#667085] font-normal  !bg-white px-5 shadow-xs flex items-center">
                          {connection?.connectionAlerts?.[0]?.status ===
                            'active' &&
                          connection?.connectionAlerts?.[0]?.requiredAction ===
                            'PLAID_AUTH' ? (
                            <div className="flex gap-1 items-center">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.99935 13.3337V10.0003M9.99935 6.66699H10.0077M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                                  stroke={'#667085'}
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span>Connection Disconnected</span>
                            </div>
                          ) : connection?.connectionAlerts?.[0]?.status ===
                              'active' &&
                            connection?.connectionAlerts?.[0]
                              ?.requiredAction === 'UPDATE_PLAID_AUTH' ? (
                            updateToken?.id === connection?.id &&
                            updateToken?.linkToken ? (
                              <PlaidLink
                                loadData={loadData}
                                setIsLoading={setUpdateToken}
                                token={updateToken?.linkToken}
                                buttonText="Resolve Now"
                                buttonRef={resolveConnecRef}
                                team={team}
                              />
                            ) : (
                              <button
                                type="button"
                                className="btn text-base font-normal !bg-indigo-500 hover:!bg-indigo-600 text-white"
                                onClick={() => updateLinkToken(connection?.id)}
                              >
                                {updateTokenLoader &&
                                updateToken?.id === connection?.id
                                  ? 'Loading ...'
                                  : 'Resolve Now'}
                              </button>
                            )
                          ) : (
                            <span>
                              {team?.country === 'NZ' &&
                              connection?.status === 'revoked' ? (
                                <div className="flex gap-1 items-center">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.99935 13.3337V10.0003M9.99935 6.66699H10.0077M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                                      stroke={'#667085'}
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>Access Revoked</span>
                                </div>
                              ) : connection?.lastSync === null ? (
                                <span>Sync in progress</span>
                              ) : connection?.syncStatus === 'CREATED' ? (
                                <div className="flex gap-1 items-center">
                                  {LoadingIcon}
                                  <span>Pending</span>
                                </div>
                              ) : connection?.syncStatus === 'SYNCING' ? (
                                <div className="flex gap-1 items-center">
                                  {LoadingIcon}
                                  <span>
                                    Syncing: {connection?.syncTransactionsTotal}{' '}
                                    Transactions
                                  </span>{' '}
                                </div>
                              ) : connection?.syncStatus === 'COMPLETE' ? (
                                <div className="flex gap-1 items-center">
                                  <svg
                                    className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
                                    viewBox="0 0 12 12"
                                  >
                                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                  </svg>
                                  <span className="whitespace-nowrap">
                                    Last Synced:{' '}
                                    {moment(connection?.lastSync).format(
                                      'MMM DD, h:mm a',
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center">
                          {/* <button className="rounded-lg bg-white justify-center items-center"
 onClick={() => updateLinkToken(connection?.id)}
                        >
                          {updateTokenLoader &&
                              updateToken?.id === connection?.id?
                              <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mt-5"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                              :
                        <svg
                              width="50"
                              height="35"
                              viewBox="0 0 28 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="cursor-pointer"
                            >
                              <path
                                d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
                                fill="white"
                                stroke="#D0D5DD"
                                stroke-opacity="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
                                stroke="#667085"
                                stroke-opacity="0.8"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
                                stroke="#667085"
                                stroke-opacity="0.8"
                                stroke-width="1.3"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
                                stroke="#858D9D"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>}
                        </button> */}

                          {/* Delete Button  */}
                          {team?.country === 'NZ' ? (
                            connection?.status !== 'revoked' && (
                              <Tooltip
                                content="Revoke Access"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                position="left"
                                size="sm"
                                tooltipShow
                              >
                                <button
                                  className="h-[30px] w-[30px] rounded-lg bg-white border border-[#FF4B4B80] inline-flex justify-center items-center"
                                  onClick={() =>
                                    setRevokeAkahuAccess(connection)
                                  }
                                  type="button"
                                >
                                  <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.5 4.48568C12.28 4.26568 10.0467 4.15234 7.82 4.15234C6.5 4.15234 5.18 4.21901 3.86 4.35234L2.5 4.48568"
                                      stroke="#FF4B4B"
                                      strokeWidth="1.125"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.16797 3.81203L6.31464 2.9387C6.4213 2.30536 6.5013 1.83203 7.62797 1.83203H9.37464C10.5013 1.83203 10.588 2.33203 10.688 2.94536L10.8346 3.81203"
                                      stroke="#FF4B4B"
                                      strokeWidth="1.125"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M13.0669 6.59375L12.6336 13.3071C12.5603 14.3537 12.5003 15.1671 10.6403 15.1671H6.36026C4.50026 15.1671 4.44026 14.3537 4.36693 13.3071L3.93359 6.59375"
                                      stroke="#FF4B4B"
                                      strokeWidth="1.125"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.38672 11.5H9.60672"
                                      stroke="#FF4B4B"
                                      strokeWidth="1.125"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              content="Please email help@getcount.com to delete this connected account"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                              position="left"
                              size="sm"
                              tooltipShow
                            >
                              <button
                                className="h-[30px] w-[30px] rounded-lg bg-white border border-[#FF4B4B80] inline-flex justify-center items-center"
                                type="button"
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.5 4.48568C12.28 4.26568 10.0467 4.15234 7.82 4.15234C6.5 4.15234 5.18 4.21901 3.86 4.35234L2.5 4.48568"
                                    stroke="#FF4B4B"
                                    strokeWidth="1.125"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.16797 3.81203L6.31464 2.9387C6.4213 2.30536 6.5013 1.83203 7.62797 1.83203H9.37464C10.5013 1.83203 10.588 2.33203 10.688 2.94536L10.8346 3.81203"
                                    stroke="#FF4B4B"
                                    strokeWidth="1.125"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M13.0669 6.59375L12.6336 13.3071C12.5603 14.3537 12.5003 15.1671 10.6403 15.1671H6.36026C4.50026 15.1671 4.44026 14.3537 4.36693 13.3071L3.93359 6.59375"
                                    stroke="#FF4B4B"
                                    strokeWidth="1.125"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.38672 11.5H9.60672"
                                    stroke="#FF4B4B"
                                    strokeWidth="1.125"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )}
                  </header>
                  <svg
                    width="574"
                    height="2"
                    viewBox="0 0 574 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full  h-auto px-5"
                  >
                    <path
                      d="M1 1H573.5"
                      stroke="#D0D5DD"
                      strokeOpacity="0.8"
                      strokeLinecap="round"
                      strokeDasharray="6 6"
                    />
                  </svg>
                  {connection?.accounts?.map?.((d, i) => (
                    <div className="p-5 pb-0" key={d?.id}>
                      <h3 className="text-base font-medium leading-tight mb-3 text-[#101828]">
                        {d?.name} (...{d?.mask})
                      </h3>
                      {/* <div className=" flex gap-2 items-center mb-4">
                        <svg
                          width="36"
                          height="25"
                          viewBox="0 0 36 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.21371"
                            y="0.21371"
                            width="35.4758"
                            height="24.3629"
                            rx="1.49597"
                            fill="white"
                            stroke="#D0D5DD"
                            stroke-width="0.427419"
                          />
                          <path
                            d="M21.2376 17.959H14.8301V6.51562H21.2376V17.959Z"
                            fill="#FF5F00"
                          />
                          <path
                            d="M15.2409 12.2379C15.2409 9.91655 16.3347 7.84876 18.0379 6.5162C16.7924 5.54176 15.2205 4.96015 13.5121 4.96015C9.46782 4.96015 6.18945 8.21845 6.18945 12.2379C6.18945 16.2573 9.46782 19.5156 13.5121 19.5156C15.2205 19.5156 16.7924 18.934 18.0379 17.9596C16.3347 16.627 15.2409 14.5592 15.2409 12.2379Z"
                            fill="#EB001B"
                          />
                          <path
                            d="M29.8801 12.2379C29.8801 16.2573 26.6018 19.5156 22.5575 19.5156C20.8491 19.5156 19.2772 18.934 18.0312 17.9596C19.7349 16.627 20.8286 14.5592 20.8286 12.2379C20.8286 9.91655 19.7349 7.84876 18.0312 6.5162C19.2772 5.54176 20.8491 4.96015 22.5575 4.96015C26.6018 4.96015 29.8801 8.21845 29.8801 12.2379Z"
                            fill="#F79E1B"
                          />
                        </svg>
                        <span className="text-sm text-[#667085]">
                          Business credit card **** 2007
                        </span>
                      </div> */}
                      {connection?.syncStatus === 'COMPLETE' &&
                        connection?.status !== 'revoked' && (
                          <div className="flex gap-2 pb-5">
                            <div className="form-switch form-switch-indigo">
                              <input
                                type="checkbox"
                                id={`switch-${connection?.institution?.id}-${d?.id}`}
                                className="sr-only"
                                checked={d?.connectionSyncEnabled}
                                onChange={() =>
                                  updateAccount(d?.id, {
                                    connectionSyncEnabled:
                                      !d?.connectionSyncEnabled,
                                  })
                                }
                              />
                              <label
                                className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                                htmlFor={`switch-${connection?.institution?.id}-${d?.id}`}
                              >
                                <span
                                  className="bg-gray-300"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">Switch label</span>
                              </label>
                            </div>
                            <label
                              htmlFor={`switch-${connection?.institution?.id}-${d?.id}`}
                              className="text-sm text-[#667085]"
                            >
                              Automatically import transactions for this account
                            </label>
                          </div>
                        )}
                      {i !== connection?.accounts?.length - 1 && (
                        <svg
                          width="574"
                          height="2"
                          viewBox="0 0 574 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-full  h-auto"
                        >
                          <path
                            d="M1 1H573.5"
                            stroke="#D0D5DD"
                            strokeOpacity="0.8"
                            strokeLinecap="round"
                            strokeDasharray="6 6"
                          />
                        </svg>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </section>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <h6 className="text-center">No Connections Found </h6>
            {/* No Connection Found SVG  */}
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 800 600"
              height="200"
              className="max-w-full"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x1="635.729"
                  y1="405.426"
                  x2="463.547"
                  y2="233.244"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#ff5607" />
                  <stop offset="1" stopColor="#ffa700" />
                </linearGradient>
                <linearGradient
                  id="linear-gradient-2"
                  x1="569.245"
                  y1="415.788"
                  x2="569.245"
                  y2="253.08"
                  xlinkHref="#linear-gradient"
                />
              </defs>
              <g id="no_connection" data-name="no connection">
                <circle cx="425.839" cy="335.548" r="169.657" fill="#fcfbd9" />
                <circle cx="293.215" cy="368.329" r="67.863" fill="#f16091" />
                <line
                  x1="316.966"
                  y1="362.937"
                  x2="302.273"
                  y2="354.454"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <line
                  x1="305.378"
                  y1="366.042"
                  x2="313.861"
                  y2="351.349"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <line
                  x1="278.426"
                  y1="373.264"
                  x2="263.733"
                  y2="364.781"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <line
                  x1="266.838"
                  y1="376.369"
                  x2="275.321"
                  y2="361.676"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <path
                  d="M313.572,396.621a33.555,33.555,0,0,0-27.049,6.862"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <path
                  d="M169.462,326.614A82.04,82.04,0,0,1,251.5,244.575"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <path
                  d="M210.481,326.614A41.02,41.02,0,0,1,251.5,285.6"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="3.393"
                />
                <path
                  d="M506.8,363.916c-6.7,2.1-27.5,8.923-39.557,26.536-2.015,2.944-6.724,9.975-4.3,13.769,2.972,4.655,15.079,2.048,19.815.7,15.227-4.344,60.819-25.073,121.164-55.852C582.517,349.76,543.592,352.4,506.8,363.916Z"
                  fill="#081f2d"
                  stroke="#081f2d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.036"
                />
                <path
                  d="M614.671,400.2,502.837,410.8a223.433,223.433,0,0,0-31.882-57.433c-32.626-42.272-89.049-82.541-86.832-95.964a7.956,7.956,0,0,1,2.411-4.77c4.078-3.28,11.951.106,16.6,2.305,9.433,4.451,21.207,11.331,31.421,17.625-44.259-27.892-64.668-38.052-63.32-50.819a9.625,9.625,0,0,1,2.234-5.727c9.469-10.32,47.131,8.724,78.747,27.573-27.467-17.431-69.225-46.67-67.381-63.161.159-1.578.691-6.259,4.131-8.883,8.831-6.685,28.141,6.631,30.038,7.979-14.044-11.561-22.714-21.065-21.314-30.8.178-1.224.816-5.2,3.919-7.731,5.532-4.522,15.835-1.986,29.807,4.912,32.095,15.87,83.783,55,98.731,66.44A32.714,32.714,0,0,1,525,198.656c-1.188-8.618.053-22.077,6.667-24.86,11.118-4.664,33.779,22.519,45.713,41.279a177.917,177.917,0,0,1,25.782,71.406c4.255,34.1-4.149,49.223,4.592,89.1A219.274,219.274,0,0,0,614.671,400.2Z"
                  fill="url(#linear-gradient)"
                />
                <path
                  d="M409.065,256.7c7.386,4.594,15.876,9.816,25.491,15.875,9.649,6.079,17.291,11.022,21.447,13.724"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <path
                  d="M426.151,226.07c17.409,12.522,35.606,23.549,44.228,28.679"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <path
                  d="M408.008,169.687a196.507,196.507,0,0,0,17.929,15.4,615.605,615.605,0,0,0,53.044,37.238"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <rect
                  x="408.11"
                  y="94.795"
                  width="132.988"
                  height="230.513"
                  rx="17.732"
                  fill="#f1e302"
                />
                <line
                  x1="457.639"
                  y1="106.331"
                  x2="491.57"
                  y2="106.331"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <line
                  x1="449.495"
                  y1="311.735"
                  x2="499.713"
                  y2="311.735"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <path
                  d="M525.054,333.9c-.78-4.575-6.578-41.6,16-60.04a44.3,44.3,0,0,1,11.1-6.437c.478-16.153-3.369-27.324-7.607-35.02-7.448-13.547-17.466-18.831-19.541-33.743-1.188-8.618.053-22.077,6.667-24.86,11.118-4.664,33.779,22.519,45.713,41.279a177.917,177.917,0,0,1,25.782,71.406c4.256,34.1-4.132,49.223,4.593,89.1a222.819,222.819,0,0,0,6.915,24.612"
                  fill="url(#linear-gradient-2)"
                />
                <path
                  d="M552.149,267.419c0-20.2-3.774-28.059-7.607-35.02-7.448-13.547-17.466-18.831-19.541-33.743"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <path
                  d="M525.054,333.9c-.78-4.575-6.578-41.6,15.994-60.04a44.977,44.977,0,0,1,17.091-8.371"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <path
                  d="M630.538,446.651,476.717,497.444c-3.7-31.794-7.665-53.717-9.989-68.246q-2.108-13.2-4.321-26.21c.02.06.081.242.158.429a3.2,3.2,0,0,0,.189.455,2.108,2.108,0,0,0,.2.349c2.959,4.648,15.063,2.049,19.807.7,15.224-4.34,60.813-25.065,121.164-55.843Z"
                  fill="#b29adc"
                />
                <path
                  d="M466.338,426.759h0a56.064,56.064,0,0,0,36.974-2.155l108.841-45.359"
                  fill="none"
                  stroke="#081f2d"
                  strokeMiterlimit="10"
                  strokeWidth="2.036"
                />
                <path
                  d="M467.246,390.452c-2.015,2.944-6.724,9.975-4.3,13.769,2.972,4.655,15.079,2.048,19.815.7,15.227-4.344,60.819-25.073,121.164-55.852"
                  fill="none"
                  stroke="#081f2d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.036"
                />
                <rect width="800" height="600" fill="none" />
              </g>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default ConnectedBanks;
