import React from 'react';

const Tabs = ({ activeTab, setActiveTab, TABS }) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 flex-wrap">
    <div
      onClick={() => setActiveTab(TABS?.invoices)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS?.invoices &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Billing
    </div>
    <div
      onClick={() => setActiveTab(TABS?.tasks)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS?.tasks &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Tasks
    </div>
    <div
      onClick={() => setActiveTab(TABS?.projects)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS?.projects &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Projects
    </div>
    <div
      onClick={() => setActiveTab(TABS?.files)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS?.files &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Files
    </div>
    <div
      onClick={() => setActiveTab(TABS?.vault)}
      className={`text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS?.vault &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Client Vault
    </div>
  </div>
);

export default Tabs;
