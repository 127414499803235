import React from 'react';
import { useNavigate } from 'react-router-dom';

const CDHeader = ({
  accountantFirm,
  clientDetails,
  onTeamChange,
  onTeamDetailsChange,
  setNotesModalOpen,
}) => {
  const navigate = useNavigate();

  const customerDetails = clientDetails?.customerRecord?.[0];

  return (
    <div>
      {/* Breadcrumb  */}
      <div className="flex gap-4 ml-[10px] items-center mb-4">
        <div className="flex items-center gap-4">
          <button
            className="cursor-pointer text-[14px] text-slate-600 hover:text-indigo-500 hover:underline"
            onClick={() => {
              navigate('/accountant');
            }}
            type="button"
          >
            Practice Manager
          </button>
        </div>
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          xmlns="http://www.w3.org/2000/svg"
          fill="gray"
        >
          <path
            d="M1 5h8M6 1l4 4-4 4"
            stroke="gray"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div className="flex items-center gap-4">
          <button
            className="cursor-pointer text-[14px] text-slate-600 hover:text-indigo-500 hover:underline"
            onClick={() => {
              navigate('/accountant/clients');
            }}
            type="button"
          >
            Clients
          </button>
          {/* <div className="border-l-2 h-5" /> Vertical line */}
        </div>
        {/* <div className="flex items-center gap-4">
          <button
            className="cursor-pointer text-[14px] text-slate-600 hover:text-indigo-500 hover:underline"
            onClick={() => {
              onTeamChange(clientDetails?.id, clientDetails);
            }}
            type="button"
          >
            {clientDetails?.name}
          </button>
        </div> */}
      </div>

      <div className="flex justify-between items-center">
        <div className="flex-1 flex   items-center ">
          <div className="bg-gray-100 border h-[70px] w-16 min-w-16 flex justify-center items-center mr-4 rounded-lg">
            <p className="text-slate-950  text-5xl leading-tight">
              {customerDetails?.contacts?.[0]?.firstName ||
              customerDetails?.contacts?.[0]?.lastName
                ? `${customerDetails?.contacts?.[0]?.firstName}${customerDetails?.contacts?.[0]?.lastName}`?.charAt(
                    0,
                  )
                : customerDetails?.customer?.charAt(0)}
            </p>
          </div>
          <div>
            <p className="text-slate-950 h1 pb-2 text-[24px] font-medium ">
              {customerDetails?.contacts?.[0]?.firstName ||
              customerDetails?.contacts?.[0]?.lastName
                ? `${customerDetails?.contacts?.[0]?.firstName} ${customerDetails?.contacts?.[0]?.lastName}`
                : `${customerDetails?.customer}`}
            </p>
            <div className="flex gap-4 flex-wrap gap-y-2">
              {/* Client Statement  */}
              <button
                type="button"
                className="text-[#667085] hover:text-indigo-500 flex gap-1 items-center transition-all ease-in"
                onClick={() => {
                  onTeamDetailsChange(accountantFirm?.workspace);
                  navigate(
                    `/customer-statement/${customerDetails?.id}?from=accountant/client/${clientDetails?.id}`,
                  );
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-current"
                >
                  <path
                    className="stroke-current"
                    d="M17.0843 17.5V15.8333C17.0843 14.9493 16.7331 14.1014 16.108 13.4763C15.4829 12.8512 14.635 12.5 13.751 12.5H7.08431C6.20026 12.5 5.35241 12.8512 4.72729 13.4763C4.10217 14.1014 3.75098 14.9493 3.75098 15.8333V17.5"
                    stroke="#667085"
                    strokeWidth="1.11816"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    className="stroke-current"
                    d="M10.4183 9.16667C12.2592 9.16667 13.7516 7.67428 13.7516 5.83333C13.7516 3.99238 12.2592 2.5 10.4183 2.5C8.57735 2.5 7.08496 3.99238 7.08496 5.83333C7.08496 7.67428 8.57735 9.16667 10.4183 9.16667Z"
                    stroke="#667085"
                    strokeWidth="1.11816"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Client Statement</span>
              </button>

              {/* Create Task  */}
              {/* <button
                type="button"
                className="text-[#667085] hover:text-indigo-500 flex gap-1 items-center transition-all ease-in"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-5 w-fit text-current stroke-current"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                  <path d="M9 14l2 2l4 -4" />
                </svg>

                <span>Create Task</span>
              </button> */}

              {/* Create Invoice  */}
              {/* <button
                type="button"
                className="text-[#667085] hover:text-indigo-500 flex gap-1 items-center transition-all ease-in"
                onClick={() => {
                  onTeamDetailsChange(accountantFirm?.workspace);
                  navigate(
                    `/invoices?tab=invoices&new=invoice&from=client&customerId=${customerDetails?.id}`,
                  );
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-current"
                >
                  <path
                    d="M11 11.1667L14.5714 11.1667"
                    stroke="#667085"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    className="stroke-current"
                  />
                  <path
                    d="M13.8571 9.7381L15.2857 11.1667L13.8571 12.5952"
                    stroke="#667085"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="stroke-current"
                  />
                  <path
                    d="M14.7003 8.19704V6.49979C14.7003 4.84293 13.3572 3.49979 11.7003 3.49979H4C2.34315 3.49979 1 4.84293 1 6.49978V8.71998C1 10.3768 2.34315 11.72 4 11.72H9.09564"
                    stroke="#667085"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="stroke-current"
                  />
                  <circle
                    cx="7.85016"
                    cy="7.60988"
                    r="1.37003"
                    stroke="#667085"
                    strokeWidth="1.3"
                    className="stroke-current"
                  />
                </svg>

                <span>Create an Invoice</span>
              </button> */}

              {/* Add Notes */}
              <button
                type="button"
                className="text-[#667085] hover:text-indigo-500 flex gap-1 items-center transition-all ease-in"
                onClick={() => setNotesModalOpen(true)}
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-current"
                >
                  <path
                    d="M14.3182 2H6.31824C5.7878 2 5.2791 2.21071 4.90402 2.58579C4.52895 2.96086 4.31824 3.46957 4.31824 4V20C4.31824 20.5304 4.52895 21.0391 4.90402 21.4142C5.2791 21.7893 5.7878 22 6.31824 22H18.3182C18.8487 22 19.3574 21.7893 19.7325 21.4142C20.1075 21.0391 20.3182 20.5304 20.3182 20V8L14.3182 2Z"
                    className="stroke-current"
                    strokeWidth="1.63636"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.3182 2V8H20.3182"
                    className="stroke-current"
                    strokeWidth="1.63636"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.3182 13H8.31824"
                    className="stroke-current"
                    strokeWidth="1.01651"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.3182 17H8.31824"
                    className="stroke-current"
                    strokeWidth="1.01651"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.3182 9H9.31824H8.31824"
                    className="stroke-current"
                    strokeWidth="1.01651"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span>Add Notes</span>
              </button>
            </div>
          </div>
        </div>

        <button
          className="btn h-10 whitespace-nowrap border-[#E48642] bg-[#E486420D] text-[#E48642] text-base font-normal px-4"
          type="button"
          onClick={() => {
            onTeamChange(clientDetails?.id, clientDetails);
          }}
        >
          View Workspace
        </button>
      </div>
    </div>
  );
};

export default CDHeader;
