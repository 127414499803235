import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { TEAM_ID } from 'utils/Utils';
import useCustomerSearch from 'hooks/useCustomerSearch';
import useBasicDropdownSearch from 'hooks/useBasicDropdownSearch';
import ModalBasic from '../../../components/ModalBasic';
import DropdownFull from '../../../components/DropdownFull';
import TextInputDash from '../../../components/TextInputDash';
import {
  createTaskList,
  deleteTaskListById,
  getAllProjects,
  getCustomers,
  updateTaskList,
} from '../../../API/backend_helper';
import { Loader } from '../../../components/Svg';
import TaskListDeleteModal from './TaskListDeleteModal';

const AddTaskListModal = ({
  modalOpen,
  setModalOpen,
  clients = [],
  accountantWS,
  showAccountantProjects = false,
  projects = [],
  showAccountantCustomers = false,
  customers = [],
  loadData = null,
  accountantTask = false,
  activeTaskList,
  setActiveTaskList = null,
}) => {
  const formikRef = useRef(null);

  const [clientProjects, setClientProjects] = useState([]);
  const [clientProjectsLoading, setClientProjectsLoading] = useState(false);
  const [clientCustomers, setClientCustomers] = useState([]);
  const [clientCustomersLoading, setClientCustomersLoading] = useState(false);
  const [noClick, setNoClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteTaskListModal, setDeleteTaskListModal] = useState(false);
  const [deleteTaskListLoading, setDeleteTaskListLoading] = useState(false);

  const loadClientProjects = async (teamId) => {
    setClientProjectsLoading(true);
    localStorage.setItem(TEAM_ID, teamId);
    try {
      const res = await getAllProjects({});
      const newProjects = res?.map((project) => ({
        ...project,
        name: `${project?.name} ${project?.customId ? `(${project.customId})` : ''}`,
      }));
      if (activeTaskList?.project?.isDeleted) {
        const tempProject = { ...activeTaskList?.project };
        tempProject.name = `${tempProject?.name} ${tempProject?.customId ? `(${tempProject.customId})` : ''}`;
        setClientCustomers([tempProject, newProjects]);
      } else {
        setClientProjects(newProjects);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientProjectsLoading(false);
      localStorage.setItem(TEAM_ID, '');
    }
  };

  const loadClientCustomers = async (teamId) => {
    setClientCustomersLoading(true);
    localStorage.setItem(TEAM_ID, teamId);
    try {
      const res = await getCustomers({ status: 'active' });
      if (activeTaskList?.customer?.status === 'inactive') {
        setClientCustomers([activeTaskList?.customer, res?.data?.records]);
      } else {
        setClientCustomers(res?.data?.records);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientCustomersLoading(false);
      localStorage.setItem(TEAM_ID, '');
    }
  };

  useEffect(() => {
    if (!modalOpen) {
      formikRef.current.resetForm();
      if (setActiveTaskList) {
        setActiveTaskList(null);
      }
    }
  }, [modalOpen]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    localStorage.setItem(TEAM_ID, values?.teamId);
    try {
      if (activeTaskList?.id) {
        await updateTaskList(activeTaskList?.id, {
          projectId: values?.projectId,
          customerId: values?.projectId,
          name: values?.name,
          description: values?.description,
        });
      } else {
        await createTaskList(values);
      }

      if (loadData) {
        await loadData({});
      }
      if (activeTaskList?.id) {
        toast.success('Task list updated successfully');
      } else {
        toast.success('Task list created successfully');
      }
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      if (accountantTask) {
        localStorage.setItem(TEAM_ID, '');
      }
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteTaskListLoading(true);
    try {
      await deleteTaskListById(activeTaskList?.id);
      if (loadData) {
        await loadData({});
      }
      setDeleteTaskListModal(false);
      setModalOpen(false);
      if (setActiveTaskList) {
        setActiveTaskList(null);
      }
      toast.success('Task list deleted successfully');
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteTaskListLoading(false);
    }
  };

  useEffect(() => {
    if ((activeTaskList?.teamId || accountantWS?.id) && modalOpen) {
      loadClientProjects(activeTaskList?.teamId || accountantWS?.id);
      loadClientCustomers(activeTaskList?.teamId || accountantWS?.id);
    }
  }, [accountantWS, modalOpen, activeTaskList]);

  const {
    dropdownOptions: dropdownClients,
    handleDropdownSearch: handleClientsSearch,
    resetDropdownSearch: resetClientsSearch,
  } = useBasicDropdownSearch({ options: clients });

  const {
    dropdownCustomers,
    handleCustomerSearch,
    resetCustomerSearch,
    searchCustomerLoading,
  } = useCustomerSearch(showAccountantCustomers ? clientCustomers : customers);

  return (
    <>
      <ModalBasic
        title={`${activeTaskList?.id ? 'Edit' : 'Add'}  Task List`}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        noClick={noClick || deleteTaskListModal}
        width="!w-[800px]"
      >
        <Formik
          enableReinitialize
          initialValues={{
            teamId: activeTaskList?.teamId || accountantWS?.id,
            projectId: activeTaskList?.projectId || null,
            customerId: activeTaskList?.customerId || null,
            name: activeTaskList?.name || '',
            description: activeTaskList?.description || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .required('Task list name is required')
              .max(
                255,
                'Task list name must be less than or equal to 255 characters',
              ),
            teamId: Yup.string().required('Client is required'),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => (
            <Form>
              <div className="bg-white border-y border-[#D0D5DD]">
                <div className="w-full">
                  <div className=" gap-6 grid grid-cols-1 md:grid-cols-2 py-6 px-10 w-full">
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Name <span className="text-red-600">*</span>
                      </label>
                      <TextInputDash
                        placeholder="Enter name"
                        value={validation.values.name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        paddingBottom="pb-0"
                        name="name"
                        inputClass="!h-10 !mb-0"
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-1">
                          {validation.errors.name}
                        </span>
                      ) : null}
                    </div>
                    <div className="flex flex-col gap-2.5 w-full">
                      <label
                        className={`text-slate-600 font-medium text-[14px] leading-5 ${activeTaskList?.id ? 'opacity-50' : ''}`}
                      >
                        Client <span className="text-red-600">*</span>
                      </label>
                      <DropdownFull
                        options={
                          accountantWS?.id
                            ? [
                                {
                                  ...accountantWS,
                                  name: 'No Client, Internal Task List',
                                },
                                {
                                  id: 'dotted-line',
                                  name: 'dotted-line-item',
                                },
                                ...dropdownClients,
                              ]
                            : dropdownClients
                        }
                        selected={validation.values.teamId}
                        setSelected={(value) => {
                          validation.setFieldValue('teamId', value);
                          validation.setFieldValue('projectId', null);
                          validation.setFieldValue('customerId', null);
                          loadClientProjects(value);
                          loadClientCustomers(value);
                        }}
                        name="name"
                        height="h-10"
                        scrollIntoView={false}
                        search
                        handleSearch={(value) =>
                          handleClientsSearch(value, validation.values.clientId)
                        }
                        resetSearch={resetClientsSearch}
                        searchPlaceholder="Type To Search"
                        disabled={activeTaskList?.id}
                        className={`${activeTaskList?.id ? 'opacity-50' : ''}`}
                      />
                      {validation.touched.clientId &&
                      validation.errors.clientId ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-1">
                          {validation.errors.clientId}
                        </span>
                      ) : null}
                    </div>
                    <div className="flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center">
                        Project
                      </label>
                      {!showAccountantProjects ? (
                        <DropdownFull
                          options={projects}
                          selected={validation.values.projectId}
                          setSelected={(project) => {
                            validation.setFieldValue('projectId', project?.id);
                          }}
                          name="name"
                          height="h-10"
                          scrollIntoView={false}
                          bottomOfTable
                          setNoClick={setNoClick}
                        />
                      ) : clientProjectsLoading ? (
                        <div className="w-full flex">
                          <Loader width="w-6" height="h-6" />
                        </div>
                      ) : validation.values.teamId ? (
                        <DropdownFull
                          options={clientProjects}
                          selected={validation.values.projectId}
                          setSelected={(project) => {
                            validation.setFieldValue('projectId', project?.id);
                            validation.setFieldValue(
                              'customerId',
                              project?.customer?.id,
                            );
                          }}
                          name="name"
                          height="h-10"
                          scrollIntoView={false}
                          bottomOfTable
                          setNoClick={setNoClick}
                          selectFullObj
                        />
                      ) : (
                        <p className="text-sm">Please first select Client.</p>
                      )}
                      {validation.touched.projectId &&
                      validation.errors.projectId ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-1">
                          {validation.errors.projectId}
                        </span>
                      ) : null}
                    </div>

                    {validation.values.projectId && (
                      <div className="flex flex-col gap-2.5 w-full">
                        <label className="text-slate-600 font-medium text-[14px] leading-5 flex gap-2 items-center ">
                          Customer
                        </label>
                        {!showAccountantCustomers ? (
                          <DropdownFull
                            options={dropdownCustomers}
                            selected={validation.values.customerId}
                            setSelected={(value) =>
                              validation.setFieldValue('customerId', value)
                            }
                            name="customer"
                            height="h-10"
                            scrollIntoView={false}
                            bottomOfTable
                            setNoClick={setNoClick}
                            className="opacity-50"
                            disabled
                          />
                        ) : clientCustomersLoading ? (
                          <div className="w-full flex">
                            <Loader width="w-6" height="h-6" />
                          </div>
                        ) : validation.values.teamId ? (
                          <DropdownFull
                            options={dropdownCustomers}
                            selected={validation.values.customerId}
                            setSelected={(value) =>
                              validation.setFieldValue('customerId', value)
                            }
                            name="customer"
                            height="h-10"
                            scrollIntoView={false}
                            search
                            handleSearch={(value, selected) => {
                              localStorage.setItem(
                                TEAM_ID,
                                validation.values.teamId,
                              );
                              handleCustomerSearch(value, selected);
                            }}
                            resetSearch={resetCustomerSearch}
                            searchPlaceholder="Type To Search"
                            searchLoading={searchCustomerLoading}
                            bottomOfTable
                            setNoClick={setNoClick}
                            className="opacity-50"
                            disabled
                          />
                        ) : (
                          <p className="text-sm">Please first select Client.</p>
                        )}
                        {validation.touched.projectId &&
                        validation.errors.projectId ? (
                          <span className="text-xs text-rose-500 h-[14px] -mt-1">
                            {validation.errors.projectId}
                          </span>
                        ) : null}
                      </div>
                    )}

                    <div className="col-span-full flex flex-col gap-2.5 w-full">
                      <label className="text-slate-600 font-medium text-[14px] leading-5">
                        Description
                      </label>
                      <TextInputDash
                        placeholder="Details about the task list"
                        value={validation.values.description}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        name="description"
                        paddingBottom="pb-0"
                        type="textarea"
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <span className="text-xs text-rose-500 h-[14px] -mt-3">
                          {validation.errors.description}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-6 px-[30px] flex w-full justify-end items-center gap-4">
                {/* {activeTaskList?.id && (
                  <button
                    type="button"
                    className="h-11 w-[90px] flex justify-center items-center rounded-[5px]  border border-red-400 text-red-400 bg-[#FF4B4B05]"
                    onClick={() => setDeleteTaskListModal(true)}
                  >
                    Delete
                  </button>
                )} */}
                <div className="ml-auto flex items-center gap-4">
                  <button
                    type="button"
                    className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Loader color="#FFFFFF" width="w-4" height="h-4" />
                    ) : activeTaskList?.id ? (
                      'Update'
                    ) : (
                      'Add'
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBasic>
      <TaskListDeleteModal
        isOpen={deleteTaskListModal}
        setIsOpen={setDeleteTaskListModal}
        handleDeleteAction={handleDelete}
        deleteTaskListLoading={deleteTaskListLoading}
      />
    </>
  );
};

export default AddTaskListModal;
