import React from 'react';
import { Loader } from 'components/Svg';
import ModalBasic from './ModalBasic';

const BasicConfirmationModal = ({
  isOpen,
  setIsOpen,
  handleYes,
  children,
  loading = false,
  title = '',
  okText = 'Yes',
  cancelText = 'No',
}) => (
  <ModalBasic
    title={title}
    modalOpen={isOpen}
    setModalOpen={setIsOpen}
    smallModal
  >
    <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
      {children}
    </div>
    <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6 disabled:cursor-not-allowed"
        onClick={() => setIsOpen(false)}
        type="button"
        disabled={loading}
      >
        {cancelText}
      </button>
      <button
        className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6 disabled:cursor-not-allowed disabled:bg-indigo-400"
        onClick={handleYes}
        type="button"
        disabled={loading}
      >
        {loading ? <Loader color="#ffffff" height="h-4 w-4" /> : okText}
      </button>
    </div>
  </ModalBasic>
);

export default BasicConfirmationModal;
