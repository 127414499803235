import React from 'react';
import Tooltip from 'components/Tooltip';
import useDropdown from 'hooks/useDropdown';
import Transition from '../../utils/Transition';

const TableSettingsDropdown = ({
  tableColumns,
  handleChangeTableColumn,
  ids = [],
  bottomOfTable = false,
  columnVisibility,
}) => {
  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(false, bottomOfTable, ids);

  return (
    <div className={` justify-end relative inline-flex `}>
      <Tooltip
        content="Show/Hide Columns"
        contentClassName=" !py-px shadow-md overflow-visible text-sm text-[#667085] relative"
        position="bottom"
        isFixed
        ids={ids}
        className=""
      >
        <button
          ref={trigger}
          aria-haspopup="true"
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          aria-expanded={dropdownOpen}
          type="button"
          className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="tabler-icon tabler-icon-columns  fill-[#868e96] text-[#868e96]"
          >
            <path d="M4 6l5.5 0" />
            <path d="M4 10l5.5 0" />
            <path d="M4 14l5.5 0" />
            <path d="M4 18l5.5 0" />
            <path d="M14.5 6l5.5 0" />
            <path d="M14.5 10l5.5 0" />
            <path d="M14.5 14l5.5 0" />
            <path d="M14.5 18l5.5 0" />
          </svg>
        </button>
      </Tooltip>

      <Transition
        show={dropdownOpen}
        tag="div"
        className={`z-10 w-fit bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          bottomOfTable ? 'fixed' : 'right-0 z-10 absolute top-full'
        }`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        <div
          ref={dropdown}
          className="text-sm text-slate-600 dark:text-slate-300 w-[200px] p-2"
          // onFocus={() => setDropdownOpen(true)}
          // onBlur={() => setDropdownOpen(false)}
        >
          <div className="flex flex-col gap-[2px]">
            {tableColumns?.map((column, index) => (
              <label
                className="flex gap-3 items-center hover:bg-slate-50 p-1 cursor-pointer"
                key={column?.id}
                htmlFor={`${column?.id}-${index}`}
              >
                <div className="form-switch form-switch-indigo form-switch-short">
                  <input
                    type="checkbox"
                    id={`${column?.id}-${index}`}
                    className="sr-only"
                    checked={columnVisibility?.[column?.id]}
                    onChange={() => handleChangeTableColumn(column?.id)}
                  />
                  <label
                    className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                    htmlFor={`${column?.id}-${index}`}
                  >
                    <span className="bg-gray-300" aria-hidden="true" />
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
                {column?.name}
              </label>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default TableSettingsDropdown;
