import React from 'react';

const Tabs = ({ activeTab, setActiveTab, TABS, InvitesCount }) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-6 flex-wrap">
    <div
      onClick={() => setActiveTab(TABS.editPractice)}
      className={`px-1 text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS.editPractice &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Edit Practice
    </div>
    <div
      onClick={() => setActiveTab(TABS.teamMembers)}
      className={`px-1 text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS.teamMembers &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Team Members
    </div>
    <div
      onClick={() => setActiveTab(TABS.clientInvites)}
      className={`px-1 text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS.clientInvites &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Client Invites{' '}
      {InvitesCount > 0 && (
        <span className="ml-2 rounded-[4px] bg-indigo-500 text-sm !text-white min-h-4 min-w-6 inline-flex justify-center items-center">
          {InvitesCount}
        </span>
      )}
    </div>
    <div
      onClick={() => setActiveTab(TABS.billing)}
      className={`px-1 text-sm  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
         ${
           activeTab === TABS.billing &&
           ' !border-indigo-500  font-medium !text-[#101828]'
         } `}
      role="button"
      tabIndex="0"
    >
      Billing
    </div>
  </div>
);

export default Tabs;
