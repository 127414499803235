import moment from 'moment';
import React, { useMemo } from 'react';
import { formatValueWithSign, getFormattedDate } from 'utils/Utils';
import { CalendarIcon } from '../CalendarIcon';
import { TableHeader } from '../TableHeader';

const BalanceSheetTable = ({
  generatedReport,
  currencySymbol,
  setActiveTab = () => {},
  values,
  activeTab = 'details',
  handleAccountClick = () => {},
  teamDateFormat = 'mm/dd/yyyy',
  isPdf = false,
}) => {
  const price = useMemo(() => {
    const assets =
      generatedReport.find((obj) => obj.name === 'Assets')?.total || 0;
    const liabilities =
      generatedReport.find((obj) => obj.name === 'Liabilities')?.total || 0;
    const equity =
      generatedReport.find((obj) => obj.name === 'Equity')?.total || 0;
    return Math.abs(assets - (liabilities + equity));
  }, [generatedReport]);

  return (
    <>
      {!isPdf && (
        <div className="grid grid-cols-7 text-center py-8">
          <TableHeader
            price={generatedReport.find((obj) => obj.name === 'Assets')?.total}
            label="Assets"
            sign={currencySymbol}
          />

          <div className="text-[#101828] self-center">
            <p className="font-semibold"> =</p>
          </div>

          <TableHeader
            price={
              generatedReport.find((obj) => obj.name === 'Liabilities')?.total
            }
            label="Liabilities"
            sign={currencySymbol}
          />
          <div className="text-[#101828] self-center">
            <p className="font-semibold"> +</p>
          </div>
          <TableHeader
            price={generatedReport.find((obj) => obj.name === 'Equity')?.total}
            label="Equity"
            sign={currencySymbol}
          />

          <div className="text-[#101828] self-center">
            <p className="font-semibold">|</p>
          </div>
          <TableHeader
            price={price}
            label="Difference"
            color={
              parseFloat(price.toFixed(2)) === 0
                ? 'text-[#40AA00]'
                : 'text-indigo-500'
            }
            sign={currencySymbol}
          />
        </div>
      )}
      {!isPdf && (
        <div className="flex justify-center border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
          <div
            onClick={() => setActiveTab('summary')}
            className={`text-sm text-center  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
    ${
      activeTab === 'summary' &&
      ' !border-indigo-500  font-medium !text-[#101828]'
    } `}
            role="button"
            tabIndex={0}
          >
            Summary
          </div>

          <div
            onClick={() => setActiveTab('details')}
            className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
    ${
      activeTab === 'details' &&
      ' !border-indigo-500  font-medium !text-[#101828]'
    } `}
            role="button"
            tabIndex={0}
          >
            Details
          </div>
        </div>
      )}
      <table className="table-fixed w-full dark:text-slate-300">
        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
          <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
            <th className="flex-1">
              <div className="text-left font-medium">Account</div>
            </th>

            <th className={values.secondStartDate && 'w-[9rem]'}>
              <div className="flex gap-1 align-middle text-right justify-center font-bold">
                <CalendarIcon />
                {isPdf
                  ? moment(values.firstEndDate).format('MMM DD, YYYY')
                  : moment(
                      getFormattedDate(values.firstEndDate, teamDateFormat),
                    ).format('ll')}
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
          {generatedReport?.map((category) => (
            <>
              <tr className="w-full px-[1.375rem] flex items-center justify-between min-h-12 bg-[#DDD] text-[#101828] font-semibold">
                <td colSpan="2" className="w-full text-left">
                  {category.name}
                </td>
              </tr>
              {category.subTypes?.map((subType) => (
                <>
                  {activeTab === 'details' && (
                    <>
                      <tr className="w-full px-[1.375rem] flex items-center justify-between min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 font-semibold false text-[#101828] bg-[#EEEEEE]">
                        <td colSpan="2" className="w-full whitespace-nowrap">
                          <div className="w-full text-left pl-4">
                            {subType.name}
                          </div>
                        </td>
                      </tr>
                      {subType.accounts.map((account) => (
                        <tr
                          className="w-full px-[1.375rem] flex items-center justify-between min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5"
                          key={account?.id}
                        >
                          <td className="flex-1">
                            <div
                              className={`w-full text-left pl-8 pr-4 cursor-pointer ${isPdf ? '' : 'text-indigo-500'}`}
                              onClick={() => handleAccountClick(account)}
                              role="button"
                              tabIndex={0}
                            >
                              {account.account.name}
                            </div>
                          </td>
                          <td className="whitespace-nowrap text-right">
                            <div className="w-[9rem] text-[#101828]">
                              {formatValueWithSign(
                                account.total || 0,
                                false,
                                currencySymbol,
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  <tr className="w-full px-[1.375rem] flex items-center justify-between min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#101828] font-semibold">
                    <td className="flex-1 text-left px-4 ">
                      Total {subType.name}
                    </td>
                    <td className="whitespace-nowrap text-right">
                      <div className="w-[9rem] text-[#101828] font-semibold">
                        {formatValueWithSign(
                          subType.total || 0,
                          false,
                          currencySymbol,
                        )}
                      </div>
                    </td>
                  </tr>
                </>
              ))}
              <tr className="w-full px-[1.375rem] flex items-center justify-between min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#101828] font-semibold">
                <td className=" text-left flex-1 pr-4">
                  Total {category.name}
                </td>
                <td className="whitespace-nowrap text-right">
                  <div className="w-[9rem] text-[#101828] font-semibold">
                    {formatValueWithSign(
                      category.total || 0,
                      false,
                      currencySymbol,
                    )}
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      {!isPdf && (
        <div className=" flex justify-center">
          <button
            className="w-[7rem] cursor-pointer justify-center mt-2  rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-10  bg-indigo-500 text-white"
            type="button"
            onClick={() =>
              activeTab === 'summary'
                ? setActiveTab('details')
                : setActiveTab('summary')
            }
          >
            {activeTab === 'summary' ? 'View Details' : 'View Summary'}
          </button>
        </div>
      )}
    </>
  );
};

export default BalanceSheetTable;
