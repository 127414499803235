import Tooltip from 'components/Tooltip';
import React from 'react';

const FirmSubscriptionsTab = ({ firmSubscriptionsDetails }) => {
  const getSubscriptionsCount = (subscriptions, clientOwned = true) => {
    let total = 0;
    subscriptions?.forEach((s) => {
      if (s?.name !== 'Employee Seat') {
        total += s?.counts;
      }
    });
    return total;
  };

  const getReferralAmount = (subscriptions, clientOwned = true) => {
    let total = 0;
    subscriptions?.forEach((s) => {
      if (s?.name !== 'Employee Seat') {
        total += s?.price;
      }
    });
    return clientOwned ? total * 0.2 : total;
  };

  const getReferralEquation = (subscriptions, clientOwned = true) => {
    let equation = '';
    subscriptions?.forEach((s, i) => {
      if (s?.price > 0 && s?.name !== 'Employee Seat') {
        if (!equation && clientOwned) {
          equation += '(';
        }
        if (equation?.length > 4) {
          equation += ' + ';
        }
        equation += ` ${s?.name}: $${parseFloat(
          s?.price / s?.counts,
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}/m * ${s?.counts}`;
      }
    });
    if (equation && clientOwned) {
      equation += ' ) ';
    }
    if (equation && clientOwned) {
      equation += ' * 20% referral ';
    }
    if (equation) {
      equation += ' = ';
    }
    equation += ` $${parseFloat(
      getReferralAmount(subscriptions, clientOwned),
    )?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    return equation;
  };

  return (
    <div>
      {' '}
      <h3 className="text-[22px] font-medium text-[#00000099] mb-4">
        COUNT Subscriptions
      </h3>
      <div className="grid md:grid-cols-2 space-y-4 md:space-y-0 divide-y md:divide-y-0 md:divide-x divide-[#D0D5DD] border border-[#D0D5DD] rounded-[12px] p-4 mb-3">
        <div className="md:pr-[10%]">
          <div className="text-[18px] font-medium text-[#666666] leading-tight mb-6 flex items-center">
            Advisor Owned:{' '}
            <span className="font-normal ml-1 mr-1">
              {getSubscriptionsCount(
                firmSubscriptionsDetails?.categorizedAdvisory,
              ) || 0}{' '}
              subscriptions{' '}
            </span>
            <Tooltip
              content={
                <div className=" normal-case">
                  <ul className=" list-disc pl-4">
                    {firmSubscriptionsDetails?.categorizedAdvisory?.map(
                      (d, i) =>
                        d?.name !== 'Employee Seat' && (
                          <li key={i}>
                            <span className="font-semibold">{d?.name}:</span>{' '}
                            {d?.counts}
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              }
              contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage']}
              className="mx-1"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-px"
              >
                <path
                  d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                  fill="#667085"
                />
              </svg>
            </Tooltip>
          </div>
          <h2 className="text-[18px] font-medium text-[#666666] w-full">
            {' '}
            <span className="font-normal"> </span>
            {/* {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)} */}
            {/* {parseFloat(stat?.income)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} */}
          </h2>
          <h2 className="flex gap-2 items-center text-[18px] font-medium text-[#666666] w-full">
            Subscription Payment:{' '}
            <span className="font-normal">
              {' '}
              $
              {parseFloat(
                getReferralAmount(
                  firmSubscriptionsDetails?.categorizedAdvisory,
                  false,
                ),
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              monthly{' '}
            </span>
            <Tooltip
              content={
                <div className=" normal-case">
                  {getReferralEquation(
                    firmSubscriptionsDetails?.categorizedAdvisory,
                    false,
                  )}
                </div>
              }
              contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage']}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-px"
              >
                <path
                  d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                  fill="#667085"
                />
              </svg>
            </Tooltip>
          </h2>
        </div>
        <div className=" pt-4 md:pt-0 md:px-[10%]">
          <div className="text-[18px] font-medium text-[#666666] leading-tight mb-6 flex items-center">
            Client Owned:{' '}
            <span className="font-normal ml-1 mr-1">
              {getSubscriptionsCount(
                firmSubscriptionsDetails?.categorizedClient,
              ) || 0}{' '}
              subscriptions{' '}
            </span>
            <Tooltip
              content={
                <div className=" normal-case">
                  <ul className=" list-disc pl-4">
                    {firmSubscriptionsDetails?.categorizedClient?.map(
                      (d, i) =>
                        d?.name !== 'Employee Seat' && (
                          <li key={i}>
                            <span className="font-semibold">{d?.name}:</span>{' '}
                            {d?.counts}
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              }
              contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage']}
              className="mx-1"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-px"
              >
                <path
                  d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                  fill="#667085"
                />
              </svg>
            </Tooltip>
          </div>
          <h2 className="flex gap-2 items-center text-[18px] font-medium text-[#666666] w-full">
            Monthly Credit:{' '}
            <span className="font-normal">
              {' '}
              20% referral | $
              {parseFloat(
                getReferralAmount(
                  firmSubscriptionsDetails?.categorizedClient,
                  true,
                ),
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              credit{' '}
            </span>
            <Tooltip
              content={
                <div className=" normal-case">
                  {getReferralEquation(
                    firmSubscriptionsDetails?.categorizedClient,
                    true,
                  )}
                </div>
              }
              contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
              position="bottom"
              isFixed
              ids={['accountantMainPage']}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-px"
              >
                <path
                  d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                  stroke="#667085"
                  strokeWidth="0.784912"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                  fill="#667085"
                />
              </svg>
            </Tooltip>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default FirmSubscriptionsTab;
