import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import * as Yup from 'yup';
import { updateTransactionCategory } from 'API/backend_helper';
import { toast } from 'react-toastify';
import { getCurrencySymbol, transformAccounts } from 'utils/Utils';
import WarningBox from 'components/WarningBox';
import Tooltip from 'components/Tooltip';

const ManualTransfer = ({
  setNoClick,
  allCategories,
  reloadTransaction,
  reloadTransactions,
  transaction,
  setIsManual,
  isManual,
  setClose,
}) => {
  const formikRef = useRef();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (allCategories?.length > 0) {
      const temp = [];
      allCategories?.forEach((cat) => {
        temp.push(...(cat?.categories || []));
      });
      // temp = temp?.filter((cat) => cat?.id !== transaction?.account?.id);
      const accountsFilter = transformAccounts(temp, 'PHYSICAL_ACCOUNT');
      setAccounts(accountsFilter);
    }
  }, [allCategories]);

  const getSelectedAccountName = (id) => {
    const temp = [];
    allCategories?.forEach((cat) => {
      temp.push(...(cat?.categories || []));
    });
    const account = temp?.find((category) => category?.id === id);
    if (account) {
      return `${account?.name}${account?.mask ? ` (...${account.mask})` : ''}`;
    }
    return null;
  };

  useEffect(() => {
    if (
      transaction?.id &&
      transaction?.type === 'Transfer' &&
      transaction?.categoryAccount?.id &&
      formikRef?.current?.setFieldValue
    ) {
      formikRef.current.setFieldValue(
        'categoryAccountId',
        transaction?.categoryAccount?.id,
      );
    }
  }, [transaction, formikRef]);

  const onSubmit = async (values) => {
    setSubmitLoader(true);
    try {
      await updateTransactionCategory(transaction?.id, values);
      // await reloadTransaction({});
      toast.success('Transaction transferred successfully');
      setSubmitLoader(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setSubmitLoader(false);
      console.log('error', e);
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="px-10">
        <h3 className="text-[22px] font-medium text-slate-600 mb-4">
          {transaction?.amount?.toString().charAt(0) === '-' ? 'To' : 'From'}:
        </h3>
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm text-slate-600 font-semibold mb-1">
              Account:
            </label>
            <div className="block text-base text-slate-600 mb-1">
              {transaction?.account?.name}
            </div>
          </div>
          <div>
            <label className="block text-sm text-slate-600 font-semibold mb-1">
              Amount:
            </label>
            <div className="block text-base text-slate-600 mb-1">
              {transaction?.amount?.toString().charAt(0) === '-' ? '' : '-'}
              {getCurrencySymbol(transaction?.currency)}
              {transaction?.amount?.toString().charAt(0) === '-'
                ? parseFloat(
                    transaction?.amount?.toString().replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(transaction?.amount)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
          </div>
        </div>
        <h3 className="text-[22px] font-medium text-slate-600 my-4">
          {transaction?.amount?.toString().charAt(0) === '-' ? 'From' : 'To'}:
        </h3>
      </div>
      <Formik
        initialValues={{
          notes: '',
          categoryAccountId: null,
          autoCreateMatching: false,
        }}
        validationSchema={Yup.object({
          categoryAccountId: Yup.number().required('Please Select Account'),
          autoCreateMatching: Yup.bool().optional(),
          notes: Yup.string().optional(),
        })}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className="flex-1 flex flex-col justify-between">
            <div className="flex flex-col items-start gap-[1.875rem] w-full px-10 mb-8">
              <div className="grid sm:grid-cols-2 gap-4 w-full ">
                <div className="flex flex-col items-start w-full">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                    htmlFor="categoryAccountId"
                  >
                    Account <span className="text-rose-500">*</span>
                  </label>
                  <CategoriesDropdown
                    allCategories={accounts}
                    selectedCategoryId={validation.values.categoryAccountId}
                    setSelectedCategoryId={(value) => {
                      validation.setFieldValue('categoryAccountId', value);
                    }}
                    isSetCategoryStyle
                    id="categoryAccountId"
                    name="categoryAccountId"
                    height="h-12 !max-w-full"
                    type="account"
                    setNoClick={setNoClick}
                    bottomOfTable
                    // isTableDropdown
                    ids={[
                      'transactionsCategoryModal',
                      'transactionsCategoryModalTransferTab',
                    ]}
                    placeholder="Select Account"
                    buttonClass={`${
                      validation.touched.categoryAccountId &&
                      validation.errors.categoryAccountId
                        ? '!border-rose-500'
                        : ''
                    }`}
                    isError={
                      validation.touched.categoryAccountId &&
                      validation.errors.categoryAccountId
                    }
                  />
                  {validation.touched.categoryAccountId &&
                  validation.errors.categoryAccountId ? (
                    <div className="text-xs mt-1 text-rose-500">
                      {validation.errors.categoryAccountId}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col items-start w-full">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                    htmlFor="notes"
                  >
                    Reference
                  </label>
                  <input
                    id="notes"
                    type="notes"
                    placeholder="Reference"
                    name="notes"
                    className={`btn text-base h-12 w-full rounded-[0.313rem] border border-[#D0D5DD] text-slate-600leading-6 font-normal px-4 py-3.5 bg-white
                              placeholder:text-base placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal ${
                                validation.touched.notes &&
                                validation.errors.notes &&
                                'border-rose-300'
                              }`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.notes || ''}
                  />
                  {validation.touched.notes && validation.errors.notes ? (
                    <div className="text-xs mt-1 text-rose-500">
                      {validation.errors.notes}
                    </div>
                  ) : null}
                </div>

                {validation.values.categoryAccountId &&
                  !transaction?.linkedTransferTransactionId && (
                    <label className="col-span-full w-fit text-sm text-[#667085] inline-flex items-center gap-4">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5"
                        onChange={() => {
                          validation.setFieldValue(
                            'autoCreateMatching',
                            !validation.values.autoCreateMatching,
                          );
                        }}
                        checked={validation.values.autoCreateMatching}
                      />
                      <p>
                        Auto-create a matching transaction in account{' '}
                        <span className="font-semibold">
                          {getSelectedAccountName(
                            validation.values.categoryAccountId,
                          )}
                        </span>
                        ?
                      </p>
                    </label>
                  )}
              </div>
              {transaction?.linkedTransferTransaction?.id && (
                <WarningBox
                  className="mb-5 mt-0"
                  content="You cannot create a matching transaction for a transaction that already has a match."
                />
              )}
            </div>
            <footer className="px-10 py-6 border-t border-[#D0D5DD]">
              <div className="flex gap-4 justify-between flex-wrap">
                <button
                  type="button"
                  onClick={() => setIsManual(!isManual)}
                  className="h-11 w-fit inline-flex items-center underline justify-center gap-2  text-indigo-500"
                >
                  Switch to Auto Transfer
                </button>
                <div className="flex gap-4 ml-auto">
                  <Tooltip
                    content="You cannot transfer a transaction to the same account it originates from, or back to the originating account."
                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative min-w-[270px] text-wrap tooltipArrowRight"
                    size="sm"
                    position="left"
                    tooltipShow={
                      (formikRef?.current?.values?.categoryAccountId ===
                        transaction?.categoryAccount?.id &&
                        transaction?.type === 'Transfer') ||
                      transaction?.account?.id ===
                        formikRef?.current?.values?.categoryAccountId
                    }
                  >
                    <button
                      type="submit"
                      className="h-11 w-fit px-6 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:bg-indigo-300 disabled:cursor-not-allowed"
                      disabled={
                        (formikRef?.current?.values?.categoryAccountId ===
                          transaction?.categoryAccount?.id &&
                          transaction?.type === 'Transfer') ||
                        submitLoader ||
                        transaction?.account?.id ===
                          formikRef?.current?.values?.categoryAccountId
                      }
                    >
                      {submitLoader && (
                        <svg
                          className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                        </svg>
                      )}
                      Transfer
                    </button>
                  </Tooltip>
                </div>
              </div>
            </footer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ManualTransfer;
