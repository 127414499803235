import React from 'react';

function SearchForm({
  placeholder,
  searchVal,
  setSearchVal,
  transaction = false,
  height = '',
  crossButtonClassName = '',
  className = '',
  id = 'action-search',
  searchIconClassName = '',
}) {
  return (
    <div className={`relative w-fit  rounded-md ${className}`}>
      <label htmlFor={id} className="sr-only">
        Search
      </label>
      <input
        id={id}
        className={`form-input pl-9 pr-7 ${transaction ? 'h-10' : 'h-11'} ${height} rounded-[5px] text-[#667085] border-[#D0D5DD] focus:border-slate-400   placeholder:text-[#66708580] w-[330px] max-w-full`}
        type="search"
        placeholder={placeholder}
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
      />
      <button
        className="absolute inset-0 right-auto group left-3 "
        type="button"
        aria-label="Search"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`text-[#667085] stroke-[#667085] ${searchIconClassName}`}
        >
          <path
            d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
            className=" stroke-current"
            strokeOpacity="1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {searchVal && (
        <button
          onClick={() => {
            setSearchVal('');
          }}
          className={`absolute h-full top-0 right-0 group px-2 ${crossButtonClassName}`}
          type="button"
        >
          <svg
            className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
          </svg>
        </button>
      )}
    </div>
  );
}

SearchForm.defaultProps = {
  placeholder: 'Search…',
};

export default SearchForm;
