import React, { useEffect, useState } from 'react';
import useDropdown from 'hooks/useDropdown';
import Transition from '../../../utils/Transition';
import { OWNER } from '../../../utils/Utils';

export const MEMBERS_ACCESS_TYPES = [
  {
    id: 'admin',
    period: 'Admin',
  },
  {
    id: 'standard',
    period: 'Standard',
  },
  {
    id: 'read only',
    period: 'Read Only',
  },
  {
    id: 'accounts approve & pay',
    period: 'Accounts Approve & Pay',
  },
  {
    id: 'accounts draft',
    period: 'Accounts Draft',
  },

  {
    id: 'view company reports',
    period: 'View Company Reports',
  },
  {
    id: 'billing clerk',
    period: 'Billing Clerk',
  },
];

const MembersDropdown = ({
  fullWidth = false,
  onChangeValue,
  value = 'admin',
  isTable = false,
  id,
  disabled = false,
  overflowHidden = false,
  isTableDropdown = false,
  ids = [],
  bottomOfTable = false,
  setNoClick = () => {},
}) => {
  const [selected, setSelected] = useState(value);

  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(
    isTableDropdown,
    bottomOfTable,
    ids,
    overflowHidden,
    () => {},
    MEMBERS_ACCESS_TYPES?.length,
    setNoClick,
  );

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (dropdownOpen) {
      dropdown.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [dropdownOpen]);

  return (
    <div className={`relative inline-flex ${fullWidth ? 'w-full' : ''}`}>
      <button
        ref={trigger}
        className={`btn justify-between min-w-44 ${
          fullWidth ? 'w-full' : ''
        } bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200`}
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        disabled={isTable || value === OWNER || disabled}
        aria-expanded={dropdownOpen}
        type="button"
      >
        <span className="flex items-center">
          <span className="capitalize">{selected}</span>
        </span>
        <svg
          className="shrink-0 ml-1 fill-current text-slate-400"
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`${
          bottomOfTable
            ? 'fixed !w-fit min-w-44'
            : 'top-full left-0 z-10 absolute'
        }  left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded shadow-lg overflow-hidden mt-1`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        <div
          ref={dropdown}
          className="font-medium text-sm pointer-events-auto text-slate-600 dark:text-slate-300 z-20 overflow-auto scrollbar  py-1.5 flex flex-col"
          // onFocus={() => setDropdownOpen(true)}
          // onBlur={() => setDropdownOpen(false)}
        >
          {MEMBERS_ACCESS_TYPES.map((option) => (
            <button
              key={option.id}
              tabIndex="0"
              className={`flex items-center w-full hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer ${
                option.id === selected && 'text-indigo-500'
              }`}
              disabled={option.disabled}
              onClick={() => {
                setSelected(option.id);
                setDropdownOpen(false);
                onChangeValue(id, option?.id);
              }}
              type="button"
            >
              <svg
                className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                  option.id !== selected && 'invisible'
                }`}
                width="12"
                height="9"
                viewBox="0 0 12 9"
              >
                <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
              </svg>
              <span>{option.period}</span>
            </button>
          ))}
        </div>
      </Transition>
    </div>
  );
};

export default MembersDropdown;
