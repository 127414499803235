import React, { useEffect, useState } from 'react';
import ModalBasic from 'components/ModalBasic';
import { Loader } from 'components/Svg';
import TextInputDash from 'components/TextInputDash';

const AddNotesModal = ({
  isOpen,
  setIsOpen,
  notes,
  handleUpdateCustomerNotes,
}) => {
  const [data, setData] = useState(notes);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateNotes = async () => {
    setIsLoading(true);
    try {
      await handleUpdateCustomerNotes(data);
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setData(notes);
    }
  }, [notes, isOpen]);

  return (
    <ModalBasic
      title="Add Notes"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
        <TextInputDash
          label="Notes"
          type="textarea"
          placeholder="Enter Notes"
          value={data}
          onChange={(e) => setData(e.target.value)}
        />
      </div>
      <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
          onClick={() => setIsOpen(false)}
          type="button"
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6 disabled:cursor-not-allowed disabled:bg-indigo-400"
          onClick={(e) => {
            e.stopPropagation();
            handleUpdateNotes();
          }}
          type="button"
          disabled={isLoading}
        >
          {isLoading ? <Loader color="#ffff" height="h-5 w-5" /> : 'Add'}
        </button>
      </div>
    </ModalBasic>
  );
};

export default AddNotesModal;
