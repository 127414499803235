import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInputDash from 'components/TextInputDash';
import { createAccountingFirmTasks, getTeamById } from 'API/backend_helper';
import { Loader } from 'components/Svg';
import {
  getFormattedDate,
  TaskStatusOptions,
  TEAM_DATE_FORMAT,
  TEAM_ID,
} from 'utils/Utils';
import DatePickerDash from 'components/DatePickerDash';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DropdownFull from 'components/DropdownFull';
import useBasicDropdownSearch from 'hooks/useBasicDropdownSearch';
import TasksDropdown from '../TasksDropdown';
import AssigneeDropdown from '../AssigneeDropdown';

const InlineTaskModal = ({
  buttonContainerClass = '',
  formContainerClass = '',
  fieldsContainerClass = '',
  buttonsContainerClass = '',
  firmMembers = [],
  taskList = {},
  ids = [],
  loadData,
  accountingFirmId,
  defaultAccountingWS,
  disabledClients = false,
  clients = [],
}) => {
  const formikRef = useRef(null);
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            ele.style.pointerEvents = 'none';
          }
        });
      },
      onClose() {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            ele.style.pointerEvents = 'auto';
          }
        });
      },
    }),
    [ids, teamDateFormat],
  );

  const [showForm, setShowForm] = useState(false);
  const [taskListId, setTaskListId] = useState(null);
  const [clientUsers, setClientUsers] = useState([]);
  const [clientUsersLoading, setClientUsersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadClientUsers = async (teamId) => {
    setClientUsersLoading(true);
    try {
      const res = await getTeamById(teamId);
      setClientUsers(res?.users);
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientUsersLoading(false);
    }
  };

  useEffect(() => {
    if (taskList?.teamId && !taskListId && showForm) {
      setTaskListId(taskList?.teamId);
      loadClientUsers(taskList?.teamId || defaultAccountingWS);
    } else if (!showForm) {
      setTaskListId(null);
    }
  }, [taskList, showForm, defaultAccountingWS]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formattedDate = getFormattedDate(
      values.deadlineDate,
      teamDateFormat || 'mm/dd/yyyy',
    );
    if (!values.assigneeId) {
      values.assigneeId = null;
    }

    try {
      let res = {};

      localStorage.setItem(TEAM_ID, values.clientId || defaultAccountingWS);
      const data = new FormData();
      data.append('taskName', values.taskName);
      data.append('deadlineDate', formattedDate);
      data.append('taskType', values.taskType);
      data.append('description', values.description);
      data.append('taskStatus', values.taskStatus);
      data.append('priority', values.priority);
      data.append('assigneeId', values.assigneeId);
      data.append('projectId', values.projectId);
      data.append('accountingFirmId', accountingFirmId);
      if (values.taskListId) {
        data.append('taskListId', values.taskListId);
      }
      res = await createAccountingFirmTasks(data);

      if (loadData) {
        await loadData({
          newTaskId: res?.id,
        });
      }
      toast.success('Task created successfully');
      setShowForm(false);
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.setItem(TEAM_ID, '');
      setIsLoading(false);
    }
  };

  const {
    dropdownOptions: dropdownClients,
    handleDropdownSearch: handleClientsSearch,
    resetDropdownSearch: resetClientsSearch,
  } = useBasicDropdownSearch({ options: clients });

  return !showForm ? (
    <div className={`${buttonContainerClass}`}>
      <button
        type="button"
        className="text-base  h-[30px] rounded-[4px] text-[#667085] inline-flex gap-1.5 items-center w-[112px] justify-center"
        onClick={(e) => {
          e.stopPropagation();
          setShowForm(true);
        }}
      >
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.25 1.125V9.875M0.875 5.5H9.625"
            stroke="#667085"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Add Task
      </button>
    </div>
  ) : (
    <div className={`${formContainerClass}`}>
      <Formik
        enableReinitialize
        initialValues={{
          taskName: '',
          taskType: 'toDo',
          description: '',
          assigneeId: null,
          taskStatus: 'notStarted',
          deadlineDate: '',
          priority: 'low',
          projectId: taskList?.projectId || null,
          clientId: taskList?.teamId || defaultAccountingWS || null,
          accountingFirmId: null,
          taskListId: taskList?.id || null,
        }}
        validationSchema={Yup.object({
          taskName: Yup.string()
            .required('Task Title is required')
            .max(
              255,
              'Task title must be less than or equal to 255 characters',
            ),
          description: Yup.string(),
          taskStatus: Yup.string(),
          deadlineDate: Yup.string(),
          priority: Yup.string().required('Priority is required'),
          clientId: '',
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className={`${fieldsContainerClass}`}>
            <div className="flex gap-3 items-start">
              <TextInputDash
                placeholder="Enter Task Title"
                value={validation.values.taskName}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                paddingBottom={
                  validation.touched.taskName && validation.errors.taskName
                    ? 'pb-5'
                    : 'pb-0'
                }
                name="taskName"
                inputClass="!h-10 !mb-0"
                error={
                  validation.touched.taskName && validation.errors.taskName
                    ? validation.errors.taskName
                    : ''
                }
              />
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  validation.setFieldValue(
                    'priority',
                    validation.values.priority === 'high' ? 'low' : 'high',
                  );
                }}
                className="mt-3"
              >
                {validation.values.priority === 'high' ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.27941 1.55205C7.57876 0.630739 8.88217 0.63074 9.18153 1.55205L10.2511 4.84385C10.385 5.25587 10.7689 5.53483 11.2022 5.53483H14.6634C15.6321 5.53483 16.0348 6.77445 15.2511 7.34385L12.451 9.37829C12.1005 9.63293 11.9538 10.0843 12.0877 10.4963L13.1573 13.7881C13.4566 14.7094 12.4021 15.4756 11.6184 14.9062L8.81825 12.8717C8.46777 12.6171 7.99317 12.6171 7.64268 12.8717L4.84251 14.9062C4.0588 15.4756 3.00432 14.7094 3.30367 13.7881L4.37324 10.4963C4.50712 10.0843 4.36046 9.63293 4.00997 9.37829L1.2098 7.34385C0.426088 6.77445 0.828864 5.53483 1.79759 5.53483H5.25879C5.69201 5.53483 6.07597 5.25587 6.20984 4.84385L7.27941 1.55205Z"
                      fill="#E48642"
                    />
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.89659 1.27062C8.00168 0.947176 8.45926 0.947176 8.56435 1.27062L9.809 5.10127C9.98988 5.65794 10.5086 6.03483 11.0939 6.03483H15.1217C15.4618 6.03483 15.6032 6.47001 15.3281 6.66991L12.0695 9.03738C11.596 9.38142 11.3979 9.99125 11.5787 10.5479L12.8234 14.3786C12.9285 14.702 12.5583 14.971 12.2832 14.7711L9.0246 12.4036C8.55107 12.0596 7.90986 12.0596 7.43633 12.4036L4.17779 14.7711C3.90265 14.971 3.53246 14.702 3.63755 14.3786L4.88221 10.5479C5.06308 9.99125 4.86494 9.38142 4.39141 9.03738L1.13286 6.66991C0.857725 6.47001 0.999125 6.03483 1.33921 6.03483H5.36699C5.95231 6.03483 6.47106 5.65794 6.65193 5.10127L7.89659 1.27062Z"
                      stroke="#D0D5DD"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="w-full">
              <DropdownFull
                options={dropdownClients}
                selected={validation.values.clientId}
                setSelected={(value) => {
                  validation.setFieldValue('clientId', value);
                  validation.setFieldValue('assigneeId', null);
                  loadClientUsers(value);
                }}
                name="name"
                height="h-10"
                scrollIntoView={false}
                search
                handleSearch={(value) =>
                  handleClientsSearch(value, validation.values.clientId)
                }
                resetSearch={resetClientsSearch}
                searchPlaceholder="Type To Search"
                disabled={disabledClients}
                bottomOfTable
                ids={ids}
              />
              {validation.touched.clientId && validation.errors.clientId ? (
                <span className="text-xs text-rose-500 h-[14px] -mt-1">
                  {validation.errors.clientId}
                </span>
              ) : null}
            </div>
            <div className="pointer-events-auto">
              <DatePickerDash
                required
                validation={validation}
                placeholder="Deadline"
                value={
                  validation.values.deadlineDate
                    ? validation.values.deadlineDate
                    : null
                }
                options={options}
                onChange={(selectedDates) => {
                  validation.setFieldValue('deadlineDate', selectedDates[0]);
                }}
                name="deadlineDate"
                id="deadlineDate"
                error={
                  validation.touched.deadlineDate &&
                  validation.errors.deadlineDate
                    ? validation.errors.deadlineDate
                    : ''
                }
                width="w-full"
                inputClass="!h-10"
                onBlur={(e) => {
                  if (!e.target.classList?.value?.includes('active')) {
                    validation.setFieldValue('deadlineDate', e.target.value);
                  }
                }}
              />
            </div>
            <div>
              {clientUsersLoading ? (
                <div className="w-full justify-center items-center h-10 flex">
                  <Loader width="w-6" height="h-6" />
                </div>
              ) : (
                <AssigneeDropdown
                  options={
                    validation.values.clientId
                      ? [
                          {
                            name: 'Client Members',
                            users: clientUsers || [],
                            accountingFirmId: null,
                          },
                          {
                            name: 'Firm Members',
                            users: firmMembers,
                          },
                        ]
                      : firmMembers
                  }
                  accountingFirmView={validation.values.clientId}
                  selected={validation.values.assigneeId}
                  setSelected={(value) => {
                    validation.setFieldValue('assigneeId', value);
                  }}
                  users
                  height="h-10"
                  scrollIntoView={false}
                  placeholder="Select Assignee"
                  bottomOfTable
                  ids={ids}
                  transitionClass="!translate-x-0"
                />
              )}
            </div>
            <div
              className={`flex justify-between flex-wrap gap-4 ${buttonsContainerClass}`}
            >
              <div className="flex h-10 items-center">
                <TasksDropdown
                  statusOptions={TaskStatusOptions}
                  task={{
                    status: validation.values.taskStatus,
                  }}
                  isFlyout
                  handleUpdateTask={(id) =>
                    validation.setFieldValue('taskStatus', id)
                  }
                  bottomOfTable
                  ids={ids}
                />
              </div>
              <div className="ml-auto flex h-fit justify-end items-center gap-4">
                <button
                  className="h-10 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color="#FFFFFF" width="w-4" height="h-4" />
                  ) : (
                    'Save'
                  )}
                </button>
                <button
                  type="button"
                  className="h-10 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600 disabled:cursor-not-allowed"
                  onClick={() => setShowForm(false)}
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InlineTaskModal;
