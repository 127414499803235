import React, { useEffect, useState } from 'react';
import {
  formatReportData,
  getCurrencySymbol,
  PnlNoTagValue,
} from 'utils/Utils';
import { useSearchParams } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { calculateDifferenceAndPercent, mergeArrays } from '.';

import ProfitAndLossTable from './ProfitAndLossTable';

const ProfitAndLossPdf = () => {
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({});

  const accountId = searchParams.get('accounts');
  const startDate = searchParams.get('startDate');
  const secondStartDate = searchParams.get('secondStartDate');
  const endDate = searchParams.get('endDate');
  const secondEndDate = searchParams.get('secondEndDate');
  const currency = searchParams.get('currency');
  const tag = searchParams.get('tags') || [];
  const reportType = searchParams.get('reportType');

  const accessToken = searchParams.get('accessToken');
  const teamId = searchParams.get('teamId');
  const teamName = searchParams.get('teamName');

  const [generatedReport, setGeneratedReport] = useState(null);

  const generateReports = async ({
    accounts = [],
    startDate = '',
    endDate = '',
    tags = [],
    save,
    reportTitle = '',
    reportType = 'accrual',
    compareWith = {},
    currency = '',
    isPnLByTag = false,
    noTags = false,
  }) => {
    const data = {
      save,
      reportTitle,
      isPnLByTag,
      ...(Object.keys(compareWith).length && { compareWith }),
    };
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/pnl?accounts=${accounts.join(
          ',',
        )}${startDate ? `&startDate=${startDate}` : ''}${isPnLByTag ? `&isPnLByTag=${isPnLByTag}` : ''}${endDate ? `&endDate=${endDate}` : ''}&tags=${tags.join(
          ',',
        )}&reportType=${reportType}&currency=${currency}&noTags=${noTags}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'count-workspace-id': teamId,
          },
        },
      );
      return response?.data;
    } catch (e) {
      throw e?.response?.data?.message;
    }
  };

  const handleSubmit = async (paramValues = {}) => {
    try {
      let _tag = [];
      _tag =
        typeof paramValues?.tags === 'string'
          ? paramValues?.tags?.length > 2
            ? paramValues?.tags?.split?.(',')?.map?.(Number)
            : []
          : paramValues?.tags || [];

      const payloadData = {
        accounts: paramValues?.accounts || [],
        tags: _tag?.filter?.((tag) => tag !== PnlNoTagValue),
        noTags: _tag?.includes(PnlNoTagValue),

        reportType: paramValues?.reportType || 'accrual',
        ...(paramValues?.secondStartDate && {
          compareWith: {
            startDate: paramValues?.secondStartDate || null,

            endDate: paramValues?.secondEndDate || null,
          },
        }),
        currency: paramValues?.currency || currency || '',
      };
      if (paramValues?.startDate) {
        payloadData.startDate = paramValues?.startDate;
      }
      if (paramValues?.endDate) {
        payloadData.endDate = paramValues?.endDate || null;
      }
      const response = await generateReports(payloadData);

      const data = response?.data?.categorized;
      setFilter(response?.data?.filters);
      const formattedData =
        Object.keys(data).length && formatReportData(data, 'firstDateRange');

      if (
        paramValues?.secondStartDate &&
        paramValues?.secondEndDate &&
        paramValues?.secondStartDate &&
        paramValues?.secondEndDate
      ) {
        const payloadData1 = {
          accounts: paramValues?.accounts || [],
          tags: _tag?.filter?.((tag) => tag !== PnlNoTagValue),
          noTags: _tag?.includes(PnlNoTagValue),
          reportType: paramValues?.reportType || 'accrual',
          currency: paramValues?.currency || currency || '',
        };
        if (paramValues?.secondStartDate) {
          payloadData1.startDate = paramValues?.secondStartDate || null;
        }
        if (paramValues?.secondEndDate) {
          payloadData1.endDate = paramValues?.secondEndDate || null;
        }
        const response2 = await generateReports(payloadData1);
        const data2 = response2?.data?.categorized;
        const formattedData2 =
          Object.keys(data2).length &&
          formatReportData(data2, 'secondDateRange');
        const mergedData = calculateDifferenceAndPercent(
          mergeArrays(formattedData, formattedData2),
        );

        setGeneratedReport(mergedData);
      } else {
        setGeneratedReport(formattedData);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    if (searchParams?.size !== 0) {
      const _tag =
        typeof tag === 'string'
          ? tag?.length > 2
            ? tag?.split?.(',')?.map?.(Number)
            : []
          : tag || [];
      const tags = _tag === '[]' ? [] : _tag;
      handleSubmit({
        startDate,
        endDate,
        secondStartDate: secondStartDate || null,
        secondEndDate: secondEndDate || null,
        accounts: accountId ? accountId.split(',').map(Number) : [],
        reportType,
        currency,
        tags,
      });
    }
  }, [searchParams]);

  return (
    <div className="p-4">
      <h1 className="text-2xl text-center text-black  font-medium mb-4">
        Profit & Loss Statement ({reportType})
      </h1>
      <h2 className="text-lg text-center text-black  font-medium mb-1">
        {teamName}
      </h2>
      <div>
        {generatedReport && (
          <ProfitAndLossTable
            values={{
              firstStartDate: startDate,
              firstEndDate: endDate,
              compare: secondStartDate && secondEndDate,
              secondStartDate,
              secondEndDate,
              accounts: accountId ? accountId.split(',').map(Number) : [],
              tags: tag,
              reportType,
            }}
            isMobile
            generatedReport={generatedReport}
            currencySymbol={getCurrencySymbol(currency || 'USD')}
            filter={filter}
            selectedType={reportType}
            selectedTags={tag}
            isPdf
          />
        )}
      </div>
    </div>
  );
};

export default ProfitAndLossPdf;
