import React, { useEffect, useState } from 'react';
import { getCountriesData } from 'API/backend_helper';
import Tabs from './Tabs';
import EditPracticeForm from './EditPracticeForm';
import FirmMembers from '../firmMembers';
import FirmClientsInvite from '../FirmClientsInvite/InvitesTable';
import FirmSubscriptionsTab from '../FirmSubscriptionsTab';

const TABS = {
  editPractice: 'editPractice',
  teamMembers: 'teamMembers',
  clientInvites: 'clientInvites',
  billing: 'billing',
};

const ManagePracticeTab = ({
  accountantFirm,
  loadAccountantFirm,
  setFiles,
  setCurrentImageForCrop,
  setCropModalOpen,
  setImgName,
  imgPreview,
  setImgPreview,
  files,
  FirmId,
  onChangeInvite,
  firmSubscriptionsDetails,
}) => {
  const [activeTab, setActiveTab] = useState(TABS.editPractice);
  const [countries, setCountries] = useState([]);

  // Team Members
  const [members, setMembers] = useState([]);

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (countries?.length === 0) {
      loadCountries();
    }
  }, []);

  return (
    <div>
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        TABS={TABS}
        InvitesCount={accountantFirm?.firmInvites?.length}
      />
      {activeTab === TABS.editPractice && (
        <EditPracticeForm
          accountantFirm={accountantFirm}
          loadAccountantFirm={loadAccountantFirm}
          setFiles={setFiles}
          setCurrentImageForCrop={setCurrentImageForCrop}
          setCropModalOpen={setCropModalOpen}
          setImgName={setImgName}
          imgPreview={imgPreview}
          setImgPreview={setImgPreview}
          files={files}
          activeTab={activeTab}
          TABS={TABS}
          countries={countries}
        />
      )}
      {activeTab === TABS.teamMembers && (
        <FirmMembers
          accountantFirmId={FirmId}
          memberInvitations={accountantFirm?.memberInvitations}
          loadAccountantFirm={loadAccountantFirm}
          isOpen={activeTab === TABS.teamMembers}
          members={members}
          setMembers={setMembers}
        />
      )}
      {activeTab === TABS.clientInvites && (
        <div>
          <h3 className="text-[22px] font-medium text-[#00000099] mb-4">
            Client Invites
          </h3>
          <FirmClientsInvite
            onChangeInvite={onChangeInvite}
            accountantFirm={accountantFirm}
            loadAccountantFirm={loadAccountantFirm}
          />
        </div>
      )}
      {activeTab === TABS.billing && (
        <FirmSubscriptionsTab
          firmSubscriptionsDetails={firmSubscriptionsDetails}
        />
      )}
    </div>
  );
};

export default ManagePracticeTab;
