/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ModalBasic from '../../components/ModalBasic';

const RecordChoiceModal = ({
  modalOpen,
  setModalOpen,
  onAssignCredit,
  onTransaction,
}) => (
  <ModalBasic
    modalOpen={modalOpen}
    setModalOpen={setModalOpen}
    title={
      modalOpen?.invoiceType === 'memo'
        ? 'Manage Credit'
        : 'Record a payment or apply credit'
    }
  >
    <div className="w-full px-[30px] py-4 pb-6">
      <div className="grid grid-cols-2 gap-4">
        <div
          className="text-indigo-500 text-center h-20 border border-indigo-500 inline-flex gap-2 justify-center rounded-[6px] hover:bg-indigo-500 hover:bg-opacity-5 font-medium items-center leading-tight transition-all"
          onClick={() => {
            onTransaction(modalOpen);
            setModalOpen(false);
          }}
          role="button"
          tabIndex="0"
          onKeyDown={null}
        >
          <span>
            {modalOpen?.invoiceType === 'memo'
              ? 'Assign Transaction'
              : ' Select a Transaction'}
          </span>
        </div>

        <div
          className="text-indigo-500 text-center h-20 border border-indigo-500 inline-flex gap-2 justify-center rounded-[6px] hover:bg-indigo-500 hover:bg-opacity-5 font-medium items-center leading-tight transition-all"
          onClick={() => {
            onAssignCredit(modalOpen);
            setModalOpen(false);
          }}
          role="button"
          tabIndex="0"
          onKeyDown={null}
        >
          <span>
            {modalOpen?.invoiceType === 'memo'
              ? 'Apply to Invoice'
              : 'Apply Credit'}
          </span>
        </div>
      </div>
    </div>
  </ModalBasic>
);

export default RecordChoiceModal;
