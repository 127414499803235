import React, { useEffect, useState } from 'react';
import AddEditEncryptedRecord from './EncryptionForm';
import EncryptedTable from './EncryptionTable';

const EncryptionTab = ({
  encryptions,
  encryptionLoader,
  addEditEncryptionLoader,
  deleteEncryptionLoader,
  createEncryptions,
  getEncryptions,
  updateEncryptions,
  deleteEncryptions,
  WSId,
  clientId,
  encryptionsSortValues,
  setEncryptionsSortValues,
}) => {
  const [addEditEncryptionModal, setAddEditEncryptionModal] = useState(false);
  const [activeEncrypt, setActiveEncrypt] = useState(null);

  useEffect(() => {
    if (WSId && !(encryptions?.records?.length > 0)) {
      getEncryptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WSId, clientId]);

  return (
    <>
      <AddEditEncryptedRecord
        isOpen={addEditEncryptionModal}
        setIsOpen={setAddEditEncryptionModal}
        activeEncrypt={activeEncrypt}
        isLoading={addEditEncryptionLoader}
        setActiveEncrypt={setActiveEncrypt}
        createEncryptions={createEncryptions}
        updateEncryptions={updateEncryptions}
      />

      <div className="">
        <div className="flex justify-end mb-4">
          <button
            onClick={() => {
              setAddEditEncryptionModal(true);
            }}
            type="button"
            className="btn-sm h-10 px-4 text-base bg-indigo-500 hover:bg-indigo-600 text-white
                             disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed  flex items-center justify-center  gap-2"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=""
            >
              <path
                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add Encrypted Record
          </button>
        </div>
        <EncryptedTable
          encryptions={encryptions}
          deleteEncryptions={deleteEncryptions}
          deleteEncryptionLoader={deleteEncryptionLoader}
          isLoading={encryptionLoader}
          getEncryptions={getEncryptions}
          setAddEditEncryptionModal={setAddEditEncryptionModal}
          setActiveEncrypt={setActiveEncrypt}
          tableSortValues={encryptionsSortValues}
          setTableSortValues={setEncryptionsSortValues}
        />
      </div>
    </>
  );
};

export default EncryptionTab;
