/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { shutdown, boot } from '@intercom/messenger-js-sdk';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeValueIfNotExistInData,
  replaceKeysWithValuesAndConvertToCamelCase,
  TEAM_ID,
} from 'utils/Utils';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DropdownFull from 'components/DropdownFull';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import {
  accountCsvImport,
  accountImportBulk,
  accountImportMapping,
  createTax,
  deleteDraftAccountById,
  getDraftAccounts,
  updateBulkDraftAccounts,
  updateTeamById,
} from '../../API/backend_helper';
import AlertBox from '../../components/AlertBox';
import AppLayout from '../../components/AppLayout';
import { setOnBoardingStep } from '../../redux/onBoarding/reducer';

const LoadingIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-spin w-5 h-5 fill-current shrink-0"
  >
    <path
      d="M14.5 2.41699V7.25033"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 21.749V26.5824"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 5.95703L9.37662 9.37662"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 19.6211L23.0426 23.0407"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.41699 14.5H7.25033"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.749 14.5H26.5824"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 23.0407L9.37662 19.6211"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 9.37662L23.0426 5.95703"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const HeadersColumns = [
  'Account Number',
  'Account Name',
  'Account Description',
  'Account Type',
  'Account Sub-Type',
];

function checkHeaderDuplicates(headerList) {
  const headers = new Set();
  for (const header of headerList) {
    const { fileHeader } = header;
    if (headers.has(fileHeader) && fileHeader !== '--') {
      return true;
    }
    headers.add(fileHeader);
  }
  return false;
}

const cleanJson = (jsonData, headers) =>
  jsonData.map((obj) => {
    const newObj = {};
    for (const header of headers) {
      const { fileHeader } = header;
      if (Object.prototype.hasOwnProperty.call(obj, fileHeader)) {
        newObj[fileHeader] = obj[fileHeader];
      }
    }
    return newObj;
  });
const ImportAccounts = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { team } = useSelector((state) => state.Team);
  const { onBoardingStep } = useSelector((state) => state.OnBoarding);

  const [searchParams] = useSearchParams();
  const From = searchParams.get('from') || '';

  const [mainLoading, setMainLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [parsedInfo, setParsedInfo] = useState([]);
  const [mapping, setMapping] = useState({});
  const [keyArray, setKeyArray] = useState([]);
  const [error, setError] = useState(null);
  const [importStatusId, setImportStatusId] = useState('');
  const [localLimit, setLocalLimit] = useState(20);
  const [draftAccounts, setDraftAccounts] = useState({});

  // For Step 3 Mapping
  const [tableRows, setTableRows] = useState([]);
  const [deactivateExistingOnes, setDeactivateExistingOnes] = useState(true);
  const [errorIndexes, setErrorIndexes] = useState([]);
  const [deleteDraftsLoading, setDeleteDraftsLoading] = useState({});

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (activeStep > 1) {
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for some browsers
        return ''; // This line is necessary for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [activeStep]);

  useEffect(() => {
    shutdown?.();

    return () => {
      boot();
    };
  }, [shutdown]);

  const createKeyArray = (product) =>
    Object?.keys(product || {})
      ?.filter?.((key) => key !== '' && key)
      ?.map?.((key) => ({
        name: key?.trim(),
        id: key?.trim(),
      }))
      ?.filter?.((item) => item.name !== '' && item.id !== '');

  const csvFileUpload = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('file', file);
      const res = await accountCsvImport(data);
      const keys = createKeyArray(res?.accounts?.[0]);
      if (res?.headers) {
        const transformedObject = replaceKeysWithValuesAndConvertToCamelCase(
          res?.headers,
        );
        setMapping(
          removeValueIfNotExistInData({ ...(transformedObject ?? {}) }, keys),
        );
      }
      setKeyArray(keys);
      setParsedInfo(res);
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const loadDraftAccounts = async ({
    _importStatusId = importStatusId,
    page = 1,
    limit = localLimit,
    loading = false,
    allAccounts = false,
    mainLoading = false,
  }) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      if (mainLoading) {
        setMainLoading(true);
      }
      const res = await getDraftAccounts({
        importStatusId: _importStatusId,
        page,
        limit,
      });
      setDraftAccounts(res?.data);
      if (allAccounts) {
        return res?.data?.drafts;
      }
      const temp = [];
      if (res?.data?.drafts?.length > 0) {
        res?.data?.drafts?.forEach((d) => {
          const tempSubDrafts = [];
          if (d?.subAccountDrafts?.length > 0) {
            d?.subAccountDrafts?.forEach((subTemp) => {
              tempSubDrafts?.push({
                id: subTemp?.id,
                parentDraftAccountId: subTemp?.parentDraftAccountId,
                selected: { value: true, error: false },
                accountNumber: {
                  value: subTemp?.accountNumber,
                  error: false,
                },
                name: { value: subTemp?.name, error: !subTemp?.name },
                description: { value: subTemp?.description, error: false },
                type: { value: subTemp?.type, error: !subTemp?.type },
                subTypeId: {
                  value: subTemp?.subTypeId,
                  error: !subTemp?.subTypeId,
                },
                subTypeName: {
                  value: subTemp?.subTypeName,
                  error: !subTemp?.subTypeName,
                },
                taxes: {
                  value: subTemp?.taxes?.length > 0 ? subTemp?.taxes?.[0] : '',
                  error: false,
                },
                color: {
                  value: subTemp?.color,
                  error: false,
                },
              });
            });
          }
          temp?.push({
            id: d?.id,
            parentDraftAccountId: d?.parentDraftAccountId,
            selected: { value: true, error: false },
            accountNumber: {
              value: d?.accountNumber,
              error: false,
            },
            name: { value: d?.name, error: !d?.name },
            description: { value: d?.description, error: false },
            type: { value: d?.type, error: !d?.type },
            subTypeId: {
              value: d?.subTypeId,
              error: !d?.subTypeId,
            },
            subTypeName: {
              value: d?.subTypeName,
              error: !d?.subTypeName,
            },
            taxes: {
              value: d?.taxes?.length > 0 ? d?.taxes?.[0] : '',
              error: false,
            },
            color: {
              value: d?.color,
              error: false,
            },
            subAccountDrafts: tempSubDrafts,
          });
        });
        setTableRows(temp);
        setErrorIndexes([]);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      if (loading) {
        setIsLoading(false);
      }
      if (mainLoading) {
        setMainLoading(false);
      }
    }
  };

  const importMapping = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('document', file);
      data.append('mapping', JSON.stringify(mapping));
      const res = await accountImportMapping(data);
      setImportStatusId(res?.importStatus?.id);
      await loadDraftAccounts({ _importStatusId: res?.importStatus?.id });

      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const ObjectErrorChecker = (d, key = '', subAccount = false) => {
    let isError = false;
    const tempRow = JSON.parse(JSON.stringify(d || {}));
    if (d?.selected?.value && key !== 'description') {
      // if (key === "accountNumber" && !d[key].value) {
      //   tempRow[key].error = "";
      // }
      // if (
      //   key === "accountNumber" &&
      //   d[key].value &&
      //   d[key].value?.length > 40
      // ) {
      //   tempRow[key].error = "Account number must be 40 digits or less";
      //   isError = true;
      // }
      if (key === 'name' && !d[key].value) {
        tempRow[key].error = 'Account Name should not be empty';
        isError = true;
      }
      if (key === 'name' && d[key].value && d[key].value?.length > 200) {
        tempRow[key].error = 'Account Name must be 200 characters or less';
        isError = true;
      }

      if (key === 'type' && !d[key].value && !subAccount) {
        tempRow[key].error = 'Select Account Type';
        isError = true;
      }
      if (key === 'subTypeId' && !d[key].value && !subAccount) {
        tempRow[key].error = 'Select Account Sub Type';
        isError = true;
      }

      const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

      if (key === 'color' && !subAccount) {
        if (
          d[key].value?.length > 0 &&
          !hexColorRegex.test(d.color.value?.trim())
        ) {
          tempRow.color.error = 'Invalid hex color';
          isError = true;
        } else if (!d.color.value) {
          tempRow.color.error = '';
        }
      }
    } else {
      tempRow[key].error = '';
      isError = false;
    }
    return { tempRow, error: isError };
  };

  const checkRowsError = () => {
    let isError = false;
    if (tableRows?.length === 0) {
      return true;
    }
    const tempRows = JSON.parse(JSON.stringify(tableRows || []));
    const indexs = [...errorIndexes];

    tableRows.forEach((d, i) => {
      Object.keys(d).forEach((key) => {
        const _tempRow = JSON.parse(JSON.stringify(d || {}));
        if (d?.subAccountDrafts?.length > 0) {
          d?.subAccountDrafts?.forEach((cd, j) => {
            const subAccountsTemp = JSON.parse(
              JSON.stringify(d?.subAccountDrafts || []),
            );

            const tempIndexName = `${(draftAccounts?.page - 1) * localLimit + i + 1}.${j + 1}`;
            Object.keys(cd).forEach((key) => {
              const { tempRow, error } = ObjectErrorChecker(cd, key, true);
              subAccountsTemp[j] = tempRow;
              if (error) {
                if (!indexs?.includes(tempIndexName)) {
                  indexs?.push(tempIndexName);
                }
              }

              if (!isError) {
                isError = error;
              }
            });
            _tempRow.subAccountDrafts = subAccountsTemp;
          });
        }
        const { tempRow, error } = ObjectErrorChecker(_tempRow, key);
        const tempIName = (draftAccounts?.page - 1) * localLimit + i + 1;
        if (error) {
          if (!indexs?.includes(tempIName)) {
            indexs?.push(tempIName);
          }
        }

        if (!isError) {
          isError = error;
        }

        tempRows[i] = tempRow;
      });
    });
    setErrorIndexes(indexs);
    setTableRows(tempRows);
    return isError;
  };

  const isDisabled = () => {
    if (activeStep === 1 && file) {
      return false;
    }
    if (activeStep === 2) {
      return false;
    }
    if (activeStep === 3) {
      return false;
    }
    return true;
  };

  const updateOnboardingStep = async () => {
    dispatch(setOnBoardingStep((onBoardingStep || 2) + 1));
    try {
      const teamId = team?.id || localStorage.getItem(TEAM_ID);
      await updateTeamById(teamId, {
        onBoardingStep: (onBoardingStep || 2) + 1,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const importAccounts = async () => {
    try {
      setIsLoading(true);

      const res = await accountImportBulk({
        deactivateExistingOnes,
        importStatusId,
      });
      toast.success('Accounts Imported Successfully');
      if (From === 'onboarding') {
        updateOnboardingStep();
        navigate('/onboarding');
      } else {
        navigate(
          `/accounts?createdAccounts=${res?.createdAccounts}&skippedAccounts=${res?.skippedAccounts}&totalAccounts=${res?.totalAccounts}`,
        );
      }

      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const handleDeleteItem = async (id, parentIndex, index) => {
    setDeleteDraftsLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await deleteDraftAccountById(id);
      const tempRows = JSON.parse(JSON.stringify(tableRows || []));
      let parentObj = null;
      if (parentIndex !== null && parentIndex >= 0 && index >= 0) {
        parentObj = JSON.parse(JSON.stringify(tempRows[parentIndex]));
        if (parentObj?.subAccountDrafts?.length > 0) {
          const filterSubAccounts = parentObj?.subAccountDrafts?.filter(
            (_, i) => i !== index,
          );
          parentObj.subAccountDrafts = filterSubAccounts;
          tempRows[parentIndex] = parentObj;
          setTableRows(tempRows);
        }
      } else {
        const filtered = tempRows?.filter((_, i) => i !== index);
        setTableRows(filtered);
      }
      // await loadDraftAccounts({ page: draftAccounts?.page });
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteDraftsLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleBulkUpdateDrafts = async () => {
    setIsLoading(true);
    const data = [];
    tableRows?.forEach((row) => {
      data?.push({
        id: row?.id,
        accountNumber: row?.accountNumber?.value || '',
        name: row?.name?.value,
        type: row?.type?.value,
        subTypeId: row?.subTypeId?.value,
        subTypeName: row?.subTypeName?.value,
        description: row?.description?.value,
        taxes: row?.taxes?.value ? [row?.taxes?.value] : [],
        color: row?.color?.value,
      });
      if (row?.subAccountDrafts?.length > 0) {
        row?.subAccountDrafts?.forEach((subRow) =>
          data.push({
            id: subRow?.id,
            accountNumber: subRow?.accountNumber?.value || '',
            name: subRow?.name?.value,
            type: row?.type?.value,
            subTypeId: row?.subTypeId?.value,
            subTypeName: row?.subTypeName?.value,
            description: subRow?.description?.value,
            taxes: subRow?.taxes?.value ? [subRow?.taxes?.value] : [],
            color: row?.color?.value,
          }),
        );
      }
    });
    try {
      await updateBulkDraftAccounts(importStatusId, data);
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
      throw new Error(err);
    }
  };

  const onClickNext = async () => {
    if (activeStep === 1 && file) {
      csvFileUpload();
    }
    if (activeStep === 2) {
      const requiredKeys = ['accountType', 'accountName'];
      const optionalKeys = [
        'accountDescription',
        'accountNumber',
        'accountSubType',
        'taxCode',
      ];

      const hasRequiredKeys = requiredKeys.every(
        (key) =>
          key in mapping &&
          mapping[key] &&
          mapping[key] !== '--' &&
          mapping[key] !== key,
      );

      if (!hasRequiredKeys) {
        setError('You must choose value for Account Name and Account Type');
        return;
      }
      const newMapping = { ...mapping };
      optionalKeys.forEach((key) => {
        if (!newMapping[key] || newMapping[key] === '--') {
          newMapping[key] = '';
        }
      });
      setMapping(newMapping);
      setError(null);
      importMapping();
    }
    if (
      activeStep === 3 &&
      +draftAccounts?.page <
        Math.ceil(draftAccounts?.totalRecords / draftAccounts?.limit)
    ) {
      try {
        const error = checkRowsError();

        if (error) {
          document.getElementById('accountsWarningError')?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
          return;
        }
        await handleBulkUpdateDrafts();
        loadDraftAccounts({
          page: +draftAccounts?.page + 1,
          loading: true,
        });
      } catch (e) {
        console.log('error', e);
      }
    } else if (
      activeStep === 3 &&
      draftAccounts?.page ===
        Math.ceil(draftAccounts?.totalRecords / draftAccounts?.limit)
    ) {
      const error = checkRowsError();
      if (error) {
        document.getElementById('accountsWarningError')?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        return;
      }
      try {
        await handleBulkUpdateDrafts();
        importAccounts();
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  const handleLimitChange = (value) => {
    setLocalLimit(value);
    loadDraftAccounts({
      limit: value,
      loading: false,
      mainLoading: true,
    });
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep((prev) => prev - 1);
    } else if (
      activeStep === 3 &&
      draftAccounts?.page > 1 &&
      Math.ceil(draftAccounts?.totalRecords / draftAccounts?.limit) > 1
    ) {
      setErrorIndexes([]);
      loadDraftAccounts({
        page: +draftAccounts?.page - 1,
        loading: false,
        mainLoading: true,
      });
    } else if (activeStep === 3) {
      setActiveStep((prev) => prev - 1);
    }
  };

  return (
    <AppLayout>
      <>
        <main
          className="grow relative overflow-auto flex flex-col"
          id="importAccountsContainer"
        >
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto pb-16">
            {isLoading && activeStep === 2 && (
              <AlertBox
                type="warning"
                heading="Account Import in Progress"
                text="This may take up to 2-3 minutes. Please do not navigate away from this page until finished."
                hideActionButton
                icon={LoadingIcon}
              />
            )}
            <div className="mb-8 flex justify-between">
              {/* Title */}
              <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium">
                Import Chart of Accounts
              </h1>
            </div>
            <div className="flex justify-between mx-6 relative mb-8">
              <div
                className="absolute h-[1px] bg-[#667085] top-[11px] mx-auto left-1/2 -translate-x-1/2"
                style={{
                  width: 'calc(100% - 3rem)',
                }}
              />
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 1
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  1
                </div>
                <h3>Upload</h3>
              </div>
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 2
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  2
                </div>
                <h3>Review column matching</h3>
              </div>
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 3
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  3
                </div>
                <h3>Review row matching</h3>
              </div>
              <div className="flex flex-col items-center z-10 relative">
                <div
                  className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 4
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
                >
                  4
                </div>
                <h3>Import</h3>
              </div>
            </div>

            {mainLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <>
                {activeStep === 1 && (
                  <Step1
                    error={fileError}
                    setError={setFileError}
                    file={file}
                    setFile={setFile}
                  />
                )}
                {activeStep === 2 && (
                  <Step2
                    mapping={mapping}
                    setMapping={setMapping}
                    parsedInfo={parsedInfo}
                    keyArray={keyArray}
                    error={error}
                    setError={setError}
                  />
                )}
                {activeStep === 3 && (
                  <>
                    <Step3
                      deactivateExistingOnes={deactivateExistingOnes}
                      setDeactivateExistingOnes={setDeactivateExistingOnes}
                      tableRows={tableRows}
                      setTableRows={setTableRows}
                      checkRowsError={checkRowsError}
                      errorIndexes={errorIndexes}
                      currentPage={+draftAccounts?.page}
                      localLimit={localLimit}
                      handleDeleteItem={handleDeleteItem}
                      deleteDraftsLoading={deleteDraftsLoading}
                      accountsTotal={draftAccounts?.totalAccounts}
                    />
                    <div className="flex flex-row gap-2 my-6 items-center mx-auto sm:mx-0">
                      {/* <p className="text-sm font-medium leading-6 text-slate-600">Row</p> */}
                      <div className="w-[72px] h-[42px]">
                        <DropdownFull
                          options={[
                            { id: 20, name: '20' },
                            { id: 30, name: '30' },
                            { id: 50, name: '50' },
                          ]}
                          selected={localLimit}
                          setSelected={(option) => handleLimitChange(option)}
                          name="name"
                          placeholder={localLimit}
                          pagination
                          scrollIntoView={false}
                        />
                      </div>
                      <p className="text-sm font-medium leading-6 text-slate-600">
                        {' '}
                        Per Page (Showing{' '}
                        {(draftAccounts?.page - 1) * localLimit + 1} to{' '}
                        {Math.min(
                          (draftAccounts?.page - 1) * localLimit + localLimit,
                          draftAccounts?.totalRecords,
                        )}{' '}
                        of {draftAccounts?.totalRecords} accounts
                        {draftAccounts?.totalRecords !==
                        draftAccounts?.totalAccounts
                          ? ` , or
                        ${draftAccounts?.totalAccounts} accounts including
                        sub-accounts)`
                          : ')'}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </main>
        <footer
          className="absolute bottom-0 h-16 bg-white w-full border-t border-[#D0D5DD80] px-6
        flex justify-between items-center"
        >
          {activeStep === 1 ? (
            <Link to={From === 'onboarding' ? '/onboarding' : '/accounts'}>
              <button
                // disabled
                className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
                type="button"
              >
                Back to {From === 'onboarding' ? 'Launchpad' : 'Accounts'}
              </button>
            </Link>
          ) : (
            <button
              onClick={handleBack}
              // disabled
              className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
              type="button"
            >
              Back
            </button>
          )}
          <button
            disabled={isDisabled() || isLoading}
            className="btn h-fit border-[#667085] rounded-full px-6 !bg-indigo-500 text-white font-medium border-indigo-500
            disabled:!bg-indigo-400 disabled:cursor-not-allowed"
            onClick={onClickNext}
            type="button"
          >
            {isLoading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}

            {activeStep === 3 &&
            draftAccounts?.page ===
              Math.ceil(draftAccounts?.totalRecords / draftAccounts?.limit)
              ? 'Import'
              : 'Next'}
          </button>
        </footer>
      </>
    </AppLayout>
  );
};

export default ImportAccounts;
