import React, { useEffect, useState } from 'react';
import { Loader } from 'components/Svg';
import ModalBasic from 'components/ModalBasic';
import { getSingleTransaction } from 'API/backend_helper';
import {
  amountColor,
  getCurrencySymbol,
  getStringSegments,
  trimText,
} from 'utils/Utils';
import moment from 'moment';
import Tooltip from 'components/Tooltip';
import Tabs from './Tabs';
import CategoryTab from './CategoryTab';
import DuplicatesTab from './DuplicatesTab';
import MatchInvoiceTab from './MatchInvoiceTab';
import TransferTab from './TransferTab';
import MatchBillTab from './MatchBillTab';
import UndepositedTab from './UndepositedTab';

export const TAB_NAMES = {
  categorize: 'categorize',
  transfer: 'transfer',
  duplicates: 'duplicates',
  matchInvoice: 'matchInvoice',
  matchBill: 'matchBill',
  undepositTransfer: 'undepositTransfer',
};
const TextLength = 15;
const CategoryModal = ({
  isOpen,
  setIsOpen,
  allCategoriesIncome,
  allCategoriesExpense,
  reloadTransaction,
  accountModalOpen,
  setAccountModalOpen,
  allCategories,
  accountTypes,
  newAddedCategory,
  setNewAddedCategory,
  handlePopupLogic,
}) => {
  const [activeTab, setActiveTab] = useState(TAB_NAMES.categorize);
  const [localTransaction, setLocalTransaction] = useState(null);
  const [noClick, setNoClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTransactionById = async (loading = false) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const res = await getSingleTransaction(
        isOpen?.id,
        'include=undepositedInvoice',
      );
      setLocalTransaction(res);
      if (loading) {
        setLoading(false);
      }
    } catch (e) {
      if (loading) {
        setLoading(false);
      }
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (isOpen?.id) {
      setLocalTransaction(isOpen);
      if (
        isOpen?.type === 'Transfer' ||
        isOpen?.categoryAccount?.name === 'Uncategorized Transfer'
      ) {
        setActiveTab(TAB_NAMES?.transfer);
        if (isOpen?.transferMatchings?.length > 0) {
          getTransactionById(true);
        } else {
          getTransactionById(false);
        }
      } else {
        getTransactionById(true);
      }
    } else {
      setLocalTransaction(null);
    }
    return () => {
      setActiveTab(TAB_NAMES.categorize);
      setLocalTransaction(null);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen?.matchingInvoices?.length > 0 && isOpen?.type === 'Income') {
      setActiveTab(TAB_NAMES.matchInvoice);
    } else if (
      isOpen?.matchingBillInstances?.length > 0 &&
      isOpen?.type === 'Expense'
    ) {
      setActiveTab(TAB_NAMES.matchBill);
    } else if (isOpen?.undepositedTransfers?.length > 0) {
      setActiveTab(TAB_NAMES.undepositTransfer);
    } else if (isOpen?.duplicateMatchings?.length > 0) {
      setActiveTab(TAB_NAMES.duplicates);
    } else if (isOpen?.transferMatchings?.length > 0) {
      setActiveTab(TAB_NAMES.transfer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!accountModalOpen) {
      setNoClick(false);
    }
  }, [accountModalOpen]);

  return (
    <ModalBasic
      title="Reconcile Transaction"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      width="!w-[1020px]"
      noClick={noClick}
      contentId="transactionsCategoryModal"
      headerClassName="!pb-2 !pt-4"
      subHeader={
        <div className="flex flex-wrap items-center text-[15px]">
          <div>
            {moment?.utc(isOpen?.authorizedDate)?.format('MMM DD, YYYY')}
          </div>
          {isOpen?.description && <span className="ml-2 mr-2">|</span>}
          <div className="font-normal leading-6 mr-2">
            {isOpen?.description?.length > TextLength ? (
              <Tooltip
                content={
                  isOpen?.description?.length > 50
                    ? (() => {
                        const segments = getStringSegments(
                          isOpen?.description,
                          70,
                        );
                        return segments.map((segment, index) => (
                          <p key={index}>{segment}</p>
                        ));
                      })()
                    : isOpen?.description
                }
                contentClassName={`border-none rounded-[10px] overflow-visible text-[13px] text-[#667085] relative tooltipArrowUp ${
                  isOpen?.description?.length > 50 ? '' : ''
                }`}
                tooltipShow={window.innerWidth > 640}
                position="bottom"
              >
                <span>{`${isOpen?.description?.slice(0, TextLength)}...`}</span>
              </Tooltip>
            ) : (
              <span>{isOpen?.description}</span>
            )}
          </div>
          {isOpen?.amount && <span className="ml-2 mr-2">|</span>}
          <div
            className={`font-semibold leading-6 ${amountColor(
              isOpen?.amount?.toString(),
            )}`}
          >
            {isOpen?.amount?.toString().charAt(0) === '-' ? '' : '-'}
            {getCurrencySymbol(isOpen?.currency)}
            {isOpen?.amount?.toString().charAt(0) === '-'
              ? parseFloat(
                  isOpen?.amount?.toString().replace('-', ''),
                )?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : parseFloat(isOpen?.amount)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </div>
          {isOpen?.vendor?.name && <span className="ml-2 mr-2">|</span>}
          <div>
            <Tooltip
              tooltipShow={isOpen?.vendor?.name?.length > TextLength}
              content={
                isOpen?.vendor?.name?.length > 30
                  ? (() => {
                      const segments = getStringSegments(
                        isOpen?.vendor?.name,
                        40,
                      );
                      return segments.map((segment, index) => (
                        <p key={index}>{segment}</p>
                      ));
                    })()
                  : isOpen?.vendor?.name
              }
              contentClassName="border-none rounded-[10px] overflow-visible text-[13px] text-[#667085] relative tooltipArrowUp"
              position="bottom"
            >
              <span className="capitalize">
                {trimText(
                  isOpen?.vendor?.name || '',
                  TextLength,
                )?.toLowerCase()}
              </span>
            </Tooltip>
          </div>
          {isOpen?.customer?.customer && <span className="ml-2 mr-2">|</span>}
          <div>
            <Tooltip
              tooltipShow={isOpen?.customer?.customer?.length > TextLength}
              content={
                isOpen?.customer?.customer?.length > 30
                  ? (() => {
                      const segments = getStringSegments(
                        isOpen?.customer?.customer,
                        40,
                      );
                      return segments.map((segment, index) => (
                        <p key={index}>{segment}</p>
                      ));
                    })()
                  : isOpen?.customer?.customer
              }
              contentClassName="border-none rounded-[10px] overflow-visible text-[13px] text-[#667085] relative tooltipArrowUp"
              position="bottom"
            >
              <span className=" capitalize">
                {trimText(
                  isOpen?.customer?.customer,
                  TextLength,
                )?.toLowerCase()}
              </span>
            </Tooltip>
          </div>
        </div>
      }
    >
      <div>
        <Tabs
          TAB_NAMES={TAB_NAMES}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          transaction={isOpen}
        />
        {activeTab === TAB_NAMES.categorize && (
          <CategoryTab
            allCategoriesIncome={allCategoriesIncome}
            allCategoriesExpense={allCategoriesExpense}
            transaction={localTransaction}
            reloadTransaction={getTransactionById}
            reloadTransactions={reloadTransaction}
            setAccountModalOpen={setAccountModalOpen}
            setNoClick={setNoClick}
            setClose={setIsOpen}
            isOpen={isOpen}
            accountTypes={accountTypes}
            newAddedCategory={newAddedCategory}
            setNewAddedCategory={setNewAddedCategory}
            activeTab={activeTab}
            handlePopupLogic={handlePopupLogic}
          />
        )}
        {loading && activeTab !== TAB_NAMES.categorize ? (
          <div
            className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 min-h-[400px]"
            style={{
              maxHeight: 'calc(100dvh - 223px)',
              height: 'calc(100dvh - 223px)',
            }}
          >
            <Loader />
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <>
            {activeTab === TAB_NAMES.transfer && (
              <TransferTab
                setNoClick={setNoClick}
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                allCategories={allCategories}
                setClose={setIsOpen}
              />
            )}
            {activeTab === TAB_NAMES.undepositTransfer && (
              <UndepositedTab
                setNoClick={setNoClick}
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                allCategories={allCategories}
                setClose={setIsOpen}
              />
            )}

            {activeTab === TAB_NAMES.duplicates && (
              <DuplicatesTab
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                setClose={setIsOpen}
              />
            )}
            {activeTab === TAB_NAMES.matchInvoice && (
              <MatchInvoiceTab
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                setClose={setIsOpen}
              />
            )}
            {activeTab === TAB_NAMES.matchBill && (
              <MatchBillTab
                transaction={localTransaction}
                reloadTransaction={getTransactionById}
                reloadTransactions={reloadTransaction}
                setClose={setIsOpen}
              />
            )}
          </>
        )}
      </div>
    </ModalBasic>
  );
};

export default CategoryModal;
