import {
  createFolder,
  deleteFileByName,
  getFolders,
  renameFilename,
  uploadFileInFolders,
} from 'API/backend_helper';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadFileInChunks } from 'utils/FileUploadUtils';

export const SYSTEM_FOLDERS = [
  'Bank Statements',
  // 'Unmatched Receipts',
  // 'Transaction Files',
];

const useFiles = () => {
  const dispatch = useDispatch();

  const [mainLoading, setMainLoading] = useState(false);
  const [folders, setFolders] = useState({});
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [parentFolder, setParentFolder] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeDeleteItem, setActiveDeleteItem] = useState({});
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [activeAttachment, setActiveAttachment] = useState({});
  const [activeFilename, setActiveFilename] = useState(null);

  const loadFolders = async ({ loading = false, parent = parentFolder }) => {
    if (loading) {
      setMainLoading(true);
    }
    try {
      const res = await getFolders(encodeURIComponent(parent));
      setFolders(res);
    } catch (err) {
      console.log('err', err);
    } finally {
      if (loading) {
        setMainLoading(false);
      }
    }
  };

  const handleCreteFolder = async (folderName, parent = parentFolder) => {
    const data = {
      folderName,
      parentFolder: parent,
    };
    try {
      const res = await createFolder(data);
      await loadFolders({});
      toast.success('Folder created successfully');
      return res;
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const handleFileUpload = async (file, parent = parentFolder) => {
    try {
      let res;
      if (file?.size > 5242880) {
        dispatch(uploadFileInChunks(file, parentFolder, loadFolders));
        setFileModalOpen(false);
      } else {
        const data = new FormData();
        data.append('file', file);
        data.append('folderPath', parent);
        res = await uploadFileInFolders(data);
        await loadFolders({});
        toast.success('File added successfully');
      }
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleFileDelete = async (filename) => {
    try {
      await deleteFileByName(encodeURIComponent(filename));
      await loadFolders({});
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const handleRename = async (oldFilename, newFilename) => {
    try {
      await renameFilename({
        oldPath: oldFilename,
        newPath: newFilename,
      });
      await loadFolders({});
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const getFileName = (path) => {
    const segments = path.split('/');
    const lastSegment = segments.filter((segment) => segment !== '').pop();
    return lastSegment;
  };

  const getParentFilePath = (path) => {
    const segments = path.split('/').filter((segment) => segment !== '');
    segments.pop();
    return segments;
  };

  const handleFolderChange = (newParent) => {
    if (newParent !== parentFolder) {
      setParentFolder(newParent);
      loadFolders({ loading: true, parent: newParent });
    }
  };

  const checkSystemDefaultFolder = (name = '') => {
    if (
      parentFolder
        ? SYSTEM_FOLDERS.includes(parentFolder?.split('/')?.[0])
        : SYSTEM_FOLDERS.includes(name)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    loadFolders({ loading: true });
  }, []);

  document.title = 'COUNT | Documents';

  return {
    folders,
    folderModalOpen,
    setFolderModalOpen,
    handleCreteFolder,
    fileModalOpen,
    setFileModalOpen,
    handleFileUpload,
    getFileName,
    handleFileDelete,
    deleteModalOpen,
    setDeleteModalOpen,
    activeDeleteItem,
    setActiveDeleteItem,
    parentFolder,
    handleFolderChange,
    mainLoading,
    attachmentModalOpen,
    setAttachmentModalOpen,
    activeAttachment,
    setActiveAttachment,
    setActiveFilename,
    activeFilename,
    handleRename,
    getParentFilePath,
    checkSystemDefaultFolder,
  };
};

export default useFiles;
