import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ColorDropdown from 'components/ColorDropdown';
import {
  getCategoriesWithInactiveAccount,
  hasUpToTwoDecimalPlaces,
  roundToTwo,
  TEAM_CURRENCY,
} from 'utils/Utils';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import TextInputDash from '../../components/TextInputDash';
import DropdownFull from '../../components/DropdownFull';
import TaxModal from '../settings/taxes/TaxModal';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import { InfoIcon, Loader } from '../../components/Svg';
import Tooltip from '../../components/Tooltip';
import { createTax, getTaxes } from '../../API/backend_helper';

const ProductForm = ({
  setActiveProduct = () => {},
  activeProduct,
  handleAddProduct,
  handleUpdateProduct,
  setFormOpen,
  setTitle,
  currencies,
  team,
  incomeCategories,
  expenseCategories,
  isModalView = false,
  projectsPage = false,
  _taxes = [],
  _setTaxes = () => {},
  isInvoices = false,
  onAddProduct,
  setProductsPanelOpen,
  loadProductHistory,
  STATUS_OPTIONS = [
    {
      name: 'Active',
      textColor: '#78BD4F',
      background: '#40AA001A',
      id: 'active',
    },
    {
      name: 'Inactive',
      textColor: '#E48642',
      background: '#E486421A',
      id: 'inactive',
    },
  ],
  loadTaxesByDefault = true,
}) => {
  const formikRef = useRef(null);
  const { accessType, permissions } = useSelector((state) => state.User);

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState(
    activeProduct?.taxes || [],
  );
  const [displayPurchaseOptions, setDisplayPurchaseOptions] = useState(false);
  const [displaySellOptions, setDisplaySellOptions] = useState(true);
  const [displayStockOptions, setDisplayStockOptions] = useState(false);
  const [selectedStockReason, setSelectedStockReason] = useState('');
  const [firstTimeAddingStock, setFirstTimeAddingStock] = useState(true);
  const [displayStockDropdown, setDisplayStockDropdown] = useState(false);
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    if (activeProduct) {
      // if (activeProduct?.taxes?.length > 0) {
      //   const temp = [];
      //   activeProduct?.taxes?.forEach((t) => temp.push(t.id));
      //   setSelectedTaxes(temp);
      // } else {
      //   setSelectedTaxes([]);
      // }
      if (
        +activeProduct?.purchasePrice ||
        activeProduct?.purchaseCategoryAccountId ||
        activeProduct?.purchaseDescription
      ) {
        setDisplayPurchaseOptions(true);
      }
      if (
        activeProduct?.unitPrice ||
        activeProduct?.categoryAccountId ||
        activeProduct?.description
      ) {
        setDisplaySellOptions(true);
      } else {
        setDisplaySellOptions(false);
      }
      if (activeProduct?.stockQuantity !== null) {
        setDisplayStockOptions(true);
        setFirstTimeAddingStock(false);
      }
    }
  }, [activeProduct]);

  const getTaxesApi = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
      if (_setTaxes) {
        _setTaxes(res?.data?.taxes);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (!loadTaxesByDefault && _taxes?.length > 0) {
      setTaxes(_taxes);
    }
  }, [_taxes, loadTaxesByDefault]);

  useEffect(() => {
    if (loadTaxesByDefault) {
      getTaxesApi();
    }
    if (!activeProduct) {
      setDisplayPurchaseOptions(false);
      setDisplaySellOptions(true);
      setDisplayStockOptions(false);
      setSelectedTaxes([]);
      setDisplayStockDropdown(false);
    }
  }, []);

  const checkForStockError = (stockAdjustment) => {
    if (firstTimeAddingStock) {
      return false;
    }

    if (activeProduct?.stockQuantity !== null && selectedStockReason) {
      let newStockQuantity;

      switch (selectedStockReason) {
        case 'Stock received':
        case 'Restock return':
          newStockQuantity = activeProduct.stockQuantity + stockAdjustment;
          break;
        case 'Inventory re-count':
          newStockQuantity = stockAdjustment;
          break;
        case 'Damage':
        case 'Theft':
        case 'Loss':
          newStockQuantity = activeProduct.stockQuantity - stockAdjustment;
          break;
        default:
          return true;
      }

      return newStockQuantity < 0;
    }

    return true;
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      let { stockAdjustment } = values;

      if (activeProduct?.stockQuantity !== null && selectedStockReason) {
        // eslint-disable-next-line default-case
        switch (selectedStockReason) {
          case 'Stock received':
            stockAdjustment = values.stockAdjustment;
            break;
          case 'Inventory re-count':
            stockAdjustment =
              values.stockAdjustment - activeProduct?.stockQuantity;
            break;
          case 'Damage':
            stockAdjustment = -values.stockAdjustment;
            break;
          case 'Theft':
            stockAdjustment = -values.stockAdjustment;
            break;
          case 'Loss':
            stockAdjustment = -values.stockAdjustment;
            break;
          case 'Restock return':
            stockAdjustment = values.stockAdjustment;
            break;
        }
      }

      const newValues = {
        ...values,
        taxes: values.taxes ? [values.taxes] : [],
        stockReason: selectedStockReason || null,
        stockAdjustment,
      };

      delete newValues.unitPrice;
      delete newValues.purchasePrice;

      if (!displayStockOptions) {
        newValues.stockQuantity = null;
      }

      if (values.unitPrice) {
        newValues.unitPrice = parseFloat(values.unitPrice);
      }

      if (displayPurchaseOptions && values.purchasePrice) {
        newValues.purchasePrice = parseFloat(values.purchasePrice);
      } else {
        newValues.purchasePrice = null;
        newValues.purchaseCategoryAccountId = null;
        newValues.purchaseDescription = '';
      }

      if (activeProduct) {
        await handleUpdateProduct(newValues, activeProduct?.id);
        toast.success('Product updated successfully');
      } else {
        const response = await handleAddProduct(newValues);
        toast.success('Product created successfully');
        if (isInvoices && onAddProduct) {
          setSelectedTaxes([]);
          onAddProduct(response?.data);
        }
      }
      setTitle('Products & Services');
      formikRef.current?.resetForm();
      setFormOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const addTax = () => {
    setModalOpen(true);
  };

  const handleAddTax = async (tax) => {
    const response = await createTax(tax);
    await getTaxesApi();
    formikRef?.current?.setFieldValue('taxes', response?.data?.tax?.id);
    // setSelectedTaxes((prev) => [...prev, response?.data?.tax?.id]);
  };

  const findStockLabel = () => {
    switch (selectedStockReason) {
      case 'Stock received':
        return 'Add stock';
      case 'Inventory re-count':
        return 'Stock on hand';
      case 'Damage':
        return 'Remove stock';
      case 'Theft':
        return 'Remove stock';
      case 'Loss':
        return 'Remove stock';
      case 'Restock return':
        return 'Add stock';
      default:
        return 'Stock Quantity';
    }
  };

  return (
    <>
      <TaxModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleAddTax={handleAddTax}
      />
      <Formik
        enableReinitialize
        initialValues={{
          name: activeProduct?.name || '',
          pricingMethod:
            activeProduct?.pricingMethod || (projectsPage ? 'hour' : 'item'),
          unitPrice: activeProduct?.unitPrice
            ? parseFloat(activeProduct?.unitPrice).toFixed(2)
            : '',
          taxes: activeProduct?.taxes?.[0]?.id || '',
          currency:
            activeProduct?.currency ||
            team?.currency ||
            localStorage.getItem(TEAM_CURRENCY) ||
            '',
          description: activeProduct?.description || '',
          categoryAccountId: activeProduct?.categoryAccountId || null,
          purchasePrice: +activeProduct?.purchasePrice
            ? parseFloat(+activeProduct?.purchasePrice).toFixed(2)
            : '',
          purchaseCategoryAccountId:
            activeProduct?.purchaseCategoryAccountId || null,
          purchaseDescription: activeProduct?.purchaseDescription || '',
          timeEntryTask: activeProduct?.timeEntryTask || projectsPage,
          stockAdjustment: '',
          notes: activeProduct?.notes || '',
          status: activeProduct?.status || 'active',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is Required'),
          pricingMethod: Yup.string().required('Pricing Method is Required'),
          unitPrice: Yup.number()
            .optional()
            .positive('Price must be a positive number'),
          purchasePrice: Yup.number()
            .optional()
            .positive('Price must be a positive number'),
          categoryAccountId: isInvoices
            ? Yup.number().required('Category is required')
            : Yup.number().nullable().optional(),
          taxes: Yup.string().optional(),
          currency: Yup.string().optional(),
          description: Yup.string().optional(),
          purchaseCategoryAccountId: Yup.number().nullable().optional(),
          purchaseDescription: Yup.string().optional(),
          timeEntryTask: Yup.string().optional(),
          stockAdjustment: Yup.number()
            .optional()
            .min(0, 'You cannot adjust stock to be negative'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className={`${isModalView ? 'px-8 py-6' : 'pb-12'}`}>
            <div className="grid grid-cols-12 gap-4 w-full gap-y-10">
              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Product or Service Details:
                    <span className="text-rose-500">*</span>
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Enter the basic details for the product or service,
                    including its name and pricing method. This helps identify
                    the item and determine how pricing is applied (e.g., per
                    unit, hourly). If a fixed price service, select Unit.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Product or Service Details:{' '}
                  <span className="text-rose-500">*</span>
                </h3>

                <div className="col-span-full sm:col-span-6 flex items-start w-full flex-col gap-2.5">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5"
                    htmlFor="name"
                  >
                    Product / Service Name{' '}
                    <span className="text-rose-400">*</span>
                  </label>
                  <TextInputDash
                    id="name"
                    name="name"
                    placeholder="Name"
                    error={
                      !!(validation.touched.name && validation.errors.name)
                    }
                    value={validation.values.name || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    required
                    paddingBottom="pb-0"
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <div className="text-xs text-rose-400">
                      {validation.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-6 w-full">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                    htmlFor="pricingMethod"
                  >
                    Pricing Method <span className="text-rose-400">*</span>
                  </label>
                  <DropdownFull
                    options={[
                      { name: 'Unit', id: 'item' },
                      { name: 'Hourly', id: 'hour' },
                    ]}
                    name="name"
                    setSelected={(name) =>
                      validation.setFieldValue('pricingMethod', name)
                    }
                    selected={validation.values.pricingMethod}
                    error={
                      validation.touched.pricingMethod &&
                      validation.errors.pricingMethod
                    }
                    placeholder="Select"
                    noSearch
                    className="!h-12"
                    scrollIntoView={false}
                  />
                </div>
                <div className="col-span-full sm:col-span-6 w-full">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                    htmlFor="currency"
                  >
                    Currency <span className="text-rose-400">*</span>
                  </label>
                  <DropdownFull
                    options={currencies?.map?.((c) => ({
                      id: c.isoCode,
                      name: c.name,
                    }))}
                    name="name"
                    setSelected={(name) =>
                      validation.setFieldValue('currency', name)
                    }
                    selected={validation.values.currency}
                    error={
                      validation.touched.currency && validation.errors.currency
                    }
                    placeholder="Select"
                    noSearch
                    className="!h-12"
                    scrollIntoView={false}
                  />
                </div>
                <div className="col-span-full sm:col-span-6 w-full">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-4"
                    htmlFor="currency"
                  >
                    Status <span className="text-rose-500">*</span>
                  </label>
                  <ColorDropdown
                    statusOptions={STATUS_OPTIONS}
                    selected={validation.values.status}
                    setSelected={(status) =>
                      validation.setFieldValue('status', status)
                    }
                  />
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Sales Information:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Enter the selling details for the product or service,
                    including the sell price per unit/hour and the income
                    account for categorizing sales. This ensures proper revenue
                    tracking in financial reports.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Sales Information:{' '}
                </h3>

                <div className="col-span-full">
                  <label className="flex items-center gap-1 w-fit">
                    <input
                      id="enableSell"
                      name="enableSell"
                      type="checkbox"
                      className="h-6 w-6 form-checkbox cursor-pointer"
                      onChange={(e) => {
                        setDisplaySellOptions(e.target.checked);
                      }}
                      checked={displaySellOptions}
                    />
                    <p className="text-slate-600 font-medium ml-2 text-lg">
                      Sell Price
                    </p>
                  </label>
                </div>
                {displaySellOptions && (
                  <>
                    <div className="col-span-full sm:col-span-6  flex items-start flex-col gap-2.5 relative">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="unitPrice"
                      >
                        Per{' '}
                        {validation?.values?.pricingMethod === 'item'
                          ? ' Unit'
                          : validation?.values?.pricingMethod
                              .charAt(0)
                              .toUpperCase() +
                            validation?.values?.pricingMethod.slice(1)}{' '}
                        Price ({validation?.values?.currency}){' '}
                      </label>
                      <TextInputDash
                        id="unitPrice"
                        name="unitPrice"
                        placeholder="0.00"
                        value={validation.values.unitPrice || ''}
                        error={
                          !!(
                            validation.touched.unitPrice &&
                            validation.errors.unitPrice
                          )
                        }
                        onChange={validation.handleChange}
                        onBlur={(e) => {
                          validation.handleBlur(e);
                          if (
                            !hasUpToTwoDecimalPlaces(
                              parseFloat(validation.values.unitPrice || 0),
                            )
                          ) {
                            validation.setFieldValue(
                              'unitPrice',
                              roundToTwo(validation.values.unitPrice),
                            );
                          }
                        }}
                        type="number"
                        required
                        currency={currencies?.find?.(
                          (c) => c.isoCode === validation.values.currency,
                        )}
                        paddingBottom="pb-0"
                        onWheel={(e) => e.target.blur()}
                      />
                      {validation.touched.unitPrice &&
                      validation.errors.unitPrice ? (
                        <div className="text-xs text-rose-400">
                          {validation.errors.unitPrice}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-full sm:col-span-6  flex items-start">
                      <div className="flex flex-col items-start w-full mb-1">
                        <label
                          className="block text-sm text-slate-600 mb-2 font-medium"
                          htmlFor="category"
                        >
                          Income Account{' '}
                          {isInvoices && (
                            <span className="text-rose-500">*</span>
                          )}
                        </label>
                        <CategoriesDropdown
                          allCategories={
                            activeProduct?.categoryAccount?.status ===
                            'inactive'
                              ? getCategoriesWithInactiveAccount(
                                  activeProduct?.categoryAccount,
                                  incomeCategories,
                                )
                              : incomeCategories
                          }
                          selectedCategoryId={
                            validation.values.categoryAccountId
                          }
                          setSelectedCategoryId={(id) =>
                            validation.setFieldValue('categoryAccountId', id)
                          }
                          isSetCategoryStyle
                          height="h-12"
                          isError={
                            validation.touched.categoryAccountId &&
                            validation.errors.categoryAccountId
                          }
                          placeholderText="Select Account"
                        />
                        {validation.touched.categoryAccountId &&
                          validation.errors.categoryAccountId && (
                            <div className="mt-1 text-xs text-rose-400">
                              {validation.errors.categoryAccountId}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-6  flex items-start">
                      <div className="w-full flex flex-col items-start gap-2.5 mb-1">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <TextInputDash
                          id="description"
                          name="description"
                          placeholder="Description"
                          value={validation.values.description || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-0"
                        />
                      </div>
                    </div>
                    {/* <div className="col-span-full sm:col-span-6 ">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5 mb-2.5"
                        htmlFor="taxes"
                      >
                        Default Taxes
                      </label>
                      <div className="relative">
                        <DropdownFull
                          selected={validation.values.taxes}
                          setSelected={(v) => {
                            validation.setFieldValue('taxes', v);
                          }}
                          options={
                            taxes?.map((t) => ({
                              name: `${t?.name}`,
                              id: t?.id,
                              percentage: t?.percentage,
                            })) || []
                          }
                          id="taxes"
                          placeholder="No Taxes"
                          flyout
                          width="w-[30rem]"
                          height={`h-12 ${validation.values.taxes ? 'pr-8' : ''}`}
                          className="!h-fit"
                          addNewOptionLabel="Add A New Tax"
                          name="name"
                          scrollIntoView={false}
                          handleNewOptionButton={addTax}
                          addNewOptionButton={checkPermission(
                            PermissionKeys.MANAGE_SETTINGS,
                            accessType,
                            permissions,
                          )}
                          taxes
                        />
                        {validation.values.taxes && (
                          <button
                            className="absolute top-1/2 right-2 -translate-y-1/2"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              validation.setFieldValue('taxes', '');
                            }}
                          >
                            <svg
                              className="w-[14px] h-[14px] fill-[#667085cc] group-hover:fill-slate-800 pointer-events-none"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div> */}
                  </>
                )}
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Purchase Information:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Specify the details related to purchasing the product,
                    including the purchase price per unit/hour and the expense
                    account to categorize purchases. This information is used
                    for accurate cost tracking.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Purchase Information:{' '}
                </h3>

                <div className="col-span-full">
                  <label className="flex items-center gap-1 w-fit">
                    <input
                      id="enablePurchase"
                      name="enablePurchase"
                      type="checkbox"
                      className="h-6 w-6 form-checkbox cursor-pointer"
                      onChange={(e) => {
                        setDisplayPurchaseOptions(e.target.checked);
                      }}
                      checked={displayPurchaseOptions}
                    />
                    <p className="text-slate-600 font-medium ml-2 text-lg">
                      Purchase Price
                    </p>
                  </label>
                </div>
                {displayPurchaseOptions && (
                  <>
                    <div className="col-span-full sm:col-span-6 flex items-start flex-col gap-2.5 relative">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="purchasePrice"
                      >
                        Per{' '}
                        {validation?.values?.pricingMethod === 'item'
                          ? ' Unit'
                          : validation?.values?.pricingMethod
                              .charAt(0)
                              .toUpperCase() +
                            validation?.values?.pricingMethod.slice(1)}{' '}
                        Price ({validation?.values?.currency})
                      </label>
                      <TextInputDash
                        id="purchasePrice"
                        name="purchasePrice"
                        placeholder="0.00"
                        value={validation.values.purchasePrice || ''}
                        onChange={validation.handleChange}
                        onBlur={(e) => {
                          validation.handleBlur(e);
                          if (
                            !hasUpToTwoDecimalPlaces(
                              parseFloat(validation.values.purchasePrice || 0),
                            )
                          ) {
                            validation.setFieldValue(
                              'purchasePrice',
                              roundToTwo(validation.values.purchasePrice),
                            );
                          }
                        }}
                        type="number"
                        required
                        currency={currencies?.find?.(
                          (c) => c.isoCode === validation.values.currency,
                        )}
                        paddingBottom="pb-0"
                        onWheel={(e) => e.target.blur()}
                      />
                      {validation.touched.purchasePrice &&
                      validation.errors.purchasePrice ? (
                        <div className="text-xs text-rose-400">
                          {validation.errors.purchasePrice}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-full sm:col-span-6 flex items-start">
                      <div className="flex flex-col items-start w-full mb-1">
                        <label
                          className="block text-sm text-slate-600 mb-2 font-medium"
                          htmlFor="purchaseCategoryAccountId"
                        >
                          Expense Account
                        </label>
                        <CategoriesDropdown
                          allCategories={
                            activeProduct?.purchaseCategoryAccount?.status ===
                            'inactive'
                              ? getCategoriesWithInactiveAccount(
                                  activeProduct?.purchaseCategoryAccount,
                                  expenseCategories,
                                )
                              : expenseCategories
                          }
                          selectedCategoryId={
                            validation.values.purchaseCategoryAccountId
                          }
                          setSelectedCategoryId={(id) =>
                            validation.setFieldValue(
                              'purchaseCategoryAccountId',
                              id,
                            )
                          }
                          isSetCategoryStyle
                          height="h-12"
                          placeholderText="Select Account"
                        />
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-6 flex items-start">
                      <div className="w-full flex flex-col items-start gap-2.5 mb-1">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="purchaseDescription"
                        >
                          Description
                        </label>
                        <TextInputDash
                          id="purchaseDescription"
                          name="purchaseDescription"
                          placeholder="Description"
                          value={validation.values.purchaseDescription || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-0"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Inventory Tracking:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    For physical products, enable inventory tracking to monitor
                    stock levels. Specify the current stock quantity if
                    applicable. When an estimate or invoice is created, the
                    stock levels will automatically be adjusted.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Inventory Tracking:{' '}
                </h3>

                <div className="col-span-full">
                  <label className="flex items-center gap-1 w-fit">
                    <input
                      id="enableStock"
                      name="enableStock"
                      type="checkbox"
                      className="h-6 w-6 form-checkbox cursor-pointer"
                      onChange={(e) => {
                        setDisplayStockOptions(e.target.checked);
                      }}
                      checked={displayStockOptions}
                    />
                    <p className="text-slate-600 font-medium ml-2 text-lg">
                      Track Stock
                    </p>
                  </label>
                </div>
                {displayStockOptions &&
                  (firstTimeAddingStock ? (
                    <div className="col-span-full sm:col-span-6 flex items-start flex-col gap-2.5">
                      <label
                        className="block text-sm text-slate-600 font-medium leading-5"
                        htmlFor="stockAdjustment"
                      >
                        Stock Quantity
                      </label>
                      <TextInputDash
                        id="stockAdjustment"
                        name="stockAdjustment"
                        placeholder="0"
                        value={validation.values.stockAdjustment || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        type="number"
                        paddingBottom="pb-0"
                        onWheel={(e) => e.target.blur()}
                      />
                      {validation.touched.stockAdjustment &&
                      validation.errors.stockAdjustment ? (
                        <div className="text-xs text-rose-400">
                          {validation.errors.stockAdjustment}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="col-span-full flex items-start flex-col">
                        <p>Stock on Hand: {activeProduct?.stockQuantity}</p>
                        <div className="flex gap-4 mt-1">
                          <p
                            className="text-sm text-indigo-500 underline cursor-pointer"
                            onClick={() => {
                              setDisplayStockDropdown((prev) => !prev);
                            }}
                          >
                            {displayStockDropdown ? 'Cancel' : 'Edit Stock'}
                          </p>
                          <p
                            className="text-sm text-indigo-500 underline cursor-pointer"
                            onClick={() => {
                              loadProductHistory({ id: activeProduct?.id });
                              setProductsPanelOpen(true);
                            }}
                          >
                            Stock History
                          </p>
                        </div>
                      </div>
                      {displayStockDropdown && (
                        <>
                          <div className="col-span-full sm:col-span-6 flex pb-4 items-start flex-col gap-2.5">
                            <label
                              className="block text-sm text-slate-600 font-medium leading-5"
                              htmlFor="stockAction"
                            >
                              Stock Action
                            </label>
                            <DropdownFull
                              options={[
                                {
                                  name: 'Stock received',
                                  id: 'Stock received',
                                },
                                {
                                  name: 'Inventory re-count',
                                  id: 'Inventory re-count',
                                },
                                { name: 'Damage', id: 'Damage' },
                                { name: 'Theft', id: 'Theft' },
                                { name: 'Loss', id: 'Loss' },
                                {
                                  name: 'Restock return',
                                  id: 'Restock return',
                                },
                              ]}
                              name="name"
                              setSelected={(name) =>
                                setSelectedStockReason(name)
                              }
                              selected={selectedStockReason}
                              placeholder="Select"
                              noSearch
                              className="!h-12"
                              scrollIntoView={false}
                            />
                          </div>
                          {selectedStockReason && (
                            <div className="col-span-full sm:col-span-6 flex items-start flex-col gap-2.5">
                              <label
                                className="block text-sm text-slate-600 font-medium leading-5"
                                htmlFor="stockAdjustment"
                              >
                                {findStockLabel()}
                              </label>
                              <TextInputDash
                                id="stockAdjustment"
                                name="stockAdjustment"
                                placeholder="0"
                                value={validation.values.stockAdjustment || ''}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                type="number"
                                paddingBottom="pb-4"
                                error={
                                  (checkForStockError(
                                    validation.values.stockAdjustment,
                                  ) &&
                                    'You cannot adjust stock to be negative') ||
                                  (validation.touched.stockAdjustment &&
                                    validation.errors.stockAdjustment)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Time Entry Service:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Check this box to make this service available for logging
                    time entries in your projects and payroll timesheets.
                    Enabling this option allows for accurate tracking of
                    billable hours and ensures proper billing for the services
                    provided.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Time Entry Service:{' '}
                </h3>

                <div className="col-span-full">
                  <div className="flex items-center">
                    <input
                      id="timeEntryTask"
                      name="timeEntryTask"
                      type="checkbox"
                      className="h-6 w-6 form-checkbox cursor-pointer"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.timeEntryTask}
                    />
                    <label
                      htmlFor="timeEntryTask"
                      className="ml-2 block text-slate-600 font-medium text-lg"
                    >
                      Time Entry Service
                    </label>
                    {/* <Tooltip
                      content={
                        <>
                          <p>
                            Check this box to make this service available as an
                            option
                          </p>
                          <p>
                            when logging time entries for your projects. This
                            ensures
                          </p>
                          <p>
                            accurate tracking and billing for the services
                            provided.
                          </p>
                        </>
                      }
                      size="lg"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] text-center relative left-[1rem]"
                    >
                      <div className="ml-2">
                        <InfoIcon />
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Notes:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Add any additional information or internal notes about the
                    product or service. These notes are for internal use only
                    and won't appear on customer-facing documents, helping you
                    keep track of important details or special instructions.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-4">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Notes:
                </h3>

                <div className="col-span-full flex flex-col items-start gap-2.5 w-[100%] mb-[1.875rem]">
                  <label
                    className="text-sm font-medium text-slate-600 block"
                    htmlFor="note"
                  >
                    Notes
                  </label>
                  <textarea
                    id="notes"
                    name="notes"
                    placeholder="Notes"
                    value={validation.values.notes || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    className="form-input w-full h-[4.875rem] px-4 text-[#667085] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px]"
                  />
                </div>
              </div>
            </div>
            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 mt-[1.875rem]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  setFormOpen(false);
                  formikRef.current?.resetForm();
                  setActiveProduct(null);
                  setTitle('Products & Services');
                }}
                type="button"
              >
                {activeProduct ? 'Discard' : 'Close'}
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 disabled:bg-indigo-400 shadow w-[6.875rem] h-11 text-white leading-6"
                type="submit"
                disabled={
                  isLoading ||
                  (validation.values.stockAdjustment
                    ? checkForStockError(validation.values.stockAdjustment)
                    : false)
                }
              >
                {isLoading ? (
                  <Loader width="w-4" height="w-4" color="#FFFFFF" />
                ) : activeProduct ? (
                  'Update'
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductForm;
