import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import TextInputDash from 'components/TextInputDash';

import ModalBasic from 'components/ModalBasic';

const CUSTOM_DEFAULT = [
  {
    name: '',
    value: '',
    nameError: '',
    valueError: '',
  },
];
const AddEditEncryptedRecord = ({
  isOpen,
  setIsOpen,
  activeEncrypt = null,
  isLoading = false,
  setActiveEncrypt = () => {},
  createEncryptions,
  updateEncryptions,
}) => {
  const [customFields, setCustomFields] = useState(CUSTOM_DEFAULT);

  const formikRef = useRef();

  useEffect(() => {
    if (activeEncrypt) {
      if (activeEncrypt?.customFields?.length > 0) {
        const temp = [];

        activeEncrypt?.customFields?.forEach((active) => {
          temp.push({
            name: active?.name,
            value: active?.value,
            nameError: '',
            valueError: '',
          });
        });
        setCustomFields(temp);
      } else {
        setCustomFields([]);
      }
    }
  }, [activeEncrypt]);

  useEffect(() => {
    if (!isOpen) {
      setCustomFields(CUSTOM_DEFAULT);
      formikRef.current?.resetForm();
      setActiveEncrypt(null);
    }
  }, [isOpen]);

  const checkError = () => {
    let isError = false;
    if (customFields?.length === 0) {
      return false;
    }
    const temp = JSON.parse(JSON.stringify(customFields || []));

    temp?.forEach((field, i) => {
      if (!field?.name || !field?.value) {
        if (!field?.name && field?.value) {
          field.nameError = 'Key is required';
          isError = true;
        }
        if (!field?.value && field?.name) {
          field.valueError = 'Value is required';
          isError = true;
        } else if (!field?.name && !field?.value) {
          if (field.nameError) {
            field.nameError = '';
          }
          if (field.valueError) {
            field.valueError = '';
          }
        }
      } else {
        if (field.nameError) {
          field.nameError = '';
        }
        if (field.valueError) {
          field.valueError = '';
        }
      }
    });
    setCustomFields(temp);
    return isError;
  };

  const onAddCustomField = () => {
    const temp = JSON.parse(JSON.stringify(customFields || []));
    setCustomFields([
      ...temp,
      {
        name: '',
        value: '',
      },
    ]);
  };

  const onEditCustomeField = ({ index, key, value }) => {
    const temp = JSON.parse(JSON.stringify(customFields || []));
    const row = { ...temp[index] };
    row[key] = value;
    temp[index] = row;
    setCustomFields(temp);
  };

  const onCustomRowDelete = (index) => {
    const temp = JSON.parse(JSON.stringify(customFields || []));
    setCustomFields(temp?.filter((_, i) => index !== i));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (checkError()) {
      return;
    }
    setSubmitting(false);
    try {
      const temp = { ...values };
      const tempCustoms = [];
      customFields?.forEach((field) => {
        if (field?.name && field?.value) {
          tempCustoms?.push({
            name: field?.name,
            value: field?.value,
          });
        }
      });

      temp.customFields = tempCustoms;
      if (activeEncrypt?.id) {
        await updateEncryptions(activeEncrypt?.id, temp);
      } else {
        await createEncryptions(temp);
      }
      formikRef.current?.resetForm();
      setCustomFields(CUSTOM_DEFAULT);
      setIsOpen(false);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    document.getElementById('username')?.setAttribute('autocomplete', 'off');
    document
      .getElementById('password')
      ?.setAttribute('autocomplete', 'new-password');
  }, []);

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title={`${activeEncrypt?.id ? 'Update' : 'Create'} Encrypted Record`}
      width="!w-[900px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: activeEncrypt?.name || '',
          username: activeEncrypt?.username || '',
          password: activeEncrypt?.password || '',
          link: activeEncrypt?.link || '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
          username: Yup.string().optional(),
          password: Yup.string().optional(),
          link: Yup.string().matches(
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+.*)$/,
            'Invalid URL',
          ),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        autoComplete="off"
      >
        {(validation) => (
          <Form className=" py-6">
            <div className="grid sm:grid-cols-2 gap-4 w-full gap-y-0 mb-4 px-8">
              <TextInputDash
                label="Name"
                id="name"
                name="name"
                placeholder="E.g. My App"
                value={validation.values.name || ''}
                error={validation.touched.name && validation.errors.name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                required
                autoComplete="off"
              />
              <TextInputDash
                label="Username"
                id="username"
                name="username"
                placeholder="E.g. john.doe@example.com"
                value={validation.values.username || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              <TextInputDash
                label="Password"
                id="password"
                name="password"
                type="password"
                placeholder="E.g. myPassword"
                value={validation.values.password || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                autoComplete="new-password"
                inputClass="!pr-12"
              />
              <TextInputDash
                label="Link (URL)"
                id="link"
                name="link"
                placeholder="E.g. https://myapp.com/login"
                value={validation.values.link || ''}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                error={validation.touched.link && validation.errors.link}
              />
            </div>
            <div className="px-8 mb-6">
              {customFields?.length > 0 && (
                <>
                  <h3 className="font-semibold mb-2">
                    Encrypted Custom Fields
                  </h3>
                  <div className="space-y-4">
                    {customFields?.map((customField, index) => (
                      <div className="flex gap-4" key={index}>
                        <TextInputDash
                          id="name"
                          name="name"
                          placeholder="Key"
                          value={customField?.name}
                          onChange={(e) => {
                            onEditCustomeField({
                              index,
                              key: 'name',
                              value: e.target.value,
                            });
                          }}
                          error={customField?.nameError}
                          onBlur={
                            customField?.nameError ? checkError : () => {}
                          }
                          paddingBottom={
                            customField?.nameError ? 'pb-5' : 'pb-0'
                          }
                        />
                        <TextInputDash
                          id="value"
                          name="value"
                          placeholder="Value"
                          value={customField?.value}
                          onChange={(e) => {
                            onEditCustomeField({
                              index,
                              key: 'value',
                              value: e.target.value,
                            });
                          }}
                          error={customField?.valueError}
                          onBlur={
                            customField?.valueError ? checkError : () => {}
                          }
                          paddingBottom={
                            customField?.valueError ? 'pb-5' : 'pb-0'
                          }
                        />
                        <button
                          className="min-w-7 min-h-7 cursor-pointer disabled:border-[1.2px] disabled:bg-white inline-flex items-center justify-center disabled:border-rose-500 disabled:rounded-[8px] disabled:cursor-not-allowed"
                          onClick={() => onCustomRowDelete(index)}
                          type="button"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-full w-full"
                          >
                            <path
                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                              fill="white"
                              stroke="#f43f5e"
                              strokeOpacity="0.6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                              stroke="#f43f5e"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.693 16.3574H14.8505"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.1552 13.7656H15.3948"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>

            <button
              onClick={onAddCustomField}
              type="button"
              className="ml-8 mb-6 pl-2 font-bold text-base text-indigo-500 hover:text-indigo-600
                             disabled:text-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed  flex items-center justify-center  gap-1"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current text-current"
              >
                <path
                  d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                  className="stroke-current"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              New Field
            </button>

            {/* Close/Add Buttons */}
            <div className="px-8 w-full flex justify-end items-center gap-4 pt-[1.875rem] border-t border-[#D0D5DD]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();
                  setActiveEncrypt(null);
                  setCustomFields(CUSTOM_DEFAULT);
                  setIsOpen(false);
                }}
                type="button"
              >
                {activeEncrypt ? 'Cancel' : 'Close'}
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
                        disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {activeEncrypt ? 'Update' : 'Add'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default AddEditEncryptedRecord;
