import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCurrencySymbol } from 'utils/Utils';

import ExpenseClaimTable from './ExpenseClaimTable';

const ExpenseClaimPdf = () => {
  const [searchParams] = useSearchParams();

  const startDate = searchParams.get('startDate') || '';
  const expenseReporterId = searchParams.get('expenseReporterId') || '';
  const endDate = searchParams.get('endDate') || '';
  const accountId = searchParams.get('accountIds') || '';
  const status = searchParams.get('status') || '';
  const accessToken = searchParams.get('accessToken') || '';
  const teamId = searchParams.get('teamId') || '';
  const teamName = searchParams.get('teamName') || '';
  const currency = searchParams.get('currency') || '';

  const [generatedTransaction, setGeneratedTransaction] = useState([]);

  const handleSubmit = async ({
    startDate,
    endDate,
    expenseReporterId,
    status,
    accountIds,
  }) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/reports/expense-reporter?startDate=${startDate}&endDate=${endDate}&accountIds=${accountIds}&expenseReporterId=${expenseReporterId}&status=${status}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'count-workspace-id': teamId,
          },
        },
      );

      setGeneratedTransaction(response?.data?.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const formatDate = (date) => moment(date).format('MMM DD, YYYY');

  useEffect(() => {
    if (searchParams?.size !== 0) {
      handleSubmit({
        startDate,
        endDate,
        expenseReporterId,
        status,
        accountIds: accountId?.length > 0 ? accountId?.split?.(',') : '',
      });
    }
  }, [searchParams]);

  return (
    <div className="p-4">
      <h1 className="text-2xl text-center text-black  font-medium mb-4">
        Expense Claims Report
      </h1>
      <h2 className="text-lg text-center text-black  font-medium mb-1">
        {teamName}
      </h2>
      <h2 className="text-[17px] text-center text-black  font-medium mb-1">
        {generatedTransaction?.reportInfo?.firstName}{' '}
        {generatedTransaction?.reportInfo?.lastName}
      </h2>
      <h2 className="text-[17px] text-center text-black  font-medium mb-1">
        {generatedTransaction?.reportInfo?.accountId?.name}{' '}
        {generatedTransaction?.reportInfo?.accountId?.mask &&
          `(
        ${generatedTransaction?.reportInfo?.accountId?.mask})`}
      </h2>
      <h2 className="text-base text-center text-black mb-4">
        {formatDate(generatedTransaction?.filters?.startDate)} to{' '}
        {formatDate(generatedTransaction?.filters?.endDate)}
      </h2>
      <div>
        <ExpenseClaimTable
          currencySymbol={getCurrencySymbol(currency || 'USD')}
          generatedReport={generatedTransaction}
          pdfView
        />
      </div>
    </div>
  );
};
export default ExpenseClaimPdf;
