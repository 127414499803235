import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { updateInvoice } from 'API/backend_helper';
import ModalBasic from 'components/ModalBasic';
import DropdownFull from 'components/DropdownFull';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import { areArraysEqual } from 'utils/Utils';

const QuickInvoiceUpdateModal = ({
  isOpen,
  setIsOpen,
  selectedProject = null,
  projects = [],
  disableChangeProject = false,
  selectedTags = [],
  tags = [],
  getInvoiceById,
  updateTags,
  invoiceId,
  editInvoice,
}) => {
  const formikRef = useRef(null);
  const { accessType, permissions } = useSelector((state) => state.User);

  const [loading, setLoading] = useState(false);
  const [noClick, setNoClick] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      formikRef?.current?.resetForm();
    }
  }, [isOpen]);

  const handleSubmit = async (values) => {
    const { project, tags } = values;

    try {
      if (
        !areArraysEqual(
          editInvoice?.tags?.map((t) => t?.id),
          tags,
        ) ||
        editInvoice?.projectId !== project
      ) {
        setLoading(true);
        if (editInvoice?.projectId !== project) {
          const data = {};
          data.projectId = project || null;
          await updateInvoice(data, invoiceId);
        }
        if (
          !areArraysEqual(
            editInvoice?.tags?.map((t) => t?.id),
            tags,
          )
        ) {
          await updateTags(invoiceId, tags);
        }
        setLoading(false);
        getInvoiceById(invoiceId, false);
        setIsOpen(false);
      }
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Update Invoice"
      dialogClassName="!overflow-visible"
      contentId="quickInvoiceUpdateModal"
      noClick={noClick}
    >
      <Formik
        enableReinitialize
        initialValues={{
          project: selectedProject || null,
          tags: selectedTags || [],
        }}
        validationSchema={Yup.object({})}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className="flex flex-col  justify-between overflow-visible ">
            <div className="px-7 py-5 min-h-[400px] space-y-4">
              <div className="w-full">
                <p className="text-sm font-medium text-nowrap mb-2">
                  Project
                  {validation.values.project && !disableChangeProject && (
                    <span
                      className="text-indigo-500 pl-2 cursor-pointer"
                      onClick={() => validation.setFieldValue('project', null)}
                      role="button"
                      tabIndex="0"
                    >
                      Clear
                    </span>
                  )}
                </p>
                <DropdownFull
                  options={projects}
                  selected={validation.values.project}
                  setSelected={(value) => {
                    validation.setFieldValue('project', value);
                  }}
                  name="name"
                  scrollIntoView={false}
                  height="h-12 w-full"
                  placeholder="Attach to Project"
                  className="whitespace-nowrap truncate !h-fit"
                  //   search
                  flyout
                  bottomOfTable
                  ids={['quickInvoiceUpdateModal']}
                  disabled={disableChangeProject}
                  setNoClick={setNoClick}
                />
              </div>
              <div className="w-full">
                <p className="text-sm font-medium text-nowrap mb-2">Tags</p>
                <BasicCheckboxDropdown
                  selectedOptions={validation.values.tags}
                  setSelectedOptions={(value) =>
                    validation.setFieldValue('tags', value)
                  }
                  options={
                    tags?.map((t) => ({
                      label: t?.name,
                      value: t?.id,
                    })) || []
                  }
                  label="Use Tags"
                  type="tag"
                  flyout
                  width="w-full !h-12"
                  className="w-full"
                  disabled={
                    !checkPermission(
                      PermissionKeys.MANAGE_INVOICES,
                      accessType,
                      permissions,
                    )
                  }
                  bottomOfTable
                  ids={['quickInvoiceUpdateModal']}
                  dropdownBoxWidth="min-w-44 w-full"
                  setNoClick={setNoClick}
                />
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap gap-4  justify-end px-5 py-4 border-t">
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6 disabled:cursor-not-allowed"
                onClick={() => setIsOpen(false)}
                type="button"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="h-11 w-20 btn !bg-indigo-500 text-white text-base leading-6 font-base rounded-[0.313rem]
            disabled:!bg-indigo-300    disabled:cursor-not-allowed"
                disabled={
                  loading ||
                  (editInvoice?.projectId === validation.values.project &&
                    areArraysEqual(
                      editInvoice?.tags?.map((t) => t?.id),
                      validation?.values?.tags,
                    ))
                }
              >
                {loading ? (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default QuickInvoiceUpdateModal;
