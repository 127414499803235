import React, { useEffect, useState } from 'react';
import InvoicesTable from 'pages/customerDetails/invoices/InvoicesTable';
import { useNavigate } from 'react-router-dom';

const TABS = {
  invoices: 'invoices',
  estimates: 'estimates',
};

const BillingTab = ({
  invoices,
  invoicesLocalLimit,
  setInvoicesLocalLimit,
  getInvoicesData,
  isInvoicesTabLoaded,
  setIsInvoicesTabLoaded,
  customerId,
  WSId,
  onTeamDetailsChange,
  accountantFirm,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [activeTable, setActiveTable] = useState(TABS.invoices);

  const loadData = async () => {
    setLoading(true);
    try {
      await getInvoicesData({});
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isInvoicesTabLoaded && customerId && WSId) {
      loadData();
      setIsInvoicesTabLoaded(true);
    }
  }, [customerId, isInvoicesTabLoaded, WSId]);

  return loading ? (
    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin w-10 h-10 fill-current shrink-0"
      >
        <path
          d="M14.5 2.41699V7.25033"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 21.749V26.5824"
          stroke="#E48642"
          strokeWidth="2.55882"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 5.95703L9.37662 9.37662"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 19.6211L23.0426 23.0407"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.41699 14.5H7.25033"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.749 14.5H26.5824"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95703 23.0407L9.37662 19.6211"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.623 9.37662L23.0426 5.95703"
          stroke="#E48642"
          strokeWidth="1.58955"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
        Fetching data, please wait...
      </p>
    </div>
  ) : (
    <div>
      <div className="flex justify-end mb-4">
        <button
          onClick={() => {
            onTeamDetailsChange(accountantFirm?.workspace);
            navigate(
              `/invoices?tab=invoices&new=invoice&from=client&customerId=${customerId}`,
            );
          }}
          type="button"
          className="btn-sm h-10 px-4 text-base bg-indigo-500 hover:bg-indigo-600 text-white
                         disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed  flex items-center justify-center  gap-2"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=""
          >
            <path
              d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Create an Invoice
        </button>
      </div>
      <InvoicesTable
        invoices={invoices}
        invoicesLocalLimit={invoicesLocalLimit}
        getInvoicesData={getInvoicesData}
        setInvoicesLocalLimit={setInvoicesLocalLimit}
      />
    </div>
  );
};

export default BillingTab;
