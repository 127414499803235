import React, { useEffect, useMemo, useState } from 'react';
import AlertBox from 'components/AlertBox';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import { toast } from 'react-toastify';
import TaxModal from 'pages/settings/taxes/TaxModal';
import DropdownFull from '../../components/DropdownFull';
import {
  createTax,
  getAccountSubTypes,
  getAccountTypes,
  getTaxes,
} from '../../API/backend_helper';

const Row = ({
  currentPage,
  localLimit,
  checkRowsError,
  b,
  key,
  handleDeleteItem,
  handleUpdateValue,
  types,
  subTypes,
  taxes,
  deleteDraftsLoading,
  isChild = false,
  parentIndex = null,
  parentObj = {},
  index,
  setTaxModalOpen,
}) => {
  const filteredSubTypes = useMemo(() => {
    const _subTypes = subTypes
      ?.filter?.((d) => {
        const _type = isChild ? parentObj.type.value : b.type.value;
        return d?.type === _type;
      })
      ?.map?.((d) => ({
        ...d,
        subTypeId: d.name,
        id: d.id,
      }));
    return _subTypes;
  }, [subTypes, isChild, parentObj?.type?.value, b?.type?.value]);

  return (
    <tr key={key}>
      <td
        className={`px-2 first:pl-5 last:pr-5  pb-[2px] text-left ${isChild ? '!pl-8' : ''}`}
      >
        #
        {isChild
          ? (currentPage - 1) * localLimit + parentIndex + 1
          : (currentPage - 1) * localLimit + index + 1}
        {isChild && `.${index + 1}`}
      </td>

      {/* <td className="px-2 first:pl-5 last:pr-5  pb-[2px] text-left">
      <div className="flex h-[60px] pt-3">
        <label className="inline-flex ">
          <span className="sr-only">Select</span>
          <input
            className="form-checkbox cursor-pointer h-5 w-5"
            type="checkbox"
            onChange={() =>
              handleUpdateValue(i, 'selected', !b.selected.value)
            }
            checked={b.selected.value}
          />
        </label>
      </div>
    </td> */}
      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <div className="h-[60px] flex flex-col">
          <input
            type="number"
            placeholder="Account Number"
            value={b.accountNumber.value}
            className={`form-input placeholder:text-slate-300 w-[160px]
          ${b.accountNumber.error ? '' : ''}
          `}
            onChange={(e) =>
              handleUpdateValue(
                parentIndex,
                index,
                'accountNumber',
                e.target.value,
              )
            }
            onBlur={b.accountNumber.error ? checkRowsError : () => {}}
            onWheel={(e) => e.target.blur()}
          />
          {b?.accountNumber?.error && (
            <span className="h-[10px] text-xs mt-1 text-rose-500 ">
              {b?.accountNumber?.error || ''}
            </span>
          )}
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <div className="h-[60px] flex flex-col">
          <input
            type="text"
            placeholder="Account Name"
            value={b?.name?.value}
            className={`form-input placeholder:text-slate-300  ${
              b.name.error ? '!border-rose-500' : ''
            }`}
            onChange={(e) =>
              handleUpdateValue(parentIndex, index, 'name', e.target.value)
            }
            onBlur={b.name.error ? checkRowsError : () => {}}
          />
          <span className="h-[10px] text-xs mt-1 text-rose-500 ">
            {b?.name?.error || ''}
          </span>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <div className="h-[60px] flex flex-col">
          <input
            type="text"
            placeholder="Description"
            value={b?.description?.value}
            className={`form-input placeholder:text-slate-300  ${
              b.description.error ? '!border-rose-500' : ''
            }`}
            onChange={(e) =>
              handleUpdateValue(
                parentIndex,
                index,
                'description',
                e.target.value,
              )
            }
            onBlur={b.description.error ? checkRowsError : () => {}}
          />
          <span className="h-[10px] text-xs mt-1 text-rose-500 ">
            {b?.description?.error || ''}
          </span>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <Tooltip
          content="The sub-account type cannot be modified"
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
          isFixed
          ids={['importAccountsContainer', 'importAccounts3TableContainer']}
          tooltipShow={isChild}
        >
          <div className="h-[60px] flex flex-col">
            <DropdownFull
              options={types.map((d) => ({
                ...d,
                name: d.name,
                id: d.name,
              }))}
              className="!w-fit !h-fit"
              setSelected={(name) =>
                handleUpdateValue(parentIndex, index, 'type', name)
              }
              selected={isChild ? parentObj.type.value : b.type.value}
              name="name"
              bottomOfTable
              ids={['importAccountsContainer', 'importAccounts3TableContainer']}
              isTableDropdown
              height="h-[38px]"
              accounts
              placeholder="Select"
              error={(isChild ? parentObj?.type?.error : b?.type?.error) || ''}
              buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
              buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
              disabled={isChild}
            />
          </div>
        </Tooltip>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <Tooltip
          content="The sub-account sub type cannot be modified"
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
          isFixed
          ids={['importAccountsContainer', 'importAccounts3TableContainer']}
          tooltipShow={isChild}
        >
          <div className="h-[60px] flex flex-col">
            <DropdownFull
              options={filteredSubTypes}
              height="h-[38px] min-w-[270px] max-w-[270px] text-nowrap overflow-hidden text-ellipsis"
              setSelected={(option) => {
                handleUpdateValue(parentIndex, index, 'subTypeId', option);
              }}
              selected={
                isChild ? parentObj.subTypeName.value : b.subTypeName.value
              }
              error={
                (isChild ? parentObj.subTypeId?.error : b.subTypeId?.error) ||
                ''
              }
              accounts
              subType
              placeholder="Select"
              disabled={!b.type.value || isChild}
              className="!w-fit !h-fit"
              name="name"
              bottomOfTable
              ids={['importAccountsContainer', 'importAccounts3TableContainer']}
              isTableDropdown
              selectFullObj
              buttonTextClass="max-w-[90%] overflow-hidden text-ellipsis"
              buttonTextClassSub="max-w-[100%] overflow-hidden text-ellipsis"
            />
          </div>
        </Tooltip>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <div className="h-[60px] flex flex-col ">
          <div className="relative w-full">
            <DropdownFull
              selected={b?.taxes?.value}
              setSelected={(newOptions) => {
                handleUpdateValue(parentIndex, index, 'taxes', newOptions);
              }}
              options={
                taxes?.map((t) => ({
                  name: `${t?.name}`,
                  id: t?.id,
                  percentage: t?.percentage,
                })) || []
              }
              placeholder="No Taxes"
              type="taxes"
              flyout
              width="!w-full h-[38px]"
              height={`h-12 ${b?.taxes?.value ? 'pr-8' : ''}`}
              id="taxes"
              className="w-full"
              bottomOfTable
              isAddOption
              scrollIntoView={false}
              addNewOptionLabel="Add A New Tax"
              name="name"
              handleNewOptionButton={() => setTaxModalOpen(true)}
              addNewOptionButton
              taxes
              ids={['importAccountsContainer', 'importAccounts3TableContainer']}
            />
            {b?.taxes?.value && (
              <button
                className="absolute top-1/2 right-2 -translate-y-1/2"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdateValue(parentIndex, index, 'taxes', '');
                }}
              >
                <svg
                  className="w-[14px] h-[14px] fill-[#667085cc] group-hover:fill-slate-800 pointer-events-none"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 pt-2 pb-[2px] text-left">
        <Tooltip
          content="The sub-account color cannot be modified"
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
          isFixed
          ids={['importAccountsContainer', 'importAccounts3TableContainer']}
          tooltipShow={isChild}
        >
          <div className="h-[60px] flex flex-col">
            <input
              type="text"
              placeholder="Color"
              value={isChild ? parentObj?.color?.value : b?.color?.value}
              className={`form-input placeholder:text-slate-300  ${
                typeof b.color.error === 'string' && b.color.error
                  ? '!border-rose-500'
                  : ''
              } disabled:cursor-not-allowed`}
              onChange={(e) =>
                handleUpdateValue(parentIndex, index, 'color', e.target.value)
              }
              onBlur={
                (isChild ? parentObj?.color?.error : b?.color?.error)
                  ? checkRowsError
                  : () => {}
              }
              disabled={isChild}
            />
            <span className="h-[10px] text-xs mt-1 text-rose-500 ">
              {(isChild ? parentObj?.color?.error : b?.color?.error) || ''}
            </span>
          </div>
        </Tooltip>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 pt-2 text-left pb-5">
        {/* Delete Button  */}
        <Tooltip
          content="Delete Item"
          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
          position="left"
        >
          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
            {deleteDraftsLoading?.[b?.id] ? (
              <Loader height="h-5" />
            ) : (
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteItem(b?.id, parentIndex, index);
                }}
              >
                <path
                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                  fill="white"
                  stroke="#D0D5DD"
                  strokeOpacity="0.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                  stroke="#858D9D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.693 16.3574H14.8505"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.1552 13.7656H15.3948"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </Tooltip>
      </td>
    </tr>
  );
};

const Step3 = ({
  tableRows,
  setTableRows,
  checkRowsError,
  deactivateExistingOnes,
  setDeactivateExistingOnes,
  errorIndexes = [],
  currentPage,
  localLimit,
  deleteDraftsLoading,
  handleDeleteItem,
  accountsTotal,
}) => {
  const { team } = useSelector((state) => state.Team);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [taxModalOpen, setTaxModalOpen] = useState(false);

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      const subTypeResponse = await getAccountSubTypes();
      setTypes(typeResponse);
      setSubTypes(subTypeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddTax = async (tax) => {
    try {
      const response = await createTax(tax);
      toast.success(
        'Tax created successfully. Please choose it from the dropdown',
      );
      loadTaxes();
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getAccountTypesApi();
    loadTaxes();
  }, []);

  const handleUpdateValue = (parentIndex, index, field, value) => {
    const tempRows = JSON.parse(JSON.stringify(tableRows || []));
    let tempObj = null;
    let parentObj = null;
    if (parentIndex !== null && parentIndex >= 0) {
      parentObj = JSON.parse(JSON.stringify(tempRows[parentIndex]));
      tempObj = JSON.parse(
        JSON.stringify(parentObj?.subAccountDrafts?.[index]),
      );
    } else {
      tempObj = JSON.parse(JSON.stringify(tempRows[index]));
    }
    if (field === 'subTypeId') {
      tempObj.subTypeId = {
        ...tempObj[field],
        value: value?.id,
        error: false,
      };
      tempObj.subTypeName = {
        ...tempObj[field],
        value: value?.name,
        error: false,
      };
    } else if (field === 'type') {
      tempObj.type = { ...tempObj[field], value };
      tempObj.subTypeId = {
        ...tempObj[field],
        value: null,
        error: !!tempObj.selected?.value,
      };
      tempObj.subTypeName = {
        ...tempObj[field],
        value: null,
        error: !!tempObj.selected?.value,
      };
    } else {
      tempObj[field] = { ...tempObj[field], value };
    }
    if (value) {
      tempObj[field] = { ...tempObj[field], error: false };
    } else {
      tempObj[field] = {
        ...tempObj[field],
        error: tempObj.selected?.value && true,
      };
    }
    if (field === 'selected' && value === false) {
      tempObj.type = { ...tempObj.type, error: false };
      tempObj.accountNumber = { ...tempObj.accountNumber, error: false };
      tempObj.name = { ...tempObj.name, error: false };
      tempObj.subTypeId = { ...tempObj.subTypeId, error: false };
      tempObj.subTypeName = { ...tempObj.subTypeName, error: false };
    }
    if (parentIndex !== null && parentIndex >= 0) {
      parentObj.subAccountDrafts[index] = tempObj;
      tempRows[parentIndex] = parentObj;
    } else {
      tempRows[index] = tempObj;
    }
    setTableRows(tempRows);
  };

  const selectAll = (selected) => {
    const tempRows = [...tableRows];
    tableRows.forEach((d, i) => {
      const tempRow = { ...tableRows[i] };
      tempRow.selected.value = !selected;
    });
    setTableRows(tempRows);
  };

  const getTotalAccountsCount = useMemo(() => {
    let count = 0;
    if (tableRows?.length > 0) {
      tableRows?.forEach((acc) => {
        count += 1;
        if (acc?.subAccountDrafts?.length > 0) {
          count += acc?.subAccountDrafts?.length;
        }
      });
    }
    return count;
  }, [tableRows]);

  return (
    <div>
      <TaxModal
        isOpen={taxModalOpen}
        setIsOpen={setTaxModalOpen}
        handleAddTax={handleAddTax}
      />
      <h3 className="text-2xl mb-2 font-semibold">
        {accountsTotal ?? '--'} accounts are ready to be imported
      </h3>
      <label className="cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
        <input
          type="checkbox"
          checked={deactivateExistingOnes}
          className="form-checkbox-orange  mr-2 h-5 w-5"
          onChange={() => setDeactivateExistingOnes(!deactivateExistingOnes)}
        />
        Check this box to deactivate all default accounts and replace them with
        the imported chart of accounts.
      </label>
      <p className="pt-1 ml-7 text-slate-400">
        Note: Certain default accounts are required for COUNT's automation
        features and cannot be deactivated.
      </p>
      {deactivateExistingOnes && team?.featureExpenseReport && (
        <AlertBox
          type="error"
          heading="Warning"
          text="You have Expense Claim Types linked to existing Expense Accounts. If you choose to deactivate these accounts after importing your new Chart of Accounts, please ensure you update your Expense Claim Types to align with the newly imported accounts."
          hideActionButton
        />
      )}
      <div id="accountsWarningError">
        {errorIndexes?.length > 0 && (
          <AlertBox
            hideActionButton
            heading="Validation Error"
            type="error"
            text={`Please resolved the errors in the following Data Row in order to proceed : #${errorIndexes?.join(', #')}`}
          />
        )}
      </div>
      <div className="bg-transparent  max-w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] flex-1 ">
        {/* Table */}
        <div
          className="overflow-x-auto h-full w-full max-w-full scrollbar"
          id="importAccounts3TableContainer"
        >
          <table className="table-fixed w-full">
            {/* Table header */}
            <thead className="text-sm font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-24 min-w-24">
                  <div className="font-medium text-left">#</div>
                </th>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-[50px] min-w-[50px]">
                  <div className="flex items-center">
                    <label className="inline-flex ">
                      <span className="sr-only">Select</span>
                      <input
                        className="form-checkbox cursor-pointer h-5 w-5"
                        type="checkbox"
                        onChange={() =>
                          selectAll(
                            getCheckedAccountsCount() === tableRows?.length,
                          )
                        }
                        checked={
                          getCheckedAccountsCount() === tableRows?.length
                        }
                      />
                    </label>
                  </div>
                </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[170px] min-w-[170px]">
                  <div className="font-medium text-left">Account Number</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[350px] min-w-[350px]">
                  <div className="font-medium text-left">Account Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Description</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-[200px] min-w-[200px]">
                  <div className="font-medium text-left">Type</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[300px] min-w-[300px] ">
                  <div className="font-medium text-left">Sub Type</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[250px] min-w-[250px] ">
                  <div className="font-medium text-left">Taxes</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[200px] min-w-[200px] ">
                  <div className="font-medium text-left">Color</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap table-cell  w-[80px] min-w-[80px]  ">
                  <div className="font-medium text-left">Action</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] ">
              {tableRows.map((b, i) => (
                <>
                  <Row
                    currentPage={currentPage}
                    localLimit={localLimit}
                    b={b}
                    key={b?.id}
                    i={i}
                    index={i}
                    types={types}
                    subTypes={subTypes}
                    parentIndex={null}
                    handleDeleteItem={handleDeleteItem}
                    handleUpdateValue={handleUpdateValue}
                    taxes={taxes}
                    deleteDraftsLoading={deleteDraftsLoading}
                    checkRowsError={checkRowsError}
                    taxModalOpen={taxModalOpen}
                    setTaxModalOpen={setTaxModalOpen}
                  />
                  {b?.subAccountDrafts?.length > 0 &&
                    b?.subAccountDrafts?.map((_b, j) => (
                      <Row
                        currentPage={currentPage}
                        localLimit={localLimit}
                        types={types}
                        b={_b}
                        i={j}
                        key={_b?.id}
                        parentIndex={i}
                        index={j}
                        isChild
                        subTypes={subTypes}
                        parentObj={b}
                        handleDeleteItem={handleDeleteItem}
                        handleUpdateValue={handleUpdateValue}
                        taxes={taxes}
                        deleteDraftsLoading={deleteDraftsLoading}
                        checkRowsError={checkRowsError}
                        taxModalOpen={taxModalOpen}
                        setTaxModalOpen={setTaxModalOpen}
                      />
                    ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Step3;
