import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import Tags from './tags';
import Taxes from './taxes';

import {
  ACCOUNTANT,
  S_USER_DETAILS,
  S_CATEGORIES,
  S_CONNECTIONS,
  S_MANANGE_MEMBERS,
  S_MEMBERS,
  S_MERCHANTS,
  S_TAGS,
  S_AUDIT,
  S_TAXES,
  S_PAYMENT_SETUP,
  DevEnv,
  TEAM_ID,
  S_APP_INTEGRATIONS,
  S_CONFIGURATION,
} from '../../utils/Utils';
import ConnectedBanks from './connectedBanks';
import PaymentSetup from './paymentSetup';
import {
  getAllProjects,
  getAuditLog,
  getTags,
  getTaxes,
  getTeamById,
} from '../../API/backend_helper';
import AuditLog from './auditLog';
import TransactionPanel from '../transactions/TransactionPanel';
import AddTaskModal from '../inbox/AddTaskModal';
import AppIntegrations from './appIntegrations';
import Configuration from './configuration';
import AppLayout from '../../components/AppLayout';
import { setTeam } from '../../redux/Team/reducer';

function NewSettings() {
  const Env = process.env.REACT_APP_ENV;
  const dispatch = useDispatch();
  const TeamId = localStorage.getItem(TEAM_ID);
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [activeTab, setActiveTab] = useState(S_CONFIGURATION);

  // Flyout
  const [tags, setTags] = useState([]);
  const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);
  const [splitTransactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [transaction, setTransaction] = useState(null);
  const [currentTab, setCurrentTab] = useState('details');
  const [fromDropdown, setFromDropdown] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [selectedTaskOption, setSelectedTaskOption] = useState(null);
  const [auditLogs, setAuditLogs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const { tab } = useParams();

  document.title = 'COUNT | Settings';

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.MANAGE_SETTINGS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const fetchTeam = async () => {
    try {
      const res = await getTeamById(TeamId);
      dispatch(setTeam(res));
      return res;
    } catch (e) {
      console.log('error', e);
    }
  };

  const getProjectsApi = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTags = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    fetchTeam();
    getProjectsApi();
    loadTags();
    loadTaxes();
  }, []);

  useEffect(() => {
    if (tab) {
      if (
        tab === S_USER_DETAILS ||
        tab === S_MEMBERS ||
        tab === S_MANANGE_MEMBERS ||
        tab === S_CONNECTIONS ||
        tab === S_CATEGORIES ||
        tab === S_TAGS ||
        tab === S_MERCHANTS ||
        tab === S_TAXES ||
        tab === 'payment-setup' ||
        tab === 'integrations' ||
        tab === 'banks' ||
        tab === 'tags' ||
        tab === 'taxes'
      ) {
        if (tab === 'payment-setup') {
          setActiveTab(S_PAYMENT_SETUP);
        } else if (tab === 'integrations') {
          setActiveTab(S_APP_INTEGRATIONS);
        } else if (tab === 'banks') {
          setActiveTab(S_CONNECTIONS);
        } else if (tab === 'tags') {
          setActiveTab(S_TAGS);
        } else if (tab === 'taxes') {
          setActiveTab(S_TAXES);
        } else {
          setActiveTab(tab);
        }
      }
    }
  }, [tab]);

  const getAuditLogApi = async ({ page = 1, limit = 50 }) => {
    try {
      const res = await getAuditLog({
        page,
        limit,
      });
      setAuditLogs(res?.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <AddTaskModal
        modalOpen={taskModalOpen}
        setModalOpen={setTaskModalOpen}
        transactions
        selectedOption={selectedTaskOption}
        setSelectedOption={setSelectedTaskOption}
        selectedTransaction={transaction}
        projects={projects}
      />
      {transaction && (
        <TransactionPanel
          transactionPanelOpen={transactionPanelOpen}
          setTransactionPanelOpen={setTransactionPanelOpen}
          splitTransactionModalOpen={splitTransactionModalOpen}
          setSplitTransactionModalOpen={setSplitTransactionModalOpen}
          transaction={transaction}
          tags={tags}
          setTransaction={setTransaction}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          fromDropdown={fromDropdown}
          setFromDropdown={setFromDropdown}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
          accounts={accounts}
          allExpenseCategories={allExpenseCategories}
          audit
          vendors={vendors}
          setVendors={setVendors}
          customers={customers}
          setCustomers={setCustomers}
          taskModalOpen={taskModalOpen}
          setTaskModalOpen={setTaskModalOpen}
          reloadTransaction={getAuditLogApi}
          taxes={taxes}
          loadTaxes={loadTaxes}
          currencies={currencies}
        />
      )}
      <AppLayout pageId="settingsPageContainer">
        {accessType && (
          <main className="grow">
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="mb-8 flex justify-between">
                {/* Title */}
                <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium">
                  Settings
                </h1>
              </div>
              {/* Top Tabs  */}
              <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                {checkPermission(
                  PermissionKeys.MANAGE_SETTINGS,
                  accessType,
                  permissions,
                ) && (
                  <div
                    onClick={() => setActiveTab(S_CONFIGURATION)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_CONFIGURATION &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                    role="button"
                    tabIndex="0"
                  >
                    Configuration
                  </div>
                )}
                {checkPermission(
                  PermissionKeys.MANAGE_SETTINGS,
                  accessType,
                  permissions,
                ) && (
                  <div
                    onClick={() => setActiveTab(S_CONNECTIONS)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_CONNECTIONS &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                    role="button"
                    tabIndex="0"
                  >
                    Connected Banks
                  </div>
                )}
                {checkPermission(
                  PermissionKeys.MANAGE_SETTINGS,
                  accessType,
                  permissions,
                ) && (
                  <div
                    onClick={() => setActiveTab(S_TAGS)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_TAGS &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                    role="button"
                    tabIndex="0"
                  >
                    Tags
                  </div>
                )}
                {checkPermission(
                  PermissionKeys.MANAGE_SETTINGS,
                  accessType,
                  permissions,
                ) && (
                  <div
                    onClick={() => setActiveTab(S_TAXES)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_TAXES &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                    role="button"
                    tabIndex="0"
                  >
                    Taxes
                  </div>
                )}
                {checkPermission(
                  PermissionKeys.MANAGE_SETTINGS,
                  accessType,
                  permissions,
                ) && (
                  <div
                    onClick={() => setActiveTab(S_PAYMENT_SETUP)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_PAYMENT_SETUP &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                    role="button"
                    tabIndex="0"
                  >
                    Payment Setup
                  </div>
                )}

                <div
                  onClick={() => setActiveTab(S_APP_INTEGRATIONS)}
                  className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_APP_INTEGRATIONS &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                  role="button"
                  tabIndex="0"
                >
                  App Integrations
                </div>

                {checkPermission(
                  PermissionKeys.MANAGE_SETTINGS,
                  accessType,
                  permissions,
                ) && (
                  <div
                    onClick={() => setActiveTab(S_AUDIT)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === S_AUDIT &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                    role="button"
                    tabIndex="0"
                  >
                    Audit
                  </div>
                )}
              </div>

              {/* Rendered Content */}
              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_CONFIGURATION && (
                  <Configuration team={team} fetchTeam={fetchTeam} />
                )}
              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_CONNECTIONS && (
                  <ConnectedBanks accessType={accessType} />
                )}
              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_TAGS && <Tags />}
              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_TAXES && <Taxes />}

              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_PAYMENT_SETUP && <PaymentSetup team={team} />}

              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_APP_INTEGRATIONS && (
                  <AppIntegrations
                    team={team}
                    setTeam={setTeam}
                    fetchTeam={fetchTeam}
                  />
                )}
              {checkPermission(
                PermissionKeys.MANAGE_SETTINGS,
                accessType,
                permissions,
              ) &&
                activeTab === S_AUDIT && (
                  <AuditLog
                    transactionPanelOpen={transactionPanelOpen}
                    setTransactionPanelOpen={setTransactionPanelOpen}
                    splitTransactionModalOpen={splitTransactionModalOpen}
                    setSplitTransactionModalOpen={setSplitTransactionModalOpen}
                    transaction={transaction}
                    setTransaction={setTransaction}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    fromDropdown={fromDropdown}
                    setFromDropdown={setFromDropdown}
                    allCategories={allCategories}
                    setAllCategories={setAllCategories}
                    accounts={accounts}
                    setAccounts={setAccounts}
                    allExpenseCategories={allExpenseCategories}
                    setAllExpenseCategories={setAllExpenseCategories}
                    vendors={vendors}
                    setVendors={setVendors}
                    customers={customers}
                    setCustomers={setCustomers}
                    auditLogs={auditLogs}
                    setAuditLogs={setAuditLogs}
                  />
                )}
            </div>
          </main>
        )}
      </AppLayout>
    </>
  );
}

export default NewSettings;
