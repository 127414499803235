import Tooltip from 'components/Tooltip';
import moment from 'moment';
import React from 'react';
import {
  amountColor,
  getContrast,
  getCurrencySymbol,
  getStringSegments,
} from 'utils/Utils';

const descriptiononLength = 70;
const accountLength = 20;

const TransactionRow = ({
  transaction,
  setMatchedTransaction,
  matchedTransaction,
  isParent = false,
  isChild = false,
  transId = '',
}) => (
  <tr
    key={transaction?.id}
    onClick={(e) => {
      e.stopPropagation();
    }}
    className={`   ${
      false ? 'bg-[#A0CD850F]' : ''
    } ${!isParent ? 'border-b' : ''}`}
  >
    <td
      className={`px-2 ${isParent ? 'pr-5' : ''} last:pr-5 pt-2.5 pb-3 whitespace-nowrap`}
    >
      {!isChild && (
        <div className="flex items-center">
          <label
            className={`inline-flex ${isParent ? 'mb-[-50px]' : 'mt-1'}`}
            htmlFor={transId}
          >
            <span className="sr-only">Select</span>
            <input
              id={transId}
              className="form-checkbox cursor-pointer h-5 w-5"
              type="checkbox"
              onChange={() => {
                if (transId !== matchedTransaction) {
                  setMatchedTransaction(transId);
                } else {
                  setMatchedTransaction(null);
                }
              }}
              checked={transId === matchedTransaction}
            />
          </label>
        </div>
      )}
    </td>

    <td className="px-2 first:pl-5 last:pr-5 py-2.5 leading-6 font-normal text-slate-700 whitespace-nowrap">
      {moment?.utc(transaction?.authorizedDate)?.format('MMM DD, YYYY')}
    </td>

    <td
      className="px-2 pl-5 last:pr-5 py-2.5 whitespace-nowrap text-slate-700"
      id="descriptiononCellId"
    >
      <div className="flex items-center">
        <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
          {transaction?.vendor?.logoUrl || transaction?.plaidCategoryIconUrl ? (
            <img
              className="rounded-full"
              src={
                transaction?.vendor?.logoUrl ||
                transaction?.plaidCategoryIconUrl
              }
              alt={transaction?.name}
            />
          ) : (
            <div
              className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
              style={{
                backgroundColor: transaction?.matchingTransaction
                  ?.mainTransaction?.categoryAccount?.group?.color
                  ? transaction?.matchingTransaction?.mainTransaction
                      ?.categoryAccount?.group?.color
                  : '',
              }}
            >
              <div
                className={`${
                  transaction?.categoryAccount?.group?.color
                    ? getContrast(
                        transaction?.matchingTransaction?.mainTransaction
                          ?.categoryAccount?.group?.color,
                        'text-black',
                        'text-white',
                      )
                    : 'text-black'
                } text-xs font-normal uppercase`}
              >
                {transaction?.description
                  ? transaction?.description
                      .replace(/[^\w\s]/gi, '')
                      .split(' ')
                      .filter(Boolean)
                      .slice(0, 2)
                      .map((word) => word.charAt(0))
                      .join('')
                  : ''}
              </div>
            </div>
          )}
        </div>

        <div className="font-normal leading-6 mr-2">
          {transaction?.description?.length > descriptiononLength ? (
            <Tooltip
              content={
                transaction?.description?.length > descriptiononLength
                  ? (() => {
                      const segments = getStringSegments(
                        transaction?.description,
                        70,
                      );
                      return segments.map((segment, index) => (
                        <p key={index}>{segment}</p>
                      ));
                    })()
                  : transaction?.description
              }
              contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowLeft ${
                transaction?.description?.length > descriptiononLength ? '' : ''
              }`}
              tooltipShow={window.innerWidth > 640}
              position="right"
            >
              <span>
                {`${transaction?.description?.slice(
                  0,
                  descriptiononLength,
                )}...`}
              </span>
            </Tooltip>
          ) : (
            <span>{transaction?.description}</span>
          )}
        </div>
      </div>
    </td>

    {(() => {
      const name = transaction?.account?.name || '';
      const mask = transaction?.account?.mask || '';
      const slicedName =
        name.length > accountLength
          ? `${name.slice(0, accountLength)}...`
          : name;
      const combinedAccount = mask ? `${slicedName} (...${mask})` : slicedName;
      return (
        <td
          className="px-2 first:pl-5 last:pr-5 py-2.5 whitespace-nowrap text-slate-700 font-sm font-normal leading-6 "
          id="accountCellId"
        >
          <Tooltip
            tooltipShow={name?.length > accountLength}
            content={`${transaction?.account?.name}${
              transaction?.account?.mask
                ? ` (...${transaction?.account?.mask})`
                : ''
            }`}
          >
            {combinedAccount}
          </Tooltip>
        </td>
      );
    })()}

    <td className={`px-5  py-2.5 whitespace-nowrap  `}>
      <div
        className={`font-semibold leading-6 text-sm  ${amountColor(
          transaction?.amount?.toString(),
        )}`}
      >
        {transaction?.amount?.toString().charAt(0) === '-' ? '' : '-'}
        {getCurrencySymbol(transaction?.currency)}
        {transaction?.amount?.toString().charAt(0) === '-'
          ? parseFloat(
              transaction?.amount?.toString().replace('-', ''),
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : parseFloat(transaction?.amount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </div>
    </td>
  </tr>
);

const ReuseableTable = ({
  transaction,
  setMatchedTransaction,
  matchedTransaction,
  transId,
}) => (
  <div className="overflow-x-auto scrollbar flex-1 -mt-5">
    <table className="table-auto w-full dark:text-slate-300  ">
      {/* Table header */}
      <thead className=" invisible h-0">
        <tr className="h-0">
          <th className="px-2 first:pl-5 last:pr-5 whitespace-nowrap w-12" />

          <th className="px-2 first:pl-5 last:pr-5 whitespace-nowrap w-[105px] 3xl:w-[120px]  min-w-full">
            <div
              className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
              role="button"
              tabIndex="0"
            >
              <span className="text-slate-600 text-sm font-medium leading-[18px]">
                Date
              </span>
            </div>
          </th>

          <th className={`px-2 first:pl-5 whitespace-nowrap w-full  `}>
            <div
              className="font-semibold text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
              role="button"
              tabIndex="0"
            >
              <span className="text-slate-600 text-sm font-medium leading-[18px]">
                Description
              </span>
            </div>
          </th>

          <th className="w-full px-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div className="text-slate-600 text-sm font-medium leading-[18px] text-left">
              Account
            </div>
          </th>

          <th className={`w-full px-5 first:pl-5 last:pr-5 whitespace-nowrap `}>
            <div
              className="text-left flex gap-2 cursor-pointer -bottom-1
                      hover:text-slate-800 dark:hover:text-slate-200
                  "
              role="button"
              tabIndex="0"
            >
              <span className="text-slate-600 text-sm font-medium leading-[18px]">
                Amount
              </span>
            </div>
          </th>
        </tr>
      </thead>

      {/* Table body */}
      {transaction?.id > 0 && (
        <tbody className="text-sm ">
          <TransactionRow
            transaction={transaction}
            key={transaction?.id}
            setMatchedTransaction={setMatchedTransaction}
            matchedTransaction={matchedTransaction}
            isParent={transaction?.linkedTransaction?.id}
            transId={transId}
          />
          {transaction?.linkedTransaction?.id && (
            <TransactionRow
              transaction={transaction?.linkedTransaction}
              key={transaction?.linkedTransaction?.id}
              setMatchedTransaction={setMatchedTransaction}
              matchedTransaction={matchedTransaction}
              transId={transId}
              isChild
            />
          )}
        </tbody>
      )}
    </table>
  </div>
);

export default ReuseableTable;
