/* eslint-disable eqeqeq */
import React from 'react';
import moment from 'moment';
import { formatValueWithSign, getFormattedDate } from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import { TableHeader } from '../TableHeader';
import { CalendarIcon } from '../CalendarIcon';
import { DecreaseIcon } from '../DecreaseIcon';
import { IncreaseIcon } from '../IncreaseIcon';

const tableHeaders = {
  income: 'Income',
  cost_of_goods_sold: 'Cost of Goods Sold',
  operating_expenses: 'Operating Expenses',
  net_margin: 'Net Margin',
  net_profit: 'Net Profit',
  gross_proft: 'Gross Profit',
  gross_margin: 'Gross Margin',
};
const ProfitAndLossTable = ({
  values,
  isMobile,
  generatedReport,
  currencySymbol,
  setActiveTab = () => {},
  activeTab = 'details',
  teamDateFormat = 'mm/dd/yyyy',
  filter,
  selectedType,
  collapsedAccounts,
  setCollapsedAccounts,
  selectedCurrency,
  selectedTags,
  isPdf = false,
}) => {
  const navigate = useNavigate();

  const getDifferenceForSubAcc = (firstTotal, secondTotal) => {
    const oldIncome = Number(secondTotal ?? 0);
    const newIncome = Number(firstTotal ?? 0);
    const difference = newIncome - oldIncome;

    const percentage = (difference / (Math.abs(oldIncome) || 1)) * 100;
    return {
      difference,
      percentage,
    };
  };

  const getTotalAmount = (obj) => {
    let total = 0;
    if (
      obj?.subAccounts?.length > 0 &&
      collapsedAccounts?.includes(obj?.account?.id)
    ) {
      total += obj?.total;
      obj?.subAccounts?.forEach((acc) => {
        total += acc?.total ?? 0;
      });
    } else {
      total = obj?.total ?? 0;
    }
    return total;
  };

  const onCollapsedAccounts = (id) => {
    if (collapsedAccounts?.includes(id)) {
      let temp = [...collapsedAccounts];
      temp = temp?.filter((_id) => id !== _id);
      setCollapsedAccounts(temp);
    } else {
      setCollapsedAccounts([...collapsedAccounts, id]);
    }
  };

  const getAccountWithSub = (det, matchId) => {
    const account = det?.find?.((val) => {
      // Check if the account IDs match
      if (val?.account?.id === matchId) {
        return true;
      }

      // Check within subAccounts if they exist
      return val?.account?.subAccounts?.some(
        (subAccount) => subAccount?.id === matchId,
      );
    });
    return account;
  };
  return (
    <>
      <div
        className={`grid grid-cols-7 text-center py-8  ${
          (values.secondStartDate || isMobile) && 'hidden'
        }`}
      >
        <TableHeader
          price={
            generatedReport?.find(
              (obj) => obj.accountLabel === tableHeaders.income,
            ).firstDateRange?.total
          }
          label={tableHeaders.income}
          sign={currencySymbol}
        />

        <div className="text-[#101828] self-center">
          <p className="font-semibold"> -</p>
        </div>
        <TableHeader
          price={
            generatedReport.find(
              (obj) => obj.accountLabel === tableHeaders.cost_of_goods_sold,
            ).firstDateRange?.total
          }
          label={tableHeaders.cost_of_goods_sold}
          sign={currencySymbol}
        />

        <div className="text-[#101828] self-center">
          <p className="font-semibold"> -</p>
        </div>
        <TableHeader
          price={
            generatedReport.find(
              (obj) => obj.accountLabel === tableHeaders.operating_expenses,
            ).firstDateRange?.total
          }
          label={tableHeaders.operating_expenses}
          sign={currencySymbol}
        />

        <div className="text-[#101828] self-center">
          <p className="font-semibold">=</p>
        </div>
        <TableHeader
          price={
            generatedReport.find(
              (obj) => obj.accountLabel === tableHeaders.net_profit,
            ).firstDateRange?.total
          }
          label={tableHeaders.net_profit}
          color={
            generatedReport.find((obj) => obj.accountLabel === 'Net Profit')
              .firstDateRange?.total >= 0
              ? 'text-[#78BD4F]'
              : 'text-[#FF4B4B]'
          }
          sign={currencySymbol}
        />
      </div>
      {!isPdf && (
        <div className="flex justify-center border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
          <div
            onClick={() => setActiveTab('summary')}
            role="button"
            tabIndex={0}
            className={`text-sm text-center  border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
  ${
    activeTab === 'summary' &&
    ' !border-indigo-500  font-medium !text-[#101828]'
  } `}
          >
            Summary
          </div>

          <div
            onClick={() => setActiveTab('details')}
            role="button"
            tabIndex={0}
            className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
  ${
    activeTab === 'details' &&
    ' !border-indigo-500  font-medium !text-[#101828]'
  } `}
          >
            Details
          </div>
        </div>
      )}
      <div className="overflow-x-auto scrollbar w-full">
        <table className="table-fixed min-w-full dark:text-slate-300">
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
              <th className="flex-1">
                <div className="text-left w-full min-w-[20rem] font-bold">
                  Account
                </div>
              </th>

              <th
                className={`${
                  activeTab === 'details' ||
                  (values.secondStartDate &&
                    values.secondEndDate &&
                    values.compare)
                    ? 'w-[13rem]'
                    : ''
                }`}
              >
                <div className="flex gap-1 align-middle font-bold -ml-2">
                  <CalendarIcon />
                  {values?.firstStartDate && isPdf
                    ? `${moment(values.firstStartDate).format('MMM DD, YYYY')} -`
                    : `${moment(
                        getFormattedDate(values.firstStartDate, teamDateFormat),
                      ).format('ll')} -`}
                  {isPdf
                    ? `${moment(values.firstEndDate).format('MMM DD, YYYY')}`
                    : `${moment(getFormattedDate(values.firstEndDate, teamDateFormat)).format('ll')}`}
                </div>
              </th>

              {values.secondStartDate &&
                values.secondEndDate &&
                values.compare && (
                  <th className="w-[13rem]">
                    <div className="flex gap-1 align-middle text-right font-bold -ml-2">
                      <CalendarIcon />

                      {`${
                        isPdf
                          ? moment(values.secondStartDate).format(
                              'MMM DD, YYYY',
                            )
                          : moment(
                              getFormattedDate(
                                values.secondStartDate,
                                teamDateFormat,
                              ),
                            ).format('ll')
                      } - ${
                        isPdf
                          ? moment(values.secondEndDate).format('MMM DD, YYYY')
                          : moment(
                              getFormattedDate(
                                values.secondEndDate,
                                teamDateFormat,
                              ),
                            ).format('ll')
                      }`}
                    </div>
                  </th>
                )}

              {values.compare &&
                values.secondStartDate &&
                values.secondEndDate && (
                  <th className="">
                    <div className="text-left w-[17rem] font-bold">Change</div>
                  </th>
                )}
            </tr>
          </thead>
          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
            {generatedReport.map((data) => (
              <>
                <tr
                  className={`w-full px-[1.375rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 ${
                    activeTab === 'summary' &&
                    ([
                      tableHeaders.gross_proft,
                      tableHeaders.gross_margin,
                      tableHeaders.net_profit,
                      tableHeaders.net_margin,
                    ].includes(data.accountLabel)
                      ? 'text-[#101828] bg-[#EEEEEE] font-semibold'
                      : '')
                  } ${
                    activeTab === 'details' &&
                    ([
                      tableHeaders.gross_proft,
                      tableHeaders.gross_margin,
                      tableHeaders.net_profit,
                      tableHeaders.net_margin,
                    ].includes(data.accountLabel)
                      ? 'bg-[#FFF9F4]'
                      : 'text-[#101828] bg-[#EEEEEE]')
                  }`}
                >
                  <td className="whitespace-nowrap flex-1">
                    <div className="w-full min-w-[20rem] text-[#101828] text-left font-semibold">
                      {data.accountLabel}
                    </div>
                  </td>

                  <td
                    className={`whitespace-nowrap ${
                      data.secondDateRange?.total ? 'text-left' : 'text-right'
                    }`}
                  >
                    <div className="w-[13rem] text-[#101828] font-semibold ">
                      {activeTab === 'details' &&
                      ![
                        tableHeaders.gross_proft,
                        tableHeaders.gross_margin,
                        tableHeaders.net_profit,
                        tableHeaders.net_margin,
                      ].includes(data.accountLabel)
                        ? ''
                        : formatValueWithSign(
                            data.firstDateRange?.total,
                            [
                              tableHeaders.gross_margin,
                              tableHeaders.net_margin,
                            ].includes(data.accountLabel),
                            currencySymbol,
                          )}
                    </div>
                  </td>

                  {values.compare && data?.secondDateRange && (
                    <td className="whitespace-nowrap">
                      <div className="w-[13rem] text-[#101828] font-semibold">
                        {activeTab === 'details' &&
                        ![
                          tableHeaders.gross_proft,
                          tableHeaders.gross_margin,
                          tableHeaders.net_profit,
                          tableHeaders.net_margin,
                        ].includes(data.accountLabel)
                          ? ''
                          : formatValueWithSign(
                              data.secondDateRange?.total,
                              [
                                tableHeaders.gross_margin,
                                tableHeaders.net_margin,
                              ].includes(data.accountLabel),
                              currencySymbol,
                            )}
                      </div>
                    </td>
                  )}

                  {values.compare &&
                    data?.secondDateRange &&
                    ([
                      tableHeaders.income,
                      tableHeaders.cost_of_goods_sold,
                      tableHeaders.operating_expenses,
                    ].includes(data.accountLabel) && activeTab === 'details' ? (
                      ''
                    ) : (
                      <td className="whitespace-nowrap">
                        <div className="w-[17rem] flex gap-2 text-[#101828] ">
                          <p className="font-semibold ">
                            {formatValueWithSign(
                              data?.difference,
                              [
                                tableHeaders.gross_margin,
                                tableHeaders.net_margin,
                              ].includes(data.accountLabel),
                              currencySymbol,
                            )}
                          </p>
                          {!(
                            data?.secondDateRange?.total === '0' ||
                            data?.firstDateRange?.total === '0'
                          ) && (
                            <div className="flex gap-1 flex-wrap align-middle font-light">
                              {data?.difference < 0 ? (
                                <DecreaseIcon />
                              ) : (
                                <IncreaseIcon />
                              )}
                              <p>
                                {formatValueWithSign(
                                  data?.percentage,
                                  true,
                                  currencySymbol,
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </td>
                    ))}
                </tr>

                {activeTab === 'details' &&
                  data?.firstDateRange?.details?.length > 0 &&
                  data?.firstDateRange?.details?.map((elem, index) => (
                    <>
                      <tr
                        className={`w-full px-[1.375rem] ${
                          elem?.subAccounts?.length === 0 &&
                          Number(elem?.total || 0) === 0 &&
                          Number(
                            data.secondDateRange?.details.find(
                              (val) => val?.account?.id === elem?.account?.id,
                            )?.total || 0,
                          ).toFixed() === 0
                            ? 'hidden'
                            : 'block'
                        } flex items-center justify-between min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 ${isPdf ? 'text-slate-500' : 'text-[#E48642]'}`}
                      >
                        <td
                          className="whitespace-nowrap cursor-pointer flex-1"
                          onClick={() =>
                            navigate(
                              `/reports/account-transaction?accountId=${
                                elem?.account?.id
                              }&startDate=${
                                filter?.startDate
                              }&endDate=${filter?.endDate}&${
                                filter?.accounts?.length > 0
                                  ? `accounts=${filter?.accounts?.join(',')}`
                                  : 'accounts'
                              }&from=profit-and-loss&reportType=${selectedType}&currency=${selectedCurrency}&tags=${selectedTags?.join(',')}&reportType=${selectedType}&compare=${
                                values.compare ? 'true' : 'false'
                              }&secondStartDate=${
                                values?.compare && values.secondStartDate
                                  ? getFormattedDate(
                                      values.secondStartDate,
                                      teamDateFormat,
                                    )
                                  : ''
                              }&secondEndDate=${
                                values?.compare && values.secondEndDate
                                  ? getFormattedDate(
                                      values.secondEndDate,
                                      teamDateFormat,
                                    )
                                  : ''
                              }`,
                            )
                          }
                        >
                          <div className="w-full min-w-[20rem] text-left px-4 whitespace-normal">
                            {/* <Tooltip
                          content={
                            elem?.account?.name?.length > 30
                              ? (() => {
                                  const segments =
                                    getStringSegments(
                                      elem?.account?.name,
                                      40,
                                    );
                                  return segments.map(
                                    (segment, index) => (
                                      <p key={index}>
                                        {segment}
                                      </p>
                                    ),
                                  );
                                })()
                              : elem?.account?.name
                          }
                          contentClassName="border-non text-center overflow-visible text-sm text-[#667085] relative tooltipArrowLeft"
                          position="right"
                          tooltipShow={
                            elem?.account?.name?.length > 17
                          }
                          className="w-fit"
                        >
                          {elem?.account?.name?.length > 17
                            ? trimText(
                                elem?.account?.name,
                                17,
                              )
                            : elem?.account?.name}
                        </Tooltip> */}
                            {elem?.account?.name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap">
                          <div
                            className={` w-[13rem] flex items-center  ${
                              data.secondDateRange ? 'text-left' : 'text-right'
                            }  text-[#101828]`}
                          >
                            {formatValueWithSign(
                              elem?.subAccounts?.length > 0
                                ? (getTotalAmount(elem) ?? 0)
                                : (elem?.total ?? 0),
                              false,
                              currencySymbol,
                            )}
                            {elem?.subAccounts?.length > 0 &&
                              !values.compare && (
                                <div
                                  role="button"
                                  tabIndex={0}
                                  className="font-extrabold  text-[10px] cursor-pointer ml-2"
                                  onClick={() =>
                                    onCollapsedAccounts(elem?.account?.id)
                                  }
                                >
                                  <svg
                                    className={`shrink-0 ml-1 stroke-[#667085cc] ${
                                      !collapsedAccounts?.includes(
                                        elem?.account?.id,
                                      )
                                        ? 'rotate-180'
                                        : ''
                                    }`}
                                    width="10"
                                    height="6"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                                      className="stroke-current"
                                      strokeOpacity="0.8"
                                      strokeWidth="1.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              )}
                          </div>
                        </td>
                        {values.compare && data?.secondDateRange && (
                          <td className="whitespace-nowrap flex items-center w-[13rem]">
                            <div className="text-[#101828] ">
                              {formatValueWithSign(
                                elem?.subAccounts?.length > 0
                                  ? getTotalAmount(
                                      data.secondDateRange?.details.find(
                                        (val) =>
                                          val?.account?.id == elem?.account?.id,
                                      ),
                                      true,
                                    )
                                  : (data.secondDateRange?.details.find(
                                      (val) =>
                                        val?.account?.id == elem?.account?.id,
                                    )?.total ?? 0),
                                false,
                                currencySymbol,
                              )}
                            </div>
                          </td>
                        )}

                        {values.compare && data.secondDateRange && (
                          <td className="whitespace-nowrap">
                            <div className="w-[17rem] flex gap-2 text-[#101828]">
                              <p className="font-semibold ">
                                {formatValueWithSign(
                                  elem?.subAccounts?.length > 0
                                    ? getDifferenceForSubAcc(
                                        getTotalAmount(elem),
                                        getTotalAmount(
                                          data.secondDateRange?.details.find(
                                            (val) =>
                                              val?.account?.id ==
                                              elem?.account?.id,
                                          ),
                                        ),
                                      )?.difference
                                    : elem?.detailDifference,
                                  false,
                                  currencySymbol,
                                )}
                              </p>
                              {!(
                                elem?.total === '0' ||
                                !data.secondDateRange?.details.find(
                                  (val) =>
                                    val?.account?.id == elem?.account?.id,
                                )
                              ) && (
                                <div className="flex gap-1 flex-wrap align-middle font-light">
                                  {(elem?.subAccounts?.length > 0
                                    ? getDifferenceForSubAcc(
                                        getTotalAmount(elem),
                                        getTotalAmount(
                                          data.secondDateRange?.details.find(
                                            (val) =>
                                              val?.account?.id ==
                                              elem?.account?.id,
                                          ),
                                        ),
                                      )?.difference
                                    : elem?.detailDifference) < 0 ? (
                                    <DecreaseIcon />
                                  ) : (
                                    <IncreaseIcon />
                                  )}
                                  <p>
                                    {formatValueWithSign(
                                      elem?.subAccounts?.length > 0
                                        ? getDifferenceForSubAcc(
                                            getTotalAmount(elem),
                                            getTotalAmount(
                                              data.secondDateRange?.details.find(
                                                (val) =>
                                                  val?.account?.id ==
                                                  elem?.account?.id,
                                              ),
                                            ),
                                          )?.percentage
                                        : elem?.detailPercentage,
                                      true,
                                      currencySymbol,
                                    )}
                                  </p>
                                </div>
                              )}
                              {elem?.subAccounts?.length > 0 && (
                                <div
                                  className="font-extrabold  text-[10px] inline-flex items-center cursor-pointer"
                                  onClick={() =>
                                    onCollapsedAccounts(elem?.account?.id)
                                  }
                                  role="button"
                                  tabIndex={0}
                                >
                                  <svg
                                    className={`shrink-0 ml-1 stroke-[#667085cc] ${
                                      !collapsedAccounts?.includes(
                                        elem?.account?.id,
                                      )
                                        ? 'rotate-180'
                                        : ''
                                    }`}
                                    width="10"
                                    height="6"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                                      className="stroke-current"
                                      strokeOpacity="0.8"
                                      strokeWidth="1.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                      {elem?.subAccounts?.length > 0 &&
                        !collapsedAccounts?.includes(elem?.account?.id) &&
                        elem?.subAccounts?.map((elemChild, index) => (
                          <tr
                            className={`w-full px-[1.375rem] ${
                              Number(elemChild?.total || 0) == 0 &&
                              Number(
                                data.secondDateRange?.details.find(
                                  (val) =>
                                    val?.account?.id == elemChild?.account?.id,
                                )?.total || 0,
                              ).toFixed() == 0
                                ? 'hidden'
                                : 'block'
                            } flex items-center justify-between h-8 hover:bg-[#a0cd85] hover:bg-opacity-5 ${isPdf ? 'text-slate-500' : 'text-[#E48642]'}`}
                            key={elemChild?.account?.id}
                          >
                            <td
                              className="whitespace-nowrap cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/reports/account-transaction?accountId=${
                                    elemChild?.account?.id
                                  }&startDate=${
                                    filter?.startDate
                                  }&endDate=${filter?.endDate}&${
                                    filter?.accounts?.length > 0
                                      ? `accounts=${filter?.accounts?.join(',')}`
                                      : 'accounts'
                                  }&from=profit-and-loss&reportType=${selectedType}&currency=${selectedCurrency}&tags=${selectedTags?.join(',')}&reportType=${selectedType}&compare=${
                                    values.compare ? 'true' : 'false'
                                  }&secondStartDate=${
                                    values?.compare && values.secondStartDate
                                      ? getFormattedDate(
                                          values.secondStartDate,
                                          teamDateFormat,
                                        )
                                      : ''
                                  }&secondEndDate=${
                                    values?.compare && values.secondEndDate
                                      ? getFormattedDate(
                                          values.secondEndDate,
                                          teamDateFormat,
                                        )
                                      : ''
                                  }`,
                                )
                              }
                            >
                              <div className="w-[11rem] text-left pl-10">
                                {elemChild?.account?.name}
                              </div>
                            </td>
                            <td className="whitespace-nowrap">
                              <div
                                className={`w-[17rem] pl-6  text-left  text-[#101828] `}
                              >
                                {formatValueWithSign(
                                  elemChild?.total || 0,
                                  false,
                                  currencySymbol,
                                )}
                              </div>
                            </td>
                            {values.compare && data?.secondDateRange && (
                              <td className="whitespace-nowrap">
                                <div className="w-[17rem] text-left text-[#101828] pl-6">
                                  {formatValueWithSign(
                                    data.secondDateRange?.details.find(
                                      (val) =>
                                        val?.account?.id == elem?.account?.id,
                                    )?.subAccounts?.[index]?.total || 0,
                                    false,
                                    currencySymbol,
                                  )}
                                </div>
                              </td>
                            )}

                            {values.compare && data.secondDateRange && (
                              <td className="whitespace-nowrap">
                                <div className="pl-6 w-[17rem] flex gap-2 text-[#101828]">
                                  <p className="font-semibold ">
                                    {formatValueWithSign(
                                      getDifferenceForSubAcc(
                                        elemChild?.total,
                                        data.secondDateRange?.details.find(
                                          (val) =>
                                            val?.account?.id ==
                                            elem?.account?.id,
                                        )?.subAccounts?.[index]?.total,
                                      )?.difference,
                                      false,
                                      currencySymbol,
                                    )}
                                  </p>
                                  {!elemChild?.total === '0' ||
                                    (!getAccountWithSub(
                                      data.secondDateRange?.details,
                                      elemChild?.account,
                                    )?.id && (
                                      <div className="flex gap-1 flex-wrap align-middle font-light">
                                        {getDifferenceForSubAcc(
                                          elemChild?.total,
                                          data.secondDateRange?.details.find(
                                            (val) =>
                                              val?.account?.id ==
                                              elem?.account?.id,
                                          )?.subAccounts?.[index]?.total,
                                        )?.difference < 0 ? (
                                          <DecreaseIcon />
                                        ) : (
                                          <IncreaseIcon />
                                        )}
                                        <p>
                                          {formatValueWithSign(
                                            getDifferenceForSubAcc(
                                              elemChild?.total,
                                              data.secondDateRange?.details.find(
                                                (val) =>
                                                  val?.account?.id ==
                                                  elem?.account?.id,
                                              )?.subAccounts?.[index]?.total,
                                            )?.percentage,
                                            true,
                                            currencySymbol,
                                          )}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}

                      {data?.firstDateRange?.details?.length == index + 1 && (
                        <tr className="w-full px-[1.375rem] flex items-center justify-between h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#101828] font-semibold">
                          <td className="whitespace-nowrap flex-1">
                            <div className="w-full min-w-[20rem] text-left">
                              {data.accountLabel == tableHeaders.income
                                ? 'Total Income'
                                : data.accountLabel ==
                                    tableHeaders.operating_expenses
                                  ? 'Total Operating Expenses'
                                  : 'Total Cost Of Goods Sold'}
                            </div>
                          </td>

                          <td className="whitespace-nowrap">
                            <div
                              className={`w-[13rem] ${
                                data.secondDateRange
                                  ? 'font-bold'
                                  : 'text-right'
                              }  text-[#101828]`}
                            >
                              {formatValueWithSign(
                                data?.firstDateRange?.total,
                                false,
                                currencySymbol,
                              )}
                            </div>
                          </td>

                          {values.compare && data?.secondDateRange && (
                            <td className="whitespace-nowrap">
                              <div className="w-[13rem] font-bold text-[#101828]">
                                {formatValueWithSign(
                                  data?.secondDateRange?.total,
                                  false,
                                  currencySymbol,
                                )}
                              </div>
                            </td>
                          )}

                          {values.compare && data?.secondDateRange && (
                            <td className="whitespace-nowrap">
                              <div className="w-[17rem] flex gap-2 text-[#101828] ">
                                <p className="font-bold">
                                  {formatValueWithSign(
                                    data?.difference,
                                    false,
                                    currencySymbol,
                                  )}
                                </p>
                                <div className="flex gap-1 flex-wrap align-middle font-light">
                                  {data?.difference < 0 ? (
                                    <DecreaseIcon />
                                  ) : (
                                    <IncreaseIcon />
                                  )}
                                  <p>
                                    {formatValueWithSign(
                                      data?.difference,
                                      true,
                                      currencySymbol,
                                    )}
                                  </p>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      )}
                    </>
                  ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
      {!isPdf && (
        <div className=" flex justify-center">
          <button
            className="w-[7rem] cursor-pointer justify-center mt-2  rounded-[0.313rem] shadow-sm leading-6 text-[0.875rem] h-10  bg-indigo-500 text-white"
            onClick={() =>
              activeTab === 'summary'
                ? setActiveTab('details')
                : setActiveTab('summary')
            }
            type="button"
          >
            {activeTab === 'summary' ? 'View Details' : 'View Summary'}
          </button>
        </div>
      )}
    </>
  );
};

export default ProfitAndLossTable;
