/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';
import Transition from 'utils/Transition';
import {
  checkImageByLink,
  checkImageByType,
  checkVideoByLink,
  checkVideoByType,
} from 'utils/Utils';
import { Loader } from 'components/Svg';
import AttachmentThumbnail from './TaskFlyout/AttachmentThumbnail';

const AttachmentModal = ({
  isOpen,
  setIsOpen,
  receipt,
  isExpenseReporting = false,
  type = '',
  title = '',
  setActiveAttachment = null,
}) => {
  const modalTitle = useRef(null);
  const modalContent = useRef(null);
  const modalButtons = useRef(null);

  const [pdfIframeLoading, setPdfIframeLoading] = useState(true);

  useEffect(() => {
    const listener = (event) => {
      if (
        !modalContent.current ||
        modalContent.current.contains(event.target) ||
        !modalTitle.current ||
        modalTitle.current.contains(event.target) ||
        !modalButtons.current ||
        modalButtons.current.contains(event.target)
      ) {
        return;
      }
      setIsOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [modalContent, modalTitle]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!isOpen || keyCode !== 27) return;
      setIsOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    if (!isOpen && setActiveAttachment) {
      setTimeout(() => setActiveAttachment(null), 200);
    }
  }, [isOpen]);

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = receipt;
    link.setAttribute('download', title);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Transition
        className="fixed inset-0 bg-[#22272b] bg-opacity-90 z-[2147483005] transition-opacity"
        show={isOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        className={`fixed h-full inset-0 z-[2147483005] overflow-hidden flex items-center justify-center `}
        role="dialog"
        aria-modal="true"
        show={isOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          className={`flex flex-col dark:bg-slate-800 rounded-xl shadow-lg overflow-auto overflow-x-hidden  w-full h-full max-h-full `}
        >
          {/* Modal header */}
          <div className={` dark:border-slate-700 py-[1.313rem] px-4`}>
            <div className="flex justify-between items-center">
              <div
                ref={modalTitle}
                className=" whitespace-nowrap max-w-[70%] w-fit text-ellipsis overflow-hidden font-medium text-[18px] leading-[2.375rem] text-[#c7d1db]"
              >
                {title}
              </div>
              <div ref={modalButtons} className="flex gap-4 ">
                {/* Download Button */}
                <button
                  className="text-[#c7d1db] hover:text-slate-500 "
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFile();
                  }}
                  type="button"
                >
                  <div className="sr-only">Download</div>
                  <svg
                    width="28px"
                    height="28px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-[#c7d1db] hover:text-slate-200"
                  >
                    <path
                      d="M5.25589 16C3.8899 15.0291 3 13.4422 3 11.6493C3 9.20008 4.8 6.9375 7.5 6.5C8.34694 4.48637 10.3514 3 12.6893 3C15.684 3 18.1317 5.32251 18.3 8.25C19.8893 8.94488 21 10.6503 21 12.4969C21 14.0582 20.206 15.4339 19 16.2417M12 21V11M12 21L9 18M12 21L15 18"
                      className="stroke-current"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {/* Close Button */}
                <button
                  className="text-[#c7d1db] hover:text-slate-500 "
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                  type="button"
                >
                  <div className="sr-only">Close</div>
                  <svg
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-[#c7d1db] hover:text-slate-200"
                  >
                    <rect
                      x="6.17676"
                      y="8.6582"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17676 8.6582)"
                      className="fill-current"
                    />
                    <rect
                      x="20.1768"
                      y="7.01172"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1768 7.01172)"
                      className="fill-current"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center w-full px-4">
            {checkVideoByLink(receipt) || checkVideoByType(type) ? (
              <video
                className="w-fit mx-auto border border-gray-300 rounded-xl object-contain overflow-auto"
                style={{ maxHeight: 'calc(100dvh - 120px)' }}
                controls
                ref={modalContent}
              >
                <source
                  src={
                    isExpenseReporting
                      ? receipt
                      : receipt?.images?.[0]?.receiptUrl
                  }
                  type="video/mp4"
                />
              </video>
            ) : checkImageByLink(receipt) || checkImageByType(type) ? (
              <img
                ref={modalContent}
                src={
                  isExpenseReporting
                    ? receipt
                    : receipt?.images?.[0]?.receiptUrl
                }
                alt="Uploaded receipt"
                className="w-fit mx-auto border border-gray-300 rounded-xl object-contain overflow-auto"
                style={{ maxHeight: 'calc(100dvh - 120px)' }}
              />
            ) : receipt?.toLocaleLowerCase()?.includes('.pdf') ||
              type?.toLocaleLowerCase()?.includes('pdf') ? (
              <>
                {pdfIframeLoading && <Loader />}
                <iframe
                  ref={modalContent}
                  src={
                    isExpenseReporting
                      ? receipt
                      : receipt?.images?.[0]?.receiptUrl
                  }
                  title="Receipt PDF"
                  className="w-full h-[80rem] border border-gray-300 rounded-xl max-w-[900px] mx-auto"
                  style={{
                    maxHeight: 'calc(100dvh - 120px)',
                    display: pdfIframeLoading ? 'none' : 'block',
                  }}
                  onLoad={() => setPdfIframeLoading(false)}
                />
              </>
            ) : (
              <div
                ref={modalContent}
                className="w-[460px] max-w-full flex flex-col items-center gap-10"
              >
                <div className="h-20 rounded-full w-20 inline-flex justify-center items-center bg-[#FEF9F6]">
                  <AttachmentThumbnail />
                </div>{' '}
                <p className="text-white text-center text-[20px]">
                  {' '}
                  {title} doesn’t have a thumbnail. to Preview open the file
                </p>
                <button
                  type="button"
                  className="h-11 bg-indigo-500 text-white w-[130px] rounded"
                  onClick={downloadFile}
                >
                  Download
                </button>
              </div>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default AttachmentModal;
