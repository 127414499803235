/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MRT_ShowHideColumnsButton } from 'mantine-react-table';
import {
  deletePendingReceiptById,
  unmatchReceiptTransaction,
} from 'API/backend_helper';
import ReceiptModal from 'pages/transactions/ReceiptModal';
import moment from 'moment';
import { formatNumberGlobally, getCurrencySymbolFromIso } from 'utils/Utils';
import ReusableTable from 'components/ReusableTable';

import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import SearchForm from 'partials/actions/SearchForm';
import PDFThumbnail from 'components/PdfThumbnail';

let searchTimeout = null;
const tableInitialState = {
  columnSizing: {
    receiptUrl: 100,
    description: 150,
    reporter: 150,
    date: 100,
    expenseReportTypeId: 130,
    vendor: 150,
    account: 100,
    total: 100,
    amount: 100,
    actions: 100,
  },
  columnVisibility: {
    receiptUrl: true,
    description: true,
    reporter: true,
    date: true,
    expenseReportTypeId: true,
    vendor: false,
    account: false,
    total: false,
    amount: true,
    actions: true,
  },
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  columnOrder: [],
  showGlobalFilter: false,
};
const ReceiptsTable = ({
  unmatchedReceipts,
  loadUnmatchedReceipts,
  isLoading,
  setEditReceiptData,
  isMatching = false,
  setSelectedRow,
  setRowDetailsOpen,
  searchValue,
  setSearchValue,
  setTableSortValues,
  tableSortValues,
  setTransactionsModalOpen = () => {},
  loadOtherReceipt,
}) => {
  const { currencies } = useSelector((state) => state.Currencies);

  const [tableState, setTableState] = useState({
    ...tableInitialState,
    isLoading: true,
    sorting: [
      { id: tableSortValues?.sortBy, desc: tableSortValues?.order === 'desc' },
    ],
  });

  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [activeReceiptTitle, setActiveReceiptTitle] = useState(null);
  const [receiptsDeleteLoading, setReceiptsDeleteLoading] = useState({});
  const [unmatchReceiptLoader, setUnmatchReceiptLoader] = useState(null);

  const fetchReceipts = ({
    page = unmatchedReceipts?.page,
    limit = unmatchedReceipts?.pageSize,
    search = searchValue,
    sortBy = tableState?.sorting?.[0]?.id || '',
    order = tableState?.sorting?.[0]?.id
      ? tableState?.sorting?.[0]?.desc
        ? 'desc'
        : 'asc'
      : '',
  }) => {
    loadUnmatchedReceipts({
      page: parseInt(page),
      limit,
      loading: true,
      search,
      order,
      sortBy,
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      fetchReceipts({ search: formattedValue, page: 1 });
    }, 900);
  };

  const handleDeleteReceipt = async (id) => {
    setReceiptsDeleteLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await deletePendingReceiptById(id);
      await loadUnmatchedReceipts({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setReceiptsDeleteLoading((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  const unmatchTransaction = async (id) => {
    setUnmatchReceiptLoader(id);
    try {
      await unmatchReceiptTransaction(id);
      await loadUnmatchedReceipts({ loading: false });
      await loadOtherReceipt({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setUnmatchReceiptLoader(null);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'receiptUrl',
        id: 'receiptUrl',
        header: 'Receipt',
        enableSorting: false,
        Cell: ({ row }) =>
          row?.original?.receiptUrl?.toLowerCase().endsWith('.pdf') ? (
            <div
              className="border overflow-hidden border-[#D0D5DD] rounded w-12 h-fit max-h-24 flex items-center justify-center relative"
              role="button"
              tabIndex="0"
              onClick={() => {
                setActiveReceipt(row?.original?.receiptUrl);
                setActiveReceiptTitle(row?.original?.vendor?.name);
                setReceiptModalOpen(true);
              }}
            >
              <PDFThumbnail fileUrl={row?.original?.receiptUrl} width={48} />
            </div>
          ) : row?.original?.receiptUrl ? (
            <img
              src={row?.original?.receiptUrl}
              alt="Receipt"
              className="w-12 h-12 object-contain cursor-pointer"
              onClick={() => {
                setActiveReceipt(row?.original?.receiptUrl);
                setActiveReceiptTitle(row?.original?.vendor?.name);
                setReceiptModalOpen(true);
              }}
            />
          ) : null,
      },
      {
        accessorKey: 'description',
        id: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'people',
        id: 'people',
        Cell: ({ row }) =>
          `${row.original?.people?.firstName || '-'} ${row.original?.people?.lastName || '-'}`,
        header: 'Reporter',
        enableSorting: false,
      },
      {
        id: 'expenseReportTypeId',
        Cell: ({ row }) =>
          `${row.original?.expenseReportType?.name || row.original?.categoryAccount?.name}`,
        header: 'Category/Expense Type',
        enableSorting: false,
      },
      {
        id: 'vendor',
        accessorKey: 'vendor.name',
        header: 'Vendor',
        enableSorting: false,
      },
      {
        id: 'account',
        accessorKey: 'account.name',
        header: 'Account',
        enableSorting: false,
      },
      {
        id: 'date',
        accessorKey: 'date',
        header: 'Date',

        Cell: ({ row }) =>
          moment(row?.original?.date?.split('T')?.[0]).format('DD MMM, YYYY'),
      },
      {
        id: 'amount',
        accessorKey: 'currency',
        header: 'Amount',

        Cell: ({ row }) =>
          `${getCurrencySymbolFromIso(row?.original.currency, currencies)}${formatNumberGlobally(row?.original.amount)}`,
      },

      {
        id: 'actions',
        accessorKey: 'actions',
        header: 'Manage',
        enableSorting: false,
        meta: { hidden: false },

        Cell: ({ row }) => (
          <div className="flex gap-3 items-center">
            {/* Edit Button  */}
            <button
              onClick={() => setEditReceiptData(row?.original)}
              className=" disabled:cursor-not-allowed h-7 w-7 border border-[#D0D5DD] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
              type="button"
            >
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.98682 1.16699H5.82015C2.90348 1.16699 1.73682 2.33366 1.73682 5.25033V8.75033C1.73682 11.667 2.90348 12.8337 5.82015 12.8337H9.32015C12.2368 12.8337 13.4035 11.667 13.4035 8.75033V7.58366"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.92691 1.76206L5.33024 6.35872C5.15524 6.53372 4.98024 6.87789 4.94524 7.12872L4.69441 8.88456C4.60108 9.52039 5.05024 9.96372 5.68608 9.87622L7.44191 9.62539C7.68691 9.59039 8.03107 9.41539 8.21191 9.24039L12.8086 4.64372C13.6019 3.85039 13.9752 2.92872 12.8086 1.76206C11.6419 0.595392 10.7202 0.968725 9.92691 1.76206Z"
                  stroke="#667085"
                  strokeOpacity="0.8"
                  strokeWidth="1.3"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.26807 2.4209C9.6589 3.81507 10.7497 4.9059 12.1497 5.30257"
                  stroke="#858D9D"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            {/* Delete Button  */}
            <button
              className="w-7 h-7 cursor-pointer disabled:border-[1.2px] disabled:bg-white inline-flex items-center justify-center disabled:border-rose-500 disabled:rounded-[8px] disabled:cursor-not-allowed"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteReceipt(row?.original?.id);
              }}
              type="button"
              disabled={receiptsDeleteLoading?.[row?.original?.id]}
            >
              {receiptsDeleteLoading?.[row?.original?.id] ? (
                <Loader height="h-4 w-4" color="#f43f5e" />
              ) : (
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-full w-full"
                >
                  <path
                    d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                    fill="white"
                    stroke="#f43f5e"
                    strokeOpacity="0.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                    stroke="#f43f5e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.693 16.3574H14.8505"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.1552 13.7656H15.3948"
                    stroke="#f43f5e"
                    strokeOpacity="0.8"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <button
              onClick={() => setTransactionsModalOpen(row?.original)}
              className="w-[75px] h-8 disabled:bg-indigo-400 disabled:cursor-not-allowed btn-sm whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 text-white"
              type="button"
            >
              Match
            </button>
          </div>
        ),
      },
      {
        id: 'manage',
        accessorKey: 'manage',
        header: 'Manage',
        enableSorting: false,
        meta: { hidden: false },

        Cell: ({ row }) => (
          <div className="flex gap-3 items-center">
            {/* Edit Button  */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                unmatchTransaction(row?.original?.id);
              }}
              disabled={row?.original?.id === unmatchReceiptLoader}
              className="w-[150px] h-8 disabled:bg-indigo-400 disabled:cursor-not-allowed btn-sm whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 text-white"
              type="button"
            >
              {unmatchReceiptLoader === row?.original?.id ? (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              ) : (
                'Unassign Transaction'
              )}
            </button>
          </div>
        ),
      },
    ],
    [unmatchedReceipts, receiptsDeleteLoading, unmatchReceiptLoader],
  );

  return (
    <>
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        title={activeReceiptTitle}
        receipt={activeReceipt}
        isExpenseReporting
      />
      <ReusableTable
        columns={
          isMatching
            ? columns?.filter((column) => column.id !== 'actions')
            : columns?.filter((column) => column.id !== 'manage')
        }
        data={unmatchedReceipts?.pendingReceipts || []}
        rowCount={unmatchedReceipts?.totalRecords || 0}
        pageCount={-1}
        onPaginationChange={(updater) => {
          const pagination =
            typeof updater === 'function' ? updater({}) : updater;

          if (!pagination || typeof pagination !== 'object') {
            return;
          }
          const { pageIndex, pageSize } = pagination;
          fetchReceipts({
            page:
              pageIndex >= 0
                ? pageIndex + 1
                : parseInt(unmatchedReceipts?.page),
            limit: pageSize ?? parseInt(unmatchedReceipts?.limit),
          });
        }}
        onSortingChange={(sorting) => {
          const sort = sorting();
          if (
            sort?.[0]?.id === tableSortValues?.sortBy &&
            tableSortValues?.order === (sort?.[0]?.desc ? 'desc' : 'asc')
          ) {
            return;
          }
          let finalSort = [];
          if (sort?.length > 0) {
            const currentSortId = sort[0]?.id;
            // Check if the same column is being sorted again
            if (tableState?.sorting?.[0]?.id === currentSortId) {
              if (tableState?.sorting?.[0]?.desc === false) {
                // If currently sorted as 'asc', toggle to 'desc'
                finalSort = [{ id: currentSortId, desc: true }];
              } else {
                // If currently sorted as 'desc', remove sorting (no sorting)
                finalSort = [];
              }
            } else {
              // If a different column is being sorted, apply 'asc' sorting
              finalSort = [{ id: currentSortId, desc: false }];
            }
          }
          // Avoid unnecessary fetch if no change in sorting state
          if (
            tableState?.sorting?.length === finalSort?.length &&
            tableState?.sorting?.[0]?.id === finalSort?.[0]?.id &&
            tableState?.sorting?.[0]?.desc === finalSort?.[0]?.desc
          ) {
            return;
          }
          // Update the table state with the new sorting
          setTableState((prev) => ({
            ...prev,
            sorting: finalSort,
          }));
          setTableSortValues({
            sortBy: finalSort?.[0]?.id || '',
            order: finalSort?.[0]?.id
              ? finalSort?.[0]?.desc
                ? 'desc'
                : 'asc'
              : '',
          });
          if (finalSort?.[0]?.id || finalSort?.[0]?.desc) {
            fetchReceipts({
              sortBy: finalSort?.[0]?.id || '',
              order: finalSort?.[0]?.id
                ? finalSort?.[0]?.desc
                  ? 'desc'
                  : 'asc'
                : '',
            });
          }
        }}
        state={{
          isLoading,
          pagination: {
            pageIndex: parseInt(unmatchedReceipts?.page) - 1 || 0,
            pageSize: parseInt(unmatchedReceipts?.limit) ?? 0,
          },
          sorting: tableState.sorting, // Ensure this is an array
          globalFilter: tableState.globalFilter,
          columnSizing: tableState?.columnSizing,
          columnVisibility: tableState?.columnVisibility,
        }}
        onStateChange={setTableState}
        mantineTopToolbarProps={{ className: 'bg-[#A0CD850F] min-h-16' }}
        renderToolbarInternalActions={({ table }) => (
          <div className="flex gap-5 items-center flex-wrap gap-y-2 ">
            <SearchForm
              searchVal={searchValue}
              setSearchVal={handleSearch}
              placeholder="Search Receipts"
              height="h-10 !border-slate-300 focus:!border-slate-400 hover:!border-slate-400"
              searchIconClassName="!stroke-[#868e96] !text-[#868e96] "
            />
            <div className="bg-[#D0D5DD] min-w-px h-10  sm:block" />
            <div className="flex gap-4 ml-auto">
              <MRT_ShowHideColumnsButton
                table={table}
                className="border border-slate-300 h-10 w-10 bg-white"
                sx={{
                  height: '10rem',
                }}
              />
              <Tooltip
                content="Reset filters"
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                position="bottom"
                isFixed
                ids={['accountantMainPage', 'clientsTableFirmView']}
              >
                <button
                  onClick={() => {
                    handleSearch('');
                    if (searchValue) {
                      fetchReceipts({ search: '', page: 1 });
                    }
                    setTableState((prevState) => {
                      const newState = {
                        ...tableInitialState,
                        pagination: prevState?.pagination,
                      };

                      return newState;
                    });
                  }}
                  className="btn p-0 h-10 w-10 py-0 font-normal text-sm leading-6 border border-slate-300  bg-white hover:bg-[#f8f9fa] active:translate-y-[0.0625rem]"
                  type="button"
                >
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" h-4 w-4 fill-[#868e96] text-[#868e96]"
                  >
                    <path
                      d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                      className="fill-current"
                    />
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>
        )}
        columnAc
        enableColumnResizing
        enableSorting
        mantineTableBodyRowProps={
          isMatching
            ? ({ row }) => ({
                onClick: (event) => {
                  console.log('hello', row.original);
                  event.stopPropagation(); // Prevent event bubbling
                  // Add your custom row click logic here
                  setSelectedRow(
                    row.original?.expenseReportTransaction?.transaction,
                  );
                  setRowDetailsOpen(true);
                },
                style: { cursor: 'pointer' }, // Change cursor to pointer on hover
              })
            : null
        }
        enableColumnActions={false}
        mantineTableProps={{
          withBorder: false, // Removes the outer border
          withColumnBorders: false, // Removes borders between columns
        }}
        mantineTableContainerProps={{
          className: 'border-none scrollbar mb-6 pb-1',
          id: 'invitesTableFirmView',
        }}
        mantinePaperProps={{
          className: '!border-none  shadow-none min-h-fit',
        }}
        enablePagination={!isLoading}
        manualPagination
      />
    </>
  );
};

export default ReceiptsTable;
