import React, { useEffect, useState } from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import InfoBox from '../InfoBox';
import { Loader } from '../../../components/Svg';
import { EditIcon } from '../../projects/Svg';
import TextInputDash from '../../../components/TextInputDash';

const Overview = ({
  customer,
  setCustomerModalOpen,
  handleUpdateCustomer,
  overviewData,
  findCurrencySymbol,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [notesEditing, setNotesEditing] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [notes, setNotes] = useState(customer?.notes);

  useEffect(() => {
    setNotes(customer?.notes);
  }, [customer]);

  const handleUpdateNotes = async () => {
    setNotesLoading(true);
    try {
      await handleUpdateCustomer(
        {
          notes,
        },
        customer?.id,
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setNotesLoading(false);
    }
  };

  return (
    <div className="space-y-10">
      <InfoBox
        customer={customer}
        setCustomerModalOpen={setCustomerModalOpen}
      />
      <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-[22px] font-medium text-[#00000099] mb-4">
            Notes
          </h3>

          {checkPermission(
            PermissionKeys.MANAGE_CUSTOMERS,
            accessType,
            permissions,
          ) &&
            (notesLoading ? (
              <Loader width="w-6" height="h-6" />
            ) : (
              <button
                aria-controls="feedback-modal"
                onClick={(e) => {
                  e.stopPropagation();
                  if (notesEditing) {
                    handleUpdateNotes();
                  }
                  setNotesEditing(!notesEditing);
                }}
                type="button"
                className={`border border-opacity-50 shadow-sm h-8 w-[4.625rem] inline-flex py-[0.375rem] px-[0.625rem] rounded-md font-inter ${
                  notesEditing ? 'border-indigo-500' : 'border-gray-300'
                }`}
              >
                <span
                  className={`${
                    notesEditing
                      ? 'text-sm pr-[0.2rem]'
                      : 'text-sm pr-[0.563rem]'
                  } font-semibold leading-5 text-slate-600 text-opacity-80`}
                >
                  {notesEditing ? 'Save' : 'Edit'}
                </span>
                <EditIcon />
              </button>
            ))}
        </div>
        {!notesEditing ? (
          <p
            dangerouslySetInnerHTML={{
              __html: notes?.replace(/\n/g, '<br>') || '--',
            }}
            className="text-sm text-[#667085]"
          />
        ) : (
          <TextInputDash
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            type="textarea"
            paddingBottom="pb-0"
          />
        )}
      </div>
      {/* Revenue Box 1  */}
      <div className="">
        <h3 className="text-[22px] font-medium text-[#00000099] mb-7">
          Revenue Overview
        </h3>

        <div className="grid lg:grid-cols-2 gap-6">
          {/* Box 1  */}
          <div className="border border-[#D0D5DD] rounded-[12px] p-6">
            <div className="border-b border-[#D0D5DD] pb-6">
              <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                Total Amount Paid
              </h3>
              <h2 className="text-[36px] font-medium text-[#101828] text-right w-full">
                {overviewData?.totalRevenue?.toString().charAt(0) === '-'
                  ? '-'
                  : ''}
                {findCurrencySymbol(overviewData?.currency)}
                {overviewData?.totalRevenue?.toString().charAt(0) === '-'
                  ? parseFloat(
                      overviewData?.totalRevenue?.toString().replace('-', ''),
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : parseFloat(overviewData?.totalRevenue)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
              </h2>
            </div>
            <div className="border-b border-[#D0D5DD] py-6">
              <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                Outstanding Balance
              </h3>
              <h2 className="text-[36px] font-medium text-[#101828] w-full text-right">
                {overviewData?.pendingPayments?.toString().charAt(0) === '-'
                  ? '-'
                  : ''}
                {findCurrencySymbol(overviewData?.currency)}
                {overviewData?.pendingPayments?.toString().charAt(0) === '-'
                  ? parseFloat(
                      overviewData?.pendingPayments
                        ?.toString()
                        .replace('-', ''),
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : parseFloat(overviewData?.pendingPayments)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
              </h2>
            </div>
            <div className=" pt-6">
              <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                Credit Balance
              </h3>
              <h2 className="text-[36px] font-medium text-[#101828] w-full text-right">
                {customer?.balance?.toString().charAt(0) === '-' ? '-' : ''}
                {findCurrencySymbol(overviewData?.currency)}
                {customer?.balance?.toString().charAt(0) === '-'
                  ? parseFloat(
                      customer?.balance?.toString().replace('-', '') || 0,
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : parseFloat(customer?.balance || 0)?.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )}
              </h2>
            </div>
          </div>
          {/* Box 2  */}
          <div className="grid grid-cols-2 border border-[#D0D5DD] rounded-[12px] p-6 h-fit">
            <div className="flex flex-col border-r border-[#D0D5DD] divide-y divide-[#D0D5DD]">
              <div className="pr-[15%] pb-6">
                <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                  Invoices Overdue
                </h3>
                <h2 className="text-[36px] font-medium text-[#101828] text-right w-full">
                  {overviewData?.totalOverdueCount}
                </h2>
              </div>
              <div className="pr-[15%] pt-6 flex-1 flex flex-col justify-between">
                <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                  Invoices Unpaid
                </h3>
                <h2 className="mt-auto text-[36px] font-medium text-[#101828] text-right w-full">
                  {overviewData?.invoiceUnpaid}
                </h2>
              </div>
            </div>
            <div className="border-[#D0D5DD] divide-y divide-[#D0D5DD]">
              <div className="pl-[15%] pb-6 ">
                <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                  Invoices Paid
                </h3>
                <h2 className="text-[36px] mt-auto font-medium text-[#101828] text-right w-full pr-4">
                  {overviewData?.invoicePaid}
                </h2>
              </div>
              <div className="pl-[15%] pt-6 ">
                <h3 className="text-[22px] font-medium text-[#666666] leading-tight mb-6">
                  Average Time To Pay
                </h3>
                <h2 className="text-[36px] font-medium text-[#101828] text-right w-full pr-4">
                  {parseFloat(overviewData?.averageDaysToPay)?.toFixed(2)} Days
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
