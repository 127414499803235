import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  assignTaskToTaskList,
  createEncryptedRecord,
  createProject,
  deleteAccountingFirmTasks,
  deleteEncryptedRecord,
  deleteTaskAttachment,
  getAccountantFirmClientById,
  getAccountantFirms,
  getAccountingFirmTasks,
  getEncryptedRecords,
  getFirmClients,
  getFirmMembersApi,
  getFolders,
  getInvoices,
  getPeople,
  getTaskAttachments,
  removeTaskFromTaskList,
  updateCustomer,
  updateEncryptedRecord,
  updateTask,
  updateTeamById,
  uploadTaskAttachment,
} from 'API/backend_helper';
import {
  ADMIN,
  FIRM_ID,
  getFormattedDate,
  TEAM_ID,
  TEAM_NAME,
} from 'utils/Utils';
import { toast } from 'react-toastify';
import { setAccessType } from '../../redux/User/reducer';
import { setTeam } from '../../redux/Team/reducer';

const TABS = {
  invoices: 'invoices',
  estimates: 'estimates',
  tasks: 'tasks',
  projects: 'projects',
  files: 'files',
  vault: 'vault',
};

const TASKS_VIEWS = {
  lists: 'lists',
  kanban: 'kanban',
  group: 'group',
};

const useClientDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FirmId = localStorage.getItem(FIRM_ID);
  const accessToken = localStorage.getItem('accessToken');

  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [accountantFirm, setAccountantFirm] = useState({});
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [salesRep, setSalesRep] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS.invoices);
  const [clients, setClients] = useState([]);
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  // Billing Tab
  const [invoices, setInvoices] = useState([]);
  const [invoicesLocalLimit, setInvoicesLocalLimit] = useState(20);
  const [isInvoicesTabLoaded, setIsInvoicesTabLoaded] = useState(false);

  // Projects Tab
  const [projects, setProjects] = useState([]);
  const [isProjectsTabLoaded, setIsProjectsTabLoaded] = useState(false);

  // Files Tab
  const [folders, setFolders] = useState({});
  const [isFilesTabLoaded, setIsFilesTabLoaded] = useState(false);
  const [parentFolder, setParentFolder] = useState('');

  // Tasks Tab
  const [firmMembers, setFirmMembers] = useState([]);
  const [taskLists, setTaskLists] = useState([]);
  const [taskListLoading, setTaskListLoading] = useState(false);
  const [selectedTaskList, setSelectedTaskList] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeAttachment, setActiveAttachment] = useState(null);
  const [selectedTaskAttachments, setSelectedTaskAttachments] = useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [clientsLoader, setClientsLoader] = useState(false);
  const [addTaskListModalOpen, setAddTaskListModalOpen] = useState(false);
  const [tasks, setTasks] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [tasksView, setTasksView] = useState(TASKS_VIEWS.group);
  const [tasksSortValues, setTasksSortValues] = useState({
    sortBy: '',
    order: '',
  });

  // Vault Tab
  const [encryptions, setEncryptions] = useState([]);
  const [encryptionLoader, setEncryptionLoader] = useState(false);
  const [addEditEncryptionLoader, setAddEditEncryptionLoader] = useState(false);
  const [deleteEncryptionLoader, setDeleteEncryptionLoader] = useState(false);
  const [encryptionsSortValues, setEncryptionsSortValues] = useState({
    sortBy: '',
    order: '',
  });

  const onTeamDetailsChange = (team) => {
    localStorage.setItem(TEAM_ID, team?.id);
    localStorage.setItem(TEAM_NAME, team?.name);
    dispatch(setAccessType(ADMIN));
    dispatch(setTeam(team));
  };

  const getEncryptions = async ({
    page = 1,
    limit = 50,
    loading = true,
    search = '',
    order = encryptionsSortValues?.order || '',
    sortBy = encryptionsSortValues?.sortBy || '',
  }) => {
    if (+localStorage.getItem(TEAM_ID) !== +clientDetails?.id) {
      localStorage.setItem(TEAM_ID, clientDetails?.id);
    }
    try {
      if (loading) {
        setEncryptionLoader(true);
      }
      const response = await getEncryptedRecords({
        page,
        limit,
        search,
        order,
        sortBy,
      });
      setEncryptions(response?.data);
      if (loading) {
        setEncryptionLoader(false);
      }
      if (+localStorage.getItem(TEAM_ID) !== +accountantFirm?.workspace?.id) {
        onTeamDetailsChange(accountantFirm?.workspace);
      }
    } catch (err) {
      if (loading) {
        setEncryptionLoader(false);
      }
      if (+localStorage.getItem(TEAM_ID) !== +accountantFirm?.workspace?.id) {
        onTeamDetailsChange(accountantFirm?.workspace);
      }
      console.log('err', err);
    }
  };

  const createEncryptions = async (values) => {
    if (+localStorage.getItem(TEAM_ID) !== +clientDetails?.id) {
      localStorage.setItem(TEAM_ID, clientDetails?.id);
    }
    try {
      setAddEditEncryptionLoader(true);
      const response = await createEncryptedRecord(values);
      getEncryptions({});
      setAddEditEncryptionLoader(false);
    } catch (err) {
      setAddEditEncryptionLoader(false);
      console.log('err', err);
      if (+localStorage.getItem(TEAM_ID) !== +accountantFirm?.workspace?.id) {
        onTeamDetailsChange(accountantFirm?.workspace);
      }
      throw new Error(err);
    }
  };

  const updateEncryptions = async (id, values) => {
    if (+localStorage.getItem(TEAM_ID) !== +clientDetails?.id) {
      localStorage.setItem(TEAM_ID, clientDetails?.id);
    }
    try {
      setAddEditEncryptionLoader(true);
      const response = await updateEncryptedRecord(id, values);
      getEncryptions({});
      setAddEditEncryptionLoader(false);
    } catch (err) {
      setAddEditEncryptionLoader(false);
      console.log('err', err);
      if (+localStorage.getItem(TEAM_ID) !== +accountantFirm?.workspace?.id) {
        onTeamDetailsChange(accountantFirm?.workspace);
      }
      throw new Error(err);
    }
  };

  const deleteEncryptions = async (id) => {
    if (+localStorage.getItem(TEAM_ID) !== +clientDetails?.id) {
      localStorage.setItem(TEAM_ID, clientDetails?.id);
    }
    try {
      setDeleteEncryptionLoader(true);
      const response = await deleteEncryptedRecord(id);
      getEncryptions({});
      setDeleteEncryptionLoader(false);
    } catch (err) {
      setDeleteEncryptionLoader(false);
      console.log('err', err);
      if (+localStorage.getItem(TEAM_ID) !== +accountantFirm?.workspace?.id) {
        onTeamDetailsChange(accountantFirm?.workspace);
      }
      throw new Error(err);
    }
  };

  const getClients = async () => {
    try {
      setClientsLoader(true);
      const response = await getFirmClients(FirmId);
      setClients(response?.data?.clients);
      setClientsLoader(false);
    } catch (err) {
      setClientsLoader(false);
      console.log('err', err);
    }
  };

  const loadClientById = async ({ loading = false }) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const res = await getAccountantFirmClientById(FirmId, id);
      setClientDetails(res?.data?.client);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const onTeamChange = (id, teamObj = null, navigateTo = '/') => {
    if (id) {
      localStorage.setItem(TEAM_ID, id);
      localStorage.setItem(TEAM_NAME, teamObj?.name);
      dispatch(setAccessType(ADMIN));
      dispatch(setTeam(teamObj));
      navigate(navigateTo);
      window.location.reload();
    }
  };

  const loadAccountantFirm = async () => {
    try {
      const res = await getAccountantFirms(FirmId);
      setAccountantFirm(res?.data?.result);
      onTeamDetailsChange(res?.data?.result?.workspace);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getSalesRepApi = async () => {
    try {
      localStorage.setItem(TEAM_ID, accountantFirm?.workspace?.id);
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleUpdateCustomer = async (data, id) => {
    try {
      localStorage.setItem(TEAM_ID, accountantFirm?.workspace?.id);
      await updateTeamById(clientDetails?.id, {
        name: data?.customer || '',
        businessAddress: {
          street: data?.billingAddress?.street || '',
          street2: data?.billingAddress?.street2 || '',
          city: data?.billingAddress?.city || '',
          zipCode: data?.billingAddress?.zipCode || '',
        },
      });

      await updateCustomer(data, id);

      await loadClientById({ loading: true });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateCustomerNotes = async (data) => {
    try {
      localStorage.setItem(TEAM_ID, accountantFirm?.workspace?.id);
      await updateCustomer(
        { notes: data },
        clientDetails?.customerRecord?.[0]?.id,
      );
      await loadClientById({ loading: false });
    } catch (err) {
      console.log('err', err);
    }
  };

  const getInvoicesData = async ({
    type = 'invoice',
    limit = invoicesLocalLimit,
    page = 1,
    customers = [clientDetails?.customerRecord?.[0]?.id],
  }) => {
    try {
      localStorage.setItem(TEAM_ID, accountantFirm?.workspace?.id);
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
      });
      setInvoices(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadAllProjects = async ({
    search = '',
    customers = [clientDetails?.customerRecord?.[0]?.id],
  }) => {
    localStorage.setItem(TEAM_ID, accountantFirm?.workspace?.id);
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/projects?search=${search}&customers=${customers}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'count-workspace-id': accountantFirm?.workspace?.id,
          },
        },
      );
      setProjects(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateProject = async (values) => {
    try {
      const teamDateFormat = accountantFirm?.workspace?.defaults?.dateFormat;
      const { startDate, endDate, ...rest } = values;
      const data = { ...rest };
      if (startDate) {
        data.startDate = getFormattedDate(startDate, teamDateFormat);
      } else {
        data.startDate = null;
      }

      if (endDate) {
        data.endDate = getFormattedDate(endDate, teamDateFormat);
      } else {
        data.endDate = null;
      }
      localStorage.setItem(TEAM_ID, accountantFirm?.workspace?.id);
      await createProject(data);
      await loadAllProjects({});
      toast.success('Project created successfully');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const loadFolders = async (parent) => {
    localStorage.setItem(TEAM_ID, clientDetails?.id);
    try {
      const res = await getFolders(encodeURIComponent(parent));
      setFolders(res);
      onTeamDetailsChange(accountantFirm?.workspace);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getMembers = async () => {
    try {
      const res = await getFirmMembersApi(FirmId);
      setFirmMembers(res?.data?.firmMembers);
    } catch (err) {
      console.log('err', err);
    }
  };

  // Tasks

  const loadTaskLists = async ({
    search = '',
    clients = id,
    status = '',
    priority = '',
  }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/task-list?search=${search}&clients=${clients}&status=${status}&priority=${priority}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'count-workspace-id': '',
          },
        },
      );
      setTaskLists(res?.data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTasks = async ({
    firmId = FirmId,
    page = tasks?.page || 1,
    limit = 50,
    status = '',
    priority = '',
    loading = false,
    orderByVal = tasksSortValues?.sortBy,
    orderDirectionVal = tasksSortValues?.order,
    search = '',
    clients = id,
    newTaskId = null,
    isTaskList = false,
  }) => {
    try {
      if (loading) {
        setTableLoading(true);
      }
      if (!isTaskList) {
        await loadTaskLists({});
      }

      const res = await getAccountingFirmTasks({
        firmId,
        page,
        limit,
        status,
        priority,
        orderBy: orderByVal,
        orderDirection: orderDirectionVal,
        search,
        clients,
      });
      setTasks(res);
    } catch (error) {
      console.error(error);
    } finally {
      setTableLoading(false);
    }
  };

  const handleTaskListShift = async ({
    oldTaskListId = null,
    newTaskListId = null,
    taskId = null,
    reloadTasks = true,
  }) => {
    if (!oldTaskListId || !newTaskListId || !taskId) {
      return;
    }
    try {
      if (tasksView !== TASKS_VIEWS.lists) {
        const tempTaskLists = JSON.parse(JSON.stringify(taskLists));
        const oldTaskListIndex = tempTaskLists?.findIndex((taskList) =>
          oldTaskListId === 'uncategorized'
            ? taskList?.name === 'Uncategorized'
            : taskList?.id === oldTaskListId,
        );
        const newTaskListIndex = tempTaskLists?.findIndex((taskList) =>
          newTaskListId === 'uncategorized'
            ? taskList?.name === 'Uncategorized'
            : taskList?.id === newTaskListId,
        );
        const tempOldTasks = JSON.parse(
          JSON.stringify(tempTaskLists?.[oldTaskListIndex]?.tasks),
        );
        const taskIndex = tempOldTasks?.findIndex((t) => t?.id === taskId);
        const tempTask = {
          ...tempOldTasks[taskIndex],
          TaskListItem: {
            ...tempOldTasks[taskIndex]?.TaskListItem,
            taskListId: newTaskListId,
          },
        };
        const tempNewTasks =
          JSON.parse(
            JSON.stringify(tempTaskLists?.[newTaskListIndex]?.tasks),
          ) || [];
        const pushedNewTasks = [...tempNewTasks, tempTask];
        const filteredOldTasks = tempOldTasks?.filter(
          (_, i) => i !== taskIndex,
        );
        tempTaskLists[oldTaskListIndex] = {
          ...tempTaskLists[oldTaskListIndex],
          tasks: filteredOldTasks,
        };
        tempTaskLists[newTaskListIndex] = {
          ...tempTaskLists[newTaskListIndex],
          tasks: pushedNewTasks,
        };
        setTaskLists(tempTaskLists);
        if (selectedTask?.id) {
          setSelectedTask((prev) => ({
            ...prev,
            TaskListItem: {
              ...prev?.TaskListItem,
              taskListId: newTaskListId,
            },
          }));
        }
      } else {
        const tempTasks = JSON.parse(JSON.stringify(tasks?.tasks));
        const taskIndex = tempTasks?.findIndex((t) => t?.id === taskId);
        const newTaskList = taskLists?.find((t) => t?.id === newTaskListId);
        const tempTask = {
          ...tempTasks[taskIndex],
          taskLists: [
            {
              id: newTaskList?.id,
              teamId: newTaskList?.teamId,
              name: newTaskList?.name,
              description: newTaskList?.description,
              projectId: newTaskList?.projectId,
              customerId: newTaskList?.customerId,
              createdById: newTaskList?.createdById,
              accountingFirmId: newTaskList?.accountingFirmId,
              task_list_items: {
                taskId,
                taskListId: newTaskList?.id,
              },
            },
          ],
        };
        tempTasks[taskIndex] = JSON.parse(JSON.stringify(tempTask));
        setTasks((prev) => ({
          ...prev,
          tasks: tempTasks,
        }));
        if (selectedTask?.id) {
          setSelectedTask(JSON.parse(JSON.stringify(tempTask)));
        }
      }

      localStorage.setItem(TEAM_ID, '');
      if (+newTaskListId >= 0) {
        await assignTaskToTaskList({
          taskListId: newTaskListId,
          taskId,
        });
      }
      if (oldTaskListId !== 'uncategorized') {
        await removeTaskFromTaskList(oldTaskListId, taskId);
      }
      if (reloadTasks) {
        await loadTasks({
          loading: false,
        });
      }
    } catch (err) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.log(err);
    }
  };

  const handleUpdateTask = async (id, values) => {
    try {
      let formattedDate;
      if (values.deadlineDate) {
        const newDate =
          values.deadlineDate instanceof Date
            ? values.deadlineDate
            : new Date(values.deadlineDate);
        formattedDate = getFormattedDate(newDate, 'mm/dd/yyyy');
      } else {
        formattedDate = null;
      }
      let tempTask = {};
      if (tasksView !== TASKS_VIEWS.lists) {
        const taskListId = flyoutOpen
          ? selectedTask?.TaskListItem?.taskListId
          : values?.taskListId;
        const tempTaskLists = JSON.parse(JSON.stringify(taskLists));
        const taskListIndex = tempTaskLists?.findIndex(
          (taskList) => taskList?.id === taskListId,
        );
        const tempTasks = JSON.parse(
          JSON.stringify(tempTaskLists?.[taskListIndex]?.tasks),
        );
        const taskIndex = tempTasks?.findIndex((t) => t?.id === id);
        tempTask = {
          ...tempTasks[taskIndex],
          ...values,
          deadlineDate: formattedDate,
          id,
        };
        tempTasks[taskIndex] = JSON.parse(JSON.stringify(tempTask));
        tempTaskLists[taskListIndex] = {
          ...tempTaskLists[taskListIndex],
          tasks: tempTasks,
        };
        setTaskLists(tempTaskLists);
      } else {
        const tempTasks = JSON.parse(JSON.stringify(tasks?.tasks));
        const taskIndex = tempTasks?.findIndex((t) => t?.id === id);
        tempTask = {
          ...tempTasks[taskIndex],
          ...values,
          deadlineDate: formattedDate,
          id,
        };
        tempTasks[taskIndex] = JSON.parse(JSON.stringify(tempTask));
        setTasks((prev) => ({
          ...prev,
          tasks: tempTasks,
        }));
      }
      if (selectedTask?.id) {
        setSelectedTask(JSON.parse(JSON.stringify(tempTask)));
      }
      if (values?.teamId) {
        localStorage.setItem(TEAM_ID, values?.teamId);
      }
      const data = new FormData();
      data.append('taskName', tempTask?.name);
      data.append('deadlineDate', formattedDate);
      data.append('type', tempTask?.type);
      data.append('description', tempTask?.description);
      data.append('status', tempTask?.status);
      data.append('priority', tempTask?.priority);
      data.append('assigneeId', tempTask?.assigneeId);
      data.append('projectId', tempTask?.projectId);
      data.append('accountingFirmId', FirmId);

      const res = await updateTask(id, {
        ...values,
        deadlineDate: formattedDate,
      });
      if (selectedTask?.id) {
        setSelectedTask((prev) => ({ ...prev, ...res }));
      }
      // eslint-disable-next-line no-use-before-define
      await loadTasks({});
      onTeamDetailsChange(accountantFirm?.workspace);
      return res;
    } catch (error) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.error(error);
    }
  };

  const findTaskType = (type) => {
    switch (type) {
      case 'toDo':
        return 'To-Do';
      case 'reviewTransaction':
        return 'Review Transaction';
      case 'transactionVendor':
        return 'Set Transaction Vendor';
      case 'transactionCategory':
        return 'Set Transaction Category';
      case 'payBill':
        return 'Pay Bill';
      case 'transactionAttachment':
        return 'Upload Transaction Attachment';
      default:
        return 'No Type Assigned';
    }
  };

  const toggleDropdown = (dropdownId) => {
    setStatusDropdownOpen(
      statusDropdownOpen === dropdownId ? null : dropdownId,
    );
  };

  const handleDeleteTask = async () => {
    try {
      if (selectedTask?.teamId) {
        localStorage.setItem(TEAM_ID, selectedTask?.teamId);
      }
      await deleteAccountingFirmTasks(selectedTask?.id);
      // eslint-disable-next-line no-use-before-define
      if (selectedTask?.id) {
        setSelectedTask(null);
      }
      setFlyoutOpen(false);
      await loadTasks({});
      onTeamDetailsChange(accountantFirm?.workspace);
    } catch (error) {
      console.error(error);
    }
  };

  const loadTaskAttachments = async (loading = false) => {
    if (loading) {
      setAttachmentsLoading(true);
    }
    try {
      if (selectedTask?.teamId) {
        localStorage.setItem(TEAM_ID, selectedTask?.teamId);
      }
      const data = await getTaskAttachments(selectedTask?.id);
      setSelectedTaskAttachments(data);
      onTeamDetailsChange(accountantFirm?.workspace);
    } catch (err) {
      console.log('err', err);
    } finally {
      setAttachmentsLoading(false);
    }
  };

  const uploadTaskFile = async (file) => {
    try {
      if (selectedTask?.teamId) {
        localStorage.setItem(TEAM_ID, selectedTask?.teamId);
      }
      const data = new FormData();
      data.append('attachment', file);
      await uploadTaskAttachment(selectedTask?.id, data);
      loadTaskAttachments();
      loadTasks({});
    } catch (err) {
      toast.error(
        'An error occurred while saving. Please refresh and try again',
      );
      console.log('err', err);
    }
  };

  const handleDeleteAttachment = async () => {
    if (activeAttachment?.id) {
      try {
        if (selectedTask?.teamId) {
          localStorage.setItem(TEAM_ID, selectedTask?.teamId);
        }
        await deleteTaskAttachment(
          activeAttachment?.taskId,
          activeAttachment?.id,
        );
        onTeamDetailsChange(accountantFirm?.workspace);
        loadTasks({});
      } catch (error) {
        console.error(error);
      }
    }
    await loadTaskAttachments();
    setActiveAttachment(null);
  };

  useEffect(() => {
    if (selectedTask?.id && flyoutOpen) {
      loadTaskAttachments(true);
    }
  }, [selectedTask?.id]);

  useEffect(() => {
    if (FirmId && id) {
      loadTasks({});
      loadClientById({ loading: true });
      loadAccountantFirm();
      getMembers();
      getClients();
    }
  }, [FirmId]);

  useEffect(() => {
    if (accountantFirm?.workspace?.id) {
      getSalesRepApi();
    }
  }, [accountantFirm]);

  return {
    FirmId,
    sidebarOpen,
    setSidebarOpen,
    loading,
    clientDetails,
    accountantFirm,
    onTeamChange,
    customerModalOpen,
    setCustomerModalOpen,
    handleUpdateCustomer,
    salesRep,
    TABS,
    activeTab,
    setActiveTab,
    onTeamDetailsChange,
    notesModalOpen,
    setNotesModalOpen,
    handleUpdateCustomerNotes,

    // Billing Tab
    invoices,
    invoicesLocalLimit,
    setInvoicesLocalLimit,
    getInvoicesData,
    isInvoicesTabLoaded,
    setIsInvoicesTabLoaded,

    // projects Tab
    projects,
    isProjectsTabLoaded,
    setIsProjectsTabLoaded,
    loadAllProjects,
    handleCreateProject,

    // files Tab
    loadFolders,
    isFilesTabLoaded,
    setIsFilesTabLoaded,
    folders,
    parentFolder,
    setParentFolder,

    // Tasks Tab
    firmMembers,
    flyoutOpen,
    setFlyoutOpen,
    selectedTask,
    setSelectedTask,
    handleUpdateTask,
    loadTasks,
    statusDropdownOpen,
    setStatusDropdownOpen,
    findTaskType,
    toggleDropdown,
    deleteModalOpen,
    setDeleteModalOpen,
    handleDeleteTask,
    activeAttachment,
    setActiveAttachment,
    handleDeleteAttachment,
    selectedTaskAttachments,
    setSelectedTaskAttachments,
    attachmentsLoading,
    uploadTaskFile,
    attachmentModalOpen,
    setAttachmentModalOpen,
    taskLists,
    setTaskLists,
    handleTaskListShift,
    clients,
    setClients,
    selectedTaskList,
    setSelectedTaskList,
    taskListLoading,
    setTaskListLoading,
    addTaskListModalOpen,
    setAddTaskListModalOpen,
    tasks,
    tableLoading,
    tasksView,
    setTasksView,
    TASKS_VIEWS,
    tasksSortValues,
    setTasksSortValues,

    // Vault Tab
    encryptions,
    encryptionLoader,
    addEditEncryptionLoader,
    deleteEncryptionLoader,
    createEncryptions,
    getEncryptions,
    updateEncryptions,
    deleteEncryptions,
    encryptionsSortValues,
    setEncryptionsSortValues,
  };
};

export default useClientDetails;
