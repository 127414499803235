import React, { useState, useRef, useEffect } from 'react';
import useDropdown from 'hooks/useDropdown';
import Transition from '../../utils/Transition';
import { VIEWER, getCurrencySymbolFromIso } from '../../utils/Utils';

const ReuseableAccount = ({
  invoiceOptionsOpen,
  assignTransactionToInvoice,
  category,
  billOptionsOpen,
  assignTransactionToBill,
  selectedCategoryId,
  setSelectedCategoryId,
  handlePopupLogic,
  transactionName,
  isSetCategoryStyle,
  getTransactionsApi,
  setDropdownOpen,
  transferModal,
  transferOptionsOpen,
  type,
  currencies,
}) => (
  <span
    role="button"
    tabIndex="0"
    onClick={(e) => {
      e.stopPropagation();
      if (invoiceOptionsOpen && assignTransactionToInvoice) {
        assignTransactionToInvoice(category.id);
      }
      if (billOptionsOpen && assignTransactionToBill) {
        assignTransactionToBill(category.id, category.instanceId);
      }
      if (
        category.id !== selectedCategoryId &&
        !invoiceOptionsOpen &&
        !billOptionsOpen
      ) {
        setSelectedCategoryId(category.id, category);
        if (handlePopupLogic) {
          handlePopupLogic(category, transactionName);
        }
        if (!isSetCategoryStyle && getTransactionsApi !== null) {
          getTransactionsApi({
            accountCategories: [category?.id],
            page: 1,
          });
        }
      }
      setDropdownOpen(false);
    }}
    className={`font-normal whitespace-normal leading-tight flex items-center pr-4 text-[15px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3
            ${
              category?.id === selectedCategoryId
                ? 'text-indigo-500 hover:!text-indigo-600'
                : ''
            }`}
  >
    {category?.name}
    {(transferModal || transferOptionsOpen || type === 'account') &&
      category?.mask &&
      ` (...${category?.mask})`}
    {invoiceOptionsOpen &&
      ` | ${category?.customer?.customer} | ${getCurrencySymbolFromIso(
        category?.currency,
        currencies,
      )}${(category?.total - category?.paidAmount).toFixed(2)}`}{' '}
    <svg
      className={`shrink-0 ml-2 fill-current text-indigo-500 ${
        category?.id !== selectedCategoryId && 'invisible'
      }`}
      width="12"
      height="9"
      viewBox="0 0 12 9"
    >
      <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
    </svg>
  </span>
);

function CategoriesDropdown({
  align,
  allCategories,
  selectedCategoryId,
  setSelectedCategoryId,
  getTransactionsApi = null,
  isSetCategoryStyle = false,
  inlineMenu = false,
  addTransactionSplit = false,
  accessType,
  isError = false,
  readOnly = false,
  isTableDropdown = false,
  height,
  addCategory = false,
  selectedCategory,
  disabled = false,
  flyout = false,
  handleCategoryClick,
  bulkDropdown = false,
  transferModal = false,
  type = null,
  allAccounts = null,
  physicalCategoryName = null,
  scrollIntoView = false,
  split = false,
  ids = [],
  transactionAmount,
  handlePopupLogic,
  transactionName = null,
  bottomOfTable = false,
  invoices,
  currencies,
  assignTransactionToInvoice,
  journalEntry = false,
  addNewOptionButton = false,
  addNewOptionLabel = '',
  handleNewOptionButton = () => {},
  setCurrentDropdownId = () => {},
  invoiceName = '',
  noClick = false,
  bills,
  billName = '',
  assignTransactionToBill,
  buttonTextClassSub = '',
  transitionClass = '',
  buttonClass = '',
  setNoClick = () => {},
  selectedCategoryName = '',
  showSubAccount = true,
  placeholderText = '',
}) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(
    physicalCategoryName ? allAccounts : [...allCategories],
  );
  const [standardData, setStandardData] = useState([...allCategories]);
  const [isResultFound, setIsResultFound] = useState(true);
  const [transferOptionsOpen, setTransferOptionsOpen] = useState(false);
  const [refundOptionsOpen, setRefundOptionsOpen] = useState(false);
  const [incomeRefundOptionsOpen, setIncomeRefundOptionsOpen] = useState(false);
  const [invoiceOptionsOpen, setInvoiceOptionsOpen] = useState(false);
  const [billOptionsOpen, setBillOptionsOpen] = useState(false);

  const searchInput = useRef(null);
  const scrollRef = useRef(null);

  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(
    isTableDropdown,
    bottomOfTable,
    ids,
    false,
    setSearchValue,
    filteredData,
    setNoClick,
  );

  useEffect(() => {
    filterUncategorized();
    if (allCategories?.length === 0) {
      setIsResultFound(false);
    } else {
      setIsResultFound(true);
    }
  }, [allCategories]);

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue('');
      if (!physicalCategoryName && !selectedCategory?.type === 'Expenses') {
        filterUncategorized();
      }
      if (allCategories?.length) {
        setIsResultFound(true);
      }
      if (window.innerWidth >= 768) {
        searchInput.current?.focus();
      }
    } else {
      setTransferOptionsOpen(false);
      setRefundOptionsOpen(false);
      setIncomeRefundOptionsOpen(false);
      setInvoiceOptionsOpen(false);
      setBillOptionsOpen(false);
    }
    if (!dropdownOpen) {
      scrollRef.current.scrollTop = 0;
      setSearchValue('');
      filterUncategorized();
      setCurrentDropdownId(null);
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (scrollIntoView) {
      if (dropdownOpen && scrollIntoView) {
        dropdown.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [dropdownOpen]);

  const mergeCategoriesAndAccounts = (allCategories, allAccounts) => {
    let newFilteredData =
      allCategories?.map((catGroup) => ({
        ...catGroup,
        categories: catGroup.categories.filter(
          (cat) => !cat.name.startsWith('Uncategorized'),
        ),
      })) || [];
    return newFilteredData.map((category) => {
      const matchedAccount = allAccounts?.find(
        (account) => account.name === category.name,
      );
      return matchedAccount
        ? { ...category, categories: matchedAccount.categories }
        : category;
    });
  };

  const getSelectedCategory = () => {
    if (invoiceName) {
      return invoiceName;
    }
    if (billName) {
      return billName;
    }
    if (!addCategory) {
      const selectedCategory = allCategories
        ?.map((grp) => grp.categories)
        .flat()
        .reduce((result, category) => {
          if (result) return result; // Stop searching once a match is found

          if (category.id === selectedCategoryId) {
            return category; // Return category if ID matches
          }

          const matchingSubAccount = category.subAccounts?.find(
            (subAcc) => subAcc.id === selectedCategoryId,
          );

          return matchingSubAccount || result; // Return subAccount if ID matches
        }, null);
      if (physicalCategoryName) {
        return physicalCategoryName;
      }
      if (
        selectedCategory?.name &&
        selectedCategory.name.startsWith('Uncategorized')
      ) {
        return 'Select Category';
      }
      if (selectedCategory?.mask) {
        return `${selectedCategory?.name} (...${selectedCategory?.mask})`;
      }
      return selectedCategory?.name ? `${selectedCategory?.name}` : '';
    } else {
      if (
        selectedCategory?.name &&
        selectedCategory.name.startsWith('Uncategorized')
      ) {
        return 'Select Category';
      }
      return selectedCategory?.name;
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);

    if (!searchTerm) {
      if (allCategories?.length) {
        setIsResultFound(true);
      }
      setStandardData(mergeCategoriesAndAccounts(allCategories, allAccounts));

      const newFilteredData =
        allCategories?.map((catGroup) => ({
          ...catGroup,
          categories: catGroup.categories.filter(
            (cat) => !cat.name.startsWith('Uncategorized'),
          ),
        })) || [];

      if (refundOptionsOpen) {
        setFilteredData([
          newFilteredData.find((item) => item.name === 'Expenses'),
        ]);
      } else if (incomeRefundOptionsOpen) {
        setFilteredData([
          newFilteredData.find((item) => item.name === 'Income'),
        ]);
      } else if (transferOptionsOpen) {
        setFilteredData(allAccounts);
      } else {
        setFilteredData([...newFilteredData]);
      }
      return;
    }

    setIsResultFound(false);
    const formattedValue = searchTerm.replace(/\s/g, '').toLowerCase();

    const result = standardData
      .map((group) => {
        const filteredCategories = group.categories
          .map((category) => {
            const categoryNameMatch = category?.name
              ?.replace(/\s/g, '')
              .toLowerCase()
              .includes(formattedValue);

            // Filter only matching sub-accounts
            const matchingSubAccounts = category?.subAccounts?.filter(
              (subAccount) =>
                subAccount?.name
                  ?.replace(/\s/g, '')
                  .toLowerCase()
                  .includes(formattedValue),
            );

            // If category matches, keep all sub-accounts; otherwise, return only matching sub-accounts
            if (categoryNameMatch || matchingSubAccounts.length > 0) {
              return {
                ...category,
                subAccounts:
                  matchingSubAccounts.length > 0
                    ? matchingSubAccounts
                    : category.subAccounts,
              };
            }

            return null;
          })
          .filter(Boolean); // Remove null values

        if (filteredCategories.length > 0) {
          setIsResultFound(true);
        }

        return {
          ...group,
          categories: filteredCategories,
        };
      })
      .filter((group) => group.categories.length > 0); // Remove groups with no matches

    setFilteredData(result);
  };

  const filterUncategorized = () => {
    let newFilteredData =
      allCategories?.map((catGroup) => ({
        ...catGroup,
        categories: catGroup.categories.filter(
          (cat) => !cat.name.startsWith('Uncategorized'),
        ),
      })) || [];
    if (type === 'Income' && !refundOptionsOpen) {
      const expensesRemoved = newFilteredData.filter(
        (category) => category.name !== 'Expenses',
      );
      setFilteredData([...expensesRemoved]);
      setStandardData(mergeCategoriesAndAccounts(allCategories, allAccounts));
    } else if (type === 'Expense') {
      const incomeRemoved = newFilteredData.filter(
        (category) => category.name !== 'Income',
      );
      setFilteredData([...incomeRemoved]);
      setStandardData(mergeCategoriesAndAccounts(allCategories, allAccounts));
    } else {
      setFilteredData([...newFilteredData]);
      setStandardData(mergeCategoriesAndAccounts(allCategories, allAccounts));
    }
  };

  const formatInvoices = (invoices) => {
    const formattedInvoices = [
      {
        id: 10,
        name: 'Invoices',
        categories: invoices.map((invoice) => ({
          ...invoice,
          name: `Invoice ${invoice.number || invoice?.invoiceNumber}`,
        })),
      },
    ];
    return formattedInvoices;
  };

  const formatBills = (bills) => {
    const formattedBills = [
      {
        id: 11,
        name: 'Bills',
        categories: bills
          ?.filter(
            (bill) => bill?.status === 'unpaid' || bill?.status === 'partial',
          )
          ?.map((bill) => ({
            ...bill,
            instanceId: bill?.instances?.find(
              (instance) =>
                instance?.status === 'unpaid' || instance?.status === 'partial',
            )?.id,
            name: `${bill?.vendor?.name} - ${getCurrencySymbolFromIso(
              bill?.currency,
              currencies,
            )}${bill?.unpaidAmount}`,
          })),
      },
    ];
    return formattedBills;
  };

  return (
    <div
      className={`relative inline-flex ${isSetCategoryStyle ? 'w-full' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        ref={trigger}
        disabled={accessType === VIEWER || disabled}
        className={
          (addTransactionSplit
            ? 'btn px-4 h-12 py-2.5 justify-between w-full rounded-[0.313rem] font-normal leading-6 bg-white form-input border-slate-600 hover:border-slate-600 dark:border-slate-700 dark:hover:border-slate-600 text-slate-600 text-opacity-60 dark:text-slate-300 dark:hover:text-slate-200 disabled:cursor-not-allowed'
            : isSetCategoryStyle
              ? `btn form-input ${
                  height ? height : 'h-10'
                } px-3 py-[11px] justify-between w-full rounded-[0.313rem] font-normal leading-6 text-base tracking-normal text-slate-600 text-opacity-80 bg-white disabled:cursor-not-allowed ${
                  bulkDropdown && disabled ? '!border-[#66708533]' : ''
                }
              border-[#D0D5DD] hover:border-slate-400  dark:border-slate-700 ${
                isTableDropdown
                  ? `${
                      height ? height : '!h-10'
                    } gap-2 w-full max-w-[170px] xl:max-w-full !py-0 !px-3 border-[#D0D5DD] !font-normal  !text-[#667085]
                   ${
                     dropdownOpen ? '!rounded-none hover:!border-[#D0D5DD]' : ''
                   }`
                  : ''
              }`
              : inlineMenu
                ? dropdownOpen
                  ? 'rounded-full bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400 disabled:cursor-not-allowed'
                  : 'rounded-full text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 disabled:cursor-not-allowed'
                : 'inline-flex justify-center items-center group disabled:cursor-not-allowed') +
          buttonClass
        }
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          if (!readOnly && !dropdownOpen) {
            setDropdownOpen(true);
            setCurrentDropdownId();
            setSearchValue('');
          } else if (!readOnly && dropdownOpen) {
            setDropdownOpen(false);
            setCurrentDropdownId(null);
            setSearchValue('');
          }
          if (flyout && readOnly) {
            handleCategoryClick(e);
          }
        }}
        aria-expanded={dropdownOpen}
        style={isError ? { borderColor: 'rgba(244 ,63, 94, 1)' } : {}}
        type="button"
      >
        {!inlineMenu ? (
          <div
            className={`flex items-center truncate ${
              isSetCategoryStyle ? 'w-full justify-between' : ''
            } ${isTableDropdown ? 'gap-2' : ''}`}
          >
            <span
              className={`truncate font-medium text-indigo-500 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 ${
                isSetCategoryStyle
                  ? 'text-slate-600 font-normal text-base tracking-normal leading-6'
                  : ''
              } ${
                bulkDropdown
                  ? disabled
                    ? '!text-[#66708533] !text-[14px]'
                    : '!text-[14px]'
                  : ''
              }
               ${
                 isSetCategoryStyle && selectedCategoryId === null
                   ? 'text-slate-600 text-opacity-80'
                   : ''
               } ${
                 isSetCategoryStyle && selectedCategoryId === null ? '' : ''
               } ${
                 isTableDropdown
                   ? ' !text-sm !font-normal  !text-[#667085CC] '
                   : ''
               } ${buttonTextClassSub}`}
            >
              {selectedCategoryId === null
                ? placeholderText ||
                  (isSetCategoryStyle
                    ? type === 'account'
                      ? 'Select Account'
                      : type === 'date'
                        ? 'Select Date Range'
                        : 'Select Category'
                    : ' All Categories')
                : getSelectedCategory() || selectedCategoryName}
            </span>
            {!readOnly && (
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`shrink-0 ml-1 stroke-[#667085CC] ${isError ? '!stroke-rose-500 !text-rose-500' : ''} ${
                  dropdownOpen ? 'rotate-180' : ''
                } `}
              >
                <path
                  d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                  className="stroke-current"
                  strokeOpacity={`${bulkDropdown && disabled ? '0.4' : '0.8'}`}
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="stroke-current"
                />
              </svg>
            )}
          </div>
        ) : (
          <>
            <span className="sr-only">Menu</span>
            <svg className="w-5 h-5 fill-current" viewBox="0 0 32 32">
              <circle cx="16" cy="16" r="2" />
              <circle cx="10" cy="16" r="2" />
              <circle cx="22" cy="16" r="2" />
            </svg>
          </>
        )}
      </button>

      <Transition
        className={`${transitionClass} ${
          bottomOfTable ? 'fixed' : 'origin-top-right z-10 absolute top-full'
        } ${flyout ? '' : '!w-fit'} ${
          addTransactionSplit
            ? 'max-w-[150px] sm:max-w-[275px]'
            : split
              ? 'max-w-[150px] sm:max-w-[225px]'
              : flyout
                ? ''
                : 'max-w-[300px] xl:max-w-[400px]'
        } ${
          isTableDropdown ? 'border-t-0' : 'min-w-44  mt-1'
        }  bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded shadow-lg overflow-hidden ${
          align === 'right' ? 'right-0' : 'left-0'
        } ${isSetCategoryStyle ? ' w-full' : ''} ${
          dropdownOpen ? '' : 'hidden'
        } `}
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="flex flex-col py-1.5"
          // onFocus={() => setDropdownOpen(true)}
          // onBlur={() => setDropdownOpen(false)}
        >
          <div className={`relative flex-1 px-3 mb-1`}>
            <input
              type="text"
              placeholder="Search..."
              className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block ${
                isSetCategoryStyle ? ' flex-1 height-fit' : ''
              } `}
              value={searchValue}
              onChange={handleSearch}
              autoFocus
              ref={searchInput}
              onClick={(e) => e.stopPropagation()}
            />
            <svg
              className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                stroke="#E48642"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            className={
              isSetCategoryStyle
                ? 'max-h-40 overflow-y-auto pointer-events-auto scrollbar overflow-x-auto sm:overflow-x-hidden w-full flex flex-col scrollbar pt-2'
                : 'max-h-60 overflow-y-auto pointer-events-auto scrollbar overflow-x-auto sm:overflow-x-hidden w-full pt-2'
            }
            ref={scrollRef}
          >
            {!isSetCategoryStyle && !searchValue && !inlineMenu && (
              <span
                className="font-medium text-sm text-slate-600 text-opacity-60 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  e.stopPropagation();
                  if (selectedCategoryId !== null) {
                    setSelectedCategoryId(null);
                    if (getTransactionsApi !== null) {
                      getTransactionsApi({
                        accountCategories: [],
                        page: 1,
                      });
                    }
                  }
                  setDropdownOpen(false);
                }}
              >
                All Categories
              </span>
            )}
            {(isTableDropdown || flyout) &&
              type !== 'bills' &&
              !refundOptionsOpen &&
              !incomeRefundOptionsOpen &&
              !searchValue &&
              !invoiceOptionsOpen &&
              !billOptionsOpen &&
              !journalEntry && (
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-default mb-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  {!transferOptionsOpen ? (
                    <>
                      <span className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                        Transfers
                      </span>
                      <div className="ml-2 cursor-pointer">
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            setTransferOptionsOpen(true);
                            if (allAccounts) {
                              setFilteredData(allAccounts);
                              setStandardData(
                                mergeCategoriesAndAccounts(
                                  allCategories,
                                  allAccounts,
                                ),
                              );
                            }
                          }}
                          className={`font-normal whitespace-nowrap flex items-center pr-4 text-[14px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3`}
                        >
                          Transfer{' '}
                          {transactionAmount
                            ? transactionAmount > 0
                              ? 'to'
                              : 'from'
                            : 'to'}{' '}
                          a Bank, Credit Card or Loan
                          <svg
                            width="5"
                            height="10"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-1 mt-px"
                          >
                            <path
                              d="M0.9375 1L6.9375 7L0.9375 13"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : (
                    <span
                      role="button"
                      tabIndex={0}
                      className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-indigo-500  py-1 px-3 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTransferOptionsOpen(false);
                        setFilteredData(allCategories);
                        setStandardData(
                          mergeCategoriesAndAccounts(
                            allCategories,
                            allAccounts,
                          ),
                        );
                      }}
                    >
                      {'<'} More Options
                    </span>
                  )}
                </div>
              )}
            {(isTableDropdown || flyout) &&
              selectedCategory?.name !== 'Uncategorized Transfer' &&
              type === 'Income' &&
              type !== 'bills' &&
              !transferOptionsOpen &&
              !searchValue &&
              !invoiceOptionsOpen &&
              !billOptionsOpen && (
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-default mb-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  {!refundOptionsOpen ? (
                    <>
                      <span className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                        Refunds
                      </span>
                      <div className="ml-2 cursor-pointer">
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilteredData([
                              allCategories.find(
                                (item) => item.name === 'Expenses',
                              ),
                            ]);
                            setStandardData(
                              mergeCategoriesAndAccounts(
                                allCategories,
                                allAccounts,
                              ),
                            );
                            setRefundOptionsOpen(true);
                          }}
                          className={`font-normal whitespace-nowrap flex items-center pr-4 text-[14px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3`}
                        >
                          Refund for an Expense
                          <svg
                            width="5"
                            height="10"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-1 mt-px"
                          >
                            <path
                              d="M0.9375 1L6.9375 7L0.9375 13"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : (
                    <span
                      className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-indigo-500  py-1 px-3 cursor-pointer"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRefundOptionsOpen(false);
                        setFilteredData(
                          allCategories.filter(
                            (category) => category.name !== 'Expenses',
                          ),
                        );
                        setStandardData(
                          mergeCategoriesAndAccounts(
                            allCategories,
                            allAccounts,
                          ),
                        );
                      }}
                    >
                      {'<'} More Options
                    </span>
                  )}
                </div>
              )}
            {(isTableDropdown || flyout) &&
              type === 'Expense' &&
              type !== 'bills' &&
              selectedCategory?.name !== 'Uncategorized Transfer' &&
              !transferOptionsOpen &&
              !searchValue &&
              !invoiceOptionsOpen &&
              !billOptionsOpen && (
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-default mb-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  {!incomeRefundOptionsOpen ? (
                    <>
                      <span className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                        Refunds
                      </span>
                      <div className="ml-2 cursor-pointer">
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilteredData([
                              allCategories.find(
                                (item) => item.name === 'Income',
                              ),
                            ]);
                            setStandardData(
                              mergeCategoriesAndAccounts(
                                allCategories,
                                allAccounts,
                              ),
                            );
                            setIncomeRefundOptionsOpen(true);
                          }}
                          className={`font-normal whitespace-nowrap flex items-center pr-4 text-[14px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3`}
                        >
                          Refund on Income
                          <svg
                            width="5"
                            height="10"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-1 mt-px"
                          >
                            <path
                              d="M0.9375 1L6.9375 7L0.9375 13"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : (
                    <span
                      className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-indigo-500  py-1 px-3 cursor-pointer"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIncomeRefundOptionsOpen(false);
                        setFilteredData(
                          allCategories.filter(
                            (category) => category.name !== 'Income',
                          ),
                        );
                        setStandardData(
                          mergeCategoriesAndAccounts(
                            allCategories,
                            allAccounts,
                          ),
                        );
                      }}
                    >
                      {'<'} More Options
                    </span>
                  )}
                </div>
              )}
            {(isTableDropdown || flyout) &&
              type !== 'bills' &&
              type === 'Income' &&
              !transferOptionsOpen &&
              !refundOptionsOpen &&
              !incomeRefundOptionsOpen &&
              !searchValue &&
              invoices?.length > 0 &&
              !billOptionsOpen && (
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-default mb-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  {!invoiceOptionsOpen ? (
                    <>
                      <span className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                        Invoices
                      </span>
                      <div className="ml-2 cursor-pointer">
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilteredData(formatInvoices(invoices));
                            setStandardData(
                              mergeCategoriesAndAccounts(
                                allCategories,
                                allAccounts,
                              ),
                            );
                            setInvoiceOptionsOpen(true);
                          }}
                          className={`font-normal whitespace-nowrap flex items-center pr-4 text-[14px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3`}
                        >
                          Payment Received for an Invoice
                          <svg
                            width="5"
                            height="10"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-1 mt-px"
                          >
                            <path
                              d="M0.9375 1L6.9375 7L0.9375 13"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : (
                    <span
                      className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-indigo-500  py-1 px-3 cursor-pointer"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        setInvoiceOptionsOpen(false);
                        setFilteredData(
                          allCategories.filter(
                            (category) => category.name !== 'Expenses',
                          ),
                        );
                        setStandardData(
                          mergeCategoriesAndAccounts(
                            allCategories,
                            allAccounts,
                          ),
                        );
                      }}
                    >
                      {'<'} More Options
                    </span>
                  )}
                </div>
              )}
            {(isTableDropdown || flyout) &&
              type !== 'bills' &&
              type === 'Expense' &&
              !transferOptionsOpen &&
              !refundOptionsOpen &&
              !incomeRefundOptionsOpen &&
              !searchValue &&
              bills?.length > 0 &&
              !invoiceOptionsOpen && (
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-default mb-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  {!billOptionsOpen ? (
                    <>
                      <span className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                        Bills
                      </span>
                      <div className="ml-2 cursor-pointer">
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilteredData(formatBills(bills));
                            setStandardData(
                              mergeCategoriesAndAccounts(
                                allCategories,
                                allAccounts,
                              ),
                            );
                            setBillOptionsOpen(true);
                          }}
                          className={`font-normal whitespace-nowrap flex items-center pr-4 text-[14px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3`}
                        >
                          Payment for a Bill
                          <svg
                            width="5"
                            height="10"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-1 mt-px"
                          >
                            <path
                              d="M0.9375 1L6.9375 7L0.9375 13"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : (
                    <span
                      className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-indigo-500  py-1 px-3 cursor-pointer"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        setBillOptionsOpen(false);
                        setFilteredData(
                          allCategories.filter(
                            (category) => category.name !== 'Expense',
                          ),
                        );
                        setStandardData(
                          mergeCategoriesAndAccounts(
                            allCategories,
                            allAccounts,
                          ),
                        );
                      }}
                    >
                      {'<'} More Options
                    </span>
                  )}
                </div>
              )}
            {bulkDropdown && (
              <span
                className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C] py-1 px-3 mb-4 cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedCategoryId(null);
                  setDropdownOpen(false);
                }}
              >
                Clear Selected
              </span>
            )}
            {filteredData?.map(
              (group) =>
                group?.name.toLowerCase() !== 'custom' &&
                group?.categories?.length > 0 && (
                  <div
                    key={group.id}
                    className="cursor-default	mb-2"
                    onClick={(e) => e.stopPropagation()}
                    role="button"
                    tabIndex="0"
                  >
                    <span className="font-semibold text-[12px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                      {group?.name}
                    </span>
                    {group?.categories?.map((category) => (
                      <div key={category.id} className="ml-2 cursor-pointer">
                        <ReuseableAccount
                          invoiceOptionsOpen={invoiceOptionsOpen}
                          assignTransactionToInvoice={
                            assignTransactionToInvoice
                          }
                          category={category}
                          billOptionsOpen={billOptionsOpen}
                          assignTransactionToBill={assignTransactionToBill}
                          selectedCategoryId={selectedCategoryId}
                          setSelectedCategoryId={setSelectedCategoryId}
                          handlePopupLogic={handlePopupLogic}
                          transactionName={transactionName}
                          isSetCategoryStyle={isSetCategoryStyle}
                          getTransactionsApi={getTransactionsApi}
                          setDropdownOpen={setDropdownOpen}
                          transferModal={transferModal}
                          transferOptionsOpen={transferOptionsOpen}
                          type={type}
                          currencies={currencies}
                        />
                        {showSubAccount &&
                          category?.subAccounts?.length > 0 && (
                            <div className="pl-4">
                              {category?.subAccounts?.map((subAccount) => (
                                <ReuseableAccount
                                  key={subAccount?.id}
                                  invoiceOptionsOpen={invoiceOptionsOpen}
                                  assignTransactionToInvoice={
                                    assignTransactionToInvoice
                                  }
                                  category={subAccount}
                                  billOptionsOpen={billOptionsOpen}
                                  assignTransactionToBill={
                                    assignTransactionToBill
                                  }
                                  selectedCategoryId={selectedCategoryId}
                                  setSelectedCategoryId={setSelectedCategoryId}
                                  handlePopupLogic={handlePopupLogic}
                                  transactionName={transactionName}
                                  isSetCategoryStyle={isSetCategoryStyle}
                                  getTransactionsApi={getTransactionsApi}
                                  setDropdownOpen={setDropdownOpen}
                                  transferModal={transferModal}
                                  transferOptionsOpen={transferOptionsOpen}
                                  type={type}
                                  currencies={currencies}
                                />
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                ),
            )}
            {!isResultFound && (
              <span className="whitespace-nowrap text-center font-bold pr-4 text-sm text-slate-600 text-opacity-60 dark:text-slate-300 py-1 px-4">
                No Option
              </span>
            )}
          </div>
          {addNewOptionButton && (
            <button
              type="button"
              className="btn h-10 border-b-0 border-x-0 !border-t border-[#D0D5DD]  gap-2 text-indigo-500 text-base w-full cursor-pointer whitespace-nowrap"
              onClick={handleNewOptionButton}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
              >
                <path
                  d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                  stroke="#E48642"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.33337 8H10.6667"
                  stroke="#E48642"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 10.6663V5.33301"
                  stroke="#E48642"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {addNewOptionLabel}
            </button>
          )}
        </div>
      </Transition>
    </div>
  );
}

export default CategoriesDropdown;
