import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getTeamById } from 'API/backend_helper';
import useDropdown from 'hooks/useDropdown';
import Transition from '../../utils/Transition';
import useClickOutside from '../../utils/useClickOutside';
import { Loader } from '../../components/Svg';
import Tooltip from '../../components/Tooltip';

function AssigneeDropdown({
  options,
  selected,
  setSelected,
  name,
  accounts = false,
  categories = false,
  error = false,
  countries = false,
  disabled = false,
  className = '',
  categoryModal = false,
  noSearch = false,
  scrollIntoView = true,
  subType = false,
  pagination = false,
  placeholder,
  accountStyle = false,
  taxes = false,
  addOption = null,
  fontFamily = false,
  height = null,
  bulkDropdown = false,
  search = false,
  flyout = false,
  currency = false,
  users = false,
  inbox = false,
  bottomOfTable = false,
  isTableDropdown = false,
  ids = [],
  addNewOptionButton = false,
  addNewOptionLabel = '',
  handleNewOptionButton = () => {},
  buttonTextClass = '',
  buttonTextClassSub = '',
  selectFullObj = false,
  manualOption = null,
  noMinW = false,
  transitionClass = '',
  handleSearch = null,
  resetSearch = null,
  searchPlaceholder = '',
  searchLoading = false,
  showAccountantAssignee = false,
  teamId = null,
  accountingFirmView = false,
  firmMembers = [],
  isFixedTooltip = false,
}) {
  const [searchValue, setSearchValue] = useState('');
  const searchInput = useRef(null);
  const [clientUsers, setClientUsers] = useState([]);
  const [clientUsersLoading, setClientUsersLoading] = useState(false);

  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(
    isTableDropdown,
    bottomOfTable,
    ids,
    false,
    setSearchValue,
    showAccountantAssignee && clientUsersLoading ? clientUsers : searchValue,
  );

  const loadClientUsers = async (teamId) => {
    setClientUsersLoading(true);
    try {
      const res = await getTeamById(teamId);
      setClientUsers(res?.users);
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientUsersLoading(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      searchInput?.current?.focus();
      if (showAccountantAssignee && teamId) {
        loadClientUsers(teamId);
      }
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (scrollIntoView) {
      if (dropdownOpen && scrollIntoView) {
        dropdown.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (searchValue) {
      setSearchValue('');
    }
  }, [dropdownOpen]);

  const OptionsToMap = ({ options, accountingFirmId = null }) =>
    options?.map((option) =>
      option[name] === 'dotted-line-item' && option.id === 'dotted-line' ? (
        <div key={option.id} className="px-1 my-1">
          <svg
            className="w-full"
            width="400"
            height="2"
            viewBox="0 0 400 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16309 1.5L399.163 0.5"
              stroke="#000000"
              strokeOpacity="0.8"
              strokeLinecap="round"
              strokeDasharray="6 6"
            />
          </svg>
        </div>
      ) : (
        (searchValue && users
          ? `${option.firstName} ${option.lastName}`
              ?.replace(/\s/g, '')
              .toLowerCase()
              ?.includes(searchValue?.replace(/\s/g, '').toLowerCase())
          : true) && (
          <button
            type="button"
            key={accounts ? option[name] : option.id}
            tabIndex="0"
            className={`text-left leading-normal flex border-none items-center gap-2 text-[13px] hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-1 px-3 cursor-pointer ${
              (accounts ? option[name] : option.id) === selected &&
              'text-indigo-500 hover:!text-indigo-600'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setSelected(
                selectFullObj ? option : accounts ? option[name] : option.id,
                option,
                accountingFirmId,
              );
              if (resetSearch) {
                resetSearch(option);
                setSearchValue('');
              }
              setDropdownOpen(false);
            }}
            style={fontFamily ? { fontFamily: option[name] } : {}}
          >
            <span
              className={`text-slate-600 hover:text-slate-800 ${
                users ? 'flex gap-2 items-center text-[14px]' : ''
              } ${
                (accounts ? option[name] : option.id) === selected &&
                '!text-indigo-500 !hover:text-indigo-600'
              }
          ${currency ? 'whitespace-nowrap' : ''}`}
            >
              {countries && (
                <span className="text-lg mr-2 leading-none">
                  {' '}
                  {option.flag}
                </span>
              )}
              {currency && <span className="mr-1">{option.symbol}</span>}
              {users &&
                (option.avatarUrl ? (
                  <img
                    src={option.avatarUrl}
                    alt="avatar"
                    className="w-8 h-8 rounded-[42px] object-contain"
                  />
                ) : (
                  <div className="min-h-8 min-w-8 rounded-[42px] bg-slate-400 text-white text-md flex items-center justify-center">
                    {option?.firstName?.charAt(0)?.toUpperCase() +
                      option?.lastName?.charAt(0)?.toUpperCase()}
                  </div>
                ))}
              {!users ? option[name] : `${option.firstName} ${option.lastName}`}
              {taxes && <span className="ml-1">({+option.percentage}%)</span>}
            </span>
            <svg
              className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                (accounts ? option[name] : option.id) !== selected &&
                'invisible'
              }`}
              width="12"
              height="9"
              viewBox="0 0 12 9"
            >
              <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
            </svg>
          </button>
        )
      ),
    );

  const getSelectedUser = useCallback(() => {
    if (accountingFirmView) {
      if (selected) {
        let selectedUser = null;
        options?.forEach((t) => {
          if (
            t?.users?.find(
              (opt) => (accounts ? opt[name] : opt.id) === selected,
            )
          ) {
            selectedUser = t?.users?.find(
              (opt) => (accounts ? opt[name] : opt.id) === selected,
            );
          }
        });
        return selectedUser;
      }
      return null;
    }
    return options?.find((opt) => (accounts ? opt[name] : opt.id) === selected);
  }, [selected, options, accountingFirmView]);

  return (
    <>
      <div className={`relative inline-flex h-full w-full ${className}`}>
        <button
          type="button"
          ref={trigger}
          className={
            !categoryModal
              ? `${
                  height || 'h-auto'
                } px-3 text-base tracking-normal max-h-full btn font-normal w-full justify-between  ${
                  pagination || noMinW ? '' : 'min-w-44'
                } ${
                  bulkDropdown ? 'py-[11px]' : ''
                } bg-white dark:bg-slate-800 border-[#D0D5DD] dark:border-slate-700 hover:border-slate-400
          dark:hover:border-slate-600 text-slate-600 hover:text-slate-600 dark:text-slate-300
          dark:hover:text-slate-200
          ${error ? '!border-rose-500 !text-rose-500' : ''}
          ${
            bulkDropdown && disabled
              ? '!border-[#66708533] cursor-not-allowed'
              : ''
          }
          ${bulkDropdown ? 'text-[14px]' : ''}
          ${flyout ? 'rounded-[6px]' : ''}
          ${
            accountStyle
              ? 'btn text-base tracking-normal h-12 rounded-[0.313rem] border border-[#D0D5DD] hover:border-slate-400 text-slate-600 leading-6 font-normal px-4 py-3.5 bg-white'
              : ''
          }`
              : 'btn form-input h-12 px-3 py-[11px] rounded-[0.313rem] font-normal leading-6 text-slate-600 border-[#D0D5DD] hover:border-slate-400 flex justify-between w-full bg-white'
          }
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          aria-expanded={dropdownOpen}
          disabled={disabled}
        >
          <Tooltip
            content={(() => {
              const selectedOption = getSelectedUser();
              const fullName = selectedOption
                ? `${selectedOption.firstName} ${selectedOption.lastName}`
                : '';
              return (
                fullName ||
                (subType && disabled && 'Please select a type') ||
                (placeholder && !disabled
                  ? placeholder
                  : manualOption
                    ? null
                    : 'Select...')
              );
            })()}
            tooltipShow={!dropdownOpen && isTableDropdown}
            className={isTableDropdown ? 'text-center w-fit' : 'w-full'}
            contentClassName={`border-none overflow-visible text-sm text-[#667085] relative ${isFixedTooltip ? '' : 'tooltipArrowDown'} `}
            isFixed={isFixedTooltip}
            ids={ids}
          >
            <span
              className={`flex items-center  ${buttonTextClass || ''}`}
              style={{ maxWidth: 'calc(100% - 12px)' }}
            >
              {manualOption && manualOption}
              {users &&
                !manualOption &&
                getSelectedUser() &&
                (getSelectedUser()?.avatarUrl ? (
                  <img
                    src={getSelectedUser()?.avatarUrl}
                    alt="avatar"
                    className="w-8 h-8 rounded-[42px] mr-2 object-contain"
                  />
                ) : (
                  <div className="min-h-8 min-w-8 rounded-[42px] bg-slate-400 text-white text-md flex items-center justify-center mr-2">
                    {getSelectedUser()?.firstName?.charAt?.(0)?.toUpperCase() +
                      getSelectedUser()?.lastName?.charAt?.(0)?.toUpperCase()}
                  </div>
                ))}
              {!isTableDropdown && (
                <span
                  className={`${
                    getSelectedUser()
                      ? 'text-slate-600 whitespace-nowrap overflow-hidden text-ellipsis max-w-full inline-block'
                      : 'text-slate-600 text-opacity-80'
                  } ${selected || ''}
              ${
                bulkDropdown
                  ? disabled
                    ? '!text-[#66708533] !text-[14px]'
                    : '!text-[14px]'
                  : ''
              }${buttonTextClassSub || ''}
              `}
                >
                  {countries && (
                    <span className="text-lg mr-2 leading-none">
                      {' '}
                      {getSelectedUser()?.flag}
                    </span>
                  )}
                  {users
                    ? (() => {
                        const selectedOption = getSelectedUser();
                        const fullName = selectedOption
                          ? `${selectedOption.firstName} ${selectedOption.lastName}`
                          : '';
                        return (
                          fullName ||
                          (subType && disabled && 'Please select a type') ||
                          (placeholder && !disabled
                            ? placeholder
                            : manualOption
                              ? null
                              : 'Select...')
                        );
                      })()
                    : getSelectedUser()?.[name] ||
                      (subType && disabled && 'Please select a type') ||
                      (placeholder && !(subType && disabled) && !manualOption
                        ? placeholder
                        : manualOption
                          ? null
                          : 'Select...')}
                </span>
              )}
            </span>
          </Tooltip>
          {!isTableDropdown && !(disabled && inbox) && (
            <svg
              className={`shrink-0 ml-1 text-[#667085cc] ${
                error ? 'fill-rose-500' : ''
              } ${dropdownOpen ? 'rotate-180' : ''}`}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                stroke="#667085"
                strokeOpacity={`${bulkDropdown && disabled ? '0.4' : '0.8'}`}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`${
            bottomOfTable
              ? 'fixed !w-fit min-w-44 md:-translate-x-1/3'
              : 'origin-top-right z-10 absolute'
          } ${
            pagination && !bulkDropdown ? 'bottom-full' : 'top-full'
          } left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded shadow-lg overflow-hidden mt-1 ${transitionClass || ''}`}
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
          style={
            bottomOfTable
              ? {
                  top: fixedDropdownPositions?.top,
                  left: fixedDropdownPositions?.left,
                  zIndex: '1000',
                }
              : {}
          }
        >
          {dropdownOpen && (
            <div
              ref={dropdown}
              className={`py-1.5 flex flex-col font-medium pointer-events-auto max-h-64 scrollbar text-sm text-slate-600 ${accountStyle ? '' : ''}`}
              // onFocus={() => setDropdownOpen(true)}
              // onBlur={() => setDropdownOpen(false)}
            >
              <div className="relative flex-1 px-3 mb-1">
                <input
                  type="text"
                  placeholder={searchPlaceholder || 'Search...'}
                  className={`form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600 my-2 px-8 w-full inline-block font-normal `}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    if (handleSearch) {
                      handleSearch(e.target.value);
                    }
                  }}
                  autoFocus
                  ref={searchInput}
                  onClick={(e) => e.stopPropagation()}
                />
                <svg
                  className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                    stroke="#E48642"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {searchLoading && (
                  <div className="absolute right-6 top-[50%] -translate-y-[50%]">
                    <Loader height="h-5 " width="w-5" fill="" />
                  </div>
                )}
              </div>

              <div className="overflow-auto max-h-48 scrollbar">
                {clientUsersLoading ? (
                  <div className="w-full flex justify-center h-12 items-center">
                    <Loader width="w-6" height="h-6" />
                  </div>
                ) : (
                  <>
                    {accountingFirmView ? (
                      (showAccountantAssignee
                        ? [
                            {
                              name: 'Client Members',
                              users: clientUsers,
                            },
                            {
                              name: 'Firm Members',
                              users: firmMembers,
                            },
                          ]
                        : options
                      )?.map(
                        (t, i) =>
                          t?.users?.length > 0 && (
                            <div key={i}>
                              {(searchValue && users
                                ? t?.users?.filter((option) =>
                                    `${option.firstName} ${option.lastName}`
                                      ?.replace(/\s/g, '')
                                      .toLowerCase()
                                      ?.includes(
                                        searchValue
                                          ?.replace(/\s/g, '')
                                          .toLowerCase(),
                                      ),
                                  )?.length > 0
                                : true) && (
                                <p
                                  className={`ml-3  mb-1 font-medium text-base ${i > 0 ? 'mt-2.5' : ''}`}
                                >
                                  {t?.name}:
                                </p>
                              )}
                              <OptionsToMap
                                options={t?.users}
                                accountingFirmId={t?.accountingFirmId || null}
                              />
                            </div>
                          ),
                      )
                    ) : (
                      <OptionsToMap
                        options={showAccountantAssignee ? clientUsers : options}
                      />
                    )}
                    {taxes && (
                      <button
                        type="button"
                        tabIndex="0"
                        className="flex border-none items-center gap-2 text-[13px] hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-1 px-3 cursor-pointer"
                        onClick={() => {
                          addOption();
                        }}
                      >
                        <span className="text-slate-600 font-semibold hover:text-slate-800">
                          Add A New Tax
                        </span>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Transition>
      </div>
      {error && <div className=" text-xs mt-1 text-rose-500">{error}</div>}
    </>
  );
}

export default AssigneeDropdown;
