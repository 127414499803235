import React from 'react';

const TobTabs = ({
  activeTab,
  setActiveTab,
  UNMATCHED_RECEIPTS_TAB,
  MATCHED_RECEIPTS_TAB,
}) => (
  <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
    <div
      onClick={() => setActiveTab(UNMATCHED_RECEIPTS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === UNMATCHED_RECEIPTS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Unmatched Receipts
    </div>

    <div
      onClick={() => setActiveTab(MATCHED_RECEIPTS_TAB)}
      className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    activeTab === MATCHED_RECEIPTS_TAB &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
      role="button"
      tabIndex="0"
    >
      Matched Receipts
    </div>
  </div>
);

export default TobTabs;
