import Tooltip from 'components/Tooltip';
import moment from 'moment';
import React from 'react';
import {
  formatValueWithSign,
  getExpenseReportStatusColor,
  getStringSegments,
  trimText,
} from 'utils/Utils';

const ExpenseClaimTable = ({
  generatedReport,
  currencySymbol,
  pdfView = false,
}) => (
  <div className="w-full overflow-auto scrollbar">
    <table
      className={`w-full table-fixed  dark:text-slate-300 ${pdfView ? 'text-sm' : ''}`}
    >
      <thead className="w-full">
        <tr className="w-full px-[2.5rem] h-12 font-semibold false text-[#101828] bg-[#EEEEEE]">
          <th
            className={` whitespace-nowrap  ${pdfView ? 'w-[5rem] max-w-[5rem] px-2' : 'w-[8rem] max-w-[8rem] px-4'}`}
          >
            <div className=" text-left">Date</div>
          </th>
          <th
            className={`whitespace-nowrap ${pdfView ? 'w-[6rem] max-w-[6rem] px-2' : 'w-[10rem] max-w-[10rem] px-4'} `}
          >
            <div className="text-left">Vendor</div>
          </th>
          <th
            className={`whitespace-nowrap ${pdfView ? 'w-[7rem] max-w-[7rem] px-2' : 'w-[8rem] max-w-[8rem] px-4'}`}
          >
            <div className="text-left">Expense Type</div>
          </th>
          <th
            className={` whitespace-nowrap ${pdfView ? 'w-[6rem] max-w-[6rem] px-2' : 'w-[8rem] max-w-[8rem] px-4'}`}
          >
            <div className="text-left">GL Account</div>
          </th>

          <th
            className={` whitespace-nowrap ${pdfView ? 'w-[7rem] max-w-[7rem] px-2' : 'w-[11rem] max-w-[11rem] px-4'} `}
          >
            <div className="text-left">Description</div>
          </th>
          <th
            className={` whitespace-nowrap ${pdfView ? 'w-[6rem] max-w-[6rem] px-2' : 'w-[8rem] max-w-[8rem] px-4'}`}
          >
            <div className="text-left">Amount</div>
          </th>
          <th
            className={`whitespace-nowrap ${pdfView ? 'w-[9rem] max-w-[9rem] px-2' : 'w-[10rem] max-w-[10rem] px-4'} `}
          >
            <div className=" text-left">Status</div>
          </th>
        </tr>
      </thead>
      <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
        {generatedReport?.report?.map((report) => (
          <tr
            key={report?.id}
            className="w-full min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5"
          >
            <td className={`py-2 ${pdfView ? 'px-2' : 'px-4'}`}>
              <div className=" text-left ">
                {moment(report?.Date).format('MMM Do, YYYY')}
              </div>
            </td>
            <td className={`py-2 ${pdfView ? 'px-2' : 'px-4'}`}>
              <div className=" text-left">{report?.Vendor || '--'}</div>
            </td>
            <td className={`py-2 ${pdfView ? 'px-2' : 'px-4'}`}>
              <div className=" text-left">{report?.ExpenseType}</div>
            </td>
            <td className={`py-2 ${pdfView ? 'px-2' : 'px-4'}`}>
              <div className=" text-left">{report?.categoryName}</div>
            </td>
            <td className={`py-2 ${pdfView ? 'px-2' : 'px-4'}`}>
              <div className=" text-left">
                {/* <Tooltip
                  tooltipShow={report?.Description?.length > 40}
                  content={
                    report?.Description?.length > 40
                      ? (() => {
                          const segments = getStringSegments(
                            report?.Description,
                            40,
                          );
                          return segments.map((segment, index) => (
                            <p key={index}>{segment}</p>
                          ));
                        })()
                      : report?.Description
                  }
                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                > */}
                <span className="">{report?.Description || ''}</span>
                {/* </Tooltip> */}
              </div>
            </td>
            <td
              className={`py-2 whitespace-nowrap ${pdfView ? 'px-2' : 'px-4'}`}
            >
              <div className=" text-left">
                {formatValueWithSign(
                  report?.Amount || 0,
                  false,
                  currencySymbol,
                )}
              </div>
            </td>
            <td
              className={`py-2 whitespace-nowrap ${pdfView ? 'px-2' : 'px-4'}`}
            >
              <div
                className={`w-fit  text-center capitalize cursor-default px-3 py-1 rounded-sm  text-sm font-medium
                  ${getExpenseReportStatusColor(report?.Status)}
                `}
              >
                {report?.Status?.replace('Need Review', 'Needs Review')}
              </div>
            </td>
          </tr>
        ))}
        <tr className="w-full   min-h-12 hover:bg-[#a0cd85] hover:bg-opacity-5 text-[#101828] font-semibold">
          <td colSpan={7} className="px-4 py-4">
            <div className="flex justify-between items-center">
              <div className=" text-left ">Total</div>
              <div className="flex gap-6">
                <div className=" text-left">
                  Approved :{' '}
                  {formatValueWithSign(
                    generatedReport?.totals?.totalApprovedAmount || 0,
                    false,
                    currencySymbol,
                  )}
                </div>
                <div className=" text-left">
                  Denied:{' '}
                  {formatValueWithSign(
                    generatedReport?.totals?.totalDeniedAmount || 0,
                    false,
                    currencySymbol,
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default ExpenseClaimTable;
