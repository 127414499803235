import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import Tooltip from '../../../components/Tooltip';
import { sortObjectsArray } from '../../../utils/Utils';

const ReusableAccount = ({
  b,
  setDeleteAccountModalOpen,
  setActiveDeleteAccount,
  setIsEditing,
  setAccountsModalOpen,
  setSelectedAccount,
  setSimpleModalOpen,
  isSubAccount = false,
  setSelectedParentAccount = () => {},
  parent = null,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <tr
      key={b?.id}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`  hover:bg-[#A0CD850F]  ${isSubAccount ? 'h-12' : 'h-20'}`}
    >
      <td
        className={`px-2 first:pl-5 last:pr-5 py-2.5 text-left ${isSubAccount ? '!pl-10' : 'text-sm'}`}
      >
        <div className="flex items-center">
          {b?.institution?.logoUrl && (
            <img
              src={b?.institution?.logoUrl}
              className="w-6 h-6 rounded-full mr-2"
              alt="institute-logo"
            />
          )}
          {b?.name} {b?.mask && <>(...{b?.mask})</>}
        </div>
        {b?.status === 'inactive' && (
          <div className="capitalize  px-1.5 py-0.5 w-fit rounded-md  text-[10px] font-medium text-[#FF4B4B] bg-[#FF4B4B1A]">
            Inactive
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
        <div
          className={`capitalize  px-2.5 py-1 w-fit rounded-md  font-medium text-sm `}
        >
          {b?.type || '--'}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
        {b?.subType?.name}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
        <div className="flex gap-[14px] justify-center">
          {/* Edit Account */}
          {(!b?.editable ||
            b?.editable ||
            b?.subType?.anchorTier === 'PHYSICAL_ACCOUNT') &&
            checkPermission(
              PermissionKeys.MANAGE_ACCOUNTS,
              accessType,
              permissions,
            ) && (
              <>
                <Tooltip
                  isFixed
                  ids={['onBoardingPage', 'onBoardingAccountsTable']}
                  content="Edit Account"
                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative translate-y-2"
                >
                  <svg
                    width="28"
                    height="27"
                    viewBox="0 0 28 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    onClick={() => {
                      if (b?.editable) {
                        setIsEditing(true);
                        setSelectedAccount(b);
                        setSelectedParentAccount(parent);
                        setAccountsModalOpen(true);
                      } else {
                        setSelectedAccount(b);
                        setSelectedParentAccount(parent);
                        setSimpleModalOpen(true);
                      }
                    }}
                  >
                    <path
                      d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
                      fill="white"
                      stroke="#D0D5DD"
                      strokeOpacity="0.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
                      stroke="#667085"
                      strokeOpacity="0.8"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
                      stroke="#667085"
                      strokeOpacity="0.8"
                      strokeWidth="1.3"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
                      stroke="#858D9D"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Tooltip>

                {/* Delete Button  */}
                <Tooltip
                  content={
                    b?.editable ? 'Delete' : "Can't Delete non editable account"
                  }
                  position={b?.editable ? '' : 'left'}
                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                  isFixed
                  ids={['onBoardingPage', 'onBoardingAccountsTable']}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (b?.editable) {
                        setDeleteAccountModalOpen(true);
                        setActiveDeleteAccount(b);
                      }
                    }}
                    className="w-[1.625rem] h-[1.625rem] cursor-pointer disabled:cursor-not-allowed"
                    type="button"
                    disabled={!b?.editable}
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                        fill="white"
                        stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                        strokeOpacity="0.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                        stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                        stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                        stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.693 16.3574H14.8505"
                        stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.1552 13.7656H15.3948"
                        stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </>
            )}
        </div>
      </td>
    </tr>
  );
};

const AccountsTable = ({
  allAccounts,
  setIsEditing,
  setSelectedAccount,
  setAccountsModalOpen,
  setSimpleModalOpen,
  setDeleteAccountModalOpen,
  setActiveDeleteAccount,
  searchVal,
  selectedTypes,
  setSelectedParentAccount = () => {},
}) => {
  const [tableAccounts, setTableAccounts] = useState(allAccounts);
  const [orderDir, setOrderDir] = useState('');
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    setOrderDir('asc');
    setSortBy('name');
    // const tempAccounts = JSON.parse(JSON.stringify(allAccounts || []));
    // const sorted = sortObjectsArray(tempAccounts, 'accountNumber', 'asc');
    setTableAccounts(allAccounts);
  }, [allAccounts]);

  const onSortBy = (colName) => {
    const tempAccounts = JSON.parse(JSON.stringify(allAccounts || []));
    const sorted = sortObjectsArray(
      tempAccounts,
      colName,
      orderDir === 'asc' ? 'desc' : 'asc',
    );
    setTableAccounts(sorted);
    setSortBy(colName);
    if (orderDir === 'asc') {
      setOrderDir('desc');
    } else {
      setOrderDir('asc');
    }
  };

  const FilteredAccounts = useMemo(
    () =>
      tableAccounts?.filter((account) => {
        // Convert accountNumber to string for consistent comparison
        const accountNumberString = account?.accountNumber?.toString() || '';

        // Check if the main account matches the criteria
        const isMainAccountMatch =
          (account?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            accountNumberString.includes(searchVal)) &&
          (selectedTypes.length ? selectedTypes.includes(account?.type) : true);

        // Check if any sub-account matches the criteria
        const isSubAccountMatch = account?.subAccounts?.some((subAccount) => {
          const subAccountNumberString =
            subAccount?.accountNumber?.toString() || '';
          return (
            (subAccount?.name.toLowerCase().includes(searchVal.toLowerCase()) ||
              subAccountNumberString.includes(searchVal)) &&
            (selectedTypes.length
              ? selectedTypes.includes(subAccount?.type)
              : true)
          );
        });

        return isMainAccountMatch || isSubAccountMatch;
      }),
    [tableAccounts, selectedTypes, searchVal],
  );

  return (
    <div
      className="bg-white  mt-5 dark:bg-slate-800 h-full rounded-sm w-full overflow-auto"
      style={{
        maxHeight: 'calc(100dvh - 490px)',
        minHeight: '400px',
      }}
      id="onBoardingAccountsTable"
    >
      <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
        {/* Table header */}
        <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
          <tr>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"
              onClick={() => onSortBy('name')}
            >
              <div className="flex items-center cursor-pointer">
                <div className="font-medium text-left">Name</div>
                {sortBy === 'name' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className={`w-4 h-3 ml-2 ${
                      orderDir === 'asc' ? 'rotate-180' : ''
                    } shrink-0 fill-current  text-slate-400
                        dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                )}
              </div>
            </th>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap flex items-center cursor-pointer"
              onClick={() => onSortBy('type')}
            >
              <div className="font-medium text-left">Type</div>
              {sortBy === 'type' && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                  className={`w-4 h-3 ml-2 ${
                    orderDir === 'asc' ? 'rotate-180' : ''
                  } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                >
                  <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                </svg>
              )}
            </th>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap cursor-pointer"
              onClick={() => onSortBy('subType.name')}
            >
              <div className="flex items-center">
                <div className="font-medium text-left">Sub-Type</div>
                {sortBy === 'subType.name' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className={`w-4 h-3 ml-2 ${
                      orderDir === 'asc' ? 'rotate-180' : ''
                    } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                )}
              </div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell ">
              <div className="font-medium text-center">Actions</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        {FilteredAccounts?.length > 0 ? (
          <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
            {FilteredAccounts?.map((b) => (
              <>
                <ReusableAccount
                  key={b?.id}
                  b={b}
                  setDeleteAccountModalOpen={setDeleteAccountModalOpen}
                  setActiveDeleteAccount={setActiveDeleteAccount}
                  setIsEditing={setIsEditing}
                  setAccountsModalOpen={setAccountsModalOpen}
                  setSelectedAccount={setSelectedAccount}
                  setSimpleModalOpen={setSimpleModalOpen}
                  setSelectedParentAccount={setSelectedParentAccount}
                />
                {b?.subAccounts?.length > 0 &&
                  b?.subAccounts?.map((subAccount) => (
                    <ReusableAccount
                      key={subAccount?.id}
                      b={subAccount}
                      setDeleteAccountModalOpen={setDeleteAccountModalOpen}
                      setActiveDeleteAccount={setActiveDeleteAccount}
                      setIsEditing={setIsEditing}
                      setAccountsModalOpen={setAccountsModalOpen}
                      setSelectedAccount={setSelectedAccount}
                      setSimpleModalOpen={setSimpleModalOpen}
                      isSubAccount
                      parent={b}
                      setSelectedParentAccount={setSelectedParentAccount}
                    />
                  ))}
              </>
            ))}
          </tbody>
        ) : (
          <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
            <tr>
              <td
                colSpan={7}
                className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap"
              >
                <div className="text-center">No Data Found</div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {tableAccounts?.length === 0 && (
        <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
          No Account Found
        </h6>
      )}
    </div>
  );
};

export default AccountsTable;
