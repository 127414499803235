import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { formatNumberGlobally, getCurrencySymbol } from 'utils/Utils';
import {
  assignTransactionToInvoiceBill,
  getInvoices,
} from 'API/backend_helper';
import { toast } from 'react-toastify';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import TextInputDash from 'components/TextInputDash';
import WarningBox from 'components/WarningBox';
import MatchInvoiceTable from './MatchInvoiceTable';

const MatchInvoiceTab = ({
  transaction,
  reloadTransaction,
  reloadTransactions,
  setClose,
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loadInvoice, setLoadInvoice] = useState(true);

  useEffect(() => {
    if (
      transaction?.matchingInvoices?.length === 1 &&
      selectedInvoices?.length === 0
    ) {
      setSelectedInvoices([
        {
          id: transaction?.matchingInvoices?.[0]?.id,
          amountDue: transaction?.matchingInvoices?.[0]?.amountDue,
          amountToPay: transaction?.matchingInvoices?.[0]?.amountDue,
          notes: transaction?.notes || '',
        },
      ]);
    }
  }, [transaction]);

  const onAssingInvoice = async () => {
    try {
      setLoading(true);
      const filteredInvoices = selectedInvoices?.filter(
        (inv) => Math.abs(inv?.amountToPay) > 0,
      );

      const records = filteredInvoices?.map((inv) => ({
        id: inv?.id,
        paymentAmount: Math.abs(inv?.amountToPay),
        notes: inv?.notes,
      }));

      await assignTransactionToInvoiceBill(transaction?.id, {
        records,
        matchingType: 'invoice',
      });
      // await reloadTransaction({});
      toast.success('Transaction assigned to Invoice(s) successfully');
      setLoading(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  const getInvoice = async () => {
    setLoadInvoice(true);
    try {
      const res = await getInvoices({
        type: 'invoice',
        limit: 100000,
        status: ['partial', 'unpaid', 'overdue'],
        currency: transaction?.currency,
        amount: transaction?.split ? Math.abs(transaction?.amount) : '',
        approvalStatus: 'approved',
      });
      const filteredInvoices = res?.data?.invoices?.filter(
        (invoice) =>
          !transaction?.matchingInvoices?.some(
            (matchingInvoice) => matchingInvoice?.id === invoice?.id,
          ),
      );
      setInvoices(filteredInvoices);
      setLoadInvoice(false);
    } catch (e) {
      setLoadInvoice(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (!transaction?.invoiceId) {
      getInvoice();
    } else {
      setLoadInvoice(false);
    }
  }, [transaction?.invoiceId]);

  const AmountLeft = () => {
    let amount = 0;
    if (selectedInvoices?.length > 0) {
      selectedInvoices?.forEach((inv) => {
        amount += parseFloat(inv?.amountToPay);
      });
      return formatNumberGlobally(
        Math.abs(transaction?.amount) - Math.abs(amount),
        true,
      );
    }
    return Math.abs(transaction?.amount);
  };

  const onSelectInvoice = (invoice) => {
    let temp = JSON.parse(JSON.stringify(selectedInvoices));
    const find = selectedInvoices?.find((inv) => inv?.id === invoice?.id);
    if (find) {
      temp = temp?.filter((inv) => inv?.id !== invoice?.id);
      setSelectedInvoices(temp);
    } else {
      if (transaction?.split) {
        temp = [
          {
            id: invoice?.id,
            amountDue: invoice?.amountDue,
            amountToPay: parseFloat(Math.abs(transaction?.amount))?.toFixed(2),
            notes: '',
          },
        ];
      } else {
        const amount = AmountLeft();
        temp.push({
          id: invoice?.id,
          amountDue: invoice?.amountDue,
          amountToPay:
            Math.abs(amount) >= Math.abs(invoice?.amountDue)
              ? parseFloat(Math.abs(invoice?.amountDue))?.toFixed(2)
              : parseFloat(Math.abs(amount))?.toFixed(2),
          notes: '',
        });
      }
      setSelectedInvoices(temp);
    }
  };

  const onAmountChange = (value, invoice) => {
    if (!value?.toString()?.match(/^[-]?\d*\.?\d{0,2}$/)) {
      return;
    }
    const findIndex = selectedInvoices?.findIndex(
      (inv) => inv?.id === invoice?.id,
    );
    if (
      (findIndex >= 0 &&
        Math.abs(parseFloat(value)) <= selectedInvoices[findIndex]?.amountDue &&
        Math.abs(parseFloat(value)) <= Math.abs(transaction?.amount)) ||
      !value
    ) {
      const temp = JSON.parse(JSON.stringify(selectedInvoices));
      const tempObj = { ...temp[findIndex] };
      tempObj.amountToPay = value;
      temp[findIndex] = tempObj;
      setSelectedInvoices(temp);
    }
  };

  const onReferenceChange = (value, invoice) => {
    const findIndex = selectedInvoices?.findIndex(
      (inv) => inv?.id === invoice?.id,
    );
    if (findIndex >= 0) {
      const temp = JSON.parse(JSON.stringify(selectedInvoices));
      const tempObj = { ...temp[findIndex] };
      tempObj.notes = value;
      temp[findIndex] = tempObj;
      setSelectedInvoices(temp);
    }
  };

  return (
    <div>
      <div
        className="px-10 flex flex-col "
        style={{
          maxHeight: 'calc(100dvh - 308px)',
          height: 'calc(100dvh - 308px)',
        }}
      >
        {((AmountLeft() > 0 &&
          AmountLeft()?.toFixed(2) !==
            Math.abs(transaction?.amount)?.toFixed(2)) ||
          AmountLeft() < 0) && (
          <div className="pb-4">
            {/* Show this as Info */}
            {AmountLeft() > 0 &&
              AmountLeft()?.toFixed(2) !==
                Math.abs(transaction?.amount)?.toFixed(2) && (
                <div className="text-[#78BD4F] border border-[#78BD4F] bg-[#78BD4F] bg-opacity-5 py-1 px-4 rounded-[5px]">
                  <div>
                    <span className="font-medium">
                      {' '}
                      Amount to Pay from Transaction:{' '}
                    </span>
                    {getCurrencySymbol(transaction?.currency)}
                    {formatNumberGlobally(
                      parseFloat(Math.abs(transaction?.amount) - AmountLeft()),
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <div>
                      <span className="font-medium"> Amount Remaining: </span>
                      {getCurrencySymbol(transaction?.currency)}
                      {formatNumberGlobally(AmountLeft())}{' '}
                    </div>
                    <Tooltip
                      content={
                        <>
                          <p>Remaining amount will be created</p>
                          <p>as a split transaction</p>
                        </>
                      }
                      contentClassName="text-center rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                      position="right"
                      className="w-fit"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                      >
                        <path
                          d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                          stroke="#78BD4F"
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                          stroke="#78BD4F"
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                          fill="#78BD4F"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                </div>
              )}
            {/* Show this as error/warning */}
            {AmountLeft() < 0 && (
              <div className="text-rose-500 border border-rose-500 bg-rose-100 py-1 px-4 rounded-[5px]">
                <div>You paid more than the actual amount</div>
                <div>
                  <span className="font-medium"> Transaction Amount:</span>{' '}
                  {formatNumberGlobally(Math.abs(transaction?.amount))}
                </div>
                <div>
                  <span className="font-medium"> Amount Used: </span>{' '}
                  {formatNumberGlobally(
                    parseFloat(
                      Math.abs(AmountLeft()) + Math.abs(transaction?.amount),
                    ),
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {transaction?.split && (
          <WarningBox
            className="mb-2"
            content="Split transactions cannot be further divided and must be fully allocated to an invoice; they cannot be assigned to invoices with a due amount lower than the transaction."
          />
        )}
        <p className="mb-4">
          Link this transaction to one or more specific invoices here. This
          keeps your receivables in sync and simplifies tracking payments.
        </p>
        <div className="flex-1 h-full flex flex-col mb-6 overflow-auto scrollbar">
          {transaction?.matchingInvoices?.length > 0 && (
            <>
              <MatchInvoiceTable
                invoices={transaction?.matchingInvoices}
                onSelectInvoice={onSelectInvoice}
                selectedInvoices={selectedInvoices}
                onAmountChange={onAmountChange}
                isSplit={transaction?.split}
                onReferenceChange={onReferenceChange}
              />
              {/* <div className="grid md:grid-cols-2 gap-4 pb-4 pr-2">
                {transaction?.matchingInvoices?.map((invoice) => (
                  <InvoiceBox
                    invoice={invoice}
                    key={invoice?.id}
                    selectedInvoices={selectedInvoices}
                    onSelectInvoice={onSelectInvoice}
                    onAmountChange={onAmountChange}
                    isSplit={transaction?.split}
                  />
                ))}
              </div> */}
            </>
          )}
          {invoices?.length > 0 &&
            transaction?.matchingInvoices?.length > 0 && (
              <div className="w-full min-h-[2px] pr-1  mb-6">
                <svg
                  width="1100"
                  height="2"
                  viewBox="0 0 1100 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-indigo-500 w-full h-[2px]"
                >
                  <path
                    d="M5 1.5L1100 0.5"
                    stroke="#E48642"
                    strokeOpacity="0.8"
                    strokeLinecap="round"
                    strokeDasharray="7 7"
                  />
                </svg>
              </div>
            )}

          {!invoices?.length > 0 && loadInvoice && (
            <div
              className="flex flex-col gap-2 items-center pb-2 justify-center mt-8"
              style={{
                maxHeight: 'calc(100dvh - 223px)',
                height: 'calc(100dvh - 223px)',
              }}
            >
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          )}

          {!invoices?.length > 0 &&
            !transaction?.matchingInvoices?.length &&
            !loadInvoice && (
              <div
                className="text-[18px] text-slate-500 text-center  flex items-center justify-center"
                style={{
                  maxHeight: 'calc(100dvh - 223px)',
                  height: 'calc(100dvh - 223px)',
                }}
              >
                No Matching Invoice found
              </div>
            )}

          {invoices?.length > 0 && (
            <>
              <MatchInvoiceTable
                invoices={invoices}
                onSelectInvoice={onSelectInvoice}
                selectedInvoices={selectedInvoices}
                onAmountChange={onAmountChange}
                isSplit={transaction?.split}
                onReferenceChange={onReferenceChange}
              />
              {/* <div
                className={`grid md:grid-cols-2 gap-4 pr-2 ${transaction?.matchingInvoices?.length > 0 ? 'pt-4' : ''}`}
              >
                {invoices?.map((invoice) => (
                  <InvoiceBox
                    invoice={invoice}
                    key={invoice?.id}
                    selectedInvoices={selectedInvoices}
                    onSelectInvoice={onSelectInvoice}
                    onAmountChange={onAmountChange}
                    isSplit={transaction?.split}
                  />
                ))}
              </div> */}
            </>
          )}
        </div>
      </div>

      <footer className="px-10 py-6 border-t border-[#D0D5DD]">
        <div className="flex gap-4 justify-between items-center flex-wrap">
          {/* <p className="text-[#667085]">Remaining Amount: $100</p> */}
          <div className="flex gap-4 ml-auto">
            <button
              type="button"
              onClick={() => setClose(null)}
              className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085]"
            >
              Close
            </button>
            {(transaction?.matchingInvoices?.length > 0 ||
              invoices?.length > 0) && (
              <Tooltip
                content={`${
                  !selectedInvoices?.length
                    ? 'Select an Invoice to proceed'
                    : parseFloat(AmountLeft() ?? 0)?.toFixed(2) ===
                        Math.abs(transaction?.amount)?.toFixed(2)
                      ? 'Please assign an amount greater than zero to the invoice(s)'
                      : parseFloat(AmountLeft() ?? 0) < 0
                        ? 'Assigned amount to invoice(s) is greater than Transaction amount'
                        : ''
                }`}
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown min-w-[200px] text-wrap"
                position="top"
                size="sm"
                tooltipShow={
                  !selectedInvoices?.length ||
                  parseFloat(AmountLeft() ?? 0) < 0 ||
                  parseFloat(AmountLeft() ?? 0)?.toFixed(2) ===
                    Math.abs(transaction?.amount)?.toFixed(2)
                }
              >
                <button
                  type="button"
                  disabled={
                    !selectedInvoices?.length ||
                    parseFloat(AmountLeft() ?? 0) < 0 ||
                    loading ||
                    parseFloat(AmountLeft() ?? 0)?.toFixed(2) ===
                      Math.abs(transaction?.amount)?.toFixed(2)
                  }
                  onClick={onAssingInvoice}
                  className="h-11 px-7 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:cursor-not-allowed "
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Apply Payment to Invoice
                  {/* Apply Payment to Invoice(s) */}
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MatchInvoiceTab;
