import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import TextInputDash from '../../components/TextInputDash';
import DropdownFull from '../../components/DropdownFull';
import {
  getCurrencySymbol,
  getCurrencySymbolFromIso,
  hasUpToTwoDecimalPlaces,
  PEOPLE_TYPES,
  roundToTwo,
  TEAM_CURRENCY,
  transformAccounts,
} from '../../utils/Utils';
import WarningBox from '../../components/WarningBox';
import FormBox from './FormBox';
import ExpenseReportingAccountsModal from './ExpenseRepAccountsModal';
import {
  createExpenseReporter,
  createSubPaymentMethod,
  fetchSubscriptions,
  getAccounts,
  getAccountsByExpenseReporter,
} from '../../API/backend_helper';
import AlertBox from '../../components/AlertBox';
import AddressBar from '../../components/AddressBar';

const PeopleForm = ({
  setActivePeople = () => {},
  activePeople = null,
  handleAddPeople,
  handleUpdatePeople = null,
  setFormOpen,
  setTitle = () => {},
  isModalView = false,
  preSelectExpenseReport = false,
}) => {
  const { currencies } = useSelector((state) => state.Currencies);
  const { subscription } = useSelector((state) => state.Subscription);
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const formikRef = useRef(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const createPersonParam = params.get('addPerson') || false;
  const _firstName = decodeURI(params.get('firstName') || '');
  const _middleName = decodeURI(params.get('middleName') || '');
  const _lastName = decodeURI(params.get('lastName') || '');
  const _email = decodeURI(params.get('email') || '');
  const _position = decodeURI(params.get('position') || '');
  const _type = decodeURI(params.get('type') || 'Employee');
  const _phone = decodeURI(params.get('phone') || '');
  const _mainPhone = decodeURI(params.get('mainPhone') || '');
  const _street = decodeURI(params.get('street') || '');
  const _street2 = decodeURI(params.get('street2') || '');
  const _state = decodeURI(params.get('state') || '');
  const _country = decodeURI(params.get('country') || '');
  const _city = decodeURI(params.get('city') || '');
  const _zipCode = decodeURI(params.get('zipCode') || '');
  const _role = decodeURI(params.get('roles') || '');
  const _paymentUnit = decodeURI(params.get('paymentUnit') || '');
  const _rate = decodeURI(params.get('rate') || '');
  const _legalName = decodeURI(params.get('legalName') || '');
  const _businessName = decodeURI(params.get('businessName') || '');
  const _taxNumber = decodeURI(params.get('taxNumber') || '');
  const _federalTaxClasiffication = decodeURI(
    params.get('federalTaxClasiffication') || '',
  );
  const _taxType = decodeURI(params.get('taxType') || 'none');
  const _addToContractorGroup = decodeURI(
    params.get('addToContractorGroup') || 'none',
  );

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [expenseModal, setExpenseModal] = useState(false);
  const [peopleSelectedExpAcc, setPoepleSelectExpAcc] = useState([]);
  const [expenseReporterAccounts, setExpenseReporterAccounts] = useState([]);
  const [notAssignedAccounts, setNotAssignedAccounts] = useState([]);
  const [localSelectedAccounts, setLocalSelectedAccounts] = useState([]);
  const [subscriptions, setSubscriptions] = useState(null);
  const [paymentMethodLoader, setPaymentMehtodLoader] = useState(false);
  const [loadingSubsription, setLoadingSubscription] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  useEffect(() => {
    if (createPersonParam === 'true' && formikRef?.current?.setFieldValue) {
      formikRef?.current?.setFieldValue('firstName', _firstName);
      formikRef?.current?.setFieldValue('lastName', _lastName);
      formikRef?.current?.setFieldValue('email', _email);
      formikRef?.current?.setFieldValue('position', _position);
      formikRef?.current?.setFieldValue('type', _type);
      formikRef?.current?.setFieldValue('phone', _phone);
      formikRef?.current?.setFieldValue('address.street', _street);
      formikRef?.current?.setFieldValue('address.street2', _street2);
      formikRef?.current?.setFieldValue('address.state', _state);
      formikRef?.current?.setFieldValue('address.country', _country);
      formikRef?.current?.setFieldValue('address.city', _city);
      formikRef?.current?.setFieldValue('address.zipCode', _zipCode);
      formikRef?.current?.setFieldValue('middleName', _middleName);
      formikRef?.current?.setFieldValue('mainPhone', _mainPhone);
      formikRef?.current?.setFieldValue('paymentUnit', _paymentUnit);
      formikRef?.current?.setFieldValue('rate', _rate);
      formikRef?.current?.setFieldValue('legalName', _legalName);
      formikRef?.current?.setFieldValue('businessName', _businessName);
      formikRef?.current?.setFieldValue('taxNumber', _taxNumber);
      formikRef?.current?.setFieldValue(
        'federalTaxClasiffication',
        _federalTaxClasiffication,
      );
      formikRef?.current?.setFieldValue('taxType', _taxType);
      formikRef?.current?.setFieldValue(
        'addToContractorGroup',
        Boolean(_addToContractorGroup || ''),
      );
      if (_role) {
        const tempRole = _role ? JSON.parse(_role) : [];
        setRole(tempRole);
      }
    }
  }, [createPersonParam, formikRef?.current?.setFieldValue]);

  const loadAccountsByExpenseReporter = async () => {
    try {
      const accountsRes = await getAccountsByExpenseReporter(activePeople?.id);
      const accounts = [
        ...accountsRes?.data?.myAccounts,
        ...accountsRes?.data?.notAssignedAccounts,
      ];
      if (accountsRes?.data?.myAccounts?.length > 0) {
        setPoepleSelectExpAcc(
          accountsRes?.data?.myAccounts?.map((acc) => acc?.id),
        );
      }
      setAllAccounts(accounts);
      if (accountsRes) {
        setExpenseReporterAccounts(
          transformAccounts(accounts, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadSubscriptions = async () => {
    try {
      setLoadingSubscription(true);
      const res = await fetchSubscriptions();
      setSubscriptions(res?.data?.perSeat);
      setLoadingSubscription(false);
    } catch (err) {
      setLoadingSubscription(false);
      console.log('err', err);
    }
  };

  const loadNotAssignedAccounts = async () => {
    try {
      const accountsRes = await getAccounts({ notAssignedToReporter: true });
      if (accountsRes) {
        setAllAccounts(accountsRes);
        setNotAssignedAccounts(
          transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (team?.featureExpenseReport) {
      if (!activePeople?.id) {
        loadNotAssignedAccounts();
      } else {
        loadAccountsByExpenseReporter();
      }
    }
  }, [activePeople, team]);

  useEffect(() => {
    if (preSelectExpenseReport) {
      setRole(['expense reporting']);
    }
  }, [preSelectExpenseReport]);

  useEffect(() => {
    if (activePeople?.role) {
      setRole(activePeople?.role);
    }
  }, [activePeople]);

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const handleSubmit = async (values) => {
    const temp = { ...values };
    temp.role = preSelectExpenseReport
      ? role?.filter((r) => r !== 'expense reporting')
      : role;
    if (team?.country === 'US') {
      temp.taxInfo = {
        legalName: temp?.legalName || '',
        businessName: temp?.businessName || '',
        taxNumber: temp?.taxNumber || '',
        federalTaxClasiffication: temp?.federalTaxClasiffication || '',
        taxType: temp?.taxType || 'none',
        addToContractorGroup: temp?.addToContractorGroup || false,
      };
    }
    delete temp.legalName;
    delete temp.businessName;
    delete temp.taxNumber;
    delete temp.federalTaxClasiffication;
    delete temp.addToContractorGroup;
    try {
      setLoading(true);
      if (activePeople) {
        await handleUpdatePeople(temp, activePeople?.id);
        if (role?.includes('expense reporting')) {
          await createExpenseReporter(activePeople?.id, {
            accountIDs: peopleSelectedExpAcc,
          });
          setLocalSelectedAccounts([]);
          setPoepleSelectExpAcc([]);
        }
      } else {
        if (role?.includes('expense reporting') && !preSelectExpenseReport) {
          temp.accountIDs = peopleSelectedExpAcc;
          temp.addToExpenseReporter = true;
        }
        await handleAddPeople(temp);
        setLocalSelectedAccounts([]);
        setPoepleSelectExpAcc([]);
      }
      setLoading(false);
      if (activePeople) {
        toast.success('Person updated successfully');
      } else {
        toast.success('Person created successfully');
      }
      setTitle('People & Contractors');
      formikRef.current?.resetForm();
      setRole([]);
      setFormOpen(false);
      if (createPersonParam === 'true') {
        navigate('/people');
      }
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  const onRoleChange = (val) => {
    const tempRole = [...role];
    if (tempRole?.includes(val)) {
      if (val === 'expense reporting' && expenseModal) {
        setExpenseModal(false);
      }
      const filter = tempRole?.filter((r) => r !== val);
      setRole(filter);
    } else {
      if (val === 'expense reporting' && !expenseModal) {
        setExpenseModal(true);
      }
      tempRole?.push(val);
      setRole(tempRole);
    }
  };

  const onConnectPaymentMethod = async () => {
    try {
      setPaymentMehtodLoader(true);
      const v = formikRef?.current?.values;
      const redirectUrl = `${window?.location?.href}?addPerson=true&firstName=${encodeURIComponent(v?.firstName || '')}&lastName=${encodeURIComponent(v?.lastName || '')}&email=${encodeURIComponent(v?.email || '')}&position=${encodeURIComponent(v?.position || '')}&type=${encodeURIComponent(v?.type || '')}&phone=${encodeURIComponent(v?.phone || '')}&street=${encodeURIComponent(v?.address?.street || '')}&street2=${encodeURIComponent(v?.address?.street2 || '')}&country=${encodeURIComponent(v?.address?.country || '')}&city=${encodeURIComponent(v?.address?.city || '')}&state=${encodeURIComponent(v?.address?.state || '')}&zipCode=${encodeURIComponent(v?.address?.zipCode || '')}&roles=${JSON.stringify(role)}&middleName=${encodeURIComponent(v?.middleName || '')}&mainPhone=${encodeURIComponent(v?.mainPhone || '')}&paymentUnit=${encodeURIComponent(v?.paymentUnit || '')}&rate=${encodeURIComponent(v?.rate || '')}&legalName=${encodeURIComponent(v?.legalName || '')}&businessName=${encodeURIComponent(v?.businessName || '')}&taxNumber=${encodeURIComponent(v?.taxNumber || '')}&federalTaxClasiffication=${encodeURIComponent(v?.federalTaxClasiffication || '')}&taxType=${encodeURIComponent(v?.taxType || '')}&addToContractorGroup=${encodeURIComponent(v?.addToContractorGroup || '')}`;
      const res = await createSubPaymentMethod(encodeURIComponent(redirectUrl));
      if (res?.data?.session?.url) {
        window.location = res?.data?.session?.url;
      } else {
        toast.error(
          "'Oops! Something unexpected occurred. Please contact support for assistance.",
        );
      }
      // setSubscriptions(res?.data?.perSeat);
      setPaymentMehtodLoader(false);
    } catch (err) {
      setPaymentMehtodLoader(false);
      console.log('err', err);
    }
  };

  const getAccountNameById = (id) => {
    const filter = allAccounts?.filter?.((acc) => acc?.id === id);
    if (filter?.length > 0) {
      return filter?.[0]?.name;
    }
    return '';
  };

  useEffect(() => {
    if (
      !expenseModal &&
      role?.includes('expense reporting') &&
      !preSelectExpenseReport &&
      peopleSelectedExpAcc?.length === 0
    ) {
      onRoleChange('expense reporting');
      setLocalSelectedAccounts([]);
      setPoepleSelectExpAcc([]);
    }
  }, [expenseModal]);

  if (loadingSubsription) {
    return (
      <div
        className={`flex flex-col gap-2 items-center pb-2 justify-center mt-8 min-h-fit ${isModalView ? '!my-8 !py-4' : ''}`}
      >
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animate-spin w-10 h-10 fill-current shrink-0"
        >
          <path
            d="M14.5 2.41699V7.25033"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 21.749V26.5824"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 5.95703L9.37662 9.37662"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 19.6211L23.0426 23.0407"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.41699 14.5H7.25033"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.749 14.5H26.5824"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 23.0407L9.37662 19.6211"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 9.37662L23.0426 5.95703"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
          Fetching data, please wait...
        </p>
      </div>
    );
  }

  return (
    <>
      <ExpenseReportingAccountsModal
        setOpen={setExpenseModal}
        open={expenseModal}
        activeReporterId={activePeople?.id}
        peopleSelectedExpAcc={peopleSelectedExpAcc}
        setPoepleSelectExpAcc={setPoepleSelectExpAcc}
        expenseReporterAccounts={expenseReporterAccounts}
        setExpenseReporterAccounts={setExpenseReporterAccounts}
        notAssignedAccounts={notAssignedAccounts}
        setNotAssignedAccounts={setNotAssignedAccounts}
        localSelectedAccounts={localSelectedAccounts}
        setLocalSelectedAccounts={setLocalSelectedAccounts}
      />
      <Formik
        // enableReinitialize
        initialValues={{
          firstName: activePeople?.firstName || '',
          lastName: activePeople?.lastName || '',
          middleName: activePeople?.middleName || '',
          email: activePeople?.email || '',
          position: activePeople?.position || '',
          type: activePeople?.type || 'Employee',
          phone: activePeople?.phone || '',
          mainPhone: activePeople?.mainPhone || '',
          socialSecurityNumber: activePeople?.socialSecurityNumber || '',
          paymentUnit:
            activePeople?.paymentUnit ||
            activePeople?.jobs?.[0]?.paymentUnit ||
            '',
          rate: +activePeople?.rate || +activePeople?.jobs?.[0]?.rate || '',
          address: {
            street: activePeople?.address?.street || '',
            street2: activePeople?.address?.street2 || '',
            country:
              activePeople?.address?.country ||
              (!activePeople?.id ? team?.country || '' : ''),
            city: activePeople?.address?.city || '',
            state: activePeople?.address?.state || '',
            zipCode: activePeople?.address?.zipCode || '',
          },
          legalName:
            activePeople?.legalName || activePeople?.vendor?.legalName || '',
          businessName:
            activePeople?.businessName ||
            activePeople?.vendor?.businessName ||
            '',
          taxNumber:
            activePeople?.taxNumber || activePeople?.vendor?.taxNumber || '',
          federalTaxClasiffication:
            activePeople?.federalTaxClasiffication ||
            activePeople?.vendor?.federalTaxClasiffication ||
            '',
          taxType:
            activePeople?.taxType || activePeople?.vendor?.taxType || 'none',
          addToContractorGroup: activePeople?.addToContractorGroup || false,
        }}
        validate={(values) => {
          const errors = {};
          const { phone, paymentUnit, rate, mainPhone } = values;
          // if (paymentUnit && (!rate || !rate < 0)) {
          //   errors.rate = 'Please enter wage';
          // }
          if (phone && !isValidPhoneNumber(phone)) {
            errors.phone = 'Mobile number is not valid';
          }
          if (mainPhone && !isValidPhoneNumber(mainPhone)) {
            errors.mainPhone = 'Home phone number is not valid';
          }

          return errors;
        }}
        validationSchema={Yup.object({
          email:
            role?.length === 0
              ? Yup.string()
                  .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    'Please enter valid email',
                  )
                  .max(100, 'Email must be at most 100 characters')
              : Yup.string()
                  .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    'Please enter valid email',
                  )
                  .required('Please Enter Email')
                  .max(100, 'Email must be at most 100 characters'),
          firstName: Yup.string()
            .trim()
            .required('Please Enter First Name')
            .min(3, 'First Name must be at least 3 characters')
            .max(20, 'First Name must be at most 20 characters'),
          middleName: Yup.string()
            .trim()
            .optional('Please Enter Middle Name')
            .min(1, 'Middle Name must be at least 1 characters')
            .max(20, 'Middle Name must be at most 20 characters'),
          lastName: Yup.string()
            .trim()
            .required('Please Enter Last Name')
            .min(3, 'Last Name must be at least 3 characters')
            .max(20, 'Last Name must be at most 20 characters'),
          socialSecurityNumber: Yup.string()
            .trim()
            .optional('Please Enter Last Name')
            .min(3, 'SSN must be at least 3 characters')
            .max(20, 'SSN must be at most 20 characters'),
          type: Yup.string().required('Please Select Type'),
          // phone: Yup.string()
          // .matches(phoneRegex, 'Phone number is not valid')
          // .nullable().optional(),
          position: Yup.string()
            .optional()
            .min(3, 'Position must be at least 3 characters')
            .max(30, 'Position must be at most 30 characters'),
          rate: Yup.number().typeError('Wage should be a number'),
          address: Yup.object({
            street: Yup.string().max(100, 'Max 100 characters are allowed'),
            street2: Yup.string().max(100, 'Max 100 characters are allowed'),
            country: Yup.string().max(50, 'Max 50 characters are allowed'),
            city: Yup.string().max(50, 'Max 50 characters are allowed'),
            state: Yup.string().max(50, 'Max 50 characters are allowed'),
            zipCode: Yup.string().max(50, 'Max 50 characters are allowed'),
          }),
          legalName: Yup.string()
            .optional()
            .min(3, 'Legal Name must be at least 3 characters')
            .max(30, 'Legal Name must be at most 30 characters'),
          businessName: Yup.string()
            .optional()
            .min(3, 'Business Name must be at least 3 characters')
            .max(30, 'Business Name must be at most 30 characters'),
          taxNumber: Yup.string()
            .optional()
            .min(3, 'Tax Number must be at least 3 characters')
            .max(30, 'Tax Number must be at most 30 characters'),
          federalTaxClasiffication: Yup.string()
            .optional()
            .min(3, 'Tax Classification must be at least 3 characters')
            .max(30, 'Tax Classification must be at most 30 characters'),
          taxType: Yup.string()
            .optional()
            .min(3, 'Tax Type must be at least 3 characters')
            .max(30, 'Tax Type must be at most 30 characters'),
          addToContractorGroup: Yup.bool().optional(),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            {activePeople?.provider === 'gusto' && (
              <WarningBox
                content={`
                You can only edit the Phone Number and Role of the ${activePeople?.provider?.charAt(0)?.toUpperCase() + activePeople?.provider?.slice(1)} imported person.`}
              />
            )}
            <div
              className={`flex flex-col items-start gap-2.5 w-[100%] ${
                isModalView ? 'p-8' : ''
              } ${activePeople?.provider ? 'mt-6' : ''}`}
            >
              <div className="grid grid-cols-12 gap-4 w-full gap-y-10">
                {activePeople?.provider !== 'gusto' && (
                  <>
                    <div className="col-span-full md:col-span-4 hidden md:block">
                      <div className="pr-4 flex flex-col gap-2 ">
                        <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                          Basic Details:<span className="text-rose-500">*</span>
                        </h3>
                        <p className="text-[#6D7585] text-sm">
                          Enter the individual's essential information, such as
                          their first and last name, email address, and phone
                          number. This helps identify the person and enables
                          easy communication.
                        </p>
                      </div>
                    </div>
                    <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                      <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                        Basic Details: <span className="text-rose-500">*</span>
                      </h3>
                      <div className="col-span-full sm:col-span-6 xl:col-span-4">
                        <TextInputDash
                          label="First Name"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          value={validation.values.firstName || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-5"
                          required
                          error={
                            validation.touched.firstName &&
                            validation.errors.firstName
                          }
                          disabled={activePeople?.provider}
                        />
                      </div>
                      <div className="col-span-full sm:col-span-6 xl:col-span-4">
                        <TextInputDash
                          label="Middle Name"
                          id="middleName"
                          name="middleName"
                          placeholder="Middle Name"
                          value={validation.values.middleName || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-5"
                          // required
                          error={
                            validation.touched.middleName &&
                            validation.errors.middleName
                          }
                          disabled={activePeople?.provider}
                        />
                      </div>
                      <div className="col-span-full sm:col-span-6 xl:col-span-4">
                        <TextInputDash
                          label="Last Name"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          value={validation.values.lastName || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-5"
                          error={
                            validation.touched.lastName &&
                            validation.errors.lastName
                          }
                          required
                          disabled={activePeople?.provider}
                        />
                      </div>
                      <div className="col-span-full grid grid-cols-12 gap-4">
                        <div className="col-span-full sm:col-span-6 xl:col-span-4">
                          <TextInputDash
                            label="Email Address"
                            id="email"
                            name="email"
                            required={role?.length > 0}
                            placeholder="Email Address"
                            value={validation.values.email || ''}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            paddingBottom="pb-5"
                            error={
                              validation.touched.email &&
                              validation.errors.email
                            }
                            disabled={activePeople?.provider}
                          />
                        </div>
                      </div>
                      {/* <div className="col-span-full sm:col-span-6">
                        <TextInputDash
                          label="SSN"
                          id="socialSecurityNumber"
                          name="socialSecurityNumber"
                          // required
                          placeholder="SSN"
                          value={validation.values.socialSecurityNumber || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          paddingBottom="pb-5"
                          error={
                            validation.touched.socialSecurityNumber &&
                            validation.errors.socialSecurityNumber
                          }
                          disabled={activePeople?.provider}
                        />
                      </div> */}
                      <div className="flex flex-col w-full col-span-full sm:col-span-6 xl:col-span-4 sm:pb-0 pb-5">
                        <label
                          htmlFor="phone"
                          className={`text-sm font-medium text-slate-600 block mb-2 ${
                            validation.errors.phone ? '!text-rose-400' : ''
                          }`}
                        >
                          Mobile Number
                        </label>
                        <PhoneInput
                          placeholder="Enter mobile number"
                          value={validation.values.phone}
                          onChange={(value) =>
                            validation.setFieldValue('phone', value || '')
                          }
                          international
                          defaultCountry={team?.country || ''}
                          id="phone"
                          name="phone"
                          className={`phone_textInputDash ${
                            validation.errors.phone
                              ? 'phone_textInputDashError'
                              : ''
                          } `}
                        />
                        {validation.errors.phone && (
                          <span className=" text-xs text-rose-400">
                            {validation.errors.phone}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col w-full col-span-full sm:col-span-6 xl:col-span-4">
                        <label
                          htmlFor="mainPhone"
                          className={`text-sm font-medium text-slate-600 block mb-2 ${
                            validation.errors.mainPhone ? '!text-rose-400' : ''
                          }`}
                        >
                          Home Phone
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={validation.values.mainPhone}
                          onChange={(value) =>
                            validation.setFieldValue('mainPhone', value || '')
                          }
                          international
                          defaultCountry={team?.country || ''}
                          id="mainPhone"
                          name="mainPhone"
                          className={`phone_textInputDash ${
                            validation.errors.mainPhone
                              ? 'phone_textInputDashError'
                              : ''
                          } `}
                        />
                        {validation.errors.mainPhone && (
                          <span className=" text-xs text-rose-400">
                            {validation.errors.mainPhone}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-span-full md:col-span-4 hidden md:block">
                      <div className="pr-4 flex flex-col gap-2 ">
                        <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                          Address Information:
                        </h3>
                        <p className="text-[#6D7585] text-sm">
                          Provide the individual's address details, including
                          street address, city, state, and postal code. This
                          information is important for mailing, tax
                          documentation, and other location-based requirements.
                        </p>
                      </div>
                    </div>

                    <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                      <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                        Address Information:
                      </h3>
                      {activePeople?.provider !== 'gusto' && (
                        <div className="flex flex-col items-start gap-2.5 w-[100%] col-span-full">
                          <label className="text-sm font-medium text-slate-600 block">
                            Address
                          </label>
                          <AddressBar
                            disabled={activePeople?.provider}
                            validation={validation}
                            values={validation.values.address}
                            streetId="address.street"
                            streetValue={validation.values.address.street}
                            streetError={
                              validation.touched?.address?.street &&
                              validation.errors?.address?.street
                            }
                            street2Id="address.street2"
                            street2Value={validation.values.address.street2}
                            street2Error={
                              validation.touched?.address?.street2 &&
                              validation.errors?.address?.street2
                            }
                            cityId="address.city"
                            cityValue={validation.values.address.city}
                            cityError={
                              validation.touched?.address?.city &&
                              validation.errors?.address?.city
                            }
                            stateId="address.state"
                            stateValue={validation.values.address.state}
                            stateError={
                              validation.touched?.address?.state &&
                              validation.errors?.address?.state
                            }
                            zipCodeId="address.zipCode"
                            zipCodeValue={validation.values.address.zipCode}
                            zipCodeError={
                              validation.touched?.address?.zipCode &&
                              validation.errors?.address?.zipCode
                            }
                            countryId="address.country"
                            countryValue={validation.values.address.country}
                            countryError={
                              validation.touched?.address?.country &&
                              validation.errors?.address?.country
                            }
                            smallView
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-span-full md:col-span-4 md:flex hidden">
                      <div className="h-fit pr-4 flex flex-col gap-2  ">
                        <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                          Worker Type <span className="text-rose-500">*</span>
                        </h3>
                        <p className="text-[#6D7585] text-sm">
                          Select the role that best describes the individual's
                          relationship with the business. This helps categorize
                          the person for more efficient record-keeping and
                          management.
                        </p>
                      </div>
                    </div>

                    <div className="col-span-full md:col-span-8">
                      <h3 className="mb-4 md:hidden text-[18px] font-medium text-[#101828] leading-tight">
                        Worker Type <span className="text-rose-500">*</span>
                      </h3>
                      <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                        {PEOPLE_TYPES.map((peopleType) => (
                          <div
                            key={peopleType?.id}
                            className={`relative border border-[#D0D5DD] rounded-[8px] p-4 cursor-pointer ${validation.values.type === peopleType?.id ? '!bg-[#FCF1E9] !border-indigo-500' : ''}`}
                            role="button"
                            tabIndex="0"
                            onClick={() => {
                              if (!activePeople?.provider) {
                                validation.setFieldValue(
                                  'type',
                                  peopleType?.id,
                                );
                              }
                            }}
                            disabled={activePeople?.provider}
                          >
                            <input
                              type="radio"
                              className="absolute top-2 right-2 form-radio checked:!bg-indigo-500  disabled:!border-indigo-300"
                              checked={
                                validation.values.type === peopleType?.id
                              }
                              disabled={activePeople?.provider}
                            />
                            <h3 className="text-sm font-medium text-[#6D7585] mb-2">
                              {peopleType?.name}
                            </h3>
                            <p className="text-xs text-[#667085]">
                              {peopleType?.details}
                            </p>
                          </div>
                        ))}
                        {validation.touched.type && validation.errors.type && (
                          <div className=" mt-1 text-xs text-rose-400">
                            {validation.touched.type && validation.errors.type}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              className={`grid grid-cols-12 gap-4 w-full mt-10 ${isModalView ? 'px-8' : ''}`}
            >
              <div className="col-span-full md:col-span-4 md:flex hidden">
                <div className="h-fit pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Compensation:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Provide information about the person's job title and how
                    they are compensated. Select the pay type (e.g., hourly,
                    salary) and enter their wage details to ensure proper
                    payroll reporting.
                  </p>
                </div>
              </div>
              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Compensation:
                </h3>
                {activePeople?.provider !== 'gusto' && (
                  <div className="col-span-full grid grid-cols-12 gap-4">
                    <div className="col-span-full sm:col-span-6">
                      <TextInputDash
                        label="Position"
                        id="position"
                        name="position"
                        placeholder="Position / Title"
                        value={validation.values.position || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        error={
                          validation.touched.position &&
                          validation.errors.position
                        }
                        disabled={activePeople?.provider}
                      />
                    </div>
                  </div>
                )}
                <div className="col-span-full sm:col-span-6 pb-5">
                  <label
                    className={`block text-sm font-medium mb-2 ${
                      validation.touched.paymentUnit &&
                      validation.errors.paymentUnit
                        ? 'text-rose-500 '
                        : ''
                    } `}
                    htmlFor="currency"
                  >
                    Pay type
                  </label>
                  <DropdownFull
                    options={[
                      {
                        id: 'Hour',
                        name: 'Hourly',
                      },
                      {
                        id: 'Year',
                        name: 'Yearly',
                      },
                    ]}
                    scrollIntoView={false}
                    name="name"
                    setSelected={(id) =>
                      validation.setFieldValue('paymentUnit', id)
                    }
                    selected={validation.values.paymentUnit}
                    error={
                      validation.touched.paymentUnit &&
                      validation.errors.paymentUnit
                    }
                    accountStyle
                    className="!h-fit"
                    // disabled={activePeople?.provider}
                  />
                </div>
                <div className="min-h-[60px] flex flex-col items-start relative col-span-full sm:col-span-6 pb-5">
                  <label
                    className={`block text-sm font-medium mb-2 ${
                      validation.touched.rate && validation.errors.rate
                        ? 'text-rose-500 '
                        : ''
                    } `}
                    htmlFor="currency"
                  >
                    Wage{' '}
                    {/* {validation.values.paymentUnit && (
                      <span className="text-rose-500">*</span>
                    )} */}
                  </label>
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="rate"
                      name="rate"
                      // required
                      placeholder="Wage"
                      className={` form-input w-full pl-12 pr-10 h-12  ${
                        validation.touched.rate && validation.errors.rate
                          ? '!border-rose-500'
                          : ''
                      }`}
                      value={validation.values.rate || ''}
                      onChange={validation.handleChange}
                      onBlur={(e) => {
                        validation.handleBlur(e);
                        if (
                          !hasUpToTwoDecimalPlaces(
                            parseFloat(validation.values.rate || 0),
                          )
                        ) {
                          validation.setFieldValue(
                            'rate',
                            roundToTwo(validation.values.rate),
                          );
                        }
                      }}
                      min={0}
                    />
                    <span className="absolute top-[50%] -translate-y-[50%] left-4 flex items-center gap-2">
                      <span className="text-slate-600">
                        {getCurrencySymbolFromIso(
                          teamDefaultCurrency,
                          currencies,
                        )}
                      </span>
                      <div className="w-[0.038rem] h-[1.188rem] bg-slate-600" />
                    </span>
                    <span className="absolute top-[50%] -translate-y-[50%]  right-2 flex items-center gap-2 font-medium">
                      <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                      {validation.values.paymentUnit === 'Hour' ? 'hr' : 'yr'}
                    </span>
                  </div>
                  {validation.touched.rate && validation.errors.rate && (
                    <p className="absolute -bottom-[2px] whitespace-nowrap  text-xs text-rose-400">
                      {validation.errors.rate}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* validation.values.type === 'Contractor' && */}
            {team?.country === 'US' && (
              <div
                className={`grid grid-cols-12 gap-4 w-full mt-10 ${isModalView ? 'px-8' : ''}`}
              >
                <div className="col-span-full md:col-span-4 md:flex hidden">
                  <div className="h-fit pr-4 flex flex-col gap-2 ">
                    <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                      Tax Information:
                    </h3>
                    <p className="text-[#6D7585] text-sm">
                      Provide the necessary tax details for the individual,
                      including their tax identification number (e.g., Social
                      Security Number or Employer Identification Number). This
                      ensures compliance with tax regulations and accurate
                      reporting for payroll and other financial records.{' '}
                    </p>
                  </div>
                </div>
                <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                  <h3 className="md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                    Tax Information:
                  </h3>
                  <div className="col-span-full grid grid-cols-12 gap-4">
                    <div className="col-span-full sm:col-span-6">
                      <TextInputDash
                        label="Name on Income Tax Return"
                        id="legalName"
                        name="legalName"
                        placeholder="Legal Name"
                        value={validation.values.legalName || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        error={
                          validation.touched.legalName &&
                          validation.errors.legalName
                        }
                      />
                    </div>
                  </div>
                  <div className="col-span-full sm:col-span-6">
                    <TextInputDash
                      label="Business or Disregarded Entity Name"
                      id="businessName"
                      name="businessName"
                      placeholder="Business Name"
                      value={validation.values.businessName || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      error={
                        validation.touched.businessName &&
                        validation.errors.businessName
                      }
                    />
                  </div>

                  <div className="col-span-full sm:col-span-6">
                    <div>
                      <label
                        className={`block text-sm font-medium mb-2 `}
                        htmlFor="taxType"
                      >
                        Tax Number
                      </label>
                    </div>
                    <div className="flex items-start">
                      <DropdownFull
                        options={[
                          {
                            id: 'none',
                            name: 'None',
                          },
                          {
                            id: 'ssn',
                            name: 'SSN',
                          },
                          {
                            id: 'ein',
                            name: 'EIN',
                          },
                          {
                            id: 'itin',
                            name: 'ITIN',
                          },
                          {
                            id: 'atin',
                            name: 'ATIN',
                          },
                        ]}
                        scrollIntoView={false}
                        name="name"
                        setSelected={(id) =>
                          validation.setFieldValue('taxType', id)
                        }
                        selected={validation.values.taxType}
                        error={
                          validation.touched.taxType &&
                          validation.errors.taxType
                        }
                        accountStyle
                        className="!h-fit border-t-r-0   !w-[100px] !border-r-0 	!rounded-tr-none !rounded-br-none"
                        height="!px-2 !border-r-0 !rounded-tr-0 !rounded-r-none"
                        // disabled={activePeople?.provider}
                        noMinW
                      />
                      <TextInputDash
                        // label="Tax Number"
                        id="taxNumber"
                        name="taxNumber"
                        placeholder="Tax Number"
                        value={validation.values.taxNumber || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        inputClass="!rounded-tl-none !rounded-bl-none"
                        error={
                          validation.touched.taxNumber &&
                          validation.errors.taxNumber
                        }
                      />
                    </div>
                  </div>
                  <div className="col-span-full sm:col-span-6">
                    <TextInputDash
                      label="Federal Tax Classification"
                      id="federalTaxClasiffication"
                      name="federalTaxClasiffication"
                      placeholder="Federal Tax Classification"
                      value={validation.values.federalTaxClasiffication || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      error={
                        validation.touched.federalTaxClasiffication &&
                        validation.errors.federalTaxClasiffication
                      }
                    />
                  </div>
                  <div className="col-span-full sm:col-span-6">
                    <label className="pt-1 sm:pt-10 cursor-pointer text-base font-medium text-nowrap inline-flex justify-center items-center">
                      <input
                        type="checkbox"
                        checked={validation.values.addToContractorGroup}
                        className="form-checkbox mr-2 h-5 w-5"
                        onChange={() =>
                          validation.setFieldValue(
                            'addToContractorGroup',
                            !validation.values.addToContractorGroup,
                          )
                        }
                      />
                      Add to 1099 group
                    </label>
                  </div>
                </div>
              </div>
            )}
            {subscriptions?.id && (
              <div
                className={`border-[#D0D5DD] mt-10 pb-5  ${
                  isModalView ? 'm-4 mx-8' : ''
                }`}
              >
                {activePeople?.seatStatus !== 'confirmed' &&
                  activePeople?.seatStatus !== 'confiriming' && (
                    <>
                      <h2 className="font-medium text-[#101828] mb-2 text-xl">
                        Unlock powerful management features for employees and
                        contractors
                      </h2>
                      <p className=" text-[#6D7585] mb-4">
                        For just{' '}
                        {getCurrencySymbol(
                          subscriptions?.prices?.[0]?.currency,
                        )}
                        {parseFloat(
                          subscriptions?.prices?.[0]?.unit_amount / 100,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        /per person per month, you can streamline your business
                        operations with Payroll, Time Tracking, Expense Claim
                        Reporting, and Sales Representative, all accessible
                        through our intuitive employee mobile app.
                      </p>
                    </>
                  )}
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                  <FormBox
                    checked={role?.includes('time tracking')}
                    objKey="time tracking"
                    onRoleChange={onRoleChange}
                    title="Time Tracking"
                    details="Allow this employee to clock in/out and track their hours effortlessly through the COUNT mobile app, providing clear visibility into work hours."
                  />
                  <FormBox
                    checked={role?.includes('sales rep')}
                    onRoleChange={onRoleChange}
                    title="Sales Representative"
                    objKey="sales rep"
                    details="Assign this person as a sales rep to customers and invoices, track their performance, and generate insightful reports to compare and optimize your sales team's effectiveness."
                  />

                  <FormBox
                    checked={role?.includes('expense reporting')}
                    onRoleChange={onRoleChange}
                    title="Expense Claim Reporting"
                    objKey="expense reporting"
                    expenseModal={expenseModal}
                    setExpenseModal={setExpenseModal}
                    hideAccounts={preSelectExpenseReport}
                    enabledExpenseReporting={team?.featureExpenseReport}
                    getAccountNameById={getAccountNameById}
                    peopleSelectedExpAcc={peopleSelectedExpAcc}
                    details="Simplify expense management and streamline the reimbursement process by enabling this person to upload receipts and submit expenses for approval directly through the COUNT mobile app."
                  />
                </div>
              </div>
            )}
            {/* {!subscription?.default_payment_method && role?.length > 0 && ( */}
            {!subscription?.default_payment_method &&
              !team?.hasPaymentMethod &&
              role?.length > 0 && (
                <div className={`mt-4 ${isModalView ? 'px-4' : ''}`}>
                  <AlertBox
                    type="warning"
                    heading="Connect Payment Method"
                    text="Please connect a payment method to enable assigning roles to people"
                    hideActionButton={
                      subscription?.default_payment_method ||
                      team?.hasPaymentMethod
                    }
                    actionButtonText="Connect Payment Method"
                    onActionButton={onConnectPaymentMethod}
                    actionLoading={paymentMethodLoader}
                  />
                </div>
              )}

            {validation.values.type === 'Contractor' &&
              team?.country === 'US' && (
                <WarningBox
                  className={`max-w-full mt-5 ${isModalView ? 'mx-8' : ''}`}
                  isInfo
                  content="When selecting 'Contractor' as the type, a Vendor record will automatically be created/updated for this person, enabling you to associate transactions and bills directly with them."
                />
              )}

            {/* Close/Add Buttons */}
            <div
              className={`w-full flex justify-end items-center gap-4 mt-[1.875rem] ${
                isModalView ? 'p-4 pb-8' : 'pb-4'
              }`}
            >
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-slate-600 w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();
                  if (!isModalView) {
                    setActivePeople(null);
                  }
                  setTitle('People & Contractors');
                  setFormOpen(false);
                  setRole([]);
                }}
                type="button"
              >
                {activePeople ? 'Cancel' : 'Close'}
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-fit h-11 text-white leading-6
                        disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={
                  loading ||
                  (!subscription?.default_payment_method &&
                    !team?.hasPaymentMethod &&
                    role?.length > 0) ||
                  (peopleSelectedExpAcc?.length === 0 &&
                    role?.includes('expense reporting') &&
                    !preSelectExpenseReport)
                }
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {`${activePeople ? `Update` : `Add`} ${
                  (!preSelectExpenseReport || role?.length > 1) &&
                  (subscription?.default_payment_method ||
                    team?.hasPaymentMethod) &&
                  activePeople?.seatStatus !== 'confirmed' &&
                  activePeople?.seatStatus !== 'confiriming' &&
                  role?.length > 0
                    ? `& Pay ${getCurrencySymbol(subscriptions?.prices?.[0]?.currency)}${parseFloat(
                        subscriptions?.prices?.[0]?.unit_amount / 100,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}/month`
                    : ''
                }`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PeopleForm;
