import React, { useState } from 'react';
import { TaskList } from 'pages/accountantFirm/TaskLists';
import TaskListTasksTable from 'pages/inbox/TaskLists/TaskListTasksTable';
import AddTaskModal from 'pages/inbox/AddTaskModal';
import { FIRM_ID, TaskStatusOptions } from 'utils/Utils';
import TaskListsKanban from 'pages/inbox/TaskLists/TaskListsKanban';
import AddTaskListModal from 'pages/inbox/TaskLists/AddTaskListModal';
import TaskListDeleteModal from 'pages/inbox/TaskLists/TaskListDeleteModal';
import { deleteTaskListById } from 'API/backend_helper';
import { toast } from 'react-toastify';
import TasksListView from 'pages/inbox/TasksListView';
import InlineTaskModal from 'pages/inbox/TaskLists/InlineTaskModal';

const TasksTab = ({
  taskLists,
  loadTasks,
  clients,
  accountantFirm,
  firmMembers,
  ids = [],
  statusDropdownOpen,
  setStatusDropdownOpen,
  findTaskType,

  setSelectedTaskList,
  handleUpdateTask,
  defaultAccountingWS,
  accountingFirmId,
  toggleDropdown,
  setSelectedTask,
  setDeleteModalOpen,
  setFlyoutOpen,
  taskListLoading = false,
  clientDetails = {},
  handleTaskListShift,
  selectedTaskList,
  tasks,
  tableLoading,
  tasksView,
  setTasksView,
  TASKS_VIEWS,
  tasksSortValues,
  setTasksSortValues,
}) => {
  const FirmId = localStorage.getItem(FIRM_ID);

  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const [addTaskListModalOpen, setAddTaskListModalOpen] = useState(false);
  const [deleteTaskListModal, setDeleteTaskListModal] = useState(false);
  const [deleteTaskListLoading, setDeleteTaskListLoading] = useState(false);
  const [activeTaskList, setActiveTaskList] = useState(null);

  const handleDelete = async () => {
    setDeleteTaskListLoading(true);
    try {
      await deleteTaskListById(activeTaskList?.id);
      await loadTasks({});
      setDeleteTaskListModal(false);
      if (setActiveTaskList) {
        setActiveTaskList(null);
      }
      toast.success('Task list deleted successfully');
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteTaskListLoading(false);
    }
  };

  return (
    <div className="w-full items-start flex flex-col overflow-x-auto overflow-y-visible pb-12">
      <TaskListDeleteModal
        isOpen={deleteTaskListModal}
        setIsOpen={setDeleteTaskListModal}
        handleDeleteAction={handleDelete}
        deleteTaskListLoading={deleteTaskListLoading}
      />
      <AddTaskListModal
        modalOpen={addTaskListModalOpen}
        setModalOpen={setAddTaskListModalOpen}
        clients={[clientDetails]}
        accountantWS={{}}
        showAccountantProjects
        showAccountantCustomers
        loadData={loadTasks}
        activeTaskList={
          activeTaskList?.teamId
            ? activeTaskList
            : {
                teamId: clientDetails?.id,
              }
        }
        setActiveTaskList={setActiveTaskList}
      />
      <AddTaskModal
        modalOpen={addTaskModalOpen}
        setModalOpen={setAddTaskModalOpen}
        loadData={loadTasks}
        projects={[]}
        statusOptions={TaskStatusOptions}
        showClients
        clients={[clientDetails]}
        clientId={clientDetails?.id}
        accountantTask
        accountingFirmId={accountantFirm?.id}
        showAccountantProjects
        firmMembers={firmMembers}
        defaultAccountingWS={accountantFirm?.workspace?.id}
        taskLists={
          taskLists
            ?.filter((t) => t?.teamId)
            ?.map((taskList) => ({
              name: taskList?.name,
              id: taskList?.id,
              teamId: taskList?.teamId,
              projectId: taskList?.projectId,
            })) || []
        }
        showTaskList
        taskListId={selectedTaskList?.id}
      />
      <div className="flex justify-between items-center w-full mb-6">
        <div className="flex gap-2.5">
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.group ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.group);
            }}
          >
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${tasksView === TASKS_VIEWS.group ? 'text-indigo-500 fill-indigo-500' : 'text-[#667085] fill-[#667085]'}`}
            >
              <path
                d="M14.25 7.875H1.75C1.41848 7.875 1.10054 8.0067 0.866116 8.24112C0.631696 8.47554 0.5 8.79348 0.5 9.125V12.25C0.5 12.5815 0.631696 12.8995 0.866116 13.1339C1.10054 13.3683 1.41848 13.5 1.75 13.5H14.25C14.5815 13.5 14.8995 13.3683 15.1339 13.1339C15.3683 12.8995 15.5 12.5815 15.5 12.25V9.125C15.5 8.79348 15.3683 8.47554 15.1339 8.24112C14.8995 8.0067 14.5815 7.875 14.25 7.875ZM14.25 12.25H1.75V9.125H14.25V12.25ZM14.25 1H1.75C1.41848 1 1.10054 1.1317 0.866116 1.36612C0.631696 1.60054 0.5 1.91848 0.5 2.25V5.375C0.5 5.70652 0.631696 6.02446 0.866116 6.25888C1.10054 6.4933 1.41848 6.625 1.75 6.625H14.25C14.5815 6.625 14.8995 6.4933 15.1339 6.25888C15.3683 6.02446 15.5 5.70652 15.5 5.375V2.25C15.5 1.91848 15.3683 1.60054 15.1339 1.36612C14.8995 1.1317 14.5815 1 14.25 1ZM14.25 5.375H1.75V2.25H14.25V5.375Z"
                stroke="white"
                strokeWidth="0.5"
                className="fill-current text-current"
              />
            </svg>

            <span> List View </span>
          </button>
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.lists ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.lists);
            }}
          >
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${tasksView === TASKS_VIEWS.lists ? 'text-indigo-500 fill-indigo-500' : 'text-[#667085] fill-[#667085]'}`}
            >
              <path
                d="M16.125 1H1.125C0.95924 1 0.800268 1.06585 0.683058 1.18306C0.565848 1.30027 0.5 1.45924 0.5 1.625V12.25C0.5 12.5815 0.631696 12.8995 0.866116 13.1339C1.10054 13.3683 1.41848 13.5 1.75 13.5H15.5C15.8315 13.5 16.1495 13.3683 16.3839 13.1339C16.6183 12.8995 16.75 12.5815 16.75 12.25V1.625C16.75 1.45924 16.6842 1.30027 16.5669 1.18306C16.4497 1.06585 16.2908 1 16.125 1ZM1.75 6H4.875V8.5H1.75V6ZM6.125 6H15.5V8.5H6.125V6ZM15.5 2.25V4.75H1.75V2.25H15.5ZM1.75 9.75H4.875V12.25H1.75V9.75ZM15.5 12.25H6.125V9.75H15.5V12.25Z"
                fill="#667085"
                stroke="white"
                strokeWidth="0.5"
                className="fill-current text-current"
              />
            </svg>

            <span> Table View </span>
          </button>
          <button
            type="button"
            className={`rounded h-10 px-2.5 inline-flex gap-1.5 items-center text-sm text-[#667085] font-normal ${tasksView === TASKS_VIEWS.kanban ? 'bg-[#FCF1E9]' : ''}`}
            onClick={() => {
              setTasksView(TASKS_VIEWS.kanban);
            }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${tasksView === TASKS_VIEWS.kanban ? 'text-indigo-500 fill-indigo-500' : 'text-[#667085] fill-[#667085]'}`}
            >
              <path
                d="M5.5 0.375H1.75C1.41848 0.375 1.10054 0.506696 0.866116 0.741116C0.631696 0.975537 0.5 1.29348 0.5 1.625V5.375C0.5 5.70652 0.631696 6.02446 0.866116 6.25888C1.10054 6.4933 1.41848 6.625 1.75 6.625H5.5C5.83152 6.625 6.14946 6.4933 6.38388 6.25888C6.6183 6.02446 6.75 5.70652 6.75 5.375V1.625C6.75 1.29348 6.6183 0.975537 6.38388 0.741116C6.14946 0.506696 5.83152 0.375 5.5 0.375ZM5.5 5.375H1.75V1.625H5.5V5.375ZM13 0.375H9.25C8.91848 0.375 8.60054 0.506696 8.36612 0.741116C8.1317 0.975537 8 1.29348 8 1.625V5.375C8 5.70652 8.1317 6.02446 8.36612 6.25888C8.60054 6.4933 8.91848 6.625 9.25 6.625H13C13.3315 6.625 13.6495 6.4933 13.8839 6.25888C14.1183 6.02446 14.25 5.70652 14.25 5.375V1.625C14.25 1.29348 14.1183 0.975537 13.8839 0.741116C13.6495 0.506696 13.3315 0.375 13 0.375ZM13 5.375H9.25V1.625H13V5.375ZM5.5 7.875H1.75C1.41848 7.875 1.10054 8.0067 0.866116 8.24112C0.631696 8.47554 0.5 8.79348 0.5 9.125V12.875C0.5 13.2065 0.631696 13.5245 0.866116 13.7589C1.10054 13.9933 1.41848 14.125 1.75 14.125H5.5C5.83152 14.125 6.14946 13.9933 6.38388 13.7589C6.6183 13.5245 6.75 13.2065 6.75 12.875V9.125C6.75 8.79348 6.6183 8.47554 6.38388 8.24112C6.14946 8.0067 5.83152 7.875 5.5 7.875ZM5.5 12.875H1.75V9.125H5.5V12.875ZM13 7.875H9.25C8.91848 7.875 8.60054 8.0067 8.36612 8.24112C8.1317 8.47554 8 8.79348 8 9.125V12.875C8 13.2065 8.1317 13.5245 8.36612 13.7589C8.60054 13.9933 8.91848 14.125 9.25 14.125H13C13.3315 14.125 13.6495 13.9933 13.8839 13.7589C14.1183 13.5245 14.25 13.2065 14.25 12.875V9.125C14.25 8.79348 14.1183 8.47554 13.8839 8.24112C13.6495 8.0067 13.3315 7.875 13 7.875ZM13 12.875H9.25V9.125H13V12.875Z"
                fill="#667085"
                stroke="#FCF1E9"
                strokeWidth="0.5"
                className="fill-current text-current"
              />
            </svg>

            <span> Kanban View </span>
          </button>
        </div>
        <div className="flex flex-wrap gap-4 ml-auto">
          <button
            className="ml-auto bg-indigo-500 text-white h-11 w-[150px] font-normal flex items-center justify-center gap-1 rounded-[0.313rem] shadow-sm whitespace-nowrap"
            onClick={() => {
              setSelectedTaskList({});
              setAddTaskModalOpen(true);
            }}
            type="button"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5013 4.16699V15.8337M4.66797 10.0003H16.3346"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add Task
          </button>
          <button
            className="ml-auto bg-white border border-indigo-500 text-indigo-500 h-11 w-[150px] font-normal flex items-center justify-center gap-1 rounded-[0.313rem] shadow-sm whitespace-nowrap"
            onClick={() => setAddTaskListModalOpen(true)}
            type="button"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-current stroke-current"
            >
              <path
                d="M10.5013 4.16699V15.8337M4.66797 10.0003H16.3346"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="stroke-current"
              />
            </svg>
            Add Task List
          </button>
        </div>
      </div>
      <div
        className="w-full overflow-x-auto scrollbar overflow-y-visible pb-4"
        id="clientDetailsTasksContainer"
      >
        {taskLists?.length === 0 || !(tasks?.tasks?.length > 0) ? (
          <div className="flex w-full items-center justify-center flex-col mt-10">
            <p className="text-slate-600 text-[20px] text-center">
              {tasksView === TASKS_VIEWS.lists
                ? 'No tasks found'
                : 'No task lists found'}
            </p>
          </div>
        ) : tasksView === TASKS_VIEWS.lists && tasks?.tasks?.length > 0 ? (
          <TasksListView
            tasks={tasks}
            clients={clients}
            findTaskType={findTaskType}
            priorityLoading={false}
            // ids={[`taskListTasks-${taskList?.id}`, ...ids]}
            handleUpdateTask={handleUpdateTask}
            showAccountantAssignee
            assigneeLoading={false}
            defaultAccountingWS={defaultAccountingWS}
            accountingFirmId={accountingFirmId}
            team={{
              users: firmMembers,
            }}
            statusLoading={false}
            statusOptions={TaskStatusOptions}
            statusDropdownOpen={statusDropdownOpen}
            setStatusDropdownOpen={setStatusDropdownOpen}
            toggleDropdown={toggleDropdown}
            setSelectedTask={setSelectedTask}
            setDeleteModalOpen={setDeleteModalOpen}
            // tableId={`taskListTasks-${taskList?.id}`}
            setFlyoutOpen={setFlyoutOpen}
            // taskListId={taskList?.id}
            loading={tableLoading}
            loadTasks={loadTasks}
            tableSortValues={tasksSortValues}
            setTableSortValues={setTasksSortValues}
          />
        ) : tasksView === TASKS_VIEWS.group ? (
          <div className="space-y-6 w-full">
            {taskLists?.map((taskList) => (
              <TaskList
                taskList={taskList}
                key={taskList?.id}
                setAddTaskModalOpen={setAddTaskModalOpen}
                setSelectedTaskList={setSelectedTaskList}
                setActiveTaskList={setActiveTaskList}
                setAddTaskListModalOpen={setAddTaskListModalOpen}
                accountantWS={accountantFirm?.workspace}
                clients={clients}
                handleActionDropdown={(id) => {
                  if (id === 'edit') {
                    setActiveTaskList(taskList);
                    setAddTaskListModalOpen(true);
                  } else if (id === 'delete') {
                    setActiveTaskList(taskList);
                    setDeleteTaskListModal(true);
                  }
                }}
                Options={[
                  {
                    id: 'edit',
                    period: 'Edit Task List',
                  },
                  {
                    id: 'delete',
                    period: 'Delete Task List',
                  },
                ]}
              >
                {taskList?.tasks?.length > 0 ? (
                  <div>
                    <TaskListTasksTable
                      tasks={taskList?.tasks}
                      clients={clients}
                      findTaskType={findTaskType}
                      priorityLoading={false}
                      ids={[`taskListTasks-${taskList?.id}`, ...ids]}
                      handleUpdateTask={handleUpdateTask}
                      showAccountantAssignee
                      assigneeLoading={false}
                      defaultAccountingWS={defaultAccountingWS}
                      accountingFirmId={accountingFirmId}
                      team={{
                        users: firmMembers,
                      }}
                      statusLoading={false}
                      statusOptions={TaskStatusOptions}
                      statusDropdownOpen={statusDropdownOpen}
                      setStatusDropdownOpen={setStatusDropdownOpen}
                      toggleDropdown={toggleDropdown}
                      setSelectedTask={setSelectedTask}
                      setDeleteModalOpen={setDeleteModalOpen}
                      tableId={`taskListTasks-${taskList?.id}`}
                      setFlyoutOpen={setFlyoutOpen}
                      taskListId={taskList?.id}
                      loading={taskListLoading}
                    />
                    <InlineTaskModal
                      buttonContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      formContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      fieldsContainerClass="grid sm:grid-cols-2 xl:grid-cols-4 gap-4"
                      buttonsContainerClass="col-span-2 lg:col-span-2 xl:col-span-4"
                      taskList={taskList}
                      firmMembers={firmMembers}
                      ids={ids}
                      loadData={loadTasks}
                      accountingFirmId={FirmId}
                      defaultAccountingWS={defaultAccountingWS}
                      clients={[clientDetails]}
                    />
                  </div>
                ) : (
                  <div className="!mb-8 flex flex-col items-center justify-center">
                    <h3 className="text-[#667085] text-base text-center mb-5">
                      {' '}
                      No task has been added yet.
                    </h3>
                    <InlineTaskModal
                      buttonContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      formContainerClass="py-4 border-t border-[#D0D5DD] w-full"
                      fieldsContainerClass="grid sm:grid-cols-2 lg:grid-cols-5 gap-4"
                      buttonsContainerClass="lg:col-span-2"
                      taskList={taskList}
                      firmMembers={firmMembers}
                      ids={ids}
                      loadData={loadTasks}
                      accountingFirmId={FirmId}
                      defaultAccountingWS={defaultAccountingWS}
                      clients={[clientDetails]}
                    />
                  </div>
                )}
              </TaskList>
            ))}
          </div>
        ) : tasksView === TASKS_VIEWS.kanban ? (
          <TaskListsKanban
            taskLists={taskLists}
            clients={clients}
            ids={['clientDetailsTasksContainer', ...ids]}
            handleTaskListShift={handleTaskListShift}
            setSelectedTaskList={setSelectedTaskList}
            setAddTaskModalOpen={setAddTaskModalOpen}
            setSelectedTask={setSelectedTask}
            setFlyoutOpen={setFlyoutOpen}
            isLoading={taskListLoading}
            accountantWS={accountantFirm?.workspace}
            handleActionDropdown={(id, taskList) => {
              if (id === 'edit') {
                setActiveTaskList(taskList);
                setAddTaskListModalOpen(true);
              } else if (id === 'delete') {
                setActiveTaskList(taskList);
                setDeleteTaskListModal(true);
              }
            }}
            onAddTaskList={(e) => {
              e.stopPropagation();
              setAddTaskListModalOpen(true);
            }}
            firmMembers={firmMembers}
            loadTasks={loadTasks}
            accountingFirmId={FirmId}
            defaultAccountingWS={defaultAccountingWS}
            addTasksClients={[clientDetails]}
            Options={[
              {
                id: 'edit',
                period: 'Edit Task List',
              },
              {
                id: 'delete',
                period: 'Delete Task List',
              },
            ]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TasksTab;
