import { MantineProvider } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import React from 'react';

const ReusableTable = ({ ...res }) => (
  <MantineProvider
    theme={{
      fontFamily: 'Outfit, sans-serif',
      colors: {
        indigo: [
          '#FFF4E6',
          '#FFE8CC',
          '#FFD8A8',
          '#FFC078',
          '#EFB68E',
          '#E99E68',
          '#E48642',
          '#e47220',
          '#E8590C',
          '#D9480F',
        ],
      },
      primaryColor: 'indigo',
      components: {},
    }}
  >
    <MantineReactTable
      localization={{
        rowsPerPage: 'Per page',
      }}
      mantinePaperProps={{ className: '!border-none scrollbar' }}
      mantineTableBodyRowProps={{
        sx: {
          transition: 'background-color 0.2s ease',
          '&:hover': {
            backgroundColor: 'rgb(160, 205, 133, 0.05) !important',
          },
          '&:hover td': {
            backgroundColor: 'transparent',
          },
          '&:last-of-type td': {
            borderBottom: 'none !important',
          },
        },
      }}
      mantineTableHeadProps={{
        className: 'shadow-sm z-[6] border-none',
        sx: {
          '& > tr > th': {
            borderBottom: 'none !important',
            color: '#101828 !important',
            fontWeight: '500 !important',
          },
          '& th .mantine-Divider-root': {
            borderWidth: '3px !important',
            borderLeftColor: '#e2e8f0',
          },
        },
      }}
      mantineBottomToolbarProps={{
        sx: {
          '& .mantine-Select-wrapper input': {
            border: '1px solid #D0D5DD',
            outline: 'none',
            borderRadius: '0.375rem',
          },
          '& .mantine-Select-wrapper input:hover': {
            border: '1px solid #94a3b8',
          },
          '& .mantine-Select-wrapper input:focus': {
            outline: 'none',
            boxShadow: 'none',
          },
        },
      }}
      mantineLoadingOverlayProps={{
        zIndex: 7,
      }}
      mantineTableHeadCellProps={{
        sx: {
          '&:last-child .mantine-17fxl7': {
            display: 'none !important',
          },
        },
      }}
      {...res}
    />
  </MantineProvider>
);
export default ReusableTable;
