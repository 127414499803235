import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppLayout from 'components/AppLayout';
import {
  getAccounts,
  getAllBills,
  getChecks,
  getMerchants,
} from 'API/backend_helper';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import { transformAccounts } from 'utils/Utils';

import BillsChoiceModal from './ChecksChoiceModal';

import AddEditCheck from './AddEditCheck';
import ChecksTable from './ChecksTable';

const BILL_CHECK = 'bill check';
const VENDOR_CHECK = 'vendor check';

const Checks = () => {
  const { team } = useSelector((state) => state.Team);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const ModeParam = searchParams.get('mode') || '';
  const BillIdParam = searchParams.get('billId') || '';

  const { accessType, permissions } = useSelector((state) => state.User);

  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [checks, setChecks] = useState({});
  const [tableSortValues, setTableSortValues] = useState({
    sortBy: '',
    order: '',
  });
  const [editCheckData, setEditCheckData] = useState(null);
  const [addCheckChoiceModalOpen, setAddCheckChoiceModalOpen] = useState(false);
  const [checkForm, setCheckForm] = useState(false);
  const [checkType, setCheckType] = useState(null);

  const [allBills, setAllBills] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    if (team?.id && team?.country !== 'US') {
      navigate('/');
    }
    if (team?.id && team?.country === 'US' && dataLoading) {
      setDataLoading(false);
    }
  }, [team]);

  useEffect(() => {
    if (ModeParam && BillIdParam) {
      setCheckType(BILL_CHECK);
      setCheckForm(true);
    }
  }, [ModeParam, BillIdParam]);

  const getAllAccounts = async () => {
    try {
      const accountsRes = await getAccounts();
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );

      setAllAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
    } catch (err) {
      setDataLoading(false);
      console.log('err', err);
    }
  };

  const getMerchantsApi = async () => {
    try {
      const res = await getMerchants({ status: 'active', limit: 100000 });
      setVendors(res?.vendors);
    } catch (e) {
      setDataLoading(false);
      console.log('error', e);
    }
  };

  const getBillsApi = async () => {
    try {
      const unpaidRes = await getAllBills({
        limit: 100000,
        approvalStatus: 'approved',
        status: ['unpaid', 'overdue', 'partial'],
      });
      // const alldRes = await getAllBills({
      //   limit: 100000,
      // });
      setAllBills(unpaidRes?.bills);
      if (team?.id && team?.country === 'US') {
        setDataLoading(false);
      }
      // setBills(unpaidRes?.bills);
    } catch (e) {
      setDataLoading(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    setDataLoading(true);
    getAllAccounts();
    getMerchantsApi();
    getBillsApi();
  }, []);

  const getChecksApi = async ({
    page = checks?.page || 1,
    limit = 50,
    loading = true,
    search = '',
    order = tableSortValues?.order || '',
    sortBy = tableSortValues?.sortBy || '',
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getChecks({
        page,
        limit,
        search,
        orderDirection: order,
        orderBy: sortBy,
      });
      setChecks(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChecksApi({ loading: true });
  }, []);

  document.title = 'COUNT | Print Checks';

  return (
    <>
      <BillsChoiceModal
        modalOpen={addCheckChoiceModalOpen}
        setModalOpen={setAddCheckChoiceModalOpen}
        setCheckForm={setCheckForm}
        BILL_CHECK={BILL_CHECK}
        VENDOR_CHECK={VENDOR_CHECK}
        setCheckType={setCheckType}
      />

      <AppLayout mainClass="relative">
        <main className="grow relative">
          {dataLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-32">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <div className="max-w-9xl px-4 sm:px-6 lg:px-8 py-8 w-full  mx-auto h-full flex flex-col">
              {checkForm ? (
                <AddEditCheck
                  allAccounts={allAccounts}
                  vendors={vendors}
                  setVendors={setVendors}
                  setIsActive={setCheckForm}
                  loadChecks={getChecksApi}
                  editCheckData={editCheckData}
                  setEditCheckData={setEditCheckData}
                  allCategories={allCategories}
                  checkType={checkType}
                  BILL_CHECK={BILL_CHECK}
                  VENDOR_CHECK={VENDOR_CHECK}
                  allBills={allBills}
                  BillIdParam={BillIdParam}
                  setSearchParams={setSearchParams}
                />
              ) : (
                <>
                  {/* Page header */}
                  <div className="flex justify-between items-center mb-10">
                    <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                      Print Checks
                    </h1>
                    <div className="sm:flex gap-4 items-center">
                      {checkPermission(
                        PermissionKeys.MANAGE_EXPENSE_CLAIMS,
                        accessType,
                        permissions,
                      ) && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setAddCheckChoiceModalOpen(true);
                          }}
                          type="button"
                          className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                              stroke="white"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Create a Check
                        </button>
                      )}
                    </div>
                  </div>

                  <ChecksTable
                    checks={checks}
                    looadChecks={getChecksApi}
                    setEditCheckData={(data) => {
                      setEditCheckData(data);
                      setCheckType(
                        data?.payeeType === 'bill' ? BILL_CHECK : VENDOR_CHECK,
                      );
                      setCheckForm(true);
                    }}
                    isLoading={isLoading}
                    setTableSortValues={setTableSortValues}
                    tableSortValues={tableSortValues}
                  />
                </>
              )}
            </div>
          )}
        </main>
      </AppLayout>
    </>
  );
};
export default Checks;
