import React, { useEffect, useState } from 'react';
import {
  ignoreMatchingSingleTransaction,
  onConfirmMatchingTrans,
} from 'API/backend_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReuseableTable from './ReuseableTable';
import InvoiceTable from './InvoiceTable';

const UndepositedTab = ({
  reloadTransaction,
  reloadTransactions,
  transaction,
  setClose,
}) => {
  const { currencies } = useSelector((state) => state.Currencies);

  const [matchedTransaction, setMatchedTransaction] = useState(null);
  const [matchingLoader, setMatchingLoader] = useState(false);
  const [skipLoader, setSkipLoader] = useState(false);

  const onMatchingTransaction = async () => {
    setMatchingLoader(true);
    try {
      await onConfirmMatchingTrans(matchedTransaction);
      // await reloadTransaction({});
      toast.success('Transaction matched successfully');
      setMatchingLoader(false);
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setMatchingLoader(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (transaction?.undepositedTransactions?.length > 0) {
      setMatchedTransaction(transaction?.undepositedTransactions?.[0]?.id);
    }
  }, [transaction?.undepositedTransactions]);

  const onSkip = async () => {
    setSkipLoader(true);
    try {
      await ignoreMatchingSingleTransaction(transaction?.id, {
        matchingType: 'transfer',
      });
      // await reloadTransaction({});
      setSkipLoader(false);
      toast.success('Matching skipped successfully');
      reloadTransactions({ scrollToTop: false });
      setClose(false);
    } catch (e) {
      setSkipLoader(false);
      console.log('error', e);
    }
  };
  return (
    <div>
      <div
        className="flex flex-col px-10"
        style={{
          maxHeight: `calc(100dvh - ${transaction?.undepositedTransfers?.length > 0 ? '308px' : '215px'})`,
          height: `calc(100dvh - ${transaction?.undepositedTransfers?.length > 0 ? '308px' : '215px'})`,
        }}
        id="transactionsCategoryModalUndepositedTab"
      >
        {!(transaction?.undepositedTransfers?.length > 0) ? (
          <div className="text-[18px] text-slate-500 text-center h-full flex items-center justify-center">
            All caught up! There are currently no paid invoices awaiting
            deposit.
          </div>
        ) : (
          <>
            <p className="mb-4">
              Track your undeposited payments. These are invoices that have been
              paid but haven’t yet been matched to a bank deposit. Select the
              invoice this transaction corresponds to in order to complete the
              deposit.
            </p>
            <div className="flex-1 overflow-auto scrollbar mb-6">
              <p className="text-[24px] text-[#101828]">Possible Matches</p>
              {transaction?.undepositedTransactions?.map((trans) => (
                <div className="mb-6 last:mb-0" key={trans?.id}>
                  {trans?.transaction?.assignedToInvoice && (
                    <>
                      <p className="text-[20px] text-[#101828]">Invoice</p>
                      <InvoiceTable
                        invoice={trans?.transaction?.assignedToInvoice}
                        customer={trans?.transaction?.customer}
                        currencies={currencies}
                      />
                    </>
                  )}
                  <p className="text-[20px] text-[#101828]">Transaction(s)</p>
                  <ReuseableTable
                    transaction={trans?.transaction}
                    setMatchedTransaction={setMatchedTransaction}
                    matchedTransaction={matchedTransaction}
                    transId={trans?.id}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {transaction?.undepositedTransfers?.length > 0 && (
        <footer className="px-10 py-6 border-t border-[#D0D5DD]">
          <div className="flex gap-4 justify-between flex-wrap">
            <div className="flex gap-4 ml-auto">
              <button
                type="button"
                onClick={onSkip}
                disabled={matchingLoader || skipLoader}
                className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085] disabled:cursor-not-allowed disabled:opacity-60"
              >
                {skipLoader && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Skip
              </button>
              <button
                type="button"
                disabled={!matchedTransaction || matchingLoader || skipLoader}
                onClick={onMatchingTransaction}
                className="h-11 w-[180px] inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:bg-indigo-300 disabled:cursor-not-allowed"
              >
                {matchingLoader && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Confirm Match
              </button>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default UndepositedTab;
