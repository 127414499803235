import React, { useState } from 'react';
import TaskListActionsDropdown from './TaskListActionsDropdown';

const TaskListTemplate = ({
  taskListTemplate,
  children,
  handleActionDropdown = () => {},
  showActionsDropdown = true,
}) => {
  const [showTable, setShowTable] = useState(true);

  return (
    <>
      <div className="p-4 bg-[#F9FBFC] rounded-[12px]">
        <div className="border border-[#D0D5DD] rounded-[8px] p-4 bg-white flex justify-between items-center flex-wrap gap-4">
          <div className="flex gap-2 items-center">
            <button
              type="button"
              className="h-8"
              onClick={() => setShowTable((prev) => !prev)}
            >
              <svg
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${!showTable ? 'rotate-180' : ''}`}
              >
                <path
                  d="M13.9425 6.55746L7.69254 0.307465C7.63449 0.249354 7.56556 0.203255 7.48969 0.171802C7.41381 0.140349 7.33248 0.124161 7.25035 0.124161C7.16821 0.124161 7.08688 0.140349 7.01101 0.171802C6.93514 0.203255 6.86621 0.249354 6.80816 0.307465L0.55816 6.55746C0.440885 6.67474 0.375 6.8338 0.375 6.99965C0.375 7.1655 0.440885 7.32456 0.55816 7.44184C0.675435 7.55912 0.834495 7.625 1.00035 7.625C1.1662 7.625 1.32526 7.55912 1.44253 7.44184L7.25035 1.63325L13.0582 7.44184C13.1162 7.49991 13.1852 7.54597 13.261 7.5774C13.3369 7.60883 13.4182 7.625 13.5003 7.625C13.5825 7.625 13.6638 7.60883 13.7397 7.5774C13.8155 7.54597 13.8845 7.49991 13.9425 7.44184C14.0006 7.38377 14.0467 7.31483 14.0781 7.23896C14.1095 7.16309 14.1257 7.08177 14.1257 6.99965C14.1257 6.91753 14.1095 6.83621 14.0781 6.76034C14.0467 6.68447 14.0006 6.61553 13.9425 6.55746Z"
                  fill="#667085"
                />
              </svg>
            </button>
            <h3 className="text-[18px] font-medium text-[#667085]">
              {taskListTemplate?.name === 'Uncategorized'
                ? 'No List'
                : taskListTemplate?.name}
            </h3>
            <div className="bg-[#F4F4F4] rounded-[4px] leading-tight py-1 px-2 w-fit text-sm text-[#667085] font-medium">
              {taskListTemplate?.taskTemplates?.length || 0}
            </div>
          </div>
          <div className="ml-auto flex items-center gap-4">
            {/* <button
              type="button"
              className="text-base  h-[30px] rounded-[4px] border border-indigo-500 text-indigo-500 inline-flex gap-1.5 items-center w-[112px] justify-center"
              onClick={() => {
                setSelectedTaskList(taskListTemplate);
                setAddTaskModalOpen(true);
              }}
            >
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.25 1.125V9.875M0.875 5.5H9.625"
                  stroke="#E48642"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Add Task
            </button> */}
            {showActionsDropdown && taskListTemplate?.id && (
              <TaskListActionsDropdown
                handleOption={handleActionDropdown}
                buttonClassName="!h-[30px] !w-[30px]"
                Options={[
                  {
                    id: 'edit',
                    period: 'Edit Template',
                  },
                  {
                    id: 'delete',
                    period: 'Delete Template',
                  },
                  {
                    id: 'create',
                    period: 'Create Task List',
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      {showTable ? children : null}
    </>
  );
};

export default TaskListTemplate;
