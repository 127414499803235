import React from 'react';
import Header from 'partials/Header';
import ModalBasic from 'components/ModalBasic';
import CustomerForm from 'pages/customers/CustomerForm';
import { INVOICE_DUE_DATE_OPTIONS } from 'pages/invoices';
import TaskFlyout from 'pages/inbox/TaskFlyout';
import { TaskStatusOptions } from 'utils/Utils';
import DeleteModal from 'pages/inbox/DeleteModal';
import AttachmentModal from 'pages/inbox/AttachmentModal';
import HeaderAccountant from 'pages/accountantFirm/HeaderAccountant';
import { useNavigate } from 'react-router-dom';
import useClientDetails from './useClientDetails';
import CDHeader from './CDHeader';
import CDCustomerDetails from './CDCustomerDetails';
import Tabs from './Tabs';
import BillingTab from './billingTab';
import ProjectsTab from './projectsTab';
import FilesTab from './filesTab';
import TasksTab from './tasksTab';
import AddNotesModal from './AddNotesModal';
import EncryptionTab from './encryptionTab';

const OVERVIEW_TAB = 'overviewTab';
const CLIENTS_TAB = 'clientsTab';
const TASKS_TAB = 'tasksTab';
const INVITES_TAB = 'invitesTab';
const FIRM_SUBSCRIPTIONS_TAB = 'firmSubscriptionsTab';
const MANAGE_PRACTICE_TAB = 'managePracticeTab';

const ClientDetails = () => {
  const navigate = useNavigate();

  const {
    FirmId,
    sidebarOpen,
    setSidebarOpen,
    loading,
    clientDetails,
    accountantFirm,
    onTeamChange,
    customerModalOpen,
    setCustomerModalOpen,
    handleUpdateCustomer,
    salesRep,
    TABS,
    activeTab,
    setActiveTab,
    onTeamDetailsChange,
    notesModalOpen,
    setNotesModalOpen,
    handleUpdateCustomerNotes,

    // Billing Tab
    invoices,
    invoicesLocalLimit,
    setInvoicesLocalLimit,
    getInvoicesData,
    isInvoicesTabLoaded,
    setIsInvoicesTabLoaded,
    // projects Tab
    projects,
    isProjectsTabLoaded,
    setIsProjectsTabLoaded,
    loadAllProjects,
    handleCreateProject,

    // Files Tab
    loadFolders,
    isFilesTabLoaded,
    setIsFilesTabLoaded,
    folders,
    parentFolder,
    setParentFolder,
    // Tasks Tab
    firmMembers,
    flyoutOpen,
    setFlyoutOpen,
    selectedTask,
    setSelectedTask,
    handleUpdateTask,
    loadTasks,
    statusDropdownOpen,
    setStatusDropdownOpen,
    findTaskType,
    toggleDropdown,
    deleteModalOpen,
    setDeleteModalOpen,
    handleDeleteTask,
    setActiveAttachment,
    handleDeleteAttachment,
    selectedTaskAttachments,
    setSelectedTaskAttachments,
    attachmentsLoading,
    uploadTaskFile,
    attachmentModalOpen,
    setAttachmentModalOpen,
    taskLists,
    handleTaskListShift,
    clients,
    setSelectedTaskList,
    taskListLoading,
    setTaskListLoading,
    activeAttachment,
    selectedTaskList,
    tasks,
    tableLoading,
    tasksView,
    setTasksView,
    TASKS_VIEWS,
    tasksSortValues,
    setTasksSortValues,

    // Encryption Vault
    encryptions,
    encryptionLoader,
    addEditEncryptionLoader,
    deleteEncryptionLoader,
    createEncryptions,
    getEncryptions,
    updateEncryptions,
    deleteEncryptions,
    encryptionsSortValues,
    setEncryptionsSortValues,
  } = useClientDetails();

  return (
    <div className="relative flex h-[100dvh] overflow-hidden">
      {TABS.tasks === activeTab && (
        <>
          <AttachmentModal
            isOpen={attachmentModalOpen}
            setIsOpen={setAttachmentModalOpen}
            receipt={
              activeAttachment !== null
                ? activeAttachment?.fileUrl || activeAttachment?.preview
                : null
            }
            isExpenseReporting={activeAttachment !== null}
            type={
              activeAttachment !== null
                ? activeAttachment?.fileType || activeAttachment?.type
                : ''
            }
            title={
              activeAttachment !== null
                ? activeAttachment?.fileName || activeAttachment?.name
                : ''
            }
            setActiveAttachment={setActiveAttachment}
          />
          <DeleteModal
            isOpen={deleteModalOpen}
            setIsOpen={setDeleteModalOpen}
            handleDone={
              deleteModalOpen === 'task'
                ? handleDeleteTask
                : handleDeleteAttachment
            }
            type={deleteModalOpen}
          />
          <TaskFlyout
            flyoutOpen={flyoutOpen}
            setFlyoutOpen={setFlyoutOpen}
            task={selectedTask}
            setTask={setSelectedTask}
            firmMembers={firmMembers}
            handleUpdateTask={handleUpdateTask}
            loadData={loadTasks}
            findTaskType={findTaskType}
            statusOptions={TaskStatusOptions}
            statusDropdownOpen={statusDropdownOpen}
            setStatusDropdownOpen={setStatusDropdownOpen}
            toggleDropdown={toggleDropdown}
            projects={[]}
            setEditTaskModalOpen={() => {}}
            setDeleteModalOpen={setDeleteModalOpen}
            setActiveAttachment={setActiveAttachment}
            deleteModalOpen={deleteModalOpen}
            selectedTaskAttachments={selectedTaskAttachments}
            setSelectedTaskAttachments={setSelectedTaskAttachments}
            attachmentsLoading={attachmentsLoading}
            uploadTaskAttachment={uploadTaskFile}
            setReceiptModalOpen={setAttachmentModalOpen}
            receiptModalOpen={attachmentModalOpen}
            showAccountantAssignee
            showClient
            showAccountantProjects
            clients={clients}
            accountantFirm
            accountingFirmId={FirmId}
            defaultAccountingWS={accountantFirm?.workspace?.id}
            showTaskLists
            taskLists={taskLists
              ?.filter((t) => t?.teamId)
              ?.map((taskList) => ({
                name: taskList?.name,
                id: taskList?.id,
                teamId: taskList?.teamId,
                projectId: taskList?.projectId,
              }))}
            handleTaskListShift={handleTaskListShift}
          />
        </>
      )}
      {clientDetails?.customerRecord?.[0]?.id && (
        <AddNotesModal
          isOpen={notesModalOpen}
          setIsOpen={setNotesModalOpen}
          notes={clientDetails?.customerRecord?.[0]?.notes}
          handleUpdateCustomerNotes={handleUpdateCustomerNotes}
        />
      )}
      <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        id="accountantClientDetailPage"
      >
        {/* <Header
          showHamburger={false}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        /> */}
        <HeaderAccountant
          activeTab={CLIENTS_TAB}
          setActiveTab={(value) => {
            if (value === OVERVIEW_TAB) {
              navigate('/accountant');
            } else if (value === CLIENTS_TAB) {
              navigate('/accountant/clients');
            } else if (value === TASKS_TAB) {
              navigate('/accountant/tasks');
            } else if (value === INVITES_TAB) {
              navigate('/accountant/invites');
            } else if (value === FIRM_SUBSCRIPTIONS_TAB) {
              navigate('/accountant/subscriptions');
            } else if (value === MANAGE_PRACTICE_TAB) {
              navigate('/accountant/manage');
            }
          }}
          OVERVIEW_TAB={OVERVIEW_TAB}
          CLIENTS_TAB={CLIENTS_TAB}
          TASKS_TAB={TASKS_TAB}
          INVITES_TAB={INVITES_TAB}
          isAccountingFirmFound
          InvitesCount={accountantFirm?.firmInvites?.length}
          FIRM_SUBSCRIPTIONS_TAB={FIRM_SUBSCRIPTIONS_TAB}
          MANAGE_PRACTICE_TAB={MANAGE_PRACTICE_TAB}
        />
        <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <ModalBasic
            modalOpen={customerModalOpen}
            setModalOpen={setCustomerModalOpen}
            title="Edit Client"
            width="!w-[1088px]"
          >
            {accountantFirm?.workspace?.id &&
              clientDetails?.customerRecord?.[0]?.id && (
                <div className="p-6">
                  <CustomerForm
                    activeCustomer={clientDetails?.customerRecord?.[0] || {}}
                    handleUpdateCustomer={handleUpdateCustomer}
                    salesRep={salesRep}
                    modalView
                    setFormOpen={setCustomerModalOpen}
                    INVOICE_PAYMENT_TERMS={INVOICE_DUE_DATE_OPTIONS}
                    isClient
                    clientAccountingFirmWSId={accountantFirm?.workspace?.id}
                  />
                </div>
              )}
          </ModalBasic>
          {loading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : !clientDetails?.id ? (
            <div className="text-xl text-center mt-4">No Client Found</div>
          ) : (
            <>
              <CDHeader
                accountantFirm={accountantFirm}
                clientDetails={clientDetails}
                onTeamChange={onTeamChange}
                onTeamDetailsChange={onTeamDetailsChange}
                setNotesModalOpen={setNotesModalOpen}
              />

              <div className="flex gap-6 mt-6 ">
                <CDCustomerDetails
                  clientDetails={clientDetails}
                  setCustomerModalOpen={setCustomerModalOpen}
                />
                <div
                  className="flex-1"
                  style={{
                    maxWidth: 'calc(100% - 320px)',
                  }}
                >
                  <Tabs
                    TABS={TABS}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {TABS.vault === activeTab && (
                    <EncryptionTab
                      encryptions={encryptions}
                      encryptionLoader={encryptionLoader}
                      addEditEncryptionLoader={addEditEncryptionLoader}
                      deleteEncryptionLoader={deleteEncryptionLoader}
                      createEncryptions={createEncryptions}
                      getEncryptions={getEncryptions}
                      updateEncryptions={updateEncryptions}
                      deleteEncryptions={deleteEncryptions}
                      WSId={accountantFirm?.workspace?.id}
                      clientId={clientDetails?.id}
                      encryptionsSortValues={encryptionsSortValues}
                      setEncryptionsSortValues={setEncryptionsSortValues}
                    />
                  )}

                  {TABS.invoices === activeTab && (
                    <BillingTab
                      customerId={clientDetails?.customerRecord?.[0]?.id}
                      WSId={accountantFirm?.workspace?.id}
                      invoices={invoices}
                      invoicesLocalLimit={invoicesLocalLimit}
                      setInvoicesLocalLimit={setInvoicesLocalLimit}
                      getInvoicesData={getInvoicesData}
                      isInvoicesTabLoaded={isInvoicesTabLoaded}
                      setIsInvoicesTabLoaded={setIsInvoicesTabLoaded}
                      onTeamDetailsChange={onTeamDetailsChange}
                      accountantFirm={accountantFirm}
                    />
                  )}
                  {TABS.projects === activeTab && (
                    <ProjectsTab
                      projects={projects}
                      isProjectsTabLoaded={isProjectsTabLoaded}
                      setIsProjectsTabLoaded={setIsProjectsTabLoaded}
                      loadAllProjects={loadAllProjects}
                      customer={clientDetails?.customerRecord?.[0]}
                      WSId={accountantFirm?.workspace?.id}
                      handleCreateProject={handleCreateProject}
                      accountantFirm={accountantFirm}
                    />
                  )}
                  {TABS.files === activeTab && (
                    <FilesTab
                      loadFolders={loadFolders}
                      isFilesTabLoaded={isFilesTabLoaded}
                      setIsFilesTabLoaded={setIsFilesTabLoaded}
                      folders={folders}
                      clientId={clientDetails?.id}
                      parentFolder={parentFolder}
                      setParentFolder={setParentFolder}
                    />
                  )}
                  {TABS.tasks === activeTab && (
                    <TasksTab
                      accountantFirm={accountantFirm}
                      setSelectedTaskList={setSelectedTaskList}
                      handleUpdateTask={handleUpdateTask}
                      defaultAccountingWS={accountantFirm?.workspace?.id}
                      accountingFirmId={FirmId}
                      toggleDropdown={toggleDropdown}
                      setSelectedTask={setSelectedTask}
                      setDeleteModalOpen={setDeleteModalOpen}
                      setFlyoutOpen={setFlyoutOpen}
                      taskLists={taskLists}
                      findTaskType={findTaskType}
                      clients={clients}
                      loadTasks={loadTasks}
                      tasksSortValues={tasksSortValues}
                      setTasksSortValues={setTasksSortValues}
                      firmMembers={firmMembers}
                      ids={['accountantClientDetailPage']}
                      statusDropdownOpen={statusDropdownOpen}
                      setStatusDropdownOpen={setStatusDropdownOpen}
                      taskListLoading={taskListLoading}
                      setTaskListLoading={setTaskListLoading}
                      clientDetails={clientDetails}
                      handleTaskListShift={handleTaskListShift}
                      selectedTaskList={selectedTaskList}
                      tasks={tasks}
                      tableLoading={tableLoading}
                      tasksView={tasksView}
                      setTasksView={setTasksView}
                      TASKS_VIEWS={TASKS_VIEWS}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default ClientDetails;
