import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { INVOICE_DUE_DATE_OPTIONS } from 'pages/invoices';
import AppLayout from '../../components/AppLayout';
import Tabs from './Tabs';
import Overview from './overview';
import Invoices from './invoices';
import {
  getCustomerById,
  getCustomerOverview,
  getInvoices,
  getPeople,
  getTransactions,
  updateCustomer,
} from '../../API/backend_helper';
import Transactions from './transactions';
import ModalBasic from '../../components/ModalBasic';
import CustomerForm from '../customers/CustomerForm';

const OVERVIEW_TAB = 'overviewTab';
const INVOICES_TAB = 'invoicesTab';
const TRANSACTIONS_TAB = 'transactionsTab';

const CustomerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currencies } = useSelector((state) => state.Currencies);
  const { accessType, permissions } = useSelector((state) => state.User);

  const [customer, setCustomer] = useState({});
  const [mainLoading, setMainLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(OVERVIEW_TAB);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [salesRep, setSalesRep] = useState([]);
  const [overviewData, setOverviewData] = useState({});

  // Invoices Tab
  const [invoices, setInvoices] = useState([]);
  const [invoicesLocalLimit, setInvoicesLocalLimit] = useState(20);
  const [estimates, setEstimates] = useState([]);
  const [estimatesLocalLimit, setEstimatesLocalLimit] = useState(20);
  const [memos, setMemos] = useState([]);
  const [memosLocalLimit, setMemosLocalLimit] = useState(20);
  const [isInvoicesTabLoaded, setIsInvoicesTabLoaded] = useState(false);

  // Transactions
  const [transactions, setTransactions] = useState({});
  const [transactionsLocalLimit, setTransactionLocalLimit] = useState(20);
  const [isTransactionsLoaded, setIsTransactionsLoaded] = useState(false);

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_CUSTOMERS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const loadCustomer = async () => {
    try {
      const res = await getCustomerById(id, '?include=balance');
      setCustomer(res?.data?.customer);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateCustomer = async (data, id) => {
    try {
      await updateCustomer(data, id);
      loadCustomer();
    } catch (err) {
      console.log('err', err);
    }
  };

  const getSalesRepApi = async () => {
    try {
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCustomerOverview = async () => {
    try {
      const res = await getCustomerOverview(id);
      setOverviewData(res?.data?.result);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadMainData = async () => {
    try {
      await Promise.all([loadCustomer(), loadCustomerOverview()]);
    } catch (err) {
      console.log(err);
    } finally {
      setMainLoading(false);
    }
  };

  useEffect(() => {
    loadMainData();
    getSalesRepApi();
  }, []);

  const getInvoicesData = async ({
    type = 'invoice',
    limit = invoicesLocalLimit,
    page = 1,
    customers = [id],
  }) => {
    try {
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
      });
      setInvoices(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getEstimatesData = async ({
    type = 'estimate',
    limit = estimatesLocalLimit,
    page = 1,
    customers = [id],
  }) => {
    try {
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
      });
      setEstimates(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getMemosData = async ({
    type = 'memo',
    limit = memosLocalLimit,
    page = 1,
    customers = [id],
  }) => {
    try {
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
      });
      setMemos(res?.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTransactions = async ({
    limit = transactionsLocalLimit,
    page = transactions?.page,
    customers = [id],
  }) => {
    // if (scrollToTop) {
    //   pageRef?.current.scrollTo({
    //     top: 0,
    //     left: 0,
    //   });
    // }

    try {
      const transRes = await getTransactions({
        limit,
        page,
        customers,
      });
      setTransactions(transRes);
    } catch (e) {
      console.log('error', e);
    }
  };

  const findCurrencySymbol = (isoCode) => {
    const currency = currencies?.find?.(
      (currency) => currency.isoCode === isoCode,
    );
    return currency?.symbol === '$' && currency?.isoCode !== 'USD'
      ? currency.symbol
      : currency?.symbol;
  };

  return (
    <>
      <ModalBasic
        modalOpen={customerModalOpen}
        setModalOpen={setCustomerModalOpen}
        title="Edit Customer"
        width="!w-[1088px]"
      >
        <div className="p-6">
          <CustomerForm
            activeCustomer={customer}
            handleUpdateCustomer={handleUpdateCustomer}
            salesRep={salesRep}
            modalView
            setFormOpen={setCustomerModalOpen}
            INVOICE_PAYMENT_TERMS={INVOICE_DUE_DATE_OPTIONS}
          />
        </div>
      </ModalBasic>
      <AppLayout
        pageId="customerDetailsPageId"
        mainClass="relative"
        isDashboardPage
      >
        <main className="grow ">
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {mainLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <>
                <div className="flex gap-2.5 items-center mb-9">
                  <button
                    className="mr-2"
                    type="button"
                    onClick={() => navigate('/customers')}
                  >
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="32"
                        height="33"
                        transform="translate(33) rotate(90)"
                        fill="#FBF3ED"
                      />
                      <path
                        d="M19 10.5L13 16.5L19 22.5"
                        stroke="#E48642"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <h3 className="text-[36px] font-medium text-[#00000099] leading-tight">
                    {customer?.customer
                      ? customer?.customer
                      : `${customer?.contacts?.[0]?.firstName || ''} ${customer?.contacts?.[0]?.lastName || ''}`}
                  </h3>
                </div>
                {/* Tabs  */}
                <Tabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  OVERVIEW_TAB={OVERVIEW_TAB}
                  INVOICES_TAB={INVOICES_TAB}
                  TRANSACTIONS_TAB={TRANSACTIONS_TAB}
                />
                {activeTab === OVERVIEW_TAB && (
                  <Overview
                    customer={customer}
                    setCustomerModalOpen={setCustomerModalOpen}
                    handleUpdateCustomer={handleUpdateCustomer}
                    overviewData={overviewData}
                    findCurrencySymbol={findCurrencySymbol}
                  />
                )}
                {activeTab === INVOICES_TAB && (
                  <Invoices
                    invoices={invoices}
                    estimates={estimates}
                    memos={memos}
                    invoicesLocalLimit={invoicesLocalLimit}
                    setInvoicesLocalLimit={setInvoicesLocalLimit}
                    estimatesLocalLimit={estimatesLocalLimit}
                    setEstimatesLocalLimit={setEstimatesLocalLimit}
                    memosLocalLimit={memosLocalLimit}
                    setMemosLocalLimit={setMemosLocalLimit}
                    getInvoicesData={getInvoicesData}
                    getEstimatesData={getEstimatesData}
                    getMemosData={getMemosData}
                    isInvoicesTabLoaded={isInvoicesTabLoaded}
                    setIsInvoicesTabLoaded={setIsInvoicesTabLoaded}
                    overviewData={overviewData}
                    findCurrencySymbol={findCurrencySymbol}
                    customer={customer}
                  />
                )}
                {activeTab === TRANSACTIONS_TAB && (
                  <Transactions
                    transactions={transactions}
                    loadTransactions={loadTransactions}
                    transactionsLocalLimit={transactionsLocalLimit}
                    setTransactionLocalLimit={setTransactionLocalLimit}
                    isTransactionsLoaded={isTransactionsLoaded}
                    setIsTransactionsLoaded={setIsTransactionsLoaded}
                    overviewData={overviewData}
                  />
                )}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default CustomerDetails;
